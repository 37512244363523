import { Component, Input, OnInit,Output, EventEmitter } from '@angular/core';
import { DashboardService } from "../../../../services/company/dashboard.service";
import { LanguageModel } from "../../../../models/language.model";
import {DeviceDetectorService} from "ngx-device-detector";
@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})
export class JobListComponent implements OnInit {
  @Input('jobId') jobId: number;
  @Input('applierId') applierId: number;
  jobs: any;
  errorMessage: string;
  applicantDetails: any;
  even = { 'background-color': 'blue' };
  odd = { 'background-color': 'green' };
  ismobiledevice : any;
  isMobile: boolean;
  mobileCurentStatus: any = '';
  @Output() postMessageEvent = new EventEmitter<any>();
  @Input() currentMsgFromChild1ToChild2: any [];
  @Output() msgToSibling = new EventEmitter<any>();
  
  constructor(private dashboardService: DashboardService,private deviceService: DeviceDetectorService) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnChanges() {
    // this.ngOnInit();
  }

  ngOnInit() {
    const urlParams = new URLSearchParams(window.location.search);
    this.ismobiledevice = urlParams.get('mstatus');
    this.dashboardService.getJobProfile(this.jobId).subscribe(
      resData => {
        this.jobs = resData;
      },
      errorMessage => {
        this.errorMessage = errorMessage;
        console.log(this.errorMessage);
      });
  }

  getApplicationStatus(status: number) {
    switch (status) {
      case 1:
        return "Eingang";
        // return "abgestimmt";
      case 2:
        // return 'Prüfung';
        return "Eingang";
      case 3:
        return "Prüfung";
      case 4:
        return "persönliches Interview";
      case 5:
        return "persönliches Interview";
      case 41:
        return "persönliches Interview";
      case 42:
        return "persönliches Interview";
      case 43:
        return "persönliches Interview";
      case 6:
        return 'Entscheidung';
      case 7:
        return 'Entscheidung';
      case 8:
        return 'Bewerber abgesagt';
      case 9:
        return 'Bewerbung zurückgezogen';
      case 11:
        return 'Match abgelehnt';
      case 12:
        return 'beidseitige Zusage';
      default:
        return "N/A";
    }
  }

  getApplicationStatusForProcessBar(status: number) {
    if (status == 2) {
      return 25;
    } else if (status == 3) {
      return 50;
    } else if (status == 4 || status == 5 || status > 40) {
      return 75;
    } else if (status == 6 || status == 7 || status == 12) {
      return 100;
    }
  }

  getApplicationStatusForProcessBarTextDisplay(status: number) {
    if (status == 2) {
      return 1;
    } else if (status == 3) {
      return 2;
    } else if (status == 4 || status == 5 || status > 40) {
      return 3;
    } else if (status == 6 || status == 7 || status == 12) {
      return 4;
    }
  }

  handleClick() {
    this.mobileCurentStatus = 'list';
    this.postMessageEvent.emit(this.mobileCurentStatus);
  }
  
}
