<mat-accordion *ngIf="isMobile">
  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{panelOpenState ? 'Bewerbungsstatus' : getApplicationStatus(isStatus)   }}
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div  class="timeline-m {{ getMobileStatus(isStatus) }}">
      <div class="container-mobile-status">
        <div class="content-mobile-status">
          <h6 class="mt-2">Eingang</h6>
        </div>
      </div>
      <div class="container-mobile-status">
        <div class="content-mobile-status">
          <h6 class="mt-2">Prüfung</h6>
        </div>
      </div>
      <div class="container-mobile-status">
        <div class="content-mobile-status">
          <h6 class="mt-2">persönliches Interview</h6>
        </div>
      </div>
      <div class="container-mobile-status">
        <div class="content-mobile-status">
          <h6 class="mt-2">Entscheidung</h6>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<div *ngIf="!isMobile" class="row">
  <div
    class="{{isStatus >= 1 ? 'col col-lg-3 text-center align-self-center reached ' :'col col-lg-3 text-center align-self-center'}}">
    <span class="fas fa-paper-plane"></span>
    <div class="process-text">
      <span class="text-nowrap" *ngIf="isStatus >= 2">{{ status_2 }}</span>
    </div>
  </div>
  <div
    class="{{isStatus >= 3 ? 'col col-lg-2 text-center align-self-center reached' :'col col-lg-3 text-center align-self-center'}}">
    <span class="fas fa-check-square"></span>
    <div class="process-text">
      <span class="text-nowrap" >{{ status_3 }} </span>
      <!-- <span class="text-nowrap" *ngIf="isStatus >= 3 ">{{ status_3 }} </span> -->
    </div>
  </div>
  <div
    class="{{isStatus >= 4 ? 'col col-lg-4 text-center align-self-center reached' :'col col-lg-3 text-center align-self-center'}}">
    <span class="fas fa-users"></span>
    <div class="process-text">
      <span class="text-nowrap" *ngIf="!interviewDetail?.date">{{ status_4 }}</span>
      <span class="text-nowrap" *ngIf="interviewDetail?.date">{{ status_4 }} {{ interviewDetail.date | date:'dd.MM.yyyy':'locale'}}</span>
    </div>
  </div>
  <div
    class="{{(isStatus >= 6 && isStatus < 9) || isStatus == 12 ? 'col col-lg-3 text-center align-self-center reached' :'col col-lg-3 text-center align-self-center'}}">
    <span class="fas fa-handshake"></span>
    <div class="process-text">
      <span class="text-nowrap">{{ status_5 }}</span>
    </div>
  </div>
</div>
<hr *ngIf="!isMobile">
<!-- <div *ngIf="isMobile" class="timeline-m {{ getMobileStatus(isStatus) }}">
  <div class="container-mobile-status">
    <div class="content-mobile-status">
      <h6>Eingang</h6>
    </div>
  </div>
  <div class="container-mobile-status">
    <div class="content-mobile-status">
      <h6>Prüfung</h6>
    </div>
  </div>
  <div class="container-mobile-status">
    <div class="content-mobile-status">
      <h6>persönliches Interview</h6>
    </div>
  </div>
  <div class="container-mobile-status">
    <div class="content-mobile-status">
      <h6>Entscheidung</h6>
    </div>
  </div>
</div> -->