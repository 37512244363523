import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {catchError} from "rxjs/operators";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {AuthService} from "./auth.service";
import {throwError} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class MessageService {

  constructor(private http: HttpClient,
              private router: Router,
              private authService: AuthService) {
  }

  get(url, params = {}) {
    return this.http.get(`${environment.BASE_URL}${url}`, params).pipe(
      catchError(this.handleError)
    );
  }

  post(url, data, httpOptions = {}) {
    return this.http.post(`${environment.BASE_URL}${url}`, data, httpOptions).pipe(
      catchError(this.handleError),
      //DO NOTHING FOR NOW. WILL HANDLE THIS LATER IF REQUIRED
    );
  }

  addMessage(postData: any,matchId) {
      let httpOptions = this.authService.getHTTPOption();
      return this.post(`match/matching/${matchId}/chat/`,
        postData,
        httpOptions)
  }

  getMessages(matchId) {
      let httpOptions = this.authService.getHTTPOption();
      return this.get(`match/matching/${matchId}/chat/`, httpOptions)
  }

  readChatMessages(matchId) {
      let httpOptions = this.authService.getHTTPOption();
      return this.get(`match/matching/${matchId}/mark-read/`, httpOptions)
  }

  handleError(errorRes: HttpErrorResponse){
    console.log(errorRes);
    let errorMessage = "An unknown error occurred! "
    return throwError(errorMessage)
  }
}
