import { Component, OnInit,Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import { CompanyLoginComponent } from '../company-login/company-login.component';
declare var _paq: any;

@Component({
  selector: 'app-company-register',
  templateUrl: './company-register.component.html',
  styleUrls: ['./company-register.component.scss']
})
export class CompanyRegisterComponent implements OnInit {

  companyRegistrationForm: FormGroup;
  companyUserDetails: any;
  isError = false;
  errorMessage: string;
  hide = true;
  public isMobile = false;
  constructor(private deviceService: DeviceDetectorService,
    private authService: AuthService, private dialog: MatDialog,
    private router: Router) {
  }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.initCompanyRegistrationForm();
  }

  onAcceptCookie() {
    console.log("Call cookied function ")
    _paq.push(['setUserId', '777444']);
    _paq.push(['trackPageView']);
    // _paq.push(['setDocumentTitle', 'test shailesh']);
    // _paq.push(['setCustomVariable', '1', 'VisitorType', 'Member']);
    // _paq.push(['enableJSErrorTracking']);
    // _paq.push(['logAllContentBlocksOnPage'])
    // _paq.push(['trackPageView']);
    // _paq.push(['trackVisibleContentImpressions', true, 750]);

    // if (typeof _paq !== 'undefined') {
    //   // 720h = 1 month
    //   _paq.push(['setCustomVariable', 1, 'Category', 'Sports', 'page']);
    //   _paq.push(['rememberConsentGiven', 720]);
    //   _paq.push(['setCustomVariable', 1, 'Category', 'Sports', 'page']);
    // }
  }

  initCompanyRegistrationForm() {
    this.companyRegistrationForm = new FormGroup({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      acceptTerms: new FormControl(null, [Validators.required])
    });
  }

  registerCompanyUser() {
    this.companyUserDetails = { ...this.companyRegistrationForm.value, role: environment.ROLE_COMPANY };
    this.authService.signUp(this.companyUserDetails).subscribe(
      resData => {
        _paq.push(['trackPageView']);
        _paq.push(['setDocumentTitle', document.title]);
        _paq.push(['trackEvent', 'Company', 'Company Signin']);
        this.isError = false;
        this.errorMessage = "";
        this.companyRegistrationForm.reset();
        this.router.navigate(['/add-company']);
      },
      errorMessage => {
        const dialogRef = this.dialog.open(CRegisterErrorDialog, {
          data: {
            errorMessage: errorMessage
          }
        });
        this.isError = true;
        this.errorMessage = errorMessage;
      });
  }

  redirectToLogin() {
    this.router.navigate(['/login-company']);
  }
}




@Component({
  selector: 'app-dialog-error',
  templateUrl: 'app-dialog-error.html',
})
export class CRegisterErrorDialog {
  msg:any
  constructor(private router: Router,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CompanyLoginComponent>) {
      this.msg = data.errorMessage;
    }

  doRegister() {
    
  }
}
