export class ApplicantStatusModel {

  MATCHED = 1;
  APPLIED = 2;
  CHECKED = 3;
  INTERVIEW_PROPOSED_C = 41;
  ALTERNETE_DATE_FROM_APPLIER = 42;
  ANOTHER_INTERVIEW_PROPOSED_C = 43;
  INTERVIEW_AGREED = 5;
  COMPANY_AGREED = 6;
  APPLICANT_AGREED = 7;
  COMPANY_CANCEL = 8;
  APPLICANT_CANCEL = 9;
  MATCH_REFUSED  = 11;
  BOTH_AGREED  = 12;

}
