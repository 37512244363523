import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {validateCheckbox} from "../../../../../services/validateCheckbox";
import {HttpClient} from "@angular/common/http";
import {JobService} from "../../../../../services/company/job.service";
import {Router} from "@angular/router";
import {DialogSuccess} from "../../../../common/dialog/dialog.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CreateJobsComponent} from "../create-jobs.component";

@Component({
  selector: 'app-questionnaire-form',
  templateUrl: './questionnaire-form.component.html',
  styleUrls: ['./questionnaire-form.component.scss']
})
export class QuestionnaireFormComponent implements OnInit {

  questionnaireForm: FormGroup;
  toursForm: FormGroup;
  shiftsFormGroup: FormGroup;
  vehicleType: FormGroup;
  additionalLicense: FormGroup;
  isError = false;

  selectedGerman = 1;
  selectedEnglish = 4;
  @Output() onDatePicked: EventEmitter<any> = new EventEmitter<any>();
  selectedDate = new Date();
  startAt = new Date();
  minDate = new Date();
  maxDate = new Date(new Date().setMonth(new Date().getMonth() + 24));
  dateEvents: Date;
  dt: any;
  apiResponse: any;

  constructor(private _formBuilder: FormBuilder,
              private http: HttpClient,
              public dialog: MatDialog,
              private router: Router,
              private jobService: JobService) {
  }

  title = 'rou';
  //Local Variable defined
  formattedAddress = " ";
  options = {
    componentRestrictions: {
      country: ["de"]
    }
  }

  ngOnInit(): void {
    this.toursForm = this._formBuilder.group({
      local: false,
      national: false,
      international: false,
    }, {
      validator: (formGroup: FormGroup) => {
        return validateCheckbox(formGroup);
      }
    });

    this.shiftsFormGroup = this._formBuilder.group({
      day: false,
      night: false,
      all: false,
      weekend: false,
    }, {
      validator: (formGroup: FormGroup) => {
        return validateCheckbox(formGroup);
      }
    });

    this.vehicleType = this._formBuilder.group({
      "tractor-trailer": false,
      "solo-vehicle": false,
      "articulated-train": false,
      "refrigerated-vehicle": false,
      "swap-body": false,
      "tanker": false,
      "silo": false,
    }, {
      validator: (formGroup: FormGroup) => {
        return validateCheckbox(formGroup);
      }
    });

    this.additionalLicense = this._formBuilder.group({
      'NO_LICENSE': false,
      'FORKLIFT': false,
      'CRANE': false,
      'ADR_BASIC': false,
      'ADR_TANK': false,
      'ADR_EXPLOSIVE': false,
      'ADR_RADIOACTIVE': false
    }, {
      validator: (formGroup: FormGroup) => {
        return validateCheckbox(formGroup);
      }
    });

    let selectedDt = this.selectedDate;
    this.dt = selectedDt.getFullYear() + '-' + (selectedDt.getMonth() + 1) + '-' + selectedDt.getDate();

    this.questionnaireForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      tours: this.toursForm,
      shift: this.shiftsFormGroup,
      postCode: new FormControl('', [Validators.required]),
      street: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      vehicleType: this.vehicleType,
      driverLicense: new FormControl('', [Validators.required]),
      additionalLicense: this.additionalLicense,
      minimumExperience: new FormControl(0, [Validators.required]),
      earliestStartDate: new FormControl(this.selectedDate, [Validators.required]),
      skillGermanLan: new FormControl(1),
      skillEnglishLan: new FormControl(4),
      beingTransported: new FormControl('', [Validators.required]),
      other: new FormControl('', [Validators.required]),
      about: new FormControl('', [Validators.required]),
    });

  }

  createJob() {
    let tours = [];
    Object.keys(this.toursForm.controls).forEach(key => {
      if (this.toursForm.get(key).value == true) {
        tours.push(key);
      }
    });

    let shift = [];
    Object.keys(this.shiftsFormGroup.controls).forEach(key => {
      if (this.shiftsFormGroup.get(key).value == true) {
        shift.push(key);
      }
    });

    let vehicleExperience = [];
    Object.keys(this.vehicleType.controls).forEach(key => {
      if (this.vehicleType.get(key).value == true) {
        vehicleExperience.push(key)
      }
    });

    let additionalLicense = [];
    Object.keys(this.additionalLicense.controls).forEach(key => {
      if (this.additionalLicense.get(key).value == true) {
        additionalLicense.push(key);
      }
    });
    // Constructing PostData object for applicant driving details.
    let jobDetails: any = {
      tours: tours,
      shift: shift,
      vehicle_experience: vehicleExperience,
      additional_license: additionalLicense,
      title: this.questionnaireForm.value.title,
      start_work: this.dt,
      work_experience: this.questionnaireForm.value.minimumExperience,
      license: this.questionnaireForm.value.driverLicense,
      postcode: this.questionnaireForm.value.postCode,
      city: this.questionnaireForm.value.city,
      street: this.questionnaireForm.value.street,
      professional_license: true,
      is_need_professional_license: true,
      being_transported: this.questionnaireForm.value.beingTransported,
      specific_tasks: '',
      language: [this.questionnaireForm.value.skillGermanLan, this.questionnaireForm.value.skillEnglishLan]
    };

    this.jobService.createJob(jobDetails).subscribe(resData => {
      this.apiResponse = resData;
      this.dialog.open(JobCreateSuccessDialog);
      this.questionnaireForm.reset();
      this.router.navigate([`/job/${this.apiResponse.id}`]);

    }, errorMessage => {
      console.log("errorMessage In user-match component");
      console.log(errorMessage);
      this.isError = true;
    });

  }

  onSelect(event) {
    this.selectedDate = event;
  }

  DateFilter = (d: Date): boolean => {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    // return day !== 0 && day !== 6;
    return true;
  }

  addEvent(event) {
    console.log(event.value);
    let selectedDt = event.value;
    this.dt = selectedDt.getFullYear() + '-' + (selectedDt.getMonth() + 1) + '-' + selectedDt.getDate();
    // console.log(this.questionnaireForm.value.earliestStartDate);
  }

}

@Component({
  selector: 'app-job-create-dialog-success',
  templateUrl: 'app-job-create-dialog-success.html',
})
export class JobCreateSuccessDialog {

  isEdit = false;
  isConfirm = false;
  constructor(private router: Router,
              private dialogRef: MatDialogRef<CreateJobsComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.isEdit = data.isEdit;
    this.isConfirm = data.isConfirm;
  }

  doRefresh(){
    this.router.navigate(['/company'])
  }
  doConfirm(){
    return this.dialogRef.afterClosed();
  }
}
