import { Component, OnInit, Input, OnChanges, Output, EventEmitter, Inject } from '@angular/core';
import { JobService } from '../../../services/company/job.service';
import { DeviceDetectorService } from "ngx-device-detector";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from "../../../services/profile.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
// import {EditProfileComponent} from "../../components/my-profile/edit-profile/edit-profile.component";
import { ApplicantService } from "../../../services/applicant.service";
import { AuthService } from "../../../services/auth.service";

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss']
})
export class StatusBarComponent implements OnInit {

  @Input('matchId') matchId: number;
  @Input('jobTitle') jobTitle: any;
  @Input('isStatus') isStatus: any;
  getUserProfile: any;
  interviewFormGroup: FormGroup;
  loggedInUserId: number;
  interviewerId: number;
  isApplierSuggested: any;
  isError = false;
  errorMessage = '';
  getChat: any;
  messages: any;
  public isMobile = false;
  panelOpenState = false;
  status_1 = 'Matched';
  status_2 = 'Eingang';
  status_3 = 'Prüfung';
  status_4 = 'persönliches Interview';
  status_5 = 'Entscheidung';
  status_6 = '';
  status_7 = '';
  status_8 = '';
  status_9 = '';
  chatFormGroup: FormGroup;
  isInterviewDate = false;
  isConformInterview = false;
  isRescheduleInterview = false;
  isButtonDisable = false;
  isConpamyAgree = false;
  isApplierAgree = false;
  proposalDetails: any;
  selectedApplier: any;
  public Editor = ClassicEditor;
  responseData: any;
  responseDatas: any;
  applier: any;
  company: any;
  job: any;
  jobs: any;
  JobStatus:any;
  distance: any;
  interviewDetail: any;
  germanLanSkill: string;
  isApplicationPage = false;
  isDecisionPending = false;
  constructor(private deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private profileService: ProfileService,
    private applicantService: ApplicantService,
    private jobService: JobService,
    public dialog: MatDialog,
    private authService: AuthService) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {
    this.getJob()
    // console.log(this.matchId + " match-Id")
    // this.isStatus = Number(this.isStatus)
  }
  getApplicationStatus(status: number) {
    status = Number(status)
    switch (status) {
      case 1:
        return "eingang";
      case 2:
        return "Eingang";
      case 3:
        return "Prüfung";
      case 4:
        return "persönliches Interview";
      case 5:
        return "persönliches Interview";
      case 41:
        return "persönliches Interview";
      case 42:
        return "persönliches Interview";
      case 43:
        return "persönliches Interview";
      case 6:
        return 'Entscheidung';
      case 7:
        return 'Entscheidung';
      case 8:
        return 'Bewerber abgesagt';
      case 9:
        return 'Bewerbung zurückgezogen';
      case 11:
        return 'Match abgelehnt';
      case 12:
        return 'beidseitige Zusage';
      default:
        return "N/A";
    }
  }

  getMobileStatus(status: number) {
    status = Number(status)
    switch (status) {
      case 1:
        return "eingang";
      case 2:
        // return "pruefung";
        return "eingang";
      case 3:
        return "pruefung";
      case 4:
        return "interview";
      case 5:
        return "interview";
      case 41:
        return "interview";
      case 42:
        return "interview";
      case 43:
        return "interview";
      case 6:
        return 'entscheidung';
      case 7:
        return 'entscheidung';
      case 8:
        return 'Bewerber abgesagt';
      case 9:
        return 'Bewerbung zurückgezogen';
      case 11:
        return 'Match abgelehnt';
      case 12:
        return 'beidseitige Zusage';
      default:
        return "N/A";
    }
  }

  getJob(): void {
    const matchId = this.matchId;
    if (typeof matchId !== 'undefined') {
      this.jobService.getJobV2(matchId)
      .subscribe(resData => {
        this.responseDatas = resData;
        this.JobStatus = this.responseDatas.status;
        this.applier = this.responseDatas.applier;
        this.company = this.responseDatas.company;
        this.jobs = this.responseDatas.job;
        this.job = this.responseDatas.job;
        this.distance = this.responseDatas.distance;
        this.interviewDetail = this.responseDatas.interview_detail;
        // console.log("=== this.interviewDetail ===")
        // console.log(this.interviewDetail)
      }, errorMessage => {
        console.log(errorMessage);
      });
    }
  }
}
