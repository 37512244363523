<div>
  <form [formGroup]="questionnaireForm" (ngSubmit)="createJob()">
    <!--  Title   -->
    <!--    <p><b>Title:</b></p>-->
    <mat-form-field class="w-100">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title">
    </mat-form-field>

    <!--    Tours  -->
    <div formGroupName="tours" class="my-2">
      <p><b>Tourenprofil:</b></p>
      <div class="row">
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="local" value="local">Nahverkehr
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="national" value="national">Fernverkehr
            (national)
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="international" value="international">
            Fernverkehr (international)
          </mat-checkbox>
        </div>
      </div>
    </div>

    <!--  Shifts  -->
    <div formGroupName="shift" class="my-2">
      <p><b>Arbeitszeiten:</b></p>
      <div class="row">
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="day" value="day">Tagesschicht (Mo.-Fr.)
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="night" value="night">Nachtschicht
            (Mo.-Fr.)
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="all" value="all">Wechselschicht
            (Mo.-Fr.)
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="weekend" value="weekend">Wochenende
          </mat-checkbox>
        </div>
      </div>
    </div>

    <!--    Location/Departure point-->
    <p><b>Abfahrsort:</b></p>
    <mat-form-field class="w-100">
      <mat-label>Postleitzahl</mat-label>
      <input matInput formControlName="postCode">
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Straße</mat-label>
      <input matInput formControlName="street">
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Stadt</mat-label>
      <input matInput formControlName="city">
    </mat-form-field>

    <!--      <input matInput ngx-google-places-autocomplete formControlName="location" [options]='options'  (onAddressChange)="AddressChange($event)"/>-->

    <!--    Vehicle type-->
    <div formGroupName="vehicleType" class="my-2">
      <p><b>Fahrzeugtypen für diese Stelle:</b></p>
      <div class="row">
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="tractor-trailer" value="tractor-trailer">
            Sattelzug
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="solo-vehicle" value="solo-vehicle">
            Solofahrzeug
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="articulated-train"
                        value="articulated-train">Gliederzug
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="refrigerated-vehicle"
                        value="refrigerated-vehicle">
            Kühlfahrzeug
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="swap-body" value="swap-body">
            Wechselbrücke
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="tanker" value="tanker">Tankfahrzeug
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="silo" value="silo">Silo</mat-checkbox>
        </div>
      </div>
    </div>

    <!--    driver license-->
    <div class="my-2"><p><b>höchste erforderliche Führerscheinklasse:</b></p>
    <mat-radio-group class="row" formControlName="driverLicense">
      <div class="col-lg-6">
        <mat-radio-button color="primary" labelPosition="after" value="CE">CE</mat-radio-button>
      </div>
      <div class="col-lg-6">
        <mat-radio-button color="primary" labelPosition="after" value="C">C</mat-radio-button>
      </div>
      <div class="col-lg-6">
        <mat-radio-button color="primary" labelPosition="after" value="C1E">C1E</mat-radio-button>
      </div>
      <div class="col-lg-6">
        <mat-radio-button color="primary" labelPosition="after" value="C1">C1</mat-radio-button>
      </div>
    </mat-radio-group></div>


    <!--    additional licence-->
    <div formGroupName="additionalLicense" class="my-2">
      <p><b>zusätzliche (Führer-) Scheine wünschenswert:</b></p>
      <div class="row">
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="NO_LICENSE" value="NO_LICENSE">
            Keinen weiteren Führerschein
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="FORKLIFT" value="FORKLIFT">
            Staplerführerschein
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="CRANE" value="CRANE">Kranführerschein
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="ADR_BASIC" value="ADR_BASIC">ADR-Basis
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="ADR_TANK" value="ADR_TANK">ADR-Tank
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="ADR_EXPLOSIVE" value="ADR_EXPLOSIVE">
            ADR-Explosiv
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox color="primary" labelPosition="after" formControlName="ADR_RADIOACTIVE" value="ADR_RADIOACTIVE">
            ADR-Radioaktiv
          </mat-checkbox>
        </div>
      </div>
    </div>

    <!--  minimum years of experience-->
    <div class="my-2">
      <p><b>min. x Jahre an Berufserfahrung erwünscht:</b></p>
      <div class="row">
        <div class="col-lg-6 align-self-center">
          <mat-slider #mySlider color="primary" (input)="myInput.value = mySlider.value"
                      formControlName="minimumExperience" style="width: 100%" thumbLabel tickInterval="1" min="0"
                      max="30" step="1"></mat-slider>
        </div>
        <div class="col-lg-3 align-self-center pt-2">
          <p>{{ mySlider.value | json }} Jahre</p>
        </div>
        <div class="col-lg-3" style="display:inline-flex">
          <mat-form-field class="align-self-center" style="width: 75px;">
            <input class="no-controls" matInput #myInput (input)="mySlider.value = myInput.value" type="number"
                   formControlName="minimumExperience"
                   min="0" max="30" placeholder="Erfahrung">
            <span matSuffix> Jahre</span>
          </mat-form-field>
        </div>
      </div>
    </div>

    <!--  Start Date   -->
    <div class="my-2">  <p><b>Eintrittsdatum</b></p>
      <mat-form-field class="w-50">
        <mat-label>Eintrittsdatum</mat-label>
        <input matInput [matDatepicker]="picker"
               formControlName="earliestStartDate"
               [min]="minDate"
               [matDatepickerFilter]="DateFilter"
               (dateInput)="addEvent($event)" (dateChange)="addEvent($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field></div>

    <!--    language skills-->
    <p><b>erwünschte Sprachkenntnisse:</b></p><br>
    <mat-form-field class="mx-2">
      <mat-label>Deutsch</mat-label>
      <mat-select [(value)]="selectedGerman" formControlName="skillGermanLan">
        <mat-option value="10">keine Kenntnis</mat-option>
        <mat-option value="1">Grundkenntnisse</mat-option>
        <mat-option value="2">fließend</mat-option>
        <mat-option value="3">Muttersprache</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="mx-2">
      <mat-label>Englisch</mat-label>
      <mat-select [(value)]="selectedEnglish" formControlName="skillEnglishLan">
        <mat-option value="11">keine Kenntnis</mat-option>
        <mat-option value="4">Grundkenntnisse</mat-option>
        <mat-option value="5">fließend</mat-option>
        <mat-option value="6">Muttersprache</mat-option>
      </mat-select>
    </mat-form-field>

    <!--    What is being transported :-->
    <mat-form-field class="example-full-width w-100 my-2">
      <mat-label><b>Was wird transportiert</b></mat-label>
      <textarea matInput #beingTransported maxlength="200" placeholder="Eingeben Was wird transportiert"
                formControlName="beingTransported" rows="2"></textarea>
      <mat-hint align="end">{{beingTransported.value.length}} / 200</mat-hint>
    </mat-form-field>

    <!--    Other:-->
    <mat-form-field class="example-full-width w-100 my-2">
      <mat-label><b>Sonstiges</b></mat-label>
      <textarea matInput #messageOther maxlength="300" placeholder="Sonstiges" formControlName="other"
                rows="3"></textarea>
      <mat-hint align="end">{{messageOther.value.length}} / 300</mat-hint>
    </mat-form-field>

    <!--    About the company:-->
    <mat-form-field class="example-full-width w-100 my-2">
      <mat-label><b>Über das Unternehmen</b></mat-label>
      <textarea matInput #about maxlength="600" placeholder="Eingeben Über das Unternehmen" formControlName="about"
                rows="5"></textarea>
      <mat-hint align="end">{{about.value.length}} / 600</mat-hint>
    </mat-form-field>
<!--    <button mat-button mat-flat-button  class="col mt-3" type="submit"-->
<!--            color="primary">-->
      <button mat-button mat-flat-button [disabled]="questionnaireForm.invalid" class="col mt-3" type="submit"
            color="primary">
      <span class="icon-text-button">Absenden</span>
    </button>

  </form>
</div>
