import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {catchError} from "rxjs/operators";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {MessageService} from "../services/message.service";
import {AuthService} from "../services/auth.service";
import {throwError} from "rxjs";
import { share, finalize } from 'rxjs/operators'; 
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private authService: AuthService
  ) {
  }

  get(url, params = {}) {
    return this.http.get(`${environment.BASE_URL}${url}`, params).pipe(
      catchError(this.handleError)
    );
  }

  getV2(page) {
    let httpOptions = this.authService.getHTTPOption();
    return this.http.get(environment.BASE_URL + `account/notification/?page=${page}`, httpOptions).pipe(share());
  }

  getNotificationOverview(page) {
    let httpOptions = this.authService.getHTTPOption();
    // return this.get(`jobs/my-job/`, httpOptions);
    return this.get(`account/notification/?page=${page}`, httpOptions);
  }

  readNotifiacation(id){
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`account/notification/${id}/mark-read/`, httpOptions);
  }  
  
  getUnreadNotificationCount(){
    let httpOptions = this.authService.getHTTPOption();
    return this.get(`account/notification/count/`, httpOptions);
  }

  handleError(errorRes: HttpErrorResponse) {
    console.log(errorRes);
    let errorMessage = "An unknown error occurred!"
    if (errorRes.error) {
      Object.keys(errorRes.error).map(function (key) {
        errorMessage = errorRes.error[key];
      });
    }
    return throwError(errorMessage);
  }

}
