import {Component, Input, OnInit} from '@angular/core';
import {JobModel} from "../../../models/job.model";
import {JobService} from 'src/app/services/job.service';
import {environment} from "../../../../environments/environment.prod";
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-user-match',
  templateUrl: './user-match.component.html',
  styleUrls: ['./user-match.component.scss']
})
export class UserMatchComponent implements OnInit {

  @Input('matchId') matchId: number;
  jobs: any;
  matchList: any;
  responseData: any;
  isSpinner = true;
  public isMobile = false;
  constructor(private jobService: JobService,
    private deviceService: DeviceDetectorService) {
      this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {
    this.getJobs(1);
  }

  getLoadMoredata(page) {
    console.log(page + " page")
    let parts = page.split("?");
    let param = parts[parts.length - 1];
    if (parts.length == 1) {
      param = 1;
    }
    console.log("=== param ===")
    console.log(param)
    this.isSpinner = true;
    this.getJobs(param);
  }

  getUserName(fname) {
    let fullname = fname ;
    let stringlength = (this.isMobile) ? 25 : 20;
    if (fullname.length < stringlength) {
      return fullname;
    } else {
      return fullname.substring(0, stringlength) + "...";
    }
  }

  getJobs(pageNo): void {
    //Have to call here Matching API
    // i will get applier,Company and Job details
    this.jobService.getJobs(environment.STATUS_MATCHED,pageNo)
      .subscribe(resData => {
        this.responseData = resData;
        this.matchList = this.responseData.results;
        let jobs = [];
        for (const jobList of this.matchList) {
          let jobObj = {};
          for (const companyList of this.matchList) {
            if (jobList.job.company === companyList.company.id) {
               jobObj = {
                ...jobList.job,
                 logo: companyList.company.logo,
                 matchPercent:jobList.rating,
                 matchId:jobList.id,
                 companyName:companyList.company.name,
              };
            }
          }
          jobs.push(jobObj);
        }
        this.isSpinner = false;
        this.jobs = jobs;
      }, errorMessage => {
        console.log("errorMessage In user-match component");
        console.log(errorMessage);
      });
  }
  checkImageExists(url: string) {
    if(url){
      let parts = url.split("/");
      let param = parts[parts.length - 1];
      if (param.length < 5) {
        return false;
      } else {
        return true;
      }
    }
  }
}
