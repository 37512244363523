<div style="text-align: center">
  <h4> 1. Kostenloses Profil erstellen</h4>

  <p> In wenigen Schritten kannst du deine Wünsche und Anforderungen in deinem Profil erstellen – speziell für dich als LKW-Fahrer.</p>



  <h4>  2. Erhalte nur passende Jobangebote</h4>

  <p> Nach deiner Anmeldung bekommst du nur Stellenangebote angezeigt, die auch wirklich zu deinen Wünschen passen.</p>



  <h4>  3. Bewirb dich mit einem Klick</h4>

  <p> Wenn dich ein Angebot überzeugt, kannst du dich mit einem Klick bewerben.</p>

  <button mat-flat-button
          style="width: 200px;margin-top: 10%;"
          color="primary" type="button"
          (click)="redirectToRegister()"
          >
    <span class="icon-text-button">Job finden</span>
  </button>
</div>
