<div *ngIf="messageType == 'cencel-popup'">
  <mat-dialog-content>
    <p>Soll den Bewerbern abgesagt werden?</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="accent" (click)="cancel()" [mat-dialog-close]="true">Abbrechen</button>
    <button mat-raised-button color="warn" (click)="confirm()" [mat-dialog-close]="true" cdkFocusInitial>Ja</button>
  </mat-dialog-actions>
</div>
<div *ngIf="messageType == 'delete-job'">
  <mat-dialog-content>
    <p>Wollen Sie diesen Job tatsächlich löschen?</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="accent" (click)="cancel()" [mat-dialog-close]="true">Abbrechen</button>
    <button mat-raised-button color="warn" (click)="confirm()" [mat-dialog-close]="true" cdkFocusInitial>Ja</button>
  </mat-dialog-actions>
</div>
 
<div *ngIf="messageType == 'application-open'">
  <mat-dialog-content>
    <p>Sie haben noch offene Bewerbungen. Der Job kann erst gelöscht werden, wenn alle offenen Bewerbungen geschlossen sind.</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="accent" (click)="cancel()" [mat-dialog-close]="true">ok</button>
  </mat-dialog-actions>
</div>
 