import {Component, OnInit,OnChanges, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../environments/environment";
import {ErrorDialog} from "../../auth/auth.component";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {DeviceDetectorService} from "ngx-device-detector";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

declare var _paq: any;
@Component({
  selector: 'app-company-login',
  templateUrl: './company-login.component.html',
  styleUrls: ['./company-login.component.scss']
})
export class CompanyLoginComponent implements OnInit {

  loginForm: FormGroup;
  isError = false;
  errorMessage: string;
  loggedInUser: any;
  public isMobile = false;

  constructor(private deviceService: DeviceDetectorService,
              private dialog: MatDialog,
              private authService: AuthService,
              // private angulartics2: Angulartics2,
              // private angulartics2Piwik: Angulartics2Piwik,
              private router: Router) {
  }

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.initForm();
  }

  initForm() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      rememberMe: new FormControl(false)
    });
  }

  doLogin() {
    let postData = {
      ...this.loginForm.value
    }
    this.authService.doSignIn(postData).subscribe(
      resData => {
        this.loggedInUser = resData;
        this.isError = false;
        this.errorMessage = "";
        this.loginForm.reset();
        if (this.loggedInUser && this.loggedInUser.user && this.loggedInUser.user.social_account === 0) {
          if (!this.loggedInUser.user.is_verified) {
            localStorage.removeItem('userData');
            this.isError = true;
            this.errorMessage = "Bitte bestätige deine Email-Adresse in der dir bereits zugesendeten Willkommens-Mail";
            const dialogRef = this.dialog.open(ErrorDialogForCLogin, {
              data: {
                errorMessage:  "Bitte bestätige deine Email-Adresse in der dir bereits zugesendeten Willkommens-Mail"
              }
            });
            return;
          }
        }
        if (this.loggedInUser.user.role == environment.ROLE_COMPANY) {
          // this.angulartics2.eventTrack.next({
          //   action: 'Company signin',
          //   properties: { category: 'Company' },
          // });
          _paq.push(['setDocumentTitle', document.title]);
          _paq.push(['trackPageView']);
          _paq.push(['trackEvent', 'Company', 'Company Login']);
          
          this.router.navigate([`/company`]);
        } else {
          this.isError = true;
          this.errorMessage = "You are not allowed to perform this action";
          const dialogRef = this.dialog.open(ErrorDialogForCLogin, {
            data: {
              errorMessage:  "You are not allowed to perform this action"
            }
          });
        }
      },
      errorMessage => {
        this.isError = true;
        this.errorMessage = errorMessage;
        const dialogRef = this.dialog.open(ErrorDialogForCLogin, {
          data: {
            errorMessage: errorMessage
          }
        });
      });
  }

  redirectToRegister() {
    this.router.navigate(['./register-company'])
  }

}



@Component({
  selector: 'app-dialog-error',
  templateUrl: 'app-dialog-error.html',
})
export class ErrorDialogForCLogin {
  msg:any
  constructor(private router: Router,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CompanyLoginComponent>) {
      this.msg = data.errorMessage;
    }

  doRegister() {
    
  }
}

