import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss']
})
export class CircleProgressComponent implements OnInit {
  @Input("matchPercent") matchPercent: number;
  @Input("outerStrokeWidth") outerStrokeWidth: number;
  @Input("innerStrokeWidth") innerStrokeWidth: number;
  @Input("radius") radius: number;
  @Input("titleFontSize") titleFontSize: number;
  constructor() { }

  ngOnInit() {
  }

}
