import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DeviceDetectorService} from "ngx-device-detector";
import {Subscription} from "rxjs";
import {JobService} from "../../services/job.service";
import {environment} from "../../../environments/environment.prod";

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})


export class ApplicationsComponent implements OnInit {

  public matchId: number;
  isMobile: boolean;
  subscriptions: Array<Subscription> = [];
  responseData: any;
  isSpinner = true;
  isMatchFound = true;
  constructor(public router: Router,
              public activatedRoute: ActivatedRoute,
              private deviceService: DeviceDetectorService,
              private jobService: JobService) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.activatedRoute.params.subscribe(params => {
        this.matchId = +params['id'];
        setTimeout(() => {
          this.isSpinner = false;
        }, 2000);
        // (this.isMobile) ? this.isMatchFound = false : '';
        if (!this.matchId && !this.isMobile) {
          this.jobService.getJobsForApplication(environment.STATUS_APPLIED)
            .subscribe(resData => {
              this.responseData = resData;
              this.isSpinner = false;
              if (this.responseData.results.length >= 1) {
                this.isMatchFound = true;
                this.matchId = this.responseData.results[0].id;
              } else {
                this.isMatchFound = false;
                // TODO:Handle with proper message
              }
            }, errorMessage => {
              console.log('errorMessage In user-match component');
              console.log(errorMessage);
            });
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

}
