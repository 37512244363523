import { Component, OnInit, Input, OnChanges,Output, EventEmitter, Inject } from '@angular/core';
import { JobService } from '../../../services/company/job.service';
import { DeviceDetectorService } from "ngx-device-detector";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {DialogComponent} from "../../common/dialog/dialog.component";
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from "../../../services/profile.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ApplicantService } from "../../../services/applicant.service";
import { AuthService } from "../../../services/auth.service";
import { MessageService } from "../../../services/message.service";
import {LanguageModel} from "../../../models/language.model";
import { ApplicantStatusModel } from "../../../models/applicant-status.model";
import { DatePipe } from "@angular/common";
import { formatDate } from "@angular/common";
import {JobDetailsComponent} from "../../../components/company/job-profile/job-details/job-details.component";
import { DialogCall } from "../../../components/applications/application-details/application-details.component";

@Component({
  selector: 'app-applier-news',
  templateUrl: './applier-news.component.html',
  styleUrls: ['./applier-news.component.scss']
})

export class ApplierNewsComponent implements OnInit {

  @Input('matchId') matchId: number;
  @Input('isStatus') isStatus: any;
  chatFormData: any;
  chatFormGroup: FormGroup;
  getUserProfile: any;
  isEditModeEnable = false;
  isSpinner = true;
  isError = false;
  isInterviewDate = false;
  isButtonDisable = false;
  errorMessage = '';
  getChat: any;
  messages: any;
  selected = new FormControl(0);
  selectedApplier: any;
  public Editor = ClassicEditor;
  public isMobile = false;
  responseData: any;
  responseDatas: any;
  applier: any;
  company: any;
  job: any;
  jobs: any;
  JobStatus:any;
  distance: any;
  interviewDetail: any;
  messageCount = 0;

  loggedInUserId = 0;
  isImgError = false;
  imgErrorMessage = '';
  imagePreview: string;
  imgSrc = './assets/no-image.jpeg';
  isImgSelected = false;
  filename = '';
  filesize = '';

  constructor(private deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private profileService: ProfileService,
    private messageService: MessageService,
    private applicantService: ApplicantService,
    private jobService: JobService,
    public dialog: MatDialog,
    private authService: AuthService) {
      this.isMobile = this.deviceService.isMobile();
    }

  ngOnChanges() {
    this.ngOnInit();
  }

  ngOnInit() {
    this.getProfile();
    this.getJob();
    this.getChatHistory(false);
    this.chatFormGroup = this._formBuilder.group({
      message: ['', Validators.required],
      logo: []
    });
    this.isStatus = this.isStatus;
  }

  addMessage() {
    let id = this.matchId
    this.chatFormData = new FormData();
    if(this.isImgSelected){
      let logoType = typeof this.chatFormGroup.get('logo').value;
      if (logoType == "object") {
        this.chatFormData.append('file', this.chatFormGroup.get('logo').value);
      }
    }
    this.chatFormData.append('message', this.chatFormGroup.value.message);
    this.chatFormData.append('match_id', this.matchId);
    if(this.chatFormGroup.get('logo').value || this.chatFormGroup.value.message){
      this.messageService.addMessage(this.chatFormData, id).subscribe(
        (postResData) => {
          this.isImgSelected = false;
          this.filename = "";
          this.filesize = "";
          this.getChatHistory(true);
          this.chatFormGroup.reset();
        },
        (errorMessage) => {
          this.isError = true;
          this.errorMessage = errorMessage;
          console.log(this.errorMessage);
        }
      );
    }
  }

  deleteImage(){
    this.chatFormGroup.get('logo').setValue(null);
    this.imgSrc = '';
    this.isImgSelected = false;
    this.filesize = this.filename = '';
  }

  getChatHistory(type) {
    const id = this.matchId;
    this.messageService.getMessages(id).subscribe(getResData => {
      this.isError = false;
      this.errorMessage = "";
      this.getChat = getResData;
      this.messages = this.getChat.results;
      this.messageCount = 0;
      let applierUser = JSON.parse(localStorage.getItem('userData'));
      for (let message of this.getChat?.results) {
        message.isPdf = false;
        message.fileName = "";
        if(message.file){
          message.isPdf = this.checkPdfExists(message.file)
          message.fileName = this.getFileNameFromUrl(message.file)
        }
        if (message.is_read == false && applierUser.id != message.created_by) {
          this.messageCount++;
        }
      }
      if (this.messageCount > 0 && type == true) {
        this.messageService.readChatMessages(this.matchId).subscribe(
          (resData) => { },
          (error) => {
            console.log("Error occurred while reading chat ");
          }
        );
      }
      this.loggedInUserId = this.authService.getUserData().id;
    }, errorMessage => {
      this.isError = true;
      this.errorMessage = errorMessage;
      console.log(this.errorMessage);
    });
  }

  checkPdfExists(url: string) {
    let parts = url.split(".");
    let param = parts[parts.length - 1];
    if (param=="pdf") {
        return true;
    }else{
        return false;
    }
  }

  getFileNameFromUrl(url: string) {
    let parts = url.split("/");
    let param = parts[parts.length - 1];
    return param;
  }

  openCallDialog() {
    var updatePhoneNumber = this.authService.getUserData();
    updatePhoneNumber.phone_number = this.applier?.phone_number;
    localStorage.setItem("userData",JSON.stringify(updatePhoneNumber));
    let applierUser = JSON.parse(localStorage.getItem("userData"));

    const dialogRef = this.dialog.open(DialogCall, {
      width: "275px",
      data: {
        applier: this.company,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let chatTextOnCallFail = `Hallo ${this.company.name}, ich hatte Sie versucht telefonisch zu erreichen, leider ohne Erfolg.
        Gerne können Sie mich bei Gelegenheit unter folgender Nummer zurückrufen:  ${applierUser.phone_number
        }`;
        this.chatFormGroup.patchValue({
          message: chatTextOnCallFail,
        });
      }
    });
  }

  tabClick(tab) {
    const selectedTab = tab.tab.textLabel;
    if (selectedTab === 'Nachrichten' || selectedTab === '') {
      // this.isRead = true;
      this.getChatHistory(true);
      setTimeout(() => {
        this.messageCount = 0;
      }, 4000);
    } else {
      this.selected.setValue(0);
    }
  }

  getProfile() {
    this.profileService.getProfile().subscribe(
      resData => {
        this.getUserProfile = resData;
        this.isSpinner = false;
      }, error => {
        console.log(error);
      });
  }


  getJob(): void {
    const matchId = this.matchId;
    this.jobService.getJobV2(matchId)
      .subscribe(resData => {
        this.responseDatas = resData;
        this.JobStatus = this.responseDatas.status;
        this.applier = this.responseDatas.applier;
        this.company = this.responseDatas.company;
        this.jobs = this.responseDatas.job;
        this.job = this.responseDatas.job;
        this.distance = this.responseDatas.distance;
        this.interviewDetail = this.responseDatas.interview_detail;
        var updatePhoneNumber = this.authService.getUserData();
        updatePhoneNumber.phone_number = this.applier.phone_number;
        localStorage.setItem("userData",JSON.stringify(updatePhoneNumber));
        this.isSpinner = false;

      }, errorMessage => {
        console.log(errorMessage);
      });
  }

  gotoChatTab() {
    this.selected.setValue(2);
  }

  onImagePicked(event) {
    this.isImgError = false;
    this.isImgSelected = true;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.filename = file.name;
      this.filesize = file.size;
      if(file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png" && file.type != "application/pdf"){
        this.isImgError = true;
        this.imgErrorMessage = 'Bitte wählen Sie den Dateityp jpeg, png, pdf.';
        return true
      }
      if (file.size <= 2000000) {
        // this.chatFormGroup.controls['message'].setErrors({'incorrect': false});
        // this.chatFormGroup.patchValue({
        //   message: " ",
        // });
        this.isImgError = false;
        this.imgErrorMessage = '';
        const reader = new FileReader();
        this.chatFormGroup.get('logo').setValue(file);
        reader.onload = () => {
          this.imagePreview = reader.result as string;
          this.imgSrc = this.imagePreview;
        }
        reader.readAsDataURL(file);
      } else {
        this.chatFormGroup.get('logo').setValue(null);
        this.imgSrc = '';
        this.isImgSelected = false;
        this.filesize = this.filename = '';
        this.isImgError = true;
        this.imgErrorMessage = 'Die Logo-Größe sollte weniger als 2 MB betragen.';
      }
    }
  }

}

