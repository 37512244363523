<div><b>Bewerbungsstatus:</b></div><br>
<app-process-bar></app-process-bar>
<!--<p class="process-point"><span class="fas fa-2x fa-paper-plane"></span>Du hast dich am 21. November beworben, du wirst
    von uns benachrichtigt, wenn das Unternehmen die Bewerbung gecheckt
    hat.</p>
<p class="process-point"><span class="fas fa-2x fa-check-square"></span>Bewerbungstermin findet am 26. November 16:00
    statt.</p>

<p>Das Unternehmen hat deine Bewerbung zur Durchsicht geöffnet.
    Du erfährst innerhalb der nächsten Tage ob das Unternehmen dich zu einem Bewerbungsgespräch einlädt.</p>
-->
<div class="mat-check-group row">
    <p>Glückwunsch!<br>
        Das Unternehmer würde dich gerne persönlich Kennenlernen.
        Hierfür wähle bitte einen der unten genannten Terminbövorschläge des Unternehmens aus?</p>
    <div class="col-lg-6">
        <mat-checkbox checked labelPosition="before" formControlName="prozessmanager" value="prozessmanager">
            25. November 16:00 - 17:00</mat-checkbox>
    </div>
    <div class="col-lg-6">
        <mat-checkbox checked labelPosition="before" formControlName="ausbilder" value="ausbilder">
            26. November 16:00 - 17:00
        </mat-checkbox>
    </div>
    <div class="col-lg-6">
        <mat-checkbox checked labelPosition="before" formControlName="meister" value="meister">
            27. November 16:00 - 17:00
        </mat-checkbox>
    </div>
    <div class="col-lg-6">
        <mat-checkbox checked labelPosition="before" formControlName="techniker" value="techniker">
            28. November 16:00 - 17:00 </mat-checkbox>
    </div>
</div>
