import {Component, OnInit, Input, OnChanges, Inject} from '@angular/core';
import {JobModel} from "../../../models/job.model";
import {JobService} from 'src/app/services/job.service';
import {DeviceDetectorService} from "ngx-device-detector";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../environments/environment";
import {MessageService} from "../../../services/message.service";
import {AuthService} from "../../../services/auth.service";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Router} from "@angular/router";
import {DialogSuccess} from "../../common/dialog/dialog.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CreateJobsComponent} from "../../company/jobs/create-jobs/create-jobs.component";
import {JobDetailsComponent} from "../../company/job-profile/job-details/job-details.component";

@Component({
  selector: 'app-application-details',
  templateUrl: './application-details.component.html',
  styleUrls: ['./application-details.component.scss']
})

export class ApplicationDetailsComponent implements OnInit, OnChanges {
  chatFormGroup: FormGroup;
  @Input('matchId') matchId: number;
  public isMobile = false;
  responseData: any;
  applicationHistory: any[] = [];
  applier: any;
  company: any;
  job: any;
  applicationStatus: any;
  isError = false;
  errorMessage = '';
  getChat: any;
  messages: any;
  loggedInUserId: number;
  date = new Date();
  messageCount = 0;
  public Editor = ClassicEditor;
  isImgError = false;
  isImgSelected = false;
  filename = '';
  filesize = '';
  imgErrorMessage = '';
  imagePreview: string;
  imgSrc = './assets/no-image.jpeg';
  chatFormData: any;
  constructor(private deviceService: DeviceDetectorService,
              private jobService: JobService,
              private _formBuilder: FormBuilder,
              private messageService: MessageService,
              private authService: AuthService,
              public dialog: MatDialog) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {
    this.getJob();
    // this.chatFormGroup = this._formBuilder.group({
    //   message: ['', Validators.required]
    // });
    this.chatFormGroup = this._formBuilder.group({
      message: ['', [Validators.required]],
      logo: ['']
    });
  }

  ngOnChanges() {
    this.getJob();
    this.getChatHistory(false);
  }

  getJob(): any {
    const id = this.matchId;
    this.jobService.getJob(id)
      .subscribe(resData => {
        this.responseData = resData;
        this.applier = this.responseData.applier;
        this.company = this.responseData.company;
        this.job = this.responseData.job;
        this.applicationStatus = this.responseData.status;
        this.applicationHistory = this.responseData?.history;
        var updatePhoneNumber = this.authService.getUserData();
        updatePhoneNumber.phone_number = this.responseData?.applier.phone_number;
        localStorage.setItem("userData",JSON.stringify(updatePhoneNumber));
      }, errorMessage => {
        console.log(errorMessage);
      });
  }

  addMessage() {
    let id = this.matchId
    this.chatFormData = new FormData();
    if(this.isImgSelected){
      let logoType = typeof this.chatFormGroup.get('logo').value;
      if (logoType == "object") {
        this.chatFormData.append('file', this.chatFormGroup.get('logo').value);
      }
    }
    this.chatFormData.append('message', this.chatFormGroup.value.message);
    this.chatFormData.append('match_id', this.matchId);
    if(this.chatFormGroup.get('logo').value || this.chatFormGroup.value.message){
      this.chatFormGroup.reset();
      this.messageService.addMessage(this.chatFormData, id).subscribe(
        (postResData) => {
          this.getChatHistory(true);
          this.isImgSelected = false;
          this.filename = "";
          this.filesize = "";
        },
        (errorMessage) => {
          this.isError = true;
          this.errorMessage = errorMessage;
          console.log(this.errorMessage);
        }
      );
    }
    // const id = this.matchId;
    // let message = this.chatFormGroup.value.message;
    // let postData = {
    //   match_id: id,
    //   message: message
    // }
    // this.chatFormGroup.reset();
    // this.messageService.addMessage(postData, id).subscribe(
    //   postResData => {
    //     this.getChatHistory(true);
    //   },
    //   errorMessage => {
    //     this.isError = true;
    //     this.errorMessage = errorMessage;
    //     console.log(this.errorMessage);
    //   });
  }

  tabClick(tab) {
    const selectedTab = tab.tab.textLabel;
    if (selectedTab === 'Nachrichten' || selectedTab === '') {
      this.getChatHistory(true);
      setTimeout(() => {
        this.messageCount = 0;
      }, 4000);
    }
  }
  onImagePicked(event) {
    this.isImgError = false;
    this.isImgSelected = true;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.filename = file.name;
      this.filesize = file.size;
      if(file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png" && file.type != "application/pdf"){
        this.isImgError = true;
        this.imgErrorMessage = 'Bitte wählen Sie den Dateityp jpeg, png, pdf.';
        return true
      }
      if (file.size <= 2000000) {
        this.chatFormGroup.controls['message'].setErrors({'incorrect': false});
        // this.chatForm.get('message').clearValidators();
        // this.chatForm.get('message').updateValueAndValidity();
        this.chatFormGroup.patchValue({
          message: "",
        });
        this.isImgError = false;
        this.imgErrorMessage = '';
        const reader = new FileReader();
        this.chatFormGroup.get('logo').setValue(file);
        reader.onload = () => {
          this.imagePreview = reader.result as string;
          this.imgSrc = this.imagePreview;
        }
        reader.readAsDataURL(file);
      } else {
        this.chatFormGroup.get('logo').setValue(null);
        this.imgSrc = '';
        this.isImgSelected = false;
        this.filesize = this.filename = '';        this.isImgError = true;
        this.imgErrorMessage = 'Die Logo-Größe sollte weniger als 2 MB betragen.';
      }
    }
  }
  getChatHistory(type) {
    const id = this.matchId;
    this.messageService.getMessages(id).subscribe(getResData => {
      this.isError = false;
      this.errorMessage = "";
      this.getChat = getResData;
      this.messages = this.getChat.results;
      this.messageCount = 0;
      // this.getChat?.results.forEach(val => {
      //    if(val.is_read == false){
      //     this.messageCount++;
      //    }
      // });
      let applierUser = JSON.parse(localStorage.getItem('userData'));
      for (let message of this.getChat?.results) {
        message.isPdf = false;
        message.fileName = "";
        if(message.file){
          message.isPdf = this.checkPdfExists(message.file)
          message.fileName = this.getFileNameFromUrl(message.file)
        }
        if (message.is_read == false && applierUser.id != message.created_by) {
          this.messageCount++;
        }
      }
      if (this.messageCount > 0 && type == true) {
        this.messageService.readChatMessages(this.matchId).subscribe(
          (resData) => { },
          (error) => {
            console.log("Error occurred while reading chat ");
          }
        );
      }
      this.loggedInUserId = this.authService.getUserData().id;
    }, errorMessage => {
      this.isError = true;
      this.errorMessage = errorMessage;
      console.log(this.errorMessage);
    });
  }

  checkPdfExists(url: string) {
    let parts = url.split(".");
    let param = parts[parts.length - 1];
    if (param=="pdf") {
        return true;
    }else{
        return false;
    }
  }

  getFileNameFromUrl(url: string) {
    let parts = url.split("/");
    let param = parts[parts.length - 1];
    return param;
  }

  openCallDialog() {
    let applierUser = JSON.parse(localStorage.getItem("userData"));
    const dialogRef = this.dialog.open(DialogCall, {
      width: "275px",
      data: {
        applier: this.company,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let chatTextOnCallFail = `Hallo ${this.company.name}, ich hatte Sie versucht telefonisch zu erreichen, leider ohne Erfolg.
         Gerne können Sie mich bei Gelegenheit unter folgender Nummer zurückrufen:  ${applierUser.phone_number
        }`;
        this.chatFormGroup.patchValue({
          message: chatTextOnCallFail,
        });
      }
    });
  }

  deleteImage(){
    this.chatFormGroup.get('logo').setValue(null);
    this.imgSrc = '';
    this.isImgSelected = false;
    this.filesize = this.filename = '';
  }

}

@Component({
  selector: 'app-dialog-call',
  templateUrl: 'app-dialog-call.html',
  styleUrls: ['./app-dialog-call.scss']
})
export class DialogCall {
  applierDetails: any;
  loginUser: any;

  constructor(private router: Router,
              @Inject(MAT_DIALOG_DATA) data,
              private dialogRef: MatDialogRef<JobDetailsComponent>) {
    this.loginUser = JSON.parse(localStorage.getItem("userData"));
    this.applierDetails = data;
  }
  doClose() {
    this.dialogRef.close();
  }
}
