<mat-spinner *ngIf="isSpinner"></mat-spinner>
<div *ngIf="job" [class.isMobile]="isMobile">
  <mat-card class="match-details user-details">
    <div *ngIf="!isApplicationPage">
      <mat-card-header>
        <mat-card-title>
          <a class="back" routerLink="/dashboard" *ngIf="isMobile">
            <span class="fa fa-arrow-left" style="color:white"></span><span style="color:#ffffff"> {{job.title}}</span></a>
          <span *ngIf="!isMobile">{{job.title}}</span>
        </mat-card-title>
      </mat-card-header>
    </div>
    <mat-card-content>
      <div class="row row-no-gutters icon-table">
        <div class="icon-box col-lg-6 col-xl-6 col-12">
          <span class="fa-road fa fa-3x child" style="vertical-align: top;"></span>
          <!--Tourengebie wie in Fragebogen-->
          <p class="child"><span style="font-size: 18px;">{{getTours(job.tours)}}</span><br>Tourenprofil</p>
        </div>
        <div class="icon-box col-lg-6 col-xl-6 col-12">
          <span class="fa-clock fa fa-3x child" style="vertical-align: top;"></span>
          <!--Schicht wie im Fragebogen-->
          <p class="child"><span style="font-size: 18px;">{{getShifts(job.shift)}}</span><br>Art der Schichten</p>
        </div>
      </div>
      <div class="row row-no-gutters icon-table mt-3">
        <div class="icon-box col-lg-6 col-xl-6 col-12">
          <span class="fa-map-signs fa fa-3x child"></span>
          <!--Job.Standort Abfahrtsort-->
          <p class="child"><span style="font-size: 18px;">{{job.city}}</span><br>{{distance.toFixed(2)}} km zum Abfahrtsort
          </p>
        </div>
        <div class="icon-box col-lg-6 col-xl-6 col-12">
          <span class="fa-truck-moving fa fa-3x child" style="vertical-align: top;"></span>
          <!--(fahrzeugtyp aus Fragebogen-->
          <p class="child"><span style="font-size: 18px;">{{getVehicleTypeExperience(job.vehicle_experience)}}</span><br>Fahrzeugtyp</p>
        </div>
      </div>

      <div *ngIf="job.salary_minimum > 0" style="font-size: 18px;" class="mb-0">
        <hr>
        <p><strong>Verdienst&nbsp;<i matTooltip="Angabe des Bruttomonatsgehalts zzgl. Spesen" matTooltipPosition="above" class="fas fa-question-circle"></i>:</strong></p>
        <p  class="salary_min">{{ job.salary_minimum }} € <span *ngIf="job.salary_maximum ">- {{ job.salary_maximum }} €</span></p>
      </div>
      <hr>
      <div style="margin: 20px 0 5px 0;font-size: 18px;">
        <b>Anforderungen:</b></div>
      <br>
      <ul>
        <li>
          <div class="row">
            <p class="col-6">Führerscheinklasse:</p>
            <div class="col-6">
              <p style="display:inline-block">{{job.license}}
              <span class="musthave" style="display:inline-block">must have</span>
              </p>
            </div>
          </div>
        </li>
        <li>
          <div class="row">
            <p class="col-6">zusätzliche Führerscheine:</p>
            <p class="col-6">{{getAdditionalLicense(job.additional_license)}}
              <!-- <span class="musthave" *ngIf="job.is_need_additional_driver_license" style="display:inline-block">must have</span> -->
            </p>
          </div>
        </li>
        <li>
          <div class="row">
            <p class="col-6">Erfahrung auf Fahrzeugtyp:</p>
            <p class="col-6">{{getVehicleTypeExperience(job.vehicle_experience)}}
              <!-- <span *ngIf="job.is_need_vehicle_type_experience" class="musthave" style="display:inline-block">must have</span> -->
            </p>
          </div>
        </li>
        <li>
          <div class="row">
            <p class="col-6">Berufserfahrung:</p>
            <p class="col-6">{{job.work_experience > 0 ? 'min. ' + job.work_experience + ' Jahre' : 'Keine Berufserfahrung notwendig'}}
              <!-- <span *ngIf="job.is_need_work_experience" class="musthave" style="display:inline-block">must have</span> -->
            </p>
          </div>
        </li>
        <li>
          <div class="row">
            <p class="col-6">Deutschkenntnisse:</p>
            <p class="col-6">{{germanLanSkill}}
              <!-- <span *ngIf="job.is_need_language_skills" class="musthave" style="display:inline-block">must have</span> -->
            </p>
          </div>
        </li>
      </ul>
      <hr>
      <div>
        <p *ngIf="job.being_transported" style="font-size: 18px;" class="mb-0  p-0"><b>Was wird transportiert:</b></p>
<!--        <div style="margin-top: -20px;">{{job.being_transported}}</div>-->
        <div style="margin-top: -20px;" [innerHtml]="job.being_transported" style="white-space: pre-line"></div><br>
        <p *ngIf="job.specific_tasks" style="font-size: 18px;" class="mb-0 mt-2"><b>Was uns sonst noch wichtig ist:</b></p>
<!--        <div style="margin-top: -20px;">{{job.specific_tasks}}</div>-->
        <div style="margin-top: -20px;" [innerHtml]="job.specific_tasks" style="white-space: pre-line"></div>
      </div>
      <hr *ngIf="job.being_transported || job.specific_tasks">
      <div class="first-key-facts" *ngIf="job.benefit">
        <p style="font-size: 18px;"><b>Vorteile des Unternehmens:</b></p>
        <mat-chip-list>
          <div *ngFor="let jobAdvantage of job.benefit.split(',')">
            <mat-chip color="accent" selected>{{jobAdvantage}}</mat-chip>
          </div>
        </mat-chip-list>
      </div>
      <hr *ngIf="job.benefit">
      <div style="padding: 0 10px 10px 0;font-size: 18px;" class="mt-2"><b>Über das Unternehmen:</b></div>
      <div>{{company.about}}</div>
    </mat-card-content>
      <div *ngIf="!isApplicationPage">
        <mat-card-actions>
        <button mat-flat-button color="primary" class="col mx-1" (click)="confirmJobApplication()">
          <span class="fas fa-1-5x fa-check-circle" style="padding-right:5px"></span> <span class="icon-text-button">Bewerben</span>
        </button>
        <button mat-flat-button color="accent" class="col mx-1" (click)="refuseJobApplication()">
          <span class="fas fa-1-5x fa-times-circle" style="padding-right:5px"></span><span class="icon-text-button">Ablehnen</span>
        </button>
        </mat-card-actions>
      </div>
      <mat-card-actions  class="{{isMobile == true ? 'mt-2 mb-2' :''}}">
      <div *ngIf="isApplicationPage && JobStatus < 8 && JobStatus != 4 && JobStatus != 5" class="row w-100 mb-2 appBtn">
        <button mat-flat-button color="accent" (click)="rejectJobApplication()" class="row w-100">
          <span class="fas fa-1-5x fa-times-circle" style="padding-right:5px"></span><span class="icon-text-button">Unternehmen absagen</span>
        </button>
      </div>
    </mat-card-actions>
    <div *ngIf="isApplicationPage  && (JobStatus == 5 || JobStatus == 4)">
      <mat-card-actions class="{{isMobile?'mb-5':''}}">
      <button mat-flat-button color="accent" class="col mx-1" >
        <span class="icon-text-button">Entscheidung</span>
      </button>
      <button mat-flat-button color="accent" class="col mx-1" (click)="rejectJobApplication()">
        <span class="fas fa-1-5x fa-times-circle" style="padding-right:5px"></span><span class="icon-text-button">Bewerbung zurückziehen</span>
      </button>
      </mat-card-actions>
    </div>
  </mat-card>
</div>
