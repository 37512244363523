<mat-card class="profil-card">
  <mat-card-content>
    <div class="name"><b>Elias Müller</b></div>

    <!--Ausbildung-->
    <mat-card class="profil-details-card">
      <div class="name-detail"><b>Ausbildung:</b></div>
      <app-educationprofil></app-educationprofil>
    </mat-card>


    <!--Weiterbildung-->
    <mat-card class="profil-details-card">
      <div class="name-detail"><b>Weiterbildung:</b></div>
      <app-further-education-profile></app-further-education-profile>
    </mat-card>

    <!--Skills-->
    <mat-card class="profil-details-card">
      <div class="name-detail"><strong>Kenntnisse und Fähigkeiten:</strong></div>
      <app-skillprofil></app-skillprofil>
    </mat-card>

    <!--Sprachen-->
    <mat-card class="profil-details-card">
      <div class="name-detail"><b>Sprachen:</b></div>
      <app-languageprofil></app-languageprofil>
    </mat-card>

    <!--Arbeitserfahrung-->
    <mat-card class="profil-details-card">
      <div class="name-detail"><b>Erfahrung</b></div>
      <p>10 Jahre</p>
    </mat-card>


    <!--Arbeitsort-->
    <mat-card class="profil-details-card">
      <div class="name-detail"><b>Arbeitsort:</b></div>
      <mat-chip-list>
        <mat-chip color="primary" selected>Servicebereich</mat-chip>
        <mat-chip color="primary" selected>Kunden</mat-chip>
        <mat-chip color="primary" selected>Im Freien</mat-chip>
        <mat-chip color="primary" selected>Baustelle</mat-chip>
      </mat-chip-list>
    </mat-card>


  </mat-card-content>


</mat-card>
