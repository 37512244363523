<div class="row">
    <div class="col col-lg-3 reached">
        <span class="fas fa-paper-plane"></span>
        <div class="process-text">
            <span>Beworben</span>
        </div>
    </div>
    <div class="col col-lg-3 reached">
        <span class="fas fa-check-square"></span>
        <div class="process-text">
            <span>Bewerbung wird gecheckt</span>
        </div>
    </div>
    <div class="col col-lg-3">
        <span class="fas fa-users"></span>
        <div class="process-text">
            <span>Bewerbungsgespräch bestätigen</span>
        </div>
    </div>
    <div class="col col-lg-3">
        <span class="fas fa-handshake"></span>
        <div class="process-text">
            <span>Arbeitgeber zusagen</span>
        </div>
    </div>
</div>

