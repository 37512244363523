<div class="row" [class.isMobile]="isMobile">
  <mat-vertical-stepper class="col stepper" style="padding: 0;">
    <mat-step>
      <p class="h3">Passwort zurücksetzen</p>
      <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
        <div id="accordion" class="mat-accordion-div">
          <mat-accordion class="col">
            <mat-expansion-panel [expanded]="true" [disabled]="true" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span style="margin: auto;"><b>Neues Passwort eingeben</b></span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-form-field appearance="outline">
                <mat-label>Passwort</mat-label>
                <input matInput
                       type="password"
                       id="password"
                       class="form-control"
                       formControlName="password"
                       required>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Bestätige Passwort</mat-label>
                <input matInput
                       type="password"
                       id="confirmPassword"
                       class="form-control"
                       formControlName="confirm_password"
                       required>
              </mat-form-field>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <p *ngIf="this.isError" class="text-danger">{{errorMessage}}</p><br>
        <p *ngIf="!this.isError" class="text-success" style="margin-bottom: 2rem;">{{successMessage}}</p><br>
        <div class="button-area row">
          <div class="col-lg-2"></div>
          <div class="col"></div>
          <button mat-flat-button [disabled]="resetPasswordForm.invalid || isError" class="col" color="primary" type="submit"
                  matStepperNext>
            <span class="icon-text-button">Zurücksetzen</span>
            <span class="fas fa-sign-in"></span>
          </button>
        </div>
      </form>
    </mat-step>
  </mat-vertical-stepper>
</div>
