import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {DeviceDetectorService} from "ngx-device-detector";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../../environments/environment.prod";
import {JobService} from "../../../../services/company/job.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {validateCheckbox} from "../../../../services/validateCheckbox";
import {JobCreateSuccessDialog} from "./questionnaire-form/questionnaire-form.component";
import {LanguageModel} from "../../../../models/language.model";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {DateAdapter} from '@angular/material/core';
import * as moment from 'moment';

declare var _paq: any;
const codes = require('german-postal-codes')
// console.log(codes)
@Component({
  selector: 'app-create-jobs',
  templateUrl: './create-jobs.component.html',
  styleUrls: ['./create-jobs.component.scss']
})
export class CreateJobsComponent implements OnInit {
  isMobile: boolean;
  jobId: number;
  jobDetails: any;
  responseData: any;
  company: any;

  questionnaireForm: FormGroup;
  toursForm: FormGroup;
  shiftsFormGroup: FormGroup;
  // mustFormGroup: FormGroup;
  vehicleType: FormGroup;
  additionalLicense: FormGroup;
  isError = false;

  selectedGerman = 1;
  selectedEnglish = 4;
  @Output() onDatePicked: EventEmitter<any> = new EventEmitter<any>();
  selectedDate = new Date();
  startAt = new Date();
  minDate = new Date();
  maxDate = new Date(new Date().setMonth(new Date().getMonth() + 24));
  dateEvents: Date;
  dt: any;
  apiResponse: any;
  companyDetails: any;

  title: string;
  tours: string;
  shift: string;
  requiredFields: string;
  address: string;
  city: string;
  vehicleTypeDisplay: string;
  drivingLicenseClass: string;
  additionalDrivingLicense: string;
  minWorkExperience = 0;
  minSalary = 0;
  maxSalary = 0;
  beingTransported: string;
  germanSkills: string;
  professionaLicense = "Ja";
  other: string;
  //Local Variable defined
  formattedAddress = " ";
  options = {
    componentRestrictions: {
      country: ["de"]
    }
  }
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  advantages = [{
    name: 'Prämienzuschlag'
  }];
  street: string;
  streetNumber: string;
  isAdditionalLicense = false;
  submitted = false;
  isPostcodeValid = false;
  IsNeedWorkExperience= false;
  IsNeedVehicleTypeExperience= false;
  IsNeedLanguageSkills= false;
  IsNeedDriverLicense= false;
  IsNeedAdditionalDriverLicense= false;
  constructor(private deviceService: DeviceDetectorService,
              private router: Router,
              private _formBuilder: FormBuilder,
              private http: HttpClient,
              public dialog: MatDialog,
              private dateAdapter: DateAdapter<Date>,
              private activatedRoute: ActivatedRoute,
              private jobService: JobService) {
    this.isMobile = this.deviceService.isMobile();
    // this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy

  }

  ngOnInit(): void {

    this.initForm();
    this.getCompanyDetails();

    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.jobId = +params['id'];
        if (this.jobId) {
          this.getJob();
        }
      }
    });
  }

  initForm() {
    this.toursForm = this._formBuilder.group({
      local: false,
      national: false,
      international: false,
    }, {
      validator: (formGroup: FormGroup) => {
        return validateCheckbox(formGroup);
      }
    });

    this.shiftsFormGroup = this._formBuilder.group({
      day: false,
      night: false,
      all: false,
      weekend: false,
    }, {
      validator: (formGroup: FormGroup) => {
        return validateCheckbox(formGroup);
      }
    });

    this.vehicleType = this._formBuilder.group({
      "tractor-trailer": false,
      "solo-vehicle": false,
      "articulated-train": false,
      "refrigerated-vehicle": false,
      "swap-body": false,
      "tanker": false,
      "silo": false,
      "heavy-transport": false
    }, {
      validator: (formGroup: FormGroup) => {
        return validateCheckbox(formGroup);
      }
    });

    this.additionalLicense = this._formBuilder.group({
      'NO_LICENSE': false,
      'FORKLIFT': false,
      'CRANE': false,
      'ADR_BASIC': false,
      'ADR_TANK': false,
      'ADR_EXPLOSIVE': false,
      'ADR_RADIOACTIVE': false
    }, {
      validator: (formGroup: FormGroup) => {
        return validateCheckbox(formGroup);
      }
    });

    let selectedDt = this.selectedDate;
    this.dt = selectedDt.getFullYear() + '-' + (selectedDt.getMonth() + 1) + '-' + selectedDt.getDate();

    this.questionnaireForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      tours: this.toursForm,
      shift: this.shiftsFormGroup,
      postCode: new FormControl('', [Validators.required,Validators.maxLength(5)]),
      // street: new FormControl('', [Validators.required]),
      street: new FormControl(''),
      city: new FormControl('', [Validators.required]),
      vehicleType: this.vehicleType,
      driverLicense: new FormControl('', [Validators.required]),
      additionalLicense: this.additionalLicense,
      minimumExperience: new FormControl(0, [Validators.required, Validators.max(30)]),
      earliestStartDate: new FormControl(this.selectedDate, [Validators.required]),
      skillGermanLan: new FormControl('', [Validators.required]),
      merit: new FormControl(0, [Validators.required, Validators.max(250)]),
      skillEnglishLan: new FormControl(),
      beingTransported: new FormControl(''),
      other: new FormControl(''),
      benefit: new FormControl(''),
      salary_minimum: new FormControl('', [Validators.min(1800),Validators.max(5000), Validators.pattern('^[0-9]*$')]),
      salary_maximum: new FormControl('', [Validators.min(1800),Validators.max(5000),Validators.pattern('^[0-9]*$')]),
      isJobActive: new FormControl(true),
      is_need_work_experience: new FormControl(false),
      is_need_driver_license: new FormControl(true),
      is_need_additional_driver_license: new FormControl(false),
      is_need_vehicle_type_experience: new FormControl(false),
      is_need_language_skills: new FormControl(false),
      is_need_professional_license: new FormControl(true),
    });
    this.getMusthave();
  }

  getCompanyDetails() {
    this.jobService.getCompanyDetails().subscribe(resData => {
      this.companyDetails = resData;
    }, errorMessage => {
      console.log("errorMessage In user-match component");
      console.log(errorMessage);
      this.dialog.open(CompanyNotFoundWarningDialog);
      this.isError = true;
    });
  }

  AddressChange(address: any) {
    for (let addressComponent of address.address_components) {
      if (addressComponent.types[0] == 'route') {
        this.street = addressComponent.long_name;
      }

      if (addressComponent.types[0] == 'street_number') {
        this.streetNumber = addressComponent.long_name;
      }

      if (addressComponent.types[0] == 'postal_code') {
        this.questionnaireForm.value.postCode = addressComponent.long_name;
        this.questionnaireForm.patchValue({
          postCode: addressComponent.long_name
        });
        this.checkPostCode(address)
      }

      if (addressComponent.types[0] == 'locality') {
        this.city = addressComponent.long_name;
        this.questionnaireForm.patchValue({
          city: addressComponent.long_name
        })
      }
    }

    if (this.street || this.streetNumber) {
      this.questionnaireForm.value.street = this.street + ', ' + this.streetNumber;
      if (this.streetNumber) {
        this.questionnaireForm.patchValue({
          street: this.street + ', ' + this.streetNumber
        });
      } else {
        this.questionnaireForm.patchValue({
          street: this.street
        });
      }
    }
  }

  scrollTo(el: Element): void {
    if (el) {
       el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
 }
 scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
    const firstElementWithGroupError = document.querySelector('.ng-invalid[formGroupName]');
    this.scrollTo(firstElementWithGroupError);
 }

  createJob() {
    this.scrollToError()
    if (this.questionnaireForm.valid) {
      console.log(this.questionnaireForm.value);
    }
    this.submitted = true;
    if(this.questionnaireForm.valid){
      let tours = [];
      Object.keys(this.toursForm.controls).forEach(key => {
        if (this.toursForm.get(key).value == true) {
          tours.push(key);
        }
      });

      let shift = [];
      Object.keys(this.shiftsFormGroup.controls).forEach(key => {
        if (this.shiftsFormGroup.get(key).value == true) {
          shift.push(key);
        }
      });

      let vehicleExperience = [];
      Object.keys(this.vehicleType.controls).forEach(key => {
        if (this.vehicleType.get(key).value == true) {
          vehicleExperience.push(key)
        }
      });

      let additionalLicense = [];
      Object.keys(this.additionalLicense.controls).forEach(key => {
        if (this.additionalLicense.get(key).value == true) {
          additionalLicense.push(key);
        }
      });
      let advantages = [];
      for (let adv of this.advantages) {
        advantages.push(adv.name);
      }
      let language = [];
      if (this.questionnaireForm.value.skillGermanLan) {
        language.push(this.questionnaireForm.value.skillGermanLan);
      }
      if (this.questionnaireForm.value.skillEnglishLan) {
        language.push(this.questionnaireForm.value.skillEnglishLan);
      }

      // Constructing PostData object for applicant driving details.
      let jobDetails: any = {
        tours: tours,
        shift: shift,
        vehicle_experience: vehicleExperience,
        additional_license: additionalLicense,
        title: this.questionnaireForm.value.title,
        start_work: this.dt,
        work_experience: this.questionnaireForm.value.minimumExperience,
        license: this.questionnaireForm.value.driverLicense,
        postcode: this.questionnaireForm.value.postCode,
        city: this.questionnaireForm.value.city,
        street: this.questionnaireForm.value.street,
        professional_license: true,
        being_transported: this.questionnaireForm.value.beingTransported,
        specific_tasks: this.questionnaireForm.value.other,
        language: language,
        benefit: advantages.toString(),
        is_active: this.questionnaireForm.value.isJobActive,
        salary_minimum: (this.questionnaireForm.value.salary_minimum) ? this.questionnaireForm.value.salary_minimum : null,
        salary_maximum: (this.questionnaireForm.value.salary_maximum ) ? this.questionnaireForm.value.salary_maximum : null,
        is_need_additional_driver_license: this.questionnaireForm.value.is_need_additional_driver_license,
        is_need_driver_license: this.questionnaireForm.value.is_need_driver_license,
        is_need_language_skills: this.questionnaireForm.value.is_need_language_skills,
        is_need_professional_license: this.questionnaireForm.value.is_need_professional_license,
        is_need_vehicle_type_experience: this.questionnaireForm.value.is_need_vehicle_type_experience,
        is_need_work_experience: this.questionnaireForm.value.is_need_work_experience,
      };

      if (this.jobId) {
        const dialogRef = this.dialog.open(JobCreateSuccessDialog, {
          data: {
            isEdit: false,
            isConfirm: true
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          // console.log(`Dialog result: ${result}`);
          if (result) {
            this.jobService.editJob(jobDetails, this.jobId).subscribe(resData => {
              this.apiResponse = resData;
              this.submitted = false
              this.dialog.open(JobCreateSuccessDialog, {
                data: {
                  isEdit: true,
                  isConfirm: false
                }
              });
              this.questionnaireForm.reset();
              this.router.navigate([`/job/${this.apiResponse.id}`]);

            }, errorMessage => {
              this.submitted = false
              console.log("errorMessage In user-match component");
              console.log(errorMessage);
              this.isError = true;
            });
          }
        });
      } else {
        this.jobService.createJob(jobDetails).subscribe(resData => {
          this.apiResponse = resData;
          this.submitted = false
          this.dialog.open(JobCreateSuccessDialog, {
            data: {
              isEdit: false
            }
          });

          _paq.push(['setDocumentTitle', document.title]);
          _paq.push(['trackPageView']);
          _paq.push(['trackEvent', 'Company', 'Create Job']);

          this.questionnaireForm.reset();
          this.router.navigate([`/job/${this.apiResponse.id}`]);

        }, errorMessage => {
          this.submitted = false
          console.log("errorMessage In user-match component");
          console.log(errorMessage);
          this.isError = true;
        });
      }
    }

  }

  onSelect(event) {
    this.selectedDate = event;
  }

  DateFilterBkp = (d: Date): boolean => {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    // return day !== 0 && day !== 6;
    return true;
  }

  DateFilter = (d: Date): boolean => {
    let myDate;
    let momentToDate = moment(d).toDate();
    const day = (momentToDate || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    // return day !== 0 && day !== 6;
    return true;
  }

  addEvent(event) {
    // let selectedDt = event.value;
    // this.dt = selectedDt.getFullYear() + '-' + (selectedDt.getMonth() + 1) + '-' + selectedDt.getDate();
    let momentToDate = moment(event.value).toDate();
    this.dt = (momentToDate || new Date()).getFullYear() + '-' + ((momentToDate || new Date()).getMonth() + 1) + '-' + (momentToDate || new Date()).getDate();
  }

  onChange(fieldName: string, event: any) {
    switch (fieldName) {
      case 'title':
        this.title = event.target.value;
        break;

      case 'tours':
        let tours = [];
        if (this.toursForm.value.national == true || this.toursForm.value.international == true) {
          this.toursForm.patchValue({
            // 'local': false
          });
        }

        Object.keys(this.toursForm.controls).forEach(key => {
          if (this.toursForm.get(key).value == true) {
            tours.push(key);
          }
        });
        this.tours = this.getTours(tours);
        break;

      case 'shift':
        let shift = [];
        Object.keys(this.shiftsFormGroup.controls).forEach(key => {
          if (this.shiftsFormGroup.get(key).value == true) {
            shift.push(key);
          }
        });
        this.shift = this.getShifts(shift);
        break;

      case 'city':
        this.city = event.target.value;
        break;

      case 'vehicleType':
        let vehicleExperience = [];
        Object.keys(this.vehicleType.controls).forEach(key => {
          if (this.vehicleType.get(key).value == true) {
            vehicleExperience.push(key)
          }
        });
        this.vehicleTypeDisplay = this.getVehicleTypeExperience(vehicleExperience);
        break;

      case 'drivingLicenseClass':
        this.drivingLicenseClass = event.value;
        break;

      case 'additionalDrivingLicense':

        if (this.additionalLicense.value.FORKLIFT == true ||
          this.additionalLicense.value.CRANE == true ||
          this.additionalLicense.value.ADR_BASIC == true ||
          this.additionalLicense.value.ADR_TANK == true ||
          this.additionalLicense.value.ADR_EXPLOSIVE == true ||
          this.additionalLicense.value.ADR_RADIOACTIVE == true) {
          this.additionalLicense.patchValue({
            'NO_LICENSE': false,
          });
        }

        let additionalLicense = [];
        Object.keys(this.additionalLicense.controls).forEach(key => {
          if (this.additionalLicense.get(key).value == true) {
            additionalLicense.push(key);
          }
        });
        this.additionalDrivingLicense = this.getAdditionalLicense(additionalLicense);
        break;

      case 'minWorkExperience':
        this.minWorkExperience = event.value;
        break;

      case 'beingTransported':
        this.beingTransported = event.target.value;
        break;

      case 'language':
        this.germanSkills = this.getLanguageSkill(event.value);
        break;

      case 'professional_license':
        this.professionaLicense = (this.questionnaireForm.value.is_need_professional_license == true) ? 'Ja' : 'Nein';
        break;

      case 'other':
        this.other = event.target.value;
        break;

      case 'salary_minimum':
        this.checkMinMax();
        break;
      case 'salary_maximum':
        this.checkMinMax();
        break;
      case 'musthave':
        this.getMusthave();

          break;

      default:
        return 'Not available';

    }
  }

  checkMinMax() {
    this.minSalary = this.questionnaireForm.get("salary_minimum").value;
    this.maxSalary = this.questionnaireForm.get("salary_maximum").value;
    if(this.questionnaireForm.get("salary_minimum").value > this.questionnaireForm.get("salary_maximum").value){
      // this.questionnaireForm.setErrors({ 'invalid': true });
    }
  }

  toggleAdditionalLicense(values: any): void {
    if (values.checked == true) {
      this.additionalLicense.patchValue({
        'NO_LICENSE': true,
        'FORKLIFT': false,
        'CRANE': false,
        'ADR_BASIC': false,
        'ADR_TANK': false,
        'ADR_EXPLOSIVE': false,
        'ADR_RADIOACTIVE': false
      });
    }else{
      this.additionalLicense.patchValue({
        'NO_LICENSE': false,
      });
    }
  }
  checkLocalTransport(values: any): void {
    if (values.checked == true) {
      this.toursForm.patchValue({
        // 'national': false,
        // 'international': false,
      });
    }else{
      this.toursForm.patchValue({
        // 'local': false,
      });
    }
  }


  getJob(): void {
    const id = this.jobId;
    this.jobService.getJob(id)
      .subscribe(resData => {
        this.responseData = resData;
        this.company = this.responseData.company;
        let germanSkill;
        let englishSkill;
        for (let lan of this.responseData.language) {
          if (lan.name.includes('german')) {
            germanSkill = lan.id;
          }
          if (lan.name.includes('english')) {
            englishSkill = lan.id;
          }
        }

        if(this.responseData.street) {
          if(this.responseData.street.length > 0) {
            this.address = this.responseData.street + ', ' + this.responseData.postcode + ', ' + this.responseData.city;
          } else {
            this.address = this.responseData.postcode + ', ' + this.responseData.city;
          }
        } else {
          this.address = this.responseData.postcode + ', ' + this.responseData.city;
        }

        this.professionaLicense = (this.responseData.is_need_professional_license == true) ? 'Ja' : 'Nein';

        this.title = this.responseData.title;
        this.tours = this.getTours(this.responseData.tours);
        this.shift = this.getShifts(this.responseData.shift);
        this.city = this.responseData.city;
        this.vehicleTypeDisplay = this.getVehicleTypeExperience(this.responseData.vehicle_experience);
        this.drivingLicenseClass = this.responseData.license;
        this.additionalDrivingLicense = this.getAdditionalLicense(this.responseData.additional_license);
        this.minWorkExperience = this.responseData.work_experience;
        this.beingTransported = this.responseData.being_transported;
        this.germanSkills = this.getLanguageSkill(germanSkill);
        this.other = this.responseData.specific_tasks;
        // this.minDate = new Date(this.responseData.start_work);
        this.questionnaireForm.patchValue({
          title: this.responseData.title,
          tours: {
            local: this.responseData.tours.includes('local'),
            national: this.responseData.tours.includes('national'),
            international: this.responseData.tours.includes('international')
          },
          shift: {
            day: this.responseData.shift.includes('day'),
            night: this.responseData.shift.includes('night'),
            all: this.responseData.shift.includes('all'),
            weekend: this.responseData.shift.includes('weekend'),
          },
          postCode: this.responseData.postcode,
          street: this.responseData.street,
          city: this.responseData.city,
          vehicleType: {
            "tractor-trailer": this.responseData.vehicle_experience.includes('tractor-trailer'),
            "solo-vehicle": this.responseData.vehicle_experience.includes('solo-vehicle'),
            "articulated-train": this.responseData.vehicle_experience.includes('articulated-train'),
            "refrigerated-vehicle": this.responseData.vehicle_experience.includes('refrigerated-vehicle'),
            "swap-body": this.responseData.vehicle_experience.includes('swap-body'),
            "tanker": this.responseData.vehicle_experience.includes('tanker'),
            "silo": this.responseData.vehicle_experience.includes('silo'),
            "heavy-transport": this.responseData.vehicle_experience.includes('heavy-transport'),
          },
          driverLicense: this.responseData.license,
          additionalLicense: {
            'NO_LICENSE': this.responseData.additional_license.includes('NO_LICENSE'),
            'FORKLIFT': this.responseData.additional_license.includes('FORKLIFT'),
            'CRANE': this.responseData.additional_license.includes('CRANE'),
            'ADR_BASIC': this.responseData.additional_license.includes('ADR_BASIC'),
            'ADR_TANK': this.responseData.additional_license.includes('ADR_TANK'),
            'ADR_EXPLOSIVE': this.responseData.additional_license.includes('ADR_EXPLOSIVE'),
            'ADR_RADIOACTIVE': this.responseData.additional_license.includes('ADR_RADIOACTIVE')
          },

          minimumExperience: this.responseData.work_experience,
          earliestStartDate: this.minDate,
          // earliestStartDate: new Date(this.responseData.start_work),
          skillGermanLan: germanSkill,
          skillEnglishLan: englishSkill,
          beingTransported: this.responseData.being_transported,
          other: this.responseData.specific_tasks,
          isJobActive: this.responseData.is_active,
          benefit: this.responseData.benefit,
          salary_minimum: this.responseData.salary_minimum,
          salary_maximum: this.responseData.salary_maximum,
          is_need_additional_driver_license: this.responseData.is_need_additional_driver_license,
          is_need_driver_license: this.responseData.is_need_driver_license,
          is_need_language_skills: this.responseData.is_need_language_skills,
          is_need_professional_license: this.responseData.is_need_professional_license,
          is_need_vehicle_type_experience: this.responseData.is_need_vehicle_type_experience,
          is_need_work_experience: this.responseData.is_need_work_experience,
        });
        this.minSalary =  this.responseData.salary_minimum;
        this.maxSalary =  this.responseData.salary_maximum;
        if (this.responseData.benefit) {
          let advantages = [];
          for (let benefit of this.responseData.benefit.split(',')) {
            advantages.push({name: benefit});
          }
          this.advantages = advantages;
        }
        this.isPostcodeValid = false;
        this.getMusthave();
      }, errorMessage => {
        console.log(errorMessage);
      });
  }

  getTours(tours) {
    let translatedTours = [];
    tours.forEach(tour => {
      if (tour == "local") {
        translatedTours.push('Nahverkehr');
      } else if (tour == "national") {
        translatedTours.push("Fernverkehr (national)");
      } else if (tour == "international") {
        translatedTours.push("Fernverkehr (international)");
      } else {
        translatedTours.push("Not available");
      }
    });
    return translatedTours.join(', ');
  }

  getShifts(shifts) {
    let translatedShifts = [];
    shifts.forEach(shift => {
      if (shift == "day") {
        translatedShifts.push('Tagesschicht');
      } else if (shift == "night") {
        translatedShifts.push("Nachtschicht");
      } else if (shift == "weekend") {
        translatedShifts.push("Wochenende");
      } else if (shift == "all") {
        translatedShifts.push("Wechselschicht");
      } else {
        translatedShifts.push("Not available");
      }
    });
    return translatedShifts.join(', ');
  }

  getMusthave() {
    let mandadryArray = [];
    mandadryArray.push({key:  'is_need_work_experience',value: this.questionnaireForm.get("is_need_work_experience").value});
    mandadryArray.push({key:  'is_need_vehicle_type_experience',value: this.questionnaireForm.get("is_need_vehicle_type_experience").value});
    mandadryArray.push({key:  'is_need_language_skills',value: this.questionnaireForm.get("is_need_language_skills").value});
    mandadryArray.push({key:  'is_need_driver_license',value: this.questionnaireForm.get("is_need_driver_license").value});
    mandadryArray.push({key:  'is_need_additional_driver_license',value: this.questionnaireForm.get("is_need_additional_driver_license").value});
    this.IsNeedWorkExperience= this.questionnaireForm.get("is_need_work_experience").value;
    this.IsNeedVehicleTypeExperience= this.questionnaireForm.get("is_need_vehicle_type_experience").value;
    this.IsNeedLanguageSkills= this.questionnaireForm.get("is_need_language_skills").value;
    this.IsNeedDriverLicense= this.questionnaireForm.get("is_need_driver_license").value;
    this.IsNeedAdditionalDriverLicense= this.questionnaireForm.get("is_need_additional_driver_license").value;
    let translatedRequired = [];
    mandadryArray.forEach(mandadry => {
      if(mandadry.value == true){
        if (mandadry.key == "is_need_work_experience" ) {
          translatedRequired.push('Arbeitserfahrung');
        } else if (mandadry.key == "is_need_vehicle_type_experience") {
          translatedRequired.push("Erfahrung auf Fahrzeugtyp");
        } else if (mandadry.key == "is_need_language_skills") {
          translatedRequired.push("Sprachkenntnisse");
        } else if (mandadry.key == "is_need_driver_license") {
          translatedRequired.push("Führerschein");
        }  else if (mandadry.key == "is_need_additional_driver_license") {
          translatedRequired.push("Weitere Führerscheine");
        }
      }
    });
    this.requiredFields = translatedRequired.join(', ');
  }

  getVehicleTypeExperience(vehicleType) {
    let translatedVehicleType = [];
    vehicleType.forEach(type => {
      if (type == "tractor-trailer") {
        translatedVehicleType.push('Sattelzug');
      } else if (type == "solo-vehicle") {
        translatedVehicleType.push("Solofahrzeug");
      } else if (type == "articulated-train") {
        translatedVehicleType.push("Gliederzug");
      } else if (type == "refrigerated-vehicle") {
        translatedVehicleType.push("Kühlfahrzeug");
      } else if (type == "swap-body") {
        translatedVehicleType.push("Wechselbrücke");
      } else if (type == "tanker") {
        translatedVehicleType.push("Tankfahrzeug");
      } else if (type == "silo") {
        translatedVehicleType.push("Silo");
      }  else if (type == "heavy-transport") {
        translatedVehicleType.push("Schwertransporte");
      } else {
        translatedVehicleType.push("Not available");
      }
    });

    return translatedVehicleType.join(', ');
  }

  getAdditionalLicense(additionalLicense) {
    let translatedAdditionalLicense = [];
    additionalLicense.forEach(license => {
      if (license == "NO_LICENSE") {
        translatedAdditionalLicense.push('Keinen weiteren Führerschein');
      } else if (license == "FORKLIFT") {
        translatedAdditionalLicense.push("Staplerführerschein");
      } else if (license == "CRANE") {
        translatedAdditionalLicense.push("Kranführerschein");
      } else if (license == "ADR_BASIC") {
        translatedAdditionalLicense.push("ADR-Basis");
      } else if (license == "ADR_TANK") {
        translatedAdditionalLicense.push("ADR-Tank");
      } else if (license == "ADR_EXPLOSIVE") {
        translatedAdditionalLicense.push("ADR Explosiv");
      } else if (license == "ADR_RADIOACTIVE") {
        translatedAdditionalLicense.push("ADR-Radioaktiv");
      } else {
        translatedAdditionalLicense.push("Not available");
      }
    });

    return translatedAdditionalLicense.join(', ');
  }

  getLanguageSkill(languagePk) {
    return new LanguageModel().getLanguageSkill(parseInt(languagePk));
  }

  compareFunction(o1: any, o2: any) {
    return (o1 == o2 && o1 == o2);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.advantages.push({name: value.trim()});
    }
    if (input) {
      input.value = '';
    }
  }

  remove(advantage): void {
    const index = this.advantages.indexOf(advantage);
    if (index >= 0) {
      this.advantages.splice(index, 1);
    }
  }

  refreshPage() {
    this.router.navigate(['/company']);
  }

  checkPostCode(event: any) {
    if(this.questionnaireForm.value.postCode !== 'undefined'){
      var target=codes.find(temp=>temp==this.questionnaireForm.value.postCode)
      if(target)
        this.isPostcodeValid = false;
      else
        this.isPostcodeValid = true;
    }
  }
}

@Component({
  selector: 'company-not-found',
  templateUrl: 'company-not-found-dialog.html',
})
export class CompanyNotFoundWarningDialog {

  constructor(private router: Router,
              private dialogRef: MatDialogRef<CreateJobsComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    dialogRef.disableClose = true;
  }

}
