<div class="row">
  <div class="col" (click)="showContent()">
    <mat-form-field class="example-chip-list" [class.noBorder]="!selectable">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let education of edu" (click)="showContent()" [selectable]="selectable" [removable]="removable"
          (removed)="remove(edu)">
          {{education}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input placeholder="Ausbildung hinzufügen..." #chipInput [formControl]="eduCtrl"
          [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let education of edu | async" [value]="education">
          {{education}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <button class="col-lg-2 col" mat-button style="color: red" *ngIf="selectable" (click)="hideContent()">
    Speichern
  </button>
  <button class="col-lg-2 col" mat-button *ngIf="!selectable" (click)="showContent()">
    Bearbeiten
  </button>
</div>