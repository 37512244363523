import { Component, Inject, Input, OnChanges, Output, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { DashboardService } from "../../../../services/company/dashboard.service";
import { JobService } from "../../../../services/company/job.service";
import { LanguageModel } from "src/app/models/language.model";
import { MatTabGroup } from "@angular/material/tabs";
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MessageService } from "../../../../services/message.service";
import { AuthService } from "../../../../services/auth.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { DialogCall } from "../../../applications/application-details/application-details.component";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CreateJobsComponent } from "../../jobs/create-jobs/create-jobs.component";
import { JobCreateSuccessDialog } from "../../jobs/create-jobs/questionnaire-form/questionnaire-form.component";
import { MatCalendarCellCssClasses } from "@angular/material/datepicker";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { formatDate } from "@angular/common";
import { ApplicantService } from "../../../../services/applicant.service";
import { ApplicantStatusModel } from "../../../../models/applicant-status.model";
import { DeviceDetectorService } from "ngx-device-detector";
const codes = require("german-postal-codes");

@Component({
  selector: "app-job-details",
  templateUrl: "./job-details.component.html",
  styleUrls: ["./job-details.component.scss"],
})
export class JobDetailsComponent implements OnInit, OnChanges {
  isMobile: boolean;
  @Input("jobId") jobId: number;
  @Input("applierId") applierId: number;
  @ViewChild("tabs", { static: false }) tabGroup: MatTabGroup;
  public Editor = ClassicEditor;
  applicationStatus = 2;
  applicantDetails: any;
  errorMessage: string;
  selected = new FormControl(0);
  selectedApplier: any;
  isError = false;
  chatForm: FormGroup;
  meetingForm: FormGroup;
  confirmInterviewDateForm: FormGroup;
  matchId: number;
  messages: any;
  loggedInUserId: number;
  isRead = false;
  chatTextOnCallFail: string;
  isSpinner = true;
  addInterviewDates = false;
  isApplierDetailsShow = false;
  confirmCuserDate = false;
  isPrpposalDetailsShow = false;
  isApplierSuggested = false;
  isApplierConfirm = false;
  isCompanyAgreed = false;
  isApplierAgreed = false;
  isCanceledByCompany = false;
  selectedDate: any;
  IsStreetNumberMsg = false;
  panelOpenState = false;
  jobStartDate = null;
  IsJobStartDate = false;
  addText = false;
  datesToHighlight = [
    "2019-01-22T18:30:00.000Z",
    "2019-01-22T18:30:00.000Z",
    "2019-01-24T18:30:00.000Z",
    "2019-01-28T18:30:00.000Z",
    "2019-01-24T18:30:00.000Z",
    "2019-01-23T18:30:00.000Z",
    "2019-01-22T18:30:00.000Z",
    "2019-01-25T18:30:00.000Z",
  ];
  private dateFormat = new RegExp(
    /^(?:(?:31(\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
  );
  options = {
    componentRestrictions: {
      country: ["de"],
    },
  };
  address: string;
  interview_date = [];
  // interview_date: string[] = [];
  dateArray1 = [];
  dateArray2 = [];
  dateArray3 = [];
  startTimes_1 = [];
  startTimes_2 = [];
  startTimes_3 = [];
  endTimes_1 = [];
  endTimes_2 = [];
  endTimes_3 = [];
  minDate = new Date();
  minDate2 = new Date();
  today = new Date();
  messageCount = 0;
  datePickedCountArray = [1];
  isStartDate_1 = false;
  isStartDate_2 = false;
  isStartDate_3 = false;
  formData: any;
  chatFormData: any;
  proposalDetails: any;
  submitted = false;
  status_1 = "Matched";
  status_2 = "Eingang";
  status_3 = "Prüfung";
  status_4 = "persönliches Interview";
  status_5 = "Entscheidung";
  status_6 = "";
  status_7 = "";
  status_8 = "";
  status_9 = "";
  mobileCurentStatus: any = "";
  isPostcodeValid = false;
  viewConfirm = false;
  @Output() postMessageEvent = new EventEmitter<any>();
  @Input() msgFromParent1: any[];
  @Input() currentMsgFromChild2ToChild1: any[];
  @Output() msgToSibling = new EventEmitter<any>();
  isImgError = false;
  screenWidth = 0;
  imgErrorMessage = '';
  currentMsgToSibling = '';
  imagePreview: string;
  imgSrc = './assets/no-image.jpeg';

  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private messageService: MessageService,
    private applicantService: ApplicantService,
    private authService: AuthService,
    private router: Router,
    private jobService: JobService,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.screenWidth = window.innerWidth;
    this.getStartTime();
  }
  dayEnum = {
    AFTERNOON: "nur nachmittags",
    MORNING: "nur vormittags",
    SATURDAY: "nur samstags",
  };

  AddressChange(address: any) {
    let street_number = "";
    this.IsStreetNumberMsg = true;
    for (let addressComponent of address.address_components) {
      if (addressComponent.types[0] == "street_number") {
        street_number = addressComponent.long_name;
        this.IsStreetNumberMsg = false;
      }

      if (addressComponent.types[0] == "route") {
        this.meetingForm.value.street =
          addressComponent.long_name + " " + street_number;
        this.meetingForm.patchValue({
          street: addressComponent.long_name + " " + street_number,
        });
      }

      if (addressComponent.types[0] == "postal_code") {
        this.meetingForm.value.postcode = addressComponent.long_name;
        this.meetingForm.patchValue({
          postcode: addressComponent.long_name,
        });
        this.checkPostCode(address);
      }

      if (addressComponent.types[0] == "locality") {
        this.meetingForm.value.city = addressComponent.long_name;
        this.meetingForm.patchValue({
          city: addressComponent.long_name,
        });
      }
    }
  }

  ngOnInit() {
    if(this.router.routerState.snapshot.url ==`/job-profile/chat/${this.jobId}/${this.applierId}` ) {
      this.selected.setValue(2);
      this.isRead = true;
    }
    this.minDate.setDate(this.today.getDate() + 1);
    // this.chatForm = new FormGroup({
    //   message: new FormControl(null, Validators.required),
    //   logo: new FormControl(null),
    // });

    this.chatForm = this.formBuilder.group({
      message: ['', [Validators.required]],
      logo: ['']
    });

    this.meetingForm = new FormGroup({
      meetingDate_1: new FormControl(null, Validators.required),
      meetingDate_2: new FormControl(null),
      meetingDate_3: new FormControl(null),
      startTime_1: new FormControl("0800", Validators.required),
      startTime_2: new FormControl(null),
      startTime_3: new FormControl(null),
      endTime_1: new FormControl("0900", Validators.required),
      endTime_2: new FormControl(null),
      endTime_3: new FormControl(null),
      messageOther: new FormControl(null),
      address: new FormControl(null),
      postcode: new FormControl(null, Validators.required),
      // postcodes:new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      street: new FormControl(null, Validators.required),
    });
    this.confirmInterviewDateForm = new FormGroup({
      job_start_date: new FormControl(null, Validators.required),
    });
    this.chatForm.patchValue({message: null});

    this.getProfile();
    // this.getInterviewProposal();
    setTimeout(() => {
      this.setEndTime("0800", 1);
    }, 1000);
  }

  onButtonClick() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate([`/job-profile/${this.jobId}/${this.applierId}`]);
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  markUserAsread() {
    this.dashboardService.readUser(this.matchId).subscribe(
      (resData) => {
        //  console.log("user mark as read");
      },
      (error) => {
        console.log("Error occurred while reading user");
        console.log(error);
      }
    );
  }

  getProfile() {
    let responseData;
    this.dashboardService.getJobProfile(this.jobId).subscribe(
      (resData) => {
        responseData = resData;
        this.isSpinner = false;
        // @ts-ignore
        for (let match of responseData?.match) {
          if (match.applier.id == this.applierId) {
            this.matchId = match.id;
            this.selectedApplier = match;
            this.jobStartDate = this.selectedApplier.job_start_date;
            this.confirmInterviewDateForm.patchValue({
              job_start_date: this.selectedApplier.job_start_date,
            });
            this.getChatHistory(false);
            this.getInterviewProposal();
            this.setDefaultAddress();
            this.markUserAsread();
            if (
              // this.selectedApplier.status == new ApplicantStatusModel().APPLIED
              this.selectedApplier.status == new ApplicantStatusModel().APPLICANT_AGREED
            ) {
              this.updateStatus(new ApplicantStatusModel().CHECKED);
            }
            let languageId = match.applier.language[0];
            if (languageId >= 4) {
              this.selectedApplier.language = "Keine Kenntnisse";
            } else {
              this.selectedApplier.language = new LanguageModel().getLanguageSkill(
                languageId
              );
            }
          }
        }
      },
      (errorMessage) => {
        this.errorMessage = errorMessage;
        console.log(this.errorMessage);
      }
    );
  }

  tabClick(tab) {
    const selectedTab = tab.tab.textLabel;
    if (selectedTab === "Nachrichten" || selectedTab === '') {
      this.isRead = true;
      this.getChatHistory(true);
    } else {
      this.selected.setValue(0);
    }
  }

  getAdditionalLicense(license: string) {
    switch (license) {
      case "NO_LICENSE":
        return "Keinen weiteren Führerschein";
      case "FORKLIFT":
        return "Staplerführerschein";
      case "CRANE":
        return "Kranführerschein";
      case "ADR_BASIC":
        return "ADR-Basis";
      case "ADR_TANK":
        return "ADR-Tank";
      case "ADR_EXPLOSIVE":
        return "ADR Explosiv";
      case "ADR_RADIOACTIVE":
        return "ADR-Radioaktiv";
    }
  }

  getVehicleExperience(vehicle: string) {
    switch (vehicle) {
      case "tractor-trailer":
        return "Sattelzug";
      case "solo-vehicle":
        return "Solofahrzeug";
      case "articulated-train":
        return "Gliederzug";
      case "refrigerated-vehicle":
        return "Kühlfahrzeug";
      case "swap-body":
        return "Wechselbrücke";
      case "tanker":
        return "Tankfahrzeug";
      case "heavy-transport":
        return "Schwertransporte";
      case "silo":
        return "Silo";
    }
  }

  getShift(shift) {
    switch (shift) {
      case "day":
        return "Tagesschicht";
      case "night":
        return "Nachtschicht";
      case "weekend":
        return "Wochenende";
      case "all":
        return "Wechselschicht";
    }
  }

  getTour(tour) {
    switch (tour) {
      case "local":
        return "Nahverkehr";
      case "national":
        return "Fernverkehr (national)";
      case "international":
        return "Fernverkehr (international)";
    }
  }

  getChatHistory(type) {
    if (this.matchId) {
      this.messageCount = 0;
      this.messageService.getMessages(this.matchId).subscribe(
        (getResData) => {
          this.isError = false;
          this.errorMessage = "";
          this.messages = getResData;
          this.isSpinner = false;
          this.loggedInUserId = this.authService.getUserData().id;
          this.messages?.results.forEach(val => {
             if(val.is_read == false){
              this.messageCount++;
             }
          });
          if (this.messageCount > 0 && type == true) {
            this.messageService.readChatMessages(this.matchId).subscribe(
              (resData) => { },
              (error) => {
                console.log("Error occurred while reading chat ");
              }
            );
          }
        },
        (errorMessage) => {
          this.isError = true;
          this.errorMessage = errorMessage;
          console.log(this.errorMessage);
        }
      );
    }
  }

  addMessage() {
    let id = this.matchId
    this.chatFormData = new FormData();
    let logoType = typeof this.chatForm.get('logo').value;
    if (logoType == "object") {
      this.chatFormData.append('file', this.chatForm.get('logo').value);
    }
    this.chatFormData.append('message', this.chatForm.value.message);
    this.chatFormData.append('match_id', this.matchId);


    this.chatForm.reset();
    this.messageService.addMessage(this.chatFormData, id).subscribe(
      (postResData) => {
        this.getChatHistory(true);
      },
      (errorMessage) => {
        this.isError = true;
        this.errorMessage = errorMessage;
        console.log(this.errorMessage);
      }
    );

    // let message = this.chatForm.value.message;
    // let postData = {
    //   match_id: id,
    //   message: message,
    // };
    // this.chatForm.reset();
    // this.messageService.addMessage(postData, id).subscribe(
    //   (postResData) => {
    //     this.getChatHistory(true);
    //   },
    //   (errorMessage) => {
    //     this.isError = true;
    //     this.errorMessage = errorMessage;
    //     console.log(this.errorMessage);
    //   }
    // );
  }

  addStartDate() {
    let id = this.matchId
    let data = { status:new ApplicantStatusModel().BOTH_AGREED,job_start_date: formatDate(this.confirmInterviewDateForm.value.job_start_date,"yyyy-MM-dd","de") };
    this.applicantService.doUpdateStatus(data, this.matchId).subscribe(
      (postResData) => {
        this.jobStartDate = this.confirmInterviewDateForm.value.job_start_date;
        this.IsJobStartDate = false;
        this.selectedApplier.status = new ApplicantStatusModel().BOTH_AGREED;
        // this.getProfile();
        this.getInterviewProposal();
      },
      (errorMessage) => {
        this.isError = true;
        this.errorMessage = errorMessage;
        console.log(this.errorMessage);
      }
    );
  }

  editJobDate(){
    this.IsJobStartDate = true;
  }

  changeTab() {
    this.isRead = true;
    this.selected.setValue(1);
  }

  openCallDialog() {
    var updatePhoneNumber = this.authService.getUserData();
    updatePhoneNumber.phone_number = this.selectedApplier.company.phone_number ;
    localStorage.setItem("userData",JSON.stringify(updatePhoneNumber));

    const dialogRef = this.dialog.open(DialogCall, {
      width: "250px",
      data: {
        applier: this.selectedApplier.applier,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let companyUser = JSON.parse(localStorage.getItem("userData"));
        this.chatTextOnCallFail = `Hallo ${this.selectedApplier.applier.user.first_name +
          " " +
          this.selectedApplier.applier.user.last_name
          }, gerne würden wir Sie telefonisch sprechen, konnten Sie jedoch nicht erreichen.
        Bitte rufen Sie uns unter folgender Nummer zurück: ${companyUser.phone_number
        // Bitte rufen Sie uns unter folgender Nummer zurück: ${this.selectedApplier.applier.phone_number
          }`;
        this.chatForm.patchValue({
          message: this.chatTextOnCallFail,
        });
      }
    });
  }

  openDialog() {
    this.addInterviewDates = true;
    this.isApplierDetailsShow = false;
    // console.log(this.selectedApplier.status);
  }

  proposeNewAppointment() {
    this.addInterviewDates = true;
    this.isPrpposalDetailsShow = false;
  }

  placePersonalInterview() {
    this.confirmCuserDate = true;
    this.isPrpposalDetailsShow = false;
    // this.minDate.setDate(this.today.getDate());
  }

  createInterViewPraposalV2() {
    this.submitted = true;
    this.isSpinner = true;
    if (this.meetingForm.valid) {
      this.formData = new FormData();
      let interviewDates = [];

      let startHours1 = this.meetingForm.value.startTime_1.substr(0, 2) + ":" + this.meetingForm.value.startTime_1.substr(2, 4) + ":00";
      let endHours1 = this.meetingForm.value.endTime_1.substr(0, 2) + ":" + this.meetingForm.value.endTime_1.substr(2, 4) + ":00";

      interviewDates.push({
        date: formatDate(this.meetingForm.value.meetingDate_1,"yyyy-MM-dd","de"),
        start_time: startHours1,
        end_time: endHours1,
      });

      let postData = {
        postcode: this.meetingForm.value.postcode,
        street: this.meetingForm.value.street,
        city: this.meetingForm.value.city,
        notes: this.meetingForm.value.messageOther,
        interview_date: interviewDates,
      };
      this.jobService.setInterviewProposal(postData, this.matchId).subscribe(
        (postResData) => {
          this.proposalDetails = postResData;
          // this.getInterviewProposal();
          setTimeout(() => {
            this.cuserAddInterviewDate();
          }, 1000);
          // this.meetingForm.reset();
        },
        (errorMessage) => {
          this.isSpinner = false;
          this.submitted = false;
          this.isError = true;
          this.errorMessage = errorMessage;
          console.log(this.errorMessage);
        }
      );
      return false;
    }
  }


  cuserAddInterviewDate() {
   console.log(this.proposalDetails)
    let postData = {
      interview_date_id: Number(this.proposalDetails['interview_date'][0]['id'] ),
      interview_schedule: this.proposalDetails['id'],
      status: new ApplicantStatusModel().INTERVIEW_AGREED,
    }
    this.msgToSibling.emit(new ApplicantStatusModel().INTERVIEW_AGREED);
    this.selectedApplier.status = new ApplicantStatusModel().INTERVIEW_AGREED;

    this.meetingForm.reset();
    this.jobService.acceptInterviewProposal(postData, this.matchId).subscribe(
      postResData => {
        // this.getInterviewProposal();
        this.getProfile();
        this.viewConfirm = true;
        this.confirmCuserDate = false;
        this.addInterviewDates = false;
        this.isApplierDetailsShow = false;
        this.isPrpposalDetailsShow = false;
        this.isApplierSuggested = false;
        this.isApplierConfirm = false;
        this.isCompanyAgreed = false;
        this.isApplierAgreed = false;
        this.isCanceledByCompany = false;
      },
      errorMessage => {
        this.isSpinner = false;
        this.isError = true;
        this.errorMessage = errorMessage;
        console.log(this.errorMessage);
      });
  }

  rejectConfirm() {
    const dialogRef = this.dialog.open(InfoDialog, {
      data: { messageType: "confirmReject" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result?.data === "you confirmed") {

        let companyUser = JSON.parse(localStorage.getItem("userData"));
        let userName = companyUser.first_name + " " + companyUser.last_name;
        this.isRead = true;
        this.selected.setValue(1);
        let chatTextOnReject = `
      <p>Guten Tag ${this.selectedApplier.applier.user.first_name +
          " " +
          this.selectedApplier.applier.user.last_name
          },</p>
      <p>wir bedanken uns für deine Bewerbung und das damit zum Ausdruck gebrachte Interesse an unserem Unternehmen.</p>
      <p>Leider müssen wir dir mitteilen, dass wir in der Zwischenzeit die Stelle erfolgreich besetzen konnten. Wir bedauern Dir keine positive Nachricht geben zu können und hoffen, dass Du bald eine deinen Vorstellungen entsprechende Stelle findest.</p>
      <p>Wir wünschen dir alles Gute für deinen weiteren Weg und hoffen, bald wieder voneinander hören zu können.</p>
      <p>Viele Grüße,</p>
      <p>${userName}</p>
          `;
        this.updateStatus(new ApplicantStatusModel().COMPANY_CANCEL);
        this.selectedApplier.status = new ApplicantStatusModel().COMPANY_CANCEL;
        this.chatForm.patchValue({
          message: chatTextOnReject,
        });
      }
    });
  }

  addRow() {
    if (this.datePickedCountArray.length <= 2) {
      this.meetingForm.get('meetingDate_2').clearValidators();
      this.meetingForm.get('meetingDate_3').clearValidators();
      this.meetingForm.get('meetingDate_2').updateValueAndValidity();
      this.meetingForm.get('meetingDate_3').updateValueAndValidity();

      // this.meetingForm.controls['meetingDate_1'].setErrors({'incorrect': false});
      if (this.datePickedCountArray.length == 1) {
        this.meetingForm.controls["meetingDate_2"].setValidators([Validators.required]);
        this.meetingForm.get("startTime_2").setValue("0800");
        // this.meetingForm.get("endTime_2").setValue("0900");
        this.setEndTime("0800", 2);
      }
      if (this.datePickedCountArray.length == 2) {
        this.meetingForm.controls["meetingDate_3"].setValidators([Validators.required]);
        this.meetingForm.get("startTime_3").setValue("0800");
        // this.meetingForm.get("endTime_3").setValue("0900");
        this.setEndTime("0800", 3);
      }
      for (var i = 1; i <= 3; i++) {
        if (this.datePickedCountArray.indexOf(i) == -1) {
          this.datePickedCountArray.push(i);
          break;
        }
      }
    }
  }

  removeRow(rowIndex) {
    console.log(rowIndex + " rowIndex ")
    this.meetingForm.get("meetingDate_" + rowIndex).setValue(null);
    this.meetingForm.get("startTime_" + rowIndex).setValue(null);
    this.meetingForm.get("endTime_" + rowIndex).setValue(null);
    if(rowIndex == 2){
      this.meetingForm.get('meetingDate_2').clearValidators();
      this.meetingForm.get('meetingDate_2').updateValueAndValidity();
    }else{
      this.meetingForm.get('meetingDate_3').clearValidators();
      this.meetingForm.get('meetingDate_3').updateValueAndValidity();
    }
    this.datePickedCountArray = this.datePickedCountArray.filter(
      (e) => e != rowIndex
    );
  }

  getStartTime() {
    this.startTimes_1 = [];
    this.startTimes_2 = [];
    this.startTimes_3 = [];
    let hoursMinutes = [];
    for (let hours = 8; hours <= 19; hours++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        let timeObj;
        timeObj = {
          displayTime:
            minutes > 0
              ? ("0" + hours).slice(-2) + ":" + minutes
              : ("0" + hours).slice(-2) + ":00",
          hours: ("0" + hours).slice(-2),
          minutes: minutes > 0 ? ("0" + minutes).slice(-2) : "00",
          hoursMinutes:
            ("0" + hours).slice(-2) +
            ":" +
            (minutes > 0 ? ("0" + minutes).slice(-2) : "00"),
        };
        this.startTimes_1.push(timeObj);
        this.startTimes_2.push(timeObj);
        this.startTimes_3.push(timeObj);
      }
    }
  }

  setEndTime(event, index) {
    let getTime;
    if (event.value == null) {
      getTime = event;
    } else {
      getTime = event.value;
    }
    let secondValue = "";
    if (index == 1) {
      this.endTimes_1 = generateEndTime(getTime);
      if(getTime != "0800"){
        // this.meetingForm.patchValue({'endTime_1': ""});
      }
      this.isStartDate_1 = true;
    } else if (index == 2) {
      this.endTimes_2 = generateEndTime(getTime);
      if(getTime !="0800"){
        // this.meetingForm.patchValue({'endTime_2': ""});
      }
      this.isStartDate_2 = true;
    } else if (index == 3) {
      this.endTimes_3 = generateEndTime(getTime);
      if(getTime != "0800"){
        // this.meetingForm.patchValue({'endTime_3': ""});
      }
      this.isStartDate_3 = true;
    }

    function generateEndTime(getTime) {
      let endTimes = [];
      let startHours = getTime.substr(0, 2);
      let startMinutes = getTime.substr(2, 4);
      // let startHours = event.value.substr(0, 2);
      // let startMinutes = event.value.substr(2, 4);
      let timeZone = startHours >= 12 ? "pm" : "am";

      let startTime = moment(
        `${startHours}:${startMinutes} : 00 ${timeZone}`,
        "HH:mm:ss a"
      );
      let isFirstIter = true;
      let i = 0;
      let endHours2 = parseInt(startHours) + 6;
      // let endHours = (startMinutes == '00') ? parseInt(startHours) + 7 : parseInt(startHours) + 7;
      let endHours = parseInt(startHours) + 7;
      endHours = endHours > 19 ? 20 : endHours;
      let cnt = 0;
      for (let hours = startHours; hours < endHours; hours++) {
        for (
          let minutes = isFirstIter ? parseInt(startMinutes) + 15 : 0;
          minutes < 60;
          minutes += 15
        ) {
          let timeObj, tt, results;
          let endTime = moment(
            `${hours}:${minutes}:00 ${timeZone} `,
            "HH:mm:ss a"
          );
          let duration = moment.duration(endTime.diff(startTime));
          let hrs = duration.asHours();
          let min = duration.asMinutes() % 60;
          // console.log(hrs +  " hrs " + hours + " hours  => " +  " endHours2 => "  + endHours2)
          if ((hrs > 6 && min > 0) || (hours >= 20 && min > 0)) {
            // if(hours >= endHours2 && min >= startMinutes){
            // console.log(min + " min  => " + " startMinutes => " + startMinutes )
          } else {
            if (hrs < 1) {
              if (hrs == 0.25) {
                min = 15;
              } else if (hrs == 0.5) {
                min = 30;
              } else {
                min = 45;
              }
              results = min + " min";
            } else {
              if (min == 0) {
                results = hrs + "h";
              } else if (Number.isInteger(hrs)) {
                results = hrs + "h and " + min + " min";
              } else {
                results = parseInt(hrs.toString()) + "h and " + min + " min";
              }
            }
            tt = " (" + results + ")";
            timeObj = {
              displayTime:
                minutes > 0
                  ? ("0" + hours).slice(-2) + ":" + minutes + tt
                  : ("0" + hours).slice(-2) + ":00" + tt,
              hours: ("0" + hours).slice(-2),
              minutes: minutes > 0 ? ("0" + minutes).slice(-2) : "00",
              hoursMinutes:
                ("0" + hours).slice(-2) +
                ":" +
                (minutes > 0 ? ("0" + minutes).slice(-2) : "00"),
            };
            cnt++;
            if(cnt== 4){
              secondValue = timeObj.hours+timeObj.minutes;
            }
            endTimes.push(timeObj);
          }
        }
        i++;
        isFirstIter = false;
      }
      return endTimes;
    }
    if(index == 1 && secondValue != '' && getTime != "0800"){
      this.meetingForm.patchValue({"endTime_1" : secondValue});
    }else if(index == 2 && secondValue != ''){
      this.meetingForm.patchValue({"endTime_2" : secondValue});
    }else if(secondValue != ''){
      this.meetingForm.patchValue({"endTime_3" : secondValue});
    }
  }

  onDateChange() {
    // console.log(this.meetingForm.value.meetingDate1 + " Date Change ");
  }

  getInterviewProcessTime(date, start_time) {
    var interviewDt: any = new Date(date + " " + start_time);
    interviewDt = new Date(interviewDt.getTime() + (15 * 60 * 1000));
    var today: any = new Date();
    if (interviewDt > today) {
      console.log('---interviewDt is greater----');
    } else {
      console.log('---today is greater-----');
    }
  }

  getInterviewProposal() {
    // this.updateStatus(new ApplicantStatusModel().INTERVIEW_PROPOSED_C);
    // console.log(this.selectedApplier.status + " status  => " + this.matchId + " matchId");
    //  return false;
    this.isApplierConfirm = false;
    this.confirmCuserDate = false;
    this.isPrpposalDetailsShow = false;
    this.isApplierSuggested = false;
    this.addInterviewDates = false;
    this.isCanceledByCompany = false;
    this.isApplierDetailsShow = false;
    this.viewConfirm = false;
    this.isApplierAgreed = false;
    this.isCompanyAgreed = false;
    this.jobService.getInterviewProposal(this.matchId).subscribe(
      (resData) => {
        if (!resData[0]) {
          if (
            this.selectedApplier.status == 8 ||
            this.selectedApplier.status == 9
          ) {
            this.isCanceledByCompany = true;
            // } else if (this.selectedApplier.status >= 3) {
          } else if (this.selectedApplier.status >= 2) {
            this.isPrpposalDetailsShow = false;
            this.addInterviewDates = false;
            this.isApplierDetailsShow = true;
          }
        } else {
          this.proposalDetails = resData[0];
          // let today = formatDate(new Date(), "yyyy-MM-dd", "en_US");
          // let InterviewSelectedDate = formatDate(this.selectedApplier.interview_detail.date, "yyyy-MM-dd", "en_US");
          // console.log(this.selectedApplier.interview_detail.start_time + " Today  " + this.selectedApplier.interview_detail.end_time);
          // this.getInterviewProcessTime(this.selectedApplier.interview_detail.date, this.selectedApplier.interview_detail.start_time)
          var InterviewSelectedDate: any = new Date(this.selectedApplier.interview_detail.date + " " + this.selectedApplier.interview_detail.start_time);
          InterviewSelectedDate = new Date(InterviewSelectedDate.getTime() + (15 * 60 * 1000));
          var today: any = new Date();
          // console.log("===========");
          // console.log(today);
          // today.setDate(new Date().getDate() + 1)
          // console.log(today);
          // console.log("InterviewSelectedDate");
          // console.log(InterviewSelectedDate);
          // console.log(this.viewConfirm);
          if (this.selectedApplier.status >= 3) {
            if (today >= InterviewSelectedDate && this.selectedApplier.status == 5) {
              this.viewConfirm = true;
              // console.log(this.viewConfirm);
              this.isApplierConfirm = false;
              this.isPrpposalDetailsShow = false;
              this.isApplierSuggested = false;
              this.addInterviewDates = false;
              this.addInterviewDates = false;
            } else if (this.selectedApplier.status == 5) {
              this.isApplierConfirm = true;
              this.isPrpposalDetailsShow = false;
              this.isApplierSuggested = false;
              this.addInterviewDates = false;
            } else if (this.selectedApplier.status == 6) {
              this.isCompanyAgreed = true;
            } else if (this.selectedApplier.status == 7) {
              this.isApplierAgreed = true;
            } else if (this.selectedApplier.status == 8 || this.selectedApplier.status == 9) {
              this.isCanceledByCompany = true;
            } else if (this.proposalDetails.is_applier_suggested == true && this.selectedApplier.status == 42) {
              this.isApplierSuggested = true;
            } else if (this.proposalDetails && (this.selectedApplier.status == 41 || this.selectedApplier.status == 43)) {
              this.isPrpposalDetailsShow = true;
            } else {
              this.isApplierDetailsShow = true;
              // this.addInterviewDates = true;
            }
          }
        }
      },
      (errorMessage) => {
        console.log("errorMessage In Proposal details component");
        console.log(errorMessage);
        this.isError = true;
      }
    );
  }

  updateStatus(changeStatus) {
    let data = { status: changeStatus };
    this.applicantService.doUpdateStatus(data, this.matchId).subscribe(
      (resData) => {
        if (changeStatus == 4 || changeStatus > 40) {
          const dialogRef = this.dialog.open(InfoDialog, {
            data: { messageType: "proposal" },
          });
          dialogRef.afterClosed().subscribe((result) => {
            // window.location.reload();
          });
          // this.dialog.open(InfoDialog, { data: { messageType: 'proposal' } });
        } else if (changeStatus == new ApplicantStatusModel().COMPANY_AGREED) {
        } else {
          // window.location.reload();
        }
        this.msgToSibling.emit(changeStatus);
        this.selectedApplier.status = changeStatus;
        this.getInterviewProposal();
      },
      (errorMessage) => {
        console.log("errorMessage In user-match component");
        console.log(errorMessage);
      }
    );
  }

  createInterViewPraposal() {
    // console.log("Calll ")
    // console.log(new ApplicantStatusModel().INTERVIEW_PROPOSED_C)
    // return false
    this.submitted = true;
    if (this.meetingForm.valid) {
      this.formData = new FormData();
      let interviewDates = [];

      let startHours1 = this.meetingForm.value.startTime_1.substr(0, 2) +
        ":" +
        this.meetingForm.value.startTime_1.substr(2, 4) +
        ":00";
      let endHours1 =
        this.meetingForm.value.endTime_1.substr(0, 2) +
        ":" +
        this.meetingForm.value.endTime_1.substr(2, 4) +
        ":00";

      interviewDates.push({
        date: formatDate(
          this.meetingForm.value.meetingDate_1,
          "yyyy-MM-dd",
          "de"
        ),
        start_time: startHours1,
        end_time: endHours1,
      });

      if (this.meetingForm.value.meetingDate_2 != null && this.meetingForm.value.startTime_2 != '' && this.meetingForm.value.endTime_2 != '') {
        let startHours2 =  this.meetingForm.value.startTime_2.substr(0, 2) +
          ":" +
          this.meetingForm.value.startTime_2.substr(2, 4) +
          ":00";
        let endHours2 =
          this.meetingForm.value.endTime_2.substr(0, 2) +
          ":" +
          this.meetingForm.value.endTime_2.substr(2, 4) +
          ":00";
        interviewDates.push({
          date: formatDate(this.meetingForm.value.meetingDate_2, "yyyy-MM-dd","de"),
          start_time: startHours2,
          end_time: endHours2,
        });
      }

      if (this.meetingForm.value.meetingDate_3 != null && this.meetingForm.value.startTime_3 != '' && this.meetingForm.value.endTime_3 != '') {
        let startHours3 =
          this.meetingForm.value.startTime_3.substr(0, 2) + ":" + this.meetingForm.value.startTime_3.substr(2, 4) + ":00";
        let endHours3 = this.meetingForm.value.endTime_3.substr(0, 2) + ":" + this.meetingForm.value.endTime_3.substr(2, 4) + ":00";
        interviewDates.push({
          date: formatDate(
            this.meetingForm.value.meetingDate_3,
            "yyyy-MM-dd",
            "de"
          ),
          start_time: startHours3,
          end_time: endHours3,
        });
      }

      let postData = {
        postcode: this.meetingForm.value.postcode,
        street: this.meetingForm.value.street,
        city: this.meetingForm.value.city,
        notes: this.meetingForm.value.messageOther,
        interview_date: interviewDates,
      };

      this.jobService.setInterviewProposal(postData, this.matchId).subscribe(
        (postResData) => {
          if (
            this.selectedApplier.status == new ApplicantStatusModel().CHECKED
          ) {
            this.updateStatus(new ApplicantStatusModel().INTERVIEW_PROPOSED_C);
          } else {
            this.updateStatus(
              new ApplicantStatusModel().ANOTHER_INTERVIEW_PROPOSED_C
            );
          }
          this.meetingForm.reset();
          this.isError = false;
          this.errorMessage = "";
          // this.getChat = getResData;
          this.isApplierSuggested = true;
          this.isApplierConfirm = true;
          this.isPrpposalDetailsShow = true;
          this.addInterviewDates = false;
        },
        (errorMessage) => {
          this.submitted = false;
          this.isError = true;
          this.errorMessage = errorMessage;
          console.log(this.errorMessage);
        }
      );
      return false;
    }
  }

  setDefaultAddress() {
    this.meetingForm.patchValue({
      address:
        this.selectedApplier.job.street +
        ", " +
        this.selectedApplier.job.city +
        ", " +
        this.selectedApplier.job.postcode,
      street: this.selectedApplier.job.street,
      postcode: this.selectedApplier.job.postcode,
      city: this.selectedApplier.job.city,
    });
  }

  addNewDate() {
    window.scroll(0, 0);
    this.meetingForm.patchValue({
      street: this.proposalDetails.street,
      messageOther: this.proposalDetails.notes,
      postcode: this.proposalDetails.postcode,
      city: this.proposalDetails.city,
    });
    this.isApplierSuggested = false;
    this.addInterviewDates = true;
  }

  rejectApplication() {
    const dialogRef = this.dialog.open(InfoDialog, {
      data: { messageType: "cencelV2-popup" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data == "you confirmed") {
        this.addText = true;

        let companyUser = JSON.parse(localStorage.getItem("userData"));
        let userName = companyUser.first_name + " " + companyUser.last_name;
        this.isRead = true;
        this.selected.setValue(1);
        let chatTextOnReject = `
        <p>Guten Tag ${this.selectedApplier.applier.user.first_name +
          " " +
          this.selectedApplier.applier.user.last_name
          },</p>
        <p>wir bedanken uns für deine Bewerbung und das damit zum Ausdruck gebrachte Interesse an unserem Unternehmen.</p>
        <p>Leider müssen wir dir mitteilen, dass wir in der Zwischenzeit die Stelle erfolgreich besetzen konnten. Wir bedauern Dir keine positive Nachricht geben zu können und hoffen, dass Du bald eine deinen Vorstellungen entsprechende Stelle findest.</p>
        <p>Wir wünschen dir alles Gute für deinen weiteren Weg und hoffen, bald wieder voneinander hören zu können.</p>
        <p>Viele Grüße,</p>
        <p>${userName}</p>
            `;
        this.chatForm.patchValue({
          message: chatTextOnReject,
        });
        this.updateStatus(new ApplicantStatusModel().COMPANY_CANCEL);
        setTimeout(() => {
          // this.addText = false
        }, 10000);
        // this.selectedApplier.status = new ApplicantStatusModel().COMPANY_CANCEL;
        // this.msgToSibling.emit(new ApplicantStatusModel().COMPANY_CANCEL);
      } else {
        console.log(" cencel button ");
      }
    });

    // this.getInterviewProposal()
  }

  gotoConfirmView() {
    this.viewConfirm = true;
    this.updateStatus(new ApplicantStatusModel().COMPANY_AGREED);
    this.selectedApplier.status = new ApplicantStatusModel().COMPANY_AGREED;
  }

  openCencelPopup() {
    const dialogRef = this.dialog.open(InfoDialog, {
      data: { messageType: "cencel-popup" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data == "you confirmed") {
        this.updateStatus(new ApplicantStatusModel().COMPANY_CANCEL);
        this.getInterviewProposal();
      } else {
        console.log(" cencel button ");
      }
    });
  }

  gotoChatTab() {
    this.selected.setValue(2);
  }
  openConfirmPopup() {
    const dialogRef = this.dialog.open(InfoDialog, {
      data: { messageType: "confirm-popup" },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data == "you confirmed") {
        this.updateStatus(new ApplicantStatusModel().COMPANY_AGREED);
        let companyUser = JSON.parse(localStorage.getItem("userData"));
        let userName = companyUser.first_name + " " + companyUser.last_name;
        this.isRead = true;
        this.selected.setValue(2);
        let chatTextOnConfirm = `
        <p>Guten Tag ${this.selectedApplier.applier.user.first_name + " " + this.selectedApplier.applier.user.last_name},</p>
        <p>Nach dem positven persönlichen Interview würden wir Sie gerne in unserem Team begrüßen.</p>
        <p> Wir freuen uns über Ihre Rückmeldung.</p>
        <p>${userName}</p>`;
        this.chatForm.patchValue({
          message: chatTextOnConfirm,
        });
        this.msgToSibling.emit(new ApplicantStatusModel().COMPANY_AGREED);
        this.selectedApplier.status = new ApplicantStatusModel().COMPANY_AGREED;
        this.getInterviewProposal();
      }
    });
  }

  handleClick() {
    this.mobileCurentStatus = "details";
    this.postMessageEvent.emit(this.mobileCurentStatus);
  }
  checkPostCode(event: any) {
    // console.log(event.target.value + "  => value")
    if (this.meetingForm.value.postcode !== "undefined") {
      var target = codes.find(
        (temp) => temp == this.meetingForm.value.postcode
      );
      if (target) this.isPostcodeValid = false;
      else this.isPostcodeValid = true;
    }
  }

  onImagePicked(event) {
    this.isImgError = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log(file.type)
      if(file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png" && file.type != "image/pdf"){
        this.isImgError = true;
        this.imgErrorMessage = 'Bitte wählen Sie den Dateityp jpeg, png, pdf.';
        return true
      }
      if (file.size <= 2000000) {
        this.chatForm.controls['message'].setErrors({'incorrect': false});
        // this.chatForm.get('message').clearValidators();
        // this.chatForm.get('message').updateValueAndValidity();
        this.chatForm.patchValue({
          message: " ",
        });
        this.isImgError = false;
        this.imgErrorMessage = '';
        const reader = new FileReader();
        this.chatForm.get('logo').setValue(file);
        reader.onload = () => {
          this.imagePreview = reader.result as string;
          this.imgSrc = this.imagePreview;
        }
        reader.readAsDataURL(file);
      } else {
        this.isImgError = true;
        this.imgErrorMessage = 'Die Logo-Größe sollte weniger als 2 MB betragen.';
      }
    }
  }

}

@Component({
  selector: "app-info-dialog",
  templateUrl: "info-dialog.html",
})
export class InfoDialog {
  messageType: any;
  constructor(private router: Router,
    private dialogRef: MatDialogRef<CreateJobsComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.messageType = data.messageType;
  }

  doRefresh() { }

  cancel() {
    // closing itself and sending data to parent component
    this.dialogRef.close({ data: "you cancelled" });
  }

  confirm() {
    // closing itself and sending data to parent component
    this.dialogRef.close({ data: "you confirmed" });
  }
}
