import { Component, OnInit, Input, OnChanges,Output, EventEmitter, Inject } from '@angular/core';
import { JobService } from '../../services/company/job.service';
import { DeviceDetectorService } from "ngx-device-detector";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {DialogComponent} from "../common/dialog/dialog.component";
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from "../../services/profile.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
// import {EditProfileComponent} from "../../components/my-profile/edit-profile/edit-profile.component";
import { ApplicantService } from "../../services/applicant.service";
import { AuthService } from "../../services/auth.service";
import { MessageService } from "../../services/message.service";
import {LanguageModel} from "../../models/language.model";
import { ApplicantStatusModel } from "../../models/applicant-status.model";
import { DatePipe } from "@angular/common";
import { formatDate } from "@angular/common";
import {JobDetailsComponent} from "../../components/company/job-profile/job-details/job-details.component";
import { DialogCall } from "../../components/applications/application-details/application-details.component";

@Component({
  selector: 'app-arrange-interview',
  templateUrl: './arrange-interview.component.html',
  styleUrls: ['./arrange-interview.component.scss']
})
export class ArrangeInterviewComponent implements OnInit {
  @Input('matchId') matchId: number;
  @Input('jobTitle') jobTitle: any;
  @Input('jobStatus') jobStatus: any;
  @Input('jobHistory') jobHistory: any[];
  chatFormData: any;
  chatFormGroup: FormGroup;
  getUserProfile: any;
  isEditModeEnable = false;
  interviewFormGroup: FormGroup;
  reScheduleinterviewFormGroup: FormGroup;
  loggedInUserId: number;
  interviewerId: number;
  isApplierSuggested: any;
  isSpinner = true;
  isStatus = 2;
  isError = false;
  isInterviewDate = false;
  isConformInterview = false;
  isRescheduleInterview = false;
  isButtonDisable = false;
  isConpamyAgree = false;
  isApplierAgree = false;
  isBothAgree = false;
  errorMessage = '';
  getChat: any;
  proposalDetails: any;
  messages: any;
  selected = new FormControl(0);
  selectedApplier: any;
  public Editor = ClassicEditor;
  public isMobile = false;
  responseData: any;
  responseDatas: any;
  applier: any;
  company: any;
  job: any;
  jobs: any;
  JobStatus:any;
  distance: any;
  interviewDetail: any;
  germanLanSkill: string;
  isApplicationPage = false;
  isDecisionPending = false;
  messageCount = 0;
  status_1 = 'Matched';
  status_2 = 'Eingang';
  status_3 = 'Prüfung';
  status_4 = 'persönliches Interview';
  status_5 = 'Entscheidung';
  status_6 = '';
  status_7 = '';
  status_8 = '';
  status_9 = '';
  isImgError = false;
  imgErrorMessage = '';
  imagePreview: string;
  imgSrc = './assets/no-image.jpeg';
  isImgSelected = false;
  filename = '';
  filesize = '';

  constructor(private deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private profileService: ProfileService,
    private messageService: MessageService,
    private applicantService: ApplicantService,
    private jobService: JobService,
    public dialog: MatDialog,
    private authService: AuthService) { 
      this.isMobile = this.deviceService.isMobile();
    }

  ngOnChanges() {
    this.ngOnInit();
  }

  ngOnInit() {
   
    this.getProfile();
    this.getJob();
    this.getChatHistory(false);
   
    // this.getJob();
    this.reScheduleinterviewFormGroup = this._formBuilder.group({
      new_date: ['', Validators.required],
      note: [''],
    });
    this.interviewFormGroup = this._formBuilder.group({
      selected_date: ['', Validators.required],
    });
    this.chatFormGroup = this._formBuilder.group({
      message: ['', Validators.required],
      logo: []
    });
    this.isStatus = this.jobStatus;
  }

  rejectJobApplication() {
    console.log("cancel")
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        jobId: this.matchId,
        isReject: true,
        isApply: false,
        isRefuse: false,
        jobStatus: this.jobStatus,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  getTours(tours: [string]) {
    let translatedTours = [];
    tours.forEach(tour => {
      if (tour == "local") {
        translatedTours.push('Nahverkehr');
      } else if (tour == "national") {
        translatedTours.push("Fernverkehr (national)");
      } else if (tour == "international") {
        translatedTours.push("Fernverkehr (international)");
      } else {
        translatedTours.push("Not available");
      }
    });
    return translatedTours.join(', ');
  }

  addMessage() {
    let id = this.matchId
    this.chatFormData = new FormData();
    if(this.isImgSelected){
      let logoType = typeof this.chatFormGroup.get('logo').value;
      if (logoType == "object") {
        this.chatFormData.append('file', this.chatFormGroup.get('logo').value);
      }
    }
    this.chatFormData.append('message', this.chatFormGroup.value.message);
    this.chatFormData.append('match_id', this.matchId);
    if(this.chatFormGroup.get('logo').value || this.chatFormGroup.value.message){
      this.messageService.addMessage(this.chatFormData, id).subscribe(
        (postResData) => {
          this.isImgSelected = false;
          this.filename = "";
          this.filesize = "";
          this.getChatHistory(true);
          this.chatFormGroup.reset();
        },
        (errorMessage) => {
          this.isError = true;
          this.errorMessage = errorMessage;
          console.log(this.errorMessage);
        }
      );
    }

    // const id = this.matchId;
    // let message = this.chatFormGroup.value.message;
    // let postData = {
    //   match_id: id,
    //   message: message
    // }
    // this.chatFormGroup.reset();
    // this.messageService.addMessage(postData, id).subscribe(
    //   postResData => {
    //     this.getChatHistory(true);
    //   },
    //   errorMessage => {
    //     this.isError = true;
    //     this.errorMessage = errorMessage;
    //     console.log(this.errorMessage);
    //   });
  }

  deleteImage(){
    this.chatFormGroup.get('logo').setValue(null);
    this.imgSrc = '';
    this.isImgSelected = false;
    this.filesize = this.filename = '';
  }

  getChatHistory(type) {
    const id = this.matchId;
    this.messageService.getMessages(id).subscribe(getResData => {
      this.isError = false;
      this.errorMessage = "";
      this.getChat = getResData;
      this.messages = this.getChat.results;
      this.messageCount = 0;
      // this.getChat?.results.forEach(val => {
      //    if(val.is_read == false){
      //     this.messageCount++;
      //    }
      // });
      let applierUser = JSON.parse(localStorage.getItem('userData'));
      
      for (let message of this.getChat?.results) {
        message.isPdf = false;
        message.fileName = "";
        if(message.file){
          message.isPdf = this.checkPdfExists(message.file)
          message.fileName = this.getFileNameFromUrl(message.file)
        }
        if (message.is_read == false && applierUser.id != message.created_by) {
          this.messageCount++;
        }
      }
      if (this.messageCount > 0 && type == true) {
        this.messageService.readChatMessages(this.matchId).subscribe(
          (resData) => { },
          (error) => {
            console.log("Error occurred while reading chat ");
          }
        );
      }
      this.loggedInUserId = this.authService.getUserData().id;
    }, errorMessage => {
      this.isError = true;
      this.errorMessage = errorMessage;
      console.log(this.errorMessage);
    });
  }

  checkPdfExists(url: string) {
    let parts = url.split(".");
    let param = parts[parts.length - 1];
    if (param=="pdf") {
        return true;
    }else{
        return false;
    }
  }

  getFileNameFromUrl(url: string) {
    let parts = url.split("/");
    let param = parts[parts.length - 1];
    return param;
  }


  tabClick(tab) {
    const selectedTab = tab.tab.textLabel;
    if (selectedTab === 'Nachrichten' || selectedTab === '') {
      // this.isRead = true;
      setTimeout(() => {
        this.messageCount = 0;
      }, 4000);
      this.getChatHistory(true);
    } else {
      this.selected.setValue(0);
    }
  }
  
  getJobDetails() {
    this.getJob()
    let responseData;
    this.jobService.getJobV2(this.matchId).subscribe(resData => {
      responseData = resData;
      this.jobHistory = responseData?.history
      this.isApplierSuggested = false
      this.proposalDetails = '';
      this.isRescheduleInterview = false;
      this.isRescheduleInterview = false;
      this.isButtonDisable = false;
      this.isConpamyAgree = false;
      this.isApplierAgree = false;
      this.isInterviewDate = false;
      this.isConformInterview = true;
    }, errorMessage => {
      console.log("errorMessage In Proposal details component");
      console.log(errorMessage);
      this.isError = true;
    });
  }

  addInterviewDate() {
    const id = this.matchId;
    let selected_date = this.interviewFormGroup.value.selected_date;
    // console.log(selected_date + " selected_date ")
    // this.dialog.open(InfoIntervieDialog, {
    //   data: { messageType: 'addInterview' }
    // });
    let postData = {
      interview_date_id: Number(this.interviewFormGroup.value.selected_date),
      interview_schedule: this.interviewerId,
      status: new ApplicantStatusModel().INTERVIEW_AGREED,
    }
    let responseData;
    this.reScheduleinterviewFormGroup.reset();
    this.jobService.acceptInterviewProposal(postData, this.matchId).subscribe(
      postResData => {
        responseData = postResData;
        this.dialog.open(InfoIntervieDialog, {
          data: { messageType: 'addInterview' }
        });
        this.isStatus = 5;
        this.jobStatus = 5;
        // this.getInterviewProposal()
        this.getJobDetails();
        this.isConpamyAgree = false;
        this.isApplierAgree = false;
        this.isApplierSuggested = false
        this.isDecisionPending = false
        this.proposalDetails = false;
        this.isRescheduleInterview = false;
        this.isInterviewDate = false;
        this.isConformInterview = true;
      },
      errorMessage => {
        this.isError = true;
        this.errorMessage = errorMessage;
        console.log(this.errorMessage);
      });
  }

  reScheduleInterview() {
    let postData = {
      applier_choice: this.reScheduleinterviewFormGroup.value.new_date,
      applier_notes: this.reScheduleinterviewFormGroup.value.note
    }
    this.jobService.addSuggestionDate(postData, this.matchId, this.interviewerId).subscribe(
      postResData => {
        // console.log(postResData)
        this.updateStatus(new ApplicantStatusModel().ALTERNETE_DATE_FROM_APPLIER);
        this.isButtonDisable = true;
        this.isApplierSuggested = true;
        this.isRescheduleInterview = false;
        this.isInterviewDate = false;
        this.dialog.open(InfoIntervieDialog, {
          data: { messageType: 'reSchedule' }
        });
      },
      errorMessage => {
        this.isError = true;
        this.errorMessage = errorMessage;
        console.log(this.errorMessage);
      });
  }

  getProfile() {
    this.profileService.getProfile().subscribe(
      resData => {
        this.getUserProfile = resData;
        this.isSpinner = false;
      }, error => {
        console.log(error);
      });
  }


  addNewSuggesion() {
    this.isInterviewDate = false;
    this.isRescheduleInterview = true;
  }

  getMobileStatus(status: number) {
    status = Number(status)
    switch (status) {
      case 1:
        return "eingang";
      case 2:
        return "pruefung";
      case 3:
        return "pruefung";
      case 4:
        return "interview";
      case 5:
        return "interview";
      case 6:
        return 'entscheidung';
      case 7:
        return 'entscheidung';
      case 8:
        return 'Bewerber abgesagt';
      case 9:
        return 'Bewerbung zurückgezogen';
      default:
        return "N/A";
    }
  }

  getInterviewProposal() {
    this.proposalDetails = null
    // this.jobService.getInterviewProposal(240).subscribe(resData => {
    this.jobService.getInterviewProposal(this.matchId).subscribe(resData => {
      if (!resData[0]) {
        this.isInterviewDate = true;
        // this.isPrpposalDetailsShow = false;
      } else {
        // this.isPrpposalDetailsShow = true;
        this.proposalDetails = resData[0];
        this.interviewerId = this.proposalDetails.id
        // this.isApplierSuggested = this.proposalDetails.is_applier_suggested
        // if (this.proposalDetails.is_applier_suggested == true) {
        //   this.isConformInterview = false;
        //   this.isRescheduleInterview = false;
        // }
        this.isInterviewDate = false;
        this.isConpamyAgree = false;
        this.isApplierAgree = false;
        this.isConformInterview = false;
        this.isDecisionPending = false;
        this.isApplierSuggested = false;

        this.proposalDetails = resData[0];
        // let today = formatDate(new Date(), "yyyy-MM-dd", "en_US");
        // let InterviewSelectedDate = formatDate(this.interviewDetail?.date, "yyyy-MM-dd", "en_US");
        
        var InterviewSelectedDate: any = new Date(this.interviewDetail.date + " " + this.interviewDetail.start_time);
        InterviewSelectedDate = new Date(InterviewSelectedDate.getTime() + (15 * 60 * 1000));
        var today: any = new Date();

        if (today >= InterviewSelectedDate && this.jobStatus == 5) {
          this.isDecisionPending = true;
        }else if (this.jobStatus == 5) {
          // console.log("History ")
          // console.log(this.jobHistory)
          this.isConformInterview = true;
        } else if (this.proposalDetails.is_applier_suggested == true || this.jobStatus == 42) {
          this.reScheduleinterviewFormGroup.patchValue({
            new_date: this.proposalDetails.applier_choice,
            note: this.proposalDetails.applier_notes,
          })
          this.isApplierSuggested = true;
        }else if (this.jobStatus == 6) {
         this.isConpamyAgree = true;
        }else if (this.jobStatus == 7) {
         this.isApplierAgree = true;
        }else if (this.jobStatus == 12) {
          this.isBothAgree = true;
         } else {
          this.isInterviewDate = true;
        }
      }
    }, errorMessage => {
      console.log("errorMessage In Proposal details component");
      console.log(errorMessage);
      this.isError = true;
    });
  } 

  gotoInterviewProposalView() {
    this.isInterviewDate = true;
    this.isRescheduleInterview = false;
  }  

  getShifts(shifts: [string]) {
    let translatedShifts = [];
    shifts.forEach(shift => {
      if (shift == "day") {
        translatedShifts.push('Tagesschicht');
      } else if (shift == "night") {
        translatedShifts.push("Nachtschicht");
      } else if (shift == "weekend") {
        translatedShifts.push("Wochenende");
      } else if (shift == "all") {
        translatedShifts.push("Wechselschicht");
      } else {
        translatedShifts.push("Not available");
      }
    });
    return translatedShifts.join(', ');
  }

  getVehicleTypeExperience(vehicleType: [string]) {
    let translatedVehicleType = [];
    vehicleType.forEach(type => {
      if (type == "tractor-trailer") {
        translatedVehicleType.push('Sattelzug');
      } else if (type == "solo-vehicle") {
        translatedVehicleType.push("Solofahrzeug");
      } else if (type == "articulated-train") {
        translatedVehicleType.push("Gliederzug");
      } else if (type == "refrigerated-vehicle") {
        translatedVehicleType.push("Kühlfahrzeug");
      } else if (type == "swap-body") {
        translatedVehicleType.push("Wechselbrücke");
      } else if (type == "tanker") {
        translatedVehicleType.push("Tankfahrzeug");
      } else if (type == "silo") {
        translatedVehicleType.push("Silo");
      } else if (type == "heavy-transport") {
        translatedVehicleType.push("Schwertransporte");
      } else {
        translatedVehicleType.push("Not available");
      }
    });

    return translatedVehicleType.join(', ');
  }

  getAdditionalLicense(additionalLicense: [string]) {
    let translatedAdditionalLicense = [];
    additionalLicense.forEach(license => {
      if (license == "NO_LICENSE") {
        translatedAdditionalLicense.push('Keinen weiteren Führerschein');
      } else if (license == "FORKLIFT") {
        translatedAdditionalLicense.push("Staplerführerschein");
      } else if (license == "CRANE") {
        translatedAdditionalLicense.push("Kranführerschein");
      } else if (license == "ADR_BASIC") {
        translatedAdditionalLicense.push("ADR-Basis");
      } else if (license == "ADR_TANK") {
        translatedAdditionalLicense.push("ADR-Tank");
      } else if (license == "ADR_EXPLOSIVE") {
        translatedAdditionalLicense.push("ADR Explosiv");
      } else if (license == "ADR_RADIOACTIVE") {
        translatedAdditionalLicense.push("ADR-Radioaktiv");
      } else {
        translatedAdditionalLicense.push("Not available");
      }
    });

    return translatedAdditionalLicense.join(', ');
  }

  
  getJob(): void {
    const matchId = this.matchId;
    this.jobService.getJobV2(matchId)
      .subscribe(resData => {
        this.responseDatas = resData;
        this.JobStatus = this.responseDatas.status;
        this.applier = this.responseDatas.applier;
        this.company = this.responseDatas.company;
        this.jobs = this.responseDatas.job;
        this.job = this.responseDatas.job;
        this.distance = this.responseDatas.distance;
        this.interviewDetail = this.responseDatas.interview_detail;
        var updatePhoneNumber = this.authService.getUserData();
        updatePhoneNumber.phone_number = this.applier.phone_number;
        localStorage.setItem("userData",JSON.stringify(updatePhoneNumber));
        this.isSpinner = false;
        this.getInterviewProposal();
        for (let lan of this.job.language) {
          if (lan <= 3) {
            this.germanLanSkill = new LanguageModel().getLanguageSkill(lan);
          } else {
            this.germanLanSkill = 'Keine Kenntnis';
          }
        }
      }, errorMessage => {
        console.log(errorMessage);
      });
  }
  
  updateStatus(newStatus) {
    let data = { status: newStatus }
    this.applicantService.doUpdateStatus(data, this.matchId).subscribe(resData => {
      if(newStatus == 9){
        window.location.reload();
      }
    }, errorMessage => {
      console.log("errorMessage In user-match component");
      console.log(errorMessage);
    });
  }
  
  gotoChatTab() {
    this.selected.setValue(2);
  }

  gotoConfirmView() {
    // this.viewConfirm = true;
    // console.log(new ApplicantStatusModel().COMPANY_AGREED + ' Company agreed');
  }

  openCencelPopup() {
    console.log(' openCencelPopup');
    const dialogRef = this.dialog.open(InfoIntervieDialog, {
      data: { messageType: 'cencel-popup', }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result?.data + " => result")
      if (result?.data == "you confirmed") {
        this.updateStatus(new ApplicantStatusModel().APPLICANT_CANCEL);
      } else {
        console.log(" cencel button ");
      }
    });
  }

  openConfirmPopup() {
    console.log(' openConfirmPopup');
    const dialogRef = this.dialog.open(InfoIntervieDialog, {
      data: { messageType: 'confirm-popup', }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result?.data + " => result")
      if (result?.data == "you confirmed") {
        this.isConpamyAgree = false;
        this.isApplierAgree = true;
        let applierUser = JSON.parse(localStorage.getItem('userData'));
        let applierName = applierUser.first_name + ' ' + applierUser.last_name;
        this.selected.setValue(2);
        let chatTextOnConfirm = `<p>Guten Tag ${this.company.name},</p>
          <p>nach dem positiven Eindruck beim persönlichen Interview, würde ich gerne bei Ihnen arbeiten.</p>
          <p>${applierName}</p>`;
        this.chatFormGroup.patchValue({
          message: chatTextOnConfirm
        })
        this.updateStatus(new ApplicantStatusModel().APPLICANT_AGREED);
        this.isStatus = new ApplicantStatusModel().APPLICANT_AGREED;
      }
    });
  }
 
  openCallDialog() {
    var updatePhoneNumber = this.authService.getUserData();
    updatePhoneNumber.phone_number = this.applier?.phone_number;
    localStorage.setItem("userData",JSON.stringify(updatePhoneNumber));
    let applierUser = JSON.parse(localStorage.getItem("userData"));
   
    const dialogRef = this.dialog.open(DialogCall, {
      width: "275px",
      data: {
        applier: this.company,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let chatTextOnCallFail = `Hallo ${this.company.name}, ich hatte Sie versucht telefonisch zu erreichen, leider ohne Erfolg.
        Gerne können Sie mich bei Gelegenheit unter folgender Nummer zurückrufen:  ${applierUser.phone_number
        }`;
        // ${this.company.phone_number}

        this.chatFormGroup.patchValue({
          message: chatTextOnCallFail,
        });
      }
    });
  }

  onImagePicked(event) {
    this.isImgError = false;
    this.isImgSelected = true;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.filename = file.name;
      this.filesize = file.size;
      if(file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png" && file.type != "application/pdf"){
        this.isImgError = true;
        this.imgErrorMessage = 'Bitte wählen Sie den Dateityp jpeg, png, pdf.';
        return true
      }
      if (file.size <= 2000000) {
        // this.chatFormGroup.controls['message'].setErrors({'incorrect': false});
        // this.chatFormGroup.patchValue({
        //   message: " ",
        // });
        this.isImgError = false;
        this.imgErrorMessage = '';
        const reader = new FileReader();
        this.chatFormGroup.get('logo').setValue(file);
        reader.onload = () => {
          this.imagePreview = reader.result as string;
          this.imgSrc = this.imagePreview;
        }
        reader.readAsDataURL(file);
      } else {
        this.chatFormGroup.get('logo').setValue(null);
        this.imgSrc = '';
        this.isImgSelected = false;
        this.filesize = this.filename = '';
        this.isImgError = true;
        this.imgErrorMessage = 'Die Logo-Größe sollte weniger als 2 MB betragen.';
      }
    }
  }

}


@Component({
  selector: 'app-info-dialog',
  templateUrl: 'info-dialog.html',
})
export class InfoIntervieDialog {
  messageType: any;
  constructor(private router: Router,
    private dialogRef: MatDialogRef<ArrangeInterviewComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.messageType = data.messageType;
  }

  doRefresh() {
  }

  cancel() {
    this.dialogRef.close({ data: 'you cancelled' })
  }

  confirm() {
    this.dialogRef.close({ data: 'you confirmed' })
  }
}

 