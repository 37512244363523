<mat-spinner *ngIf="isSpinner"></mat-spinner>
<mat-card class="application-details">
  <mat-card-header>
    <mat-card-title class="ml-2">
      <a class="back" routerLink="/applications" *ngIf="isMobile">
        <span class="fa fa-arrow-left" style="color:white"></span><span style="color:#ffffff"> {{job?.title}}</span></a>
      <span *ngIf="!isMobile">{{jobTitle}}</span>
    </mat-card-title>
  </mat-card-header>

  <mat-tab-group mat-stretch-tabs [selectedIndex]="selected.value" (selectedTabChange)="tabClick($event)">
    <mat-tab label="Bewerbungsprozess">
      <mat-card-content *ngIf="isInterviewDate && proposalDetails" class="card-content overflow-hidden"
        style="min-height: 350px;">
        <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{isStatus}}"></app-status-bar>
        <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{isStatus}}"></app-status-bar>
        <div [class.isMobile]="!isMobile">
          <mat-card class="match-details user-details mb-2">
            <div class="mb-2"> <b>Das Unternehmen möchte dich gerne zu einem persönlichen Kennenlernen einladen:</b>
            </div>
            <div class="mb-3"> Folgende Termine stehen zur Auswahl. Bitte wähle einen Termin aus.
            </div>
            <div class="row">
              <div class="col-lg-7 col-md-6 col-sm-12 dateDisplay">
                <form class="bg-light1" autocomplete="off" [formGroup]="interviewFormGroup"
                  (ngSubmit)="addInterviewDate()">
                  <div class="col">
                    <mat-radio-group class="row" formControlName="selected_date" required>
                      <div class="col-lg-10" *ngFor="let index of proposalDetails['interview_date']">
                        <mat-radio-button labelPosition="before" value="{{ index.id }}">
                          {{ index.date | date:'dd.MM.yyyy':'locale'}}
                          {{ index.start_time.substring(0, index.start_time.length-3) }} -
                          {{ index.end_time.substring(0, index.end_time.length-3) }}
                        </mat-radio-button>
                      </div>
                    </mat-radio-group>
                  </div>
                  <button mat-raised-button color="primary" class="w-75 ml-2  mr-5"
                    [disabled]="interviewFormGroup.invalid || isButtonDisable == true">
                    Termin bestätigen
                  </button>
                  <div class="text-left mt-3 ml-2">
                    <a href="javascript:;" (click)="addNewSuggesion()">Kein passender Termin dabei?</a>
                  </div>
                </form>
              </div>
              <div class="{{isMobile == true ? 'col-lg-5 col-md-6 col-sm-12 mt-xl-2 mt-4' : 'col-lg-5 col-md-6 col-sm-12 mt-xl-2' }}">
                <h6>Ort des Interviews</h6>
                <p style="font-size: 18px;" class="fas fa-map-marker-alt font-17 " aria-hidden="true">&nbsp;</p>
                {{ proposalDetails.street }}, &nbsp;
                {{ proposalDetails.city }}, &nbsp;
                {{ proposalDetails.postcode }}
                <h6 class="mt-3" *ngIf="proposalDetails.notes">Anmerkung</h6>
                <p>{{ proposalDetails.notes }}</p>
              </div>
            </div>
          </mat-card>
        </div>
      </mat-card-content>
      <mat-card-content *ngIf="isConformInterview" class="card-content overflow-hidden" style="min-height: 350px;">
        <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{isStatus}}"></app-status-bar>
        <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{isStatus}}"></app-status-bar>
        <div [class.isMobile]="!isMobile">
          <mat-card class="match-details user-details mb-2 {{isMobile?'scrollable':''}}">
            <div class="mb-3 mt-1"> <b>Du hast mit dem Unternehmen ein persönliches Interview ausgemacht. </b> </div>
            <div class="row" *ngIf="jobHistory.id">
              <div class=" pl-lg-0 pl-0 col-lg-6 col-md-6 col-sm-12">
                <h6 class="col-12">Datum:</h6>
                <p class="col-12"  style="">{{ jobHistory?.date |
                  date:'dd.MM.yyyy':'locale'}}  </p>
                    <!-- <hr /> -->
                    <h6 class="col-12">Uhrzeit:</h6>
                    <p class="col-12" *ngIf="jobHistory?.start_time" style="">
                      {{ jobHistory?.start_time.substring(0,
                        jobHistory?.start_time.length-3) }} Uhr -
                        {{ jobHistory?.end_time.substring(0, jobHistory?.end_time.length-3) }} Uhr
                    </p>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <h6>Ort des Interviews</h6>
                <p style="font-size: 18px;" class="fas fa-map-marker-alt font-17 " aria-hidden="true">&nbsp;</p>
                <span *ngIf="jobHistory.interview_schedule?.street"> {{ jobHistory?.interview_schedule?.street }},
                </span>
                <span *ngIf="jobHistory.interview_schedule?.city">{{ jobHistory?.interview_schedule?.city }}, </span>
                <span *ngIf="jobHistory.interview_schedule?.postcode">{{ jobHistory?.interview_schedule?.postcode
                  }}</span>
                  <h6 class="col-12--">Ansprechpartner:</h6>
                  <p class="col-12--"  style="">
                    <span class="">{{ company?.name }} &nbsp;</span>
                     <i class="fas fa-lg fa-phone-alt fa-primary size-17" color="primary"></i>
                     <span class="mt-1 ml-1">{{ company?.phone_number }}</span>
                  </p>
                <h6 class="mt-3" *ngIf="jobHistory.interview_schedule?.note">Anmerkung </h6>
                <p>{{ jobHistory.interview_schedule?.note }} {{ jobHistory.interview_schedule?.notes }} </p>
              </div>
            </div>
            <div class="row ml-3 mr-3 mt-4 mb-5">
              Solltest du den Termin nicht wahrnehmen können, dann gib dem Unternehmen bitte rechtzeitig telefonisch
              Bescheid.
            </div>
          </mat-card>
        </div>
      </mat-card-content>
      <mat-card-content *ngIf="isRescheduleInterview" class="card-content overflow-hidden" style="min-height: 350px;">
        <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{isStatus}}"></app-status-bar>
        <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{isStatus}}"></app-status-bar>
        <div [class.isMobile]="!isMobile">
          <mat-card class="match-details user-details mb-2">
            <div class="mb-2"> <b class="mb-3">Lass dir vom Unternehmen neue Termine vorschlagen. <br /> Damit das
                Unternehmen weiß wann du am Besten kannst, wähle die passenden Optionen.
              </b>
            </div>
            <div class="mb-3"> Für ein persönliches Kennenlernen kann ich am Besten: </div>
            <div class="row">
              <div class="col-lg-7 col-md-6 col-sm-12  dateDisplay">
                <form class="bg-light1 " [formGroup]="reScheduleinterviewFormGroup" (ngSubmit)="reScheduleInterview()">
                  <div class="col">
                    <mat-radio-group class="row" formControlName="new_date" required>
                      <div class="col-lg-10 col-sm-12">
                        <mat-radio-button labelPosition="before" value="AFTERNOON">nur nachmittags
                        </mat-radio-button>
                      </div>
                      <div class="col-lg-10">
                        <mat-radio-button labelPosition="before" value="MORNING">nur vormittags </mat-radio-button>
                      </div>
                      <div class="col-lg-10">
                        <mat-radio-button labelPosition="before" value="SATURDAY">nur samstags</mat-radio-button>
                      </div>
                    </mat-radio-group>
                  </div>
                  <mat-form-field appearance="outline" class="col-md-10 col-sm-12 col-lg-10  ml-lg-n2">
                    <mat-label><b>Anmerkung</b> (optional)</mat-label>
                    <textarea matInput formControlName="note"
                      placeholder="z.B: Ich kann erst ab dem 07.01.2021 ein Termin wahrnehmen." #note
                      maxlength="300"></textarea>
                    <mat-hint align="end">{{note.value.length}} / 300</mat-hint>
                  </mat-form-field>
                  <button mat-raised-button color="primary" class="w-75 ml-2  mr-5" [disabled]="reScheduleinterviewFormGroup.invalid || isButtonDisable == true">
                    Rückmeldung senden
                  </button>
                  <!-- <button mat-raised-button color="primary11" class="w-75 ml-2 mt-2 mr-5" (click)="gotoInterviewProposalView()">
                    Zurück zu den Terminvorschlägen
                  </button> -->
                  <div class="text-left mt-3 ml-2">
                    <a href="javascript:;" (click)="gotoInterviewProposalView()">Zurück zu den Terminvorschlägen</a>
                  </div>
                </form>
              </div>
              <div class="{{isMobile == true ? 'col-lg-5 col-md-6 col-sm-12 mb-5 mt-4' : 'col-lg-5 col-md-6 col-sm-12' }}" *ngIf="proposalDetails">
                <h6>Ort des Interviews</h6>
                <p style="font-size: 18px;" class="fas fa-map-marker-alt font-17" aria-hidden="true">&nbsp;</p>
                {{ proposalDetails.street }}, &nbsp;
                {{ proposalDetails.city }}, &nbsp;
                {{ proposalDetails.postcode }}
                <h6 class="mt-3" *ngIf="proposalDetails.notes">Anmerkung</h6>
                <p>{{ proposalDetails.notes }}</p>
              </div>
            </div>

          </mat-card>
        </div>
      </mat-card-content>

      <mat-card-content *ngIf="isApplierSuggested" class="card-content overflow-hidden" style="min-height: 350px;">
        <!-- <div  *ngIf="!isMobile"  class="row">
          <div
            class="{{isStatus >= 1 ? 'col col-lg-3 text-center align-self-center reached ' :'col col-lg-3 text-center align-self-center'}}">
            <span class="fas fa-paper-plane"></span>
            <div class="process-text">
              <span class="text-nowrap" *ngIf="isStatus >= 2">Eingang</span>
            </div>
          </div>
          <div
            class="{{isStatus >= 3 ? 'col col-lg-3 text-center align-self-center reached' :'col col-lg-3 text-center align-self-center'}}">
            <span class="fas fa-check-square"></span>
            <div class="process-text">
              <span class="text-nowrap" *ngIf="isStatus >= 3 ">Prüfung</span>
            </div>
          </div>
          <div
            class="{{isStatus >= 41 ? 'col col-lg-3 text-center align-self-center reached' :'col col-lg-3 text-center align-self-center'}}">
            <span class="fas fa-users"></span>
            <div class="process-text">
              <span class="text-nowrap" *ngIf="isStatus >= 1 ">persönliches Interview</span>
            </div>
          </div>
          <div
            class="{{ isStatus >= 6 && !isStatus > 40 ? 'col col-lg-3 text-center align-self-center reached' :'col col-lg-3 text-center align-self-center'}}">
            <span class="fas fa-handshake"></span>
            <div class="process-text">
              <span class="text-nowrap">Entscheidung</span>
            </div>
          </div>
        </div> -->
        <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{isStatus}}"></app-status-bar>
        <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{isStatus}}"></app-status-bar>
        <mat-card class="match-details user-details mb-5">
          <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12 dateDisplay">
              <form class="bg-light1 " [formGroup]="reScheduleinterviewFormGroup" (ngSubmit)="reScheduleInterview()">
                <div class="col">
                  <mat-radio-group class="row" formControlName="new_date" required>
                    <div class="col-lg-10">
                      <mat-radio-button labelPosition="before" value="AFTERNOON">nur nachmittags
                      </mat-radio-button>
                    </div>
                    <div class="col-lg-10">
                      <mat-radio-button labelPosition="before" value="MORNING">nur vormittags </mat-radio-button>
                    </div>
                    <div class="col-lg-10">
                      <mat-radio-button labelPosition="before" value="SATURDAY">nur samstags</mat-radio-button>
                    </div>
                  </mat-radio-group>
                </div>
                <mat-form-field appearance="outline" class="example-full-width w-75 ml-2 ml0">
                  <mat-label><b>Anmerkung</b> (optional)</mat-label>
                  <textarea matInput formControlName="note"
                    placeholder="z.B: Ich kann erst ab dem 07.01.2021 ein Termin wahrnehmen." #note
                    maxlength="300"></textarea>
                  <mat-hint align="end">{{note.value.length}} / 300</mat-hint>
                </mat-form-field>
                <button mat-raised-button color="primary" class="w-75 ml-2  mr-5" [disabled]="true"> Rückmeldung senden
                </button>
                <!-- <button mat-raised-button color="primary" class="w-75 ml-2  mr-5"  [disabled]="reScheduleinterviewFormGroup.invalid" >  Rückmeldung senden </button> -->
              </form>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12 mb-5" *ngIf="proposalDetails">
              <h6>Ort des Interviews</h6>
              <p style="font-size: 18px;" class="fas fa-map-marker-alt font-17" aria-hidden="true">&nbsp;</p>
              {{ proposalDetails.street }}, &nbsp;
              {{ proposalDetails.city }}, &nbsp;
              {{ proposalDetails.postcode }}
              <h6 class="mt-3" *ngIf="proposalDetails.notes">Anmerkung</h6>
              <p>{{ proposalDetails.notes }}</p>
            </div>
          </div>
          <!-- <mat-card-subtitle>
              <h6>Du hast das Unternehmen gebeten, dir neue Terminvorschläge zu senden.</h6>
            </mat-card-subtitle>
          <mat-card-content class="ml-3 mr-2">
            Sobald das Unternehmen dir neue Terminvorschlage gesendet hat, bekommst du eine Nachricht.
          </mat-card-content> -->
        </mat-card>
      </mat-card-content>
      <mat-card-content *ngIf="isStatus == 6 && isConpamyAgree" class="card-content overflow-hidden mb-3" style="min-height: auto">
        <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{isStatus}}"></app-status-bar>
        <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{isStatus}}"></app-status-bar>
        <mat-card class="match-details user-details mb-5">
          <mat-card-title class="mt-2 text-center" align="center">
            <strong>Willst du dem Unternehmen zusagen?</strong>
          </mat-card-title>
          <mat-card-content class="mb-3 p-2">Wenn dich das Unternehmen sowie die Fahrerstelle überzeugt hat, kannst du hier dem Unternehmen mit einem Klick zusagen.</mat-card-content>
          <mat-card-actions>
            <button mat-flat-button color="accent" class="col mx-1" (click)="openCencelPopup()">
              <span class="icon-text-button">Absagen</span>
            </button>
            <button mat-flat-button color="primary" class="col mx-1" (click)="openConfirmPopup()">
              <span class="icon-text-button">Zusagen</span>
            </button>
          </mat-card-actions>
        </mat-card>
      </mat-card-content>

      <mat-card-content *ngIf="isStatus == 7 && isApplierAgree"  class="card-content overflow-hidden mb-3" style="min-height: 350px">

        <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{isStatus}}"></app-status-bar>
        <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{isStatus}}"></app-status-bar>
        <!-- Arrange Interview -->
        <mat-card class="match-details user-details mb-3">
          <mat-card-subtitle class="mt-2 text-center" align="center">
            Gratulation. Jetzt fehlt nur noch die Vertragsunterzeichnung und du kannst deine neue Stelle beginnen.
        </mat-card-subtitle>
        <mat-card-subtitle class="mt-2 text-center" align="center">
          <div class="text-center mb-3 {{isMobile == true ? 'iconDivMobile mt-1'  : 'iconDiv mt-5' }}">
              <p class="{{isMobile == true ? 'iconPMobile' : 'iconP' }}" ><i class="far fa-handshake "></i></p>
          </div>
        </mat-card-subtitle>
            <mat-card-actions class="text-center mb-0" style="padding: 0% 25% 0% 25%;">
              <button mat-flat-button color="accent" class="col mx-1" (click)="gotoChatTab()">
                <span class="icon-text-button ">Nachricht senden</span>
              </button>
            </mat-card-actions>
        </mat-card>
      </mat-card-content>
      <mat-card-content *ngIf="isStatus == 12 && isBothAgree"  class="card-content overflow-hidden mb-3" style="min-height: 350px">

        <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{isStatus}}"></app-status-bar>
        <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{isStatus}}"></app-status-bar>
        <!-- Arrange Interview -->
        <mat-card class="match-details user-details mb-3">
          <mat-card-subtitle class="mt-2 text-center" align="center">
            Gratulation. Jetzt fehlt nur noch die Vertragsunterzeichnung und du kannst deine neue Stelle beginnen.
        </mat-card-subtitle>
        <mat-card-subtitle class="mt-2 text-center" align="center">
          <div class="text-center mb-3 {{isMobile == true ? 'iconDivMobile mt-1'  : 'iconDiv mt-5' }}">
              <p class="{{isMobile == true ? 'iconPMobile' : 'iconP' }}" ><i class="far fa-handshake "></i></p>
          </div>
        </mat-card-subtitle>
            <mat-card-actions class="text-center mb-0" style="padding: 0% 25% 0% 25%;">
              <button mat-flat-button color="accent" class="col mx-1" (click)="gotoChatTab()">
                <span class="icon-text-button ">Nachricht senden</span>
              </button>
            </mat-card-actions>
        </mat-card>
      </mat-card-content>

      <mat-card-content *ngIf="isStatus == 5 && isDecisionPending"  class="card-content overflow-hidden " style="min-height: auto">
        <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{isStatus}}"></app-status-bar>
        <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{isStatus}}"></app-status-bar>
        <!-- Arrange Interview -->
        <mat-card class="match-details user-details mb-3 ">
          <mat-card-title class="mt-2 text-center" align="center">Du hast das persönliche Interview hinter dir!</mat-card-title>
            <mat-card-content>Das Unternehmen  wird dir innerhalb der nächsten 7 Tage Rückmeldung geben. </mat-card-content>
            <mat-card-content class="mb-4">Du  kannst  dem Unternehmen  eine Nachricht schreiben, falls du  ihm noch weitere Informationen zukommen lassen willst oder noch eine Frage hast</mat-card-content>
          <mat-card-actions class="text-center mb-0" style="padding: 0% 25% 0% 25%;">
              <button mat-flat-button color="accent" class="col mx-1" (click)="gotoChatTab()">
                <span class="icon-text-button ">Nachricht senden</span>
              </button>
          </mat-card-actions>
        </mat-card>
      </mat-card-content>

    </mat-tab>

    <mat-tab label="Job Details"
      *ngIf="isStatus == 4 || isStatus == 41 ||isStatus == 42 || isStatus == 43  || isStatus == 5 || isStatus == 7 || isStatus == 6">
      <mat-card-content class="card-content">
        <mat-card class="match-details user-details">
          <mat-card-content>
            <div class="row row-no-gutters icon-table">
              <div class="icon-box col-lg-6 col-xl-6 col-12">
                <span class="fa-road fa fa-3x child" style="vertical-align: top;"></span>
                <p class="child" *ngIf="jobs?.tours"><span style="font-size: 18px;">{{ getTours(jobs?.tours)
                    }}</span><br>Tourenprofil</p>
              </div>
              <div class="icon-box col-lg-6 col-xl-6 col-12">
                <span class="fa-clock fa fa-3x child" style="vertical-align: top;"></span>
                <p class="child" *ngIf="jobs?.shift"><span
                    style="font-size: 18px;">{{getShifts(jobs?.shift)}}</span><br>Art der Schichten</p>
              </div>
            </div>
            <div class="row row-no-gutters icon-table mt-3">
              <div class="icon-box col-lg-6 col-xl-6 col-12">
                <span class="fa-map-signs fa fa-3x child"></span>
                <p class="child" *ngIf="job?.city"><span
                    style="font-size: 18px;">{{job.city}}</span><br>{{distance.toFixed(2)}} km zum Abfahrtsort
                </p>
              </div>
              <div class="icon-box col-lg-6 col-xl-6 col-12">
                <span class="fa-truck-moving fa fa-3x child" style="vertical-align: top;"></span>
                <p class="child" *ngIf="jobs?.vehicle_experience"><span
                    style="font-size: 18px;">{{getVehicleTypeExperience(jobs?.vehicle_experience)}}</span><br>Fahrzeugtyp
                </p>
              </div>
            </div>

            <div *ngIf="jobs?.salary_minimum > 0" style="font-size: 18px;" class="mb-0">
              <hr>
              <p><strong>Verdienst&nbsp;<i matTooltip="Angabe des Bruttomonatsgehalts zzgl. Spesen" matTooltipPosition="above" class="fas fa-question-circle"></i>:</strong></p>
                <p class="salary_min">{{ jobs?.salary_minimum }} € <span *ngIf="jobs?.salary_maximum">- {{ jobs?.salary_maximum }} €</span> </p>
            </div>
            <hr>
            <div style="margin: 20px 0 5px 0;font-size: 18px;">
              <b>Anforderungen:</b>
            </div>
            <br>
            <ul>
              <li>
                <div class="row">
                  <p class="col-6">Führerscheinklasse:</p>
                  <div class="col-6">
                    <p style="display:inline-block">{{job?.license}}</p><span class="musthave"
                      style="display:inline-block">must
                      have</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <p class="col-6">zusätzliche Führerscheine:</p>
                  <p class="col-6" *ngIf="job?.additional_license">{{getAdditionalLicense(job?.additional_license)}}</p>
                </div>
              </li>
              <li>
                <div class="row">
                  <p class="col-6">Erfahrung auf Fahrzeugtyp:</p>
                  <p class="col-6" *ngIf="job?.vehicle_experience">{{getVehicleTypeExperience(job?.vehicle_experience)}}
                  </p>
                </div>
              </li>
              <li>
                <div class="row">
                  <p class="col-6">Berufserfahrung:</p>
                  <p class="col-6">{{job?.work_experience > 0 ? 'min. ' + job.work_experience + ' Jahre' : 'Keine
                    Berufserfahrung notwendig'}}</p>
                </div>
              </li>
              <li>
                <div class="row">
                  <p class="col-6">Deutschkenntnisse:</p>
                  <p class="col-6">{{germanLanSkill}}</p>
                </div>
              </li>
            </ul>
            <hr>
            <div>
              <p *ngIf="job?.being_transported" style="font-size: 18px;" class="mb-0  p-0"><b>Was wird
                  transportiert:</b></p><br>
              <div style="margin-top: -20px;">{{job?.being_transported}}</div>
              <p *ngIf="job?.specific_tasks" style="font-size: 18px;" class="mb-0 mt-2"><b>Was uns sonst noch wichtig
                  ist:</b></p><br>
              <div style="margin-top: -20px;">{{job?.specific_tasks}}</div>
            </div>
            <hr *ngIf="job?.being_transported || job?.specific_tasks">
            <div class="first-key-facts" *ngIf="company?.benefit">
              <p style="font-size: 18px;"><b>Vorteile des Unternehmens:</b></p>
              <mat-chip-list>
                <div *ngFor="let companyAdvantage of company.benefit.split(',')">
                  <mat-chip color="accent" selected>{{companyAdvantage}}</mat-chip>
                </div>
              </mat-chip-list>
            </div>

            <div style="padding: 0 10px 10px 0;font-size: 18px;" class="mt-2"><b>Über das Unternehmen:</b></div>
            <div>{{company?.about}}</div>
          </mat-card-content>
          <div *ngIf="!isApplicationPage">
            <mat-card-actions class="{{isMobile?'mb-5':''}}">
              <!-- <button mat-flat-button color="accent" class="col mx-1" >
                <span class="icon-text-button">Entscheidung</span>
              </button> -->
              <button mat-flat-button color="accent" class="col mx-1" (click)="rejectJobApplication()">
                <span class="fas fa-1-5x fa-times-circle" style="padding-right:5px"></span><span
                  class="icon-text-button">Bewerbung zurückziehen</span>
              </button>
            </mat-card-actions>
          </div>
        </mat-card>
        <!-- <app-user-details matchId="{{matchId}}"></app-user-details> -->
      </mat-card-content>
    </mat-tab>

    <mat-tab >
      <ng-template mat-tab-label>
        Nachrichten
        <i class="ml-3 " *ngIf="messageCount > 0" matBadge="{{messageCount}}" matBadgeSize="small"
        matBadgeColor="warn"></i>
      </ng-template>
      <app-applier-news matchId="{{matchId}}" isStatus="{{responseDatas?.status}}"></app-applier-news>

      <!-- <div class="container my-3">
        <form class="bg-light1 " [formGroup]="chatFormGroup" >
          <div class="form-group">
            <label for="editor">Neue Nachricht schreiben</label>
            <button mat-button type="button" class="mr-1 float-right" color="#2fa16f"
            style="margin-top: -10px; border: 1px solid #2fa16f; color:#2fa16f;  cursor: pointer;"
            (click)="openCallDialog()">
            <i class="fas fa-lg fa-phone-alt"> </i><span  class="{{isMobile == true ? 'hideLabelForPhone'  : '' }}" style="color: black;">&nbsp;&nbsp;Telefonnummer anzeigen</span>
          </button>
            <ckeditor [editor]="Editor" formControlName="message" id="editor" data="<p>Hello, world!</p>"></ckeditor>
          </div>
          <div class="form-group">
            <div class="row mb-1" *ngIf="filename">
              <div class="col-lg-5 col-md-5 col-sm-12 ">
                {{filename }}
              </div>
              <div class="col-lg-2 col-md-2 col-sm-12 ">
                {{filesize }} KB &nbsp; <i class="fas fa-trash"  (click)="deleteImage()" aria-hidden="true"></i>
              </div>
            </div>
            <hr *ngIf="filename">
            <div class="row button-padding">
              <div class="col-lg-3 col-md-3 col-sm-12 ">
                <button mat-raised-button class="mt-2" color="warm" style="outline: 0;" (click)="filePicker.click()"><i
                    class="fa fa-paperclip" aria-hidden="true"></i> &nbsp;Anhängen</button>
                <input type="file" accept="image/jpeg,image/jpg,image/png,application/pdf" #filePicker
                  (change)="onImagePicked($event)">
              </div>
              <div class="col-lg-2 mt-3  col-md-2">
              </div>
              <div class="col-lg-5 col-md-5 col-sm-12 mt-2">
              <button mat-raised-button color="primary" (click)="addMessage()" class="11w-50 ml-2" [disabled]="!isImgSelected && chatFormGroup.invalid">
                <i class="fas fa-envelope mr-1" aria-hidden="true"></i>
                Nachricht senden
              </button>
            </div>
          </div>
          </div>
          <div *ngIf="isImgError">
            <p class="text-danger">{{imgErrorMessage}}</p>
          </div>
        </form>
        <hr>
        <div class="col-12 px-0">
          <div class="px-1 py-1 chat-box bg-white">
            <div *ngFor="let message of messages">
              <div *ngIf="loggedInUserId !== message.created_by" class="media w-100 mb-3"><img
                  src="https://image.flaticon.com/icons/png/512/1962/1962551.png" alt="user" width="50"
                  class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light1 rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b ng-if="!message.name">{{message.name}}</b></span>
                      {{ message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}</p>
                      <p class="text-small mb-0 text-muted" *ngIf="message.message != 'null'" [innerHTML]="message.message"></p>
                      <p *ngIf="message?.file" class="text-small mb-0 text-muted">
                        <a target="_blank" href="{{message?.file}}"><img src="{{message?.file}}" *ngIf="!message?.isPdf" alt="" width="50" class="rounded-circle"></a>
                        <a target="_blank" href="{{message?.file}}" *ngIf="message?.isPdf"> <i class="fas fa-file-pdf" aria-hidden="true"></i>&nbsp; {{message?.fileName}}</a>
                      </p>
                  </div>
                </div>
              </div>
              <div *ngIf="loggedInUserId === message.created_by" class="media w-100 mb-3"><img
                  src="https://www.clipartmax.com/png/middle/171-1717870_stockvader-predicted-cron-for-may-user-profile-icon-png.png"
                  alt="user" width="50" class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light1 rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2">
                      <b ng-if="!message.name">{{message.name}}</b></span>
                      {{ message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}</p>
                      <p class="text-small mb-0 text-muted" *ngIf="message.message != 'null'" [innerHTML]="message.message"></p>
                      <p *ngIf="message?.file" class="text-small mb-0 text-muted">
                        <a target="_blank" href="{{message?.file}}"><img src="{{message?.file}}" *ngIf="!message?.isPdf" alt="" width="50" class="rounded-circle"></a>
                        <a target="_blank" href="{{message?.file}}" *ngIf="message?.isPdf"> <i class="fas fa-file-pdf" aria-hidden="true"></i>&nbsp; {{message?.fileName}}</a>
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </mat-tab>

  </mat-tab-group>
</mat-card>
