export const environment = {
  production: true,
  BASE_URL: 'https://backendtruckerpilot.azurewebsites.net/api/v1/',
  BASE_URL_WEB: "https://app.truckerpilot.de/",
  GOOGLE_CLIENT_KEY: '991551654700-g4sqpljf41a7ireuirmu1h84bc7pfcis.apps.googleusercontent.com',
  FACEBOOK_CLIENT_KEY: '2641528516177860',
  MOBILE_PREFIX: '+',
  SOCIAL_ACCOUNT_GOOGLE: 1,
  SOCIAL_ACCOUNT_FACEBOOK: 2,
  ROLE_APPLIER: 2,
  ROLE_COMPANY: 3,
  STATUS_MATCHED: 1,
  STATUS_APPLIED: 2,
};
