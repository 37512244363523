import {Component, OnInit} from '@angular/core';
import {DashboardService} from '../../../services/company/dashboard.service';
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {DeviceDetectorService} from "ngx-device-detector";
@Component({
  selector: 'app-job-profile',
  templateUrl: './job-profile.component.html',
  styleUrls: ['./job-profile.component.scss']
})
export class JobProfileComponent implements OnInit {

  jobId: number;
  applierId: number;
  errorMessage: string;
  subscriptions: Array<Subscription> = [];
  applicantDetails: any;
  isSpinner = true;
  ismobiledevice : any;
  isMobile: boolean;
  currentMsgFromChild1ToChild2 : any;
  isMobileDetailsShow:boolean = true;
  constructor(private activatedRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService,
              private dashboardService: DashboardService) {
                this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {
    const urlParams = new URLSearchParams(window.location.search);
    this.ismobiledevice = urlParams.get('mstatus');
    this.subscriptions.push(
      this.activatedRoute.params.subscribe(params => {
        this.jobId = +params['id'];
        this.applierId = +params['applierId'];
        this.dashboardService.getJobProfile(this.jobId).subscribe(
          resData => {
            this.isSpinner = false;
            this.applicantDetails = resData;
          },
          errorMessage => {
            this.errorMessage = errorMessage;
            console.log(this.errorMessage);
          });
      }));
      if(this.isMobile== true){
        // this.isMobileDetailsShow = false;
      }
  }

  fwdMsgToSib2($event) {
    this.currentMsgFromChild1ToChild2 = $event;
  }

  postMessage(messageFromChild:any) {
    if(this.isMobile){
      this.isMobileDetailsShow = (messageFromChild == 'list') ? true :false;
    }
    // this.isMobileDetailsShow = messageFromChild;
    // console.log(messageFromChild + "  ===  " + this.isMobileDetailsShow);
  }

}
