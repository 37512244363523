<div *ngIf="applications?.length == 0 && isMobile" class="text-center">
    <h1 class="text-center" style="{{isMobile?'margin-top: 5rem;font-size:25px;':'margin-top: 10rem;'}}">Du hast keine offene Bewerbung!</h1>
</div>

<div *ngFor="let application of applications" class="{{isMobile?'mb-4':''}}">
    <mat-card routerLink="/applications/{{application.application_id}}"
        style="{{application.application_id == matchId ? 'border : 2px solid #2fa16f;' : '' }}" class="match-card">
        <div class="tool-tip">
            <mat-card-header>
                <mat-card-subtitle>
                    <span class="fas fa-flag-checkered"></span>
                    {{getApplicationStatus(application?.status)}} |
                    <span class="fas fa-map-marker-alt"></span>
                    {{application?.city}}
                </mat-card-subtitle>
                <mat-card-title>
                    {{application?.title}}
                </mat-card-title>
            </mat-card-header>
            <mat-card-subtitle>
                <div *ngIf="checkImageExists(application.logo)" mat-card-avatars class="mt-2 ml-3">
                    <img src="{{application.logo}}" *ngIf="checkImageExists(application.logo) == true" class="ml-1 mt-1" height="30px" style="max-width: 55px;" alt="">
                    <span class="ml-3 ">{{ application.companyName }}</span>
                </div>
                <div *ngIf="!checkImageExists(application.logo)" class="mt-2 ml-3">
                    <span class="ml-1 mt-1" height="30px" alt="">&nbsp;&nbsp;&nbsp;</span>
                    <span class="ml-3 ">{{ application.companyName }}</span>
                </div>
                <!-- <mat-card-subtitle>{{job.title}}</mat-card-subtitle> -->
                <!-- <mat-card-subtitle class="mt-2">{{ job.companyName }}</mat-card-subtitle> -->
              </mat-card-subtitle>
            <mat-card-content>
                <div *ngIf="application?.status < 8 || application?.status > 40 || application?.status == 12" class="col">
                    Status {{getApplicationStatusForProcessBarTextDisplay(application?.status)}} von 4 erreicht.
                    <mat-progress-bar mode="determinate"
                        value="{{ getApplicationStatusForProcessBar(application?.status) }}"></mat-progress-bar>
                </div>
                <div *ngIf="application?.status == 8 && application?.status < 40" class="col text-center">
                    <b>Bewerbung wurde nicht ausgewählt</b>
                </div>  <div *ngIf="application?.status == 9 && application?.status < 40" class="col text-center">
                    <b>Du hast dem Unternehmen abgesagt</b>
                </div>
                <!--                <mat-progress-bar *ngIf="id" mode="determinate" value="{{getApplicationStatusForProcessBar(application.status)}}"></mat-progress-bar>-->
            </mat-card-content>
        </div>
        <mat-card-actions class="row">
            <button mat-button class="col" routerLink="/applications/{{application.application_id}}">
                <span class="fas fa-1-5x fa-info-circle"></span> <span class="icon-text-button">Details</span>
            </button>
        </mat-card-actions>
    </mat-card>
</div>
