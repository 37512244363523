<mat-dialog-content>
  <p *ngIf="messageType == 'openDialog'">Diese Funktion is in Kürze verfügbar</p>
  <p *ngIf="messageType == 'proposal'">Du hast dem Bewerber erfolgreich Terminvorschläge gesendet. <br /> Sobald der
    Bewerber einen Termin bestätigt,
    werden Sie benachrichtigt</p>
</mat-dialog-content>
<mat-dialog-actions *ngIf="messageType == 'openDialog' || messageType == 'proposal'" align="end">
  <button mat-raised-button color="primary" [mat-dialog-close]="true">Weiter</button>
</mat-dialog-actions>

<div *ngIf="messageType == 'confirmReject'">
  <mat-dialog-content>
    <p>Soll der Bewerber für diese Stelle abgelehnt werden?</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="accent"  (click)="cancel()" [mat-dialog-close]="true">Abbrechen</button>
    <button mat-raised-button color="primary"(click)="confirm()" [mat-dialog-close]="true" cdkFocusInitial>Weiter</button>
  </mat-dialog-actions>
</div>

<div *ngIf="messageType == 'cencel-popup'">
  <mat-dialog-content>
    <p>Wollen Sie dem Bewerber entgültig abgesagen?</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="accent" (click)="cancel()" [mat-dialog-close]="true">Abbrechen</button>
    <button mat-raised-button color="warn" (click)="confirm()" [mat-dialog-close]="true" cdkFocusInitial>Absagen</button>
  </mat-dialog-actions>
</div>
<div *ngIf="messageType == 'cencelV2-popup'">
  <mat-dialog-content>
    <p>Wollen Sie dem Bewerber tatsächlich eine Absage senden?</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="accent" (click)="cancel()" [mat-dialog-close]="true">Abbrechen</button>
    <button mat-raised-button color="warn" (click)="confirm()" [mat-dialog-close]="true" cdkFocusInitial>Ja</button>
  </mat-dialog-actions>
</div>
<div *ngIf="messageType == 'confirm-popup'">
  <mat-dialog-content>
    <p>Wollen Sie dem Bewerber entgültig zusagen?</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="accent"  (click)="cancel()" [mat-dialog-close]="true">Abbrechen</button>
    <button mat-raised-button color="primary"(click)="confirm()" [mat-dialog-close]="true" cdkFocusInitial>Zusagen</button>
  </mat-dialog-actions>
</div>

<!-- <div class="row no-gutters"  *ngIf="messageType == 'confirmReject'" style="margin-top: -30px;">
  <mat-dialog-content>
    <p>Soll der Bewerber für diese Stelle abgelehnt werden?</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" (click)="cancel()" mat-raised-button color="primary" [mat-dialog-close]="true"  cdkFocusInitial>Abbrechen</button>
    <button type="button" (click)="confirm()" mat-raised-button color="primary" [mat-dialog-close]="true"  cdkFocusInitial>Weiter</button>
  </mat-dialog-actions>
</div> -->

