import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {environment} from "../../../../environments/environment";
import {JobService} from "../../../services/company/job.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {ErrorStateMatcher} from "@angular/material/core";
declare var _paq: any;
const codes = require('german-postal-codes')
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent implements OnInit {

  companyProfileForm: FormGroup
  city: string;
  address: string;
  imagePreview: string;
  isError = false;
  errorMessage: string;
  formData: any;
  isEditMode = false;
  companyDetails: any;
  companyId: any;
  imgSrc = './assets/image_upload.png';
  //Local Variable defined
  formattedAddress = " ";
  options = {
    componentRestrictions: {
      country: ["de"]
    }
  }
  dialCode = '+49';
  isImgError = false;
  imgErrorMessage = '';
  isEdit = false;
  matcher = new MyErrorStateMatcher();
  submitted = false;
  isPostcodeValid = false;
  constructor(private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private jobService: JobService,   
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.initCompanyRegistrationForm();
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.companyId = +params['id'];
        if (this.companyId) {
          this.isEdit = true;
          this.getCompanyDetails();
        }
      }
    });
    let companyUser = JSON.parse(localStorage.getItem("userData"));
    this.companyProfileForm.patchValue({
      contact_email: companyUser.email,
    });
  }

  initCompanyRegistrationForm() {
    this.companyProfileForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      dial_code: ['', [Validators.required]],
      phone_number: ['', [Validators.required, Validators.pattern("^[0-9'+']*$")]],
      contact_email: ['', [Validators.required, Validators.email]],
      city: ['', [Validators.required]],
      street: ['', [Validators.required]],
      postcode: ['', [Validators.required]],
      about: ['', [Validators.required]],
      logo: ['']
    });
  }
  
  addressChange(address: any) {
    let street_number = "";
    for (let addressComponent of address.address_components) {
      if (addressComponent.types[0] == "street_number") {
        street_number = addressComponent.long_name;
      }
      
      if (addressComponent.types[0] == "route") {
        this.companyProfileForm.value.street = addressComponent.long_name + " " + street_number;
        this.companyProfileForm.patchValue({
          street: addressComponent.long_name + " " + street_number,
        });
      }

      if (addressComponent.types[0] == 'postal_code') {
        this.companyProfileForm.value.postcode = addressComponent.long_name;
        this.companyProfileForm.patchValue({
          postcode: addressComponent.long_name
        })
        // this.checkPostCode(address);
      }

      if (addressComponent.types[0] == 'locality') {
        this.city = addressComponent.long_name;
        this.companyProfileForm.patchValue({
          city: addressComponent.long_name
        })
      }
    }
  }

  onImagePicked(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.size <= 2000000) {
        this.isImgError = false;
        this.imgErrorMessage = '';
        const reader = new FileReader();
        this.companyProfileForm.get('logo').setValue(file);
        reader.onload = () => {
          this.imagePreview = reader.result as string;
          this.imgSrc = this.imagePreview;
        }
        reader.readAsDataURL(file);
      } else {
        this.isImgError = true;
        this.imgErrorMessage = 'Die Logo-Größe sollte weniger als 2 MB betragen.';
      }
    }
  }

  registerCompany() {
    this.submitted = true;
    if(this.companyProfileForm.valid) {
      this.formData = new FormData();
      if (this.companyId) {
        Object.entries(this.companyProfileForm.value).forEach(([key, value]: any[]) => {
          if (key !== 'logo' && key !== 'phone_number') {
            this.formData.set(key, value);
          }
          if (key === 'phone_number') {
            if (!this.companyDetails?.phone_number) {
              console.log("this should never execute");
              this.formData.set(key, this.dialCode + value);
            }
            if (this.companyDetails?.phone_number) {
              if (value.charAt(0) == '+') {
                this.isError = false;
                this.formData.set(key, value);
              } else {
                this.isError = true;
                this.errorMessage = "Bitte füge ein +Ländercode vor der Handynummer hinzu."
              }
            }
          }
        });

        let logoType = typeof this.companyProfileForm.get('logo').value;
        if (logoType == "object") {
          this.formData.append('logo', this.companyProfileForm.get('logo').value);
        }
      } else {
        this.formData.append('logo', this.companyProfileForm.get('logo').value);
        Object.entries(this.companyProfileForm.value).forEach(([key, value]: any[]) => {
          this.formData.set(key, value);
          if (key === 'phone_number') {
            this.formData.set(key, this.dialCode + value);
          }
        });
      }
      // console.log("----- Form Data ----- ")
      // for (let formData of this.formData.values()) {
      //   console.log(formData);
      // }
      if (!this.isError) {
        if (!this.companyId) {
          _paq.push(['setDocumentTitle', document.title]);
          _paq.push(['trackPageView']);
          _paq.push(['trackEvent', 'Company', 'Filled out all questions']);
          this.jobService.setCompanyDetails(this.formData).subscribe(
            resData => {
              this.isError = false;
              this.errorMessage = "";
              this.companyProfileForm.reset();
              this.companyDetails = resData;
              this.router.navigate(['/company-profile']);
            },
            errorMessage => {
              this.isError = true;
              this.errorMessage = errorMessage;
            });
        } else {
          _paq.push(['setDocumentTitle', document.title]);
          _paq.push(['trackPageView']);
          _paq.push(['trackEvent', 'Company', 'Company Edit Profile']);
          this.jobService.updateCompanyDetails(this.formData, this.companyId).subscribe(
            resData => {
              this.isError = false;
              this.errorMessage = "";
              this.companyProfileForm.reset();
              this.companyDetails = resData;
              this.router.navigate(['/company-profile']);
            },
            errorMessage => {
              this.isError = true;
              this.errorMessage = errorMessage;
            });
        }
      }
    }
  }

  resetForm() {
    this.address = '';
    this.imgSrc = './assets/image_upload.png';
    this.companyProfileForm.reset();
  }

  getCompanyDetails() {
    this.jobService.getCompanyDetails().subscribe(resData => {
      this.companyDetails = resData;
      this.companyProfileForm.patchValue({
        name: this.companyDetails.name,
        phone_number: this.companyDetails.phone_number,
        city: this.companyDetails.city,
        street: this.companyDetails.street,
        postcode: this.companyDetails.postcode,
        about: this.companyDetails.about,
        contact_email: this.companyDetails.contact_email,
        logo: this.companyDetails.logo
      });
      this.imgSrc = this.companyDetails.logo;
      this.address = this.companyDetails.street + ', ' + this.companyDetails.city + ', ' + this.companyDetails.postcode;
    }, error => {
      console.log("error occurred");
      console.log(error);
    });
  }

  telInputObject(obj) {
    obj.setCountry('de');
    this.companyProfileForm.patchValue({
      dial_code: '+49'
    })
    if (obj.dialCode) {
      this.dialCode = '+' + obj.dialCode;
    }
  }

  getNumber(number) {
    // console.log(number);
  }

  onCountryChange(obj) {
    if (obj.dialCode) {
      this.dialCode = '+' + obj.dialCode;
      this.companyProfileForm.patchValue({
        dial_code: this.dialCode
      })
    }
  }

  hasError(error) {
    // console.log(error);
  }

  checkPostCode(event: any) {
    // console.log(event.target.value + "  => value")
    if(this.companyProfileForm.value.postcode !== 'undefined' && this.companyProfileForm.value.postcode) {
      var target = codes.find(temp=>temp==this.companyProfileForm.value.postcode)
      if(target)
        this.isPostcodeValid = false;
      else
        this.isPostcodeValid = true;
    }
  }

  checkZero(event) {
    let input = event.currentTarget.value;
    // console.log(input);
    if (input.search(/^0/) != -1) {
      // this.isError = true;
      // this.errorMessage = "You have entered the country code twice";
      this.companyProfileForm.patchValue({
        phone_number: ''
      })
      // console.log("you enter 0");
    } else {
      // this.isError = false;
      // this.errorMessage = "";
    }
  }

}
