<nav *ngIf="!isMobile" class="navbar  navbar-light bg-light">
    <div class="container">
        <p class="navbar-text navbar-right">&copy; TRUCKERPILOT 2020</p>
        <a class="navbar-text navbar-right" href="https://www.truckerpilot.de/impressum">Impressumg</a>
        <a class="navbar-text navbar-right" href="https://www.truckerpilot.de/datenschutz">Datenschutz</a>
        <a class="navbar-text navbar-right" href="https://www.truckerpilot.de/agbs">AGBs</a>
    </div>
</nav>

<div *ngIf="isMobile" class="row" style="margin:40px 0px 10px 0px">
    <div class="col-4 column">
        <a href="https://www.truckerpilot.de/impressum">Impressum</a>
    </div>
    <div class="col-4 column">
        <a href="https://www.truckerpilot.de/datenschutz">Datenschutz</a>
    </div>
    <div class="col-4 column">
        <a href="https://www.truckerpilot.de/agbs">AGBs</a>
    </div>
    <div class="col-12 column" style="padding-top: 5px">
        <p>&copy; TRUCKERPILOT 2020</p>
    </div>
</div>
