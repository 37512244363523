<ngx-json-ld [json]="schema"></ngx-json-ld>
<div [class.isMobile]="isMobile" class="static-job">
    <mat-card class="match-details">
      <mat-card-header>
        <mat-card-title>
          <a class="back" routerLink="/dashboard" *ngIf="isMobile">
            <span class="fa fa-arrow-left" style="color:white"></span><span style="color:white"> {{id}}</span></a>
         <span *ngIf="!isMobile">{{id}}</span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row row-no-gutters icon-table">
          <div class="icon-box col-lg-6 col-xl-6 col-12">
            <span class="fa-road fa fa-3x child"></span>
            <!--Tourengebie wie in Fragebogen-->
            <p class="child"><span style="font-size: 18px;">Nahverkehr</span><br>Tourenprofil</p>
          </div>
          <div class="icon-box col-lg-6 col-xl-6 col-12">
            <span class="fa-clock fa fa-3x child"></span>
            <!--Schicht wie im Fragebogen-->
            <p class="child"><span style="font-size: 18px;">Tagesschicht</span><br>Art der Schichten</p>
          </div>
          <div class="icon-box col-lg-6 col-xl-6 col-12">
            <span class="fa-map-signs fa fa-3x child"></span>
            <!--Job.Standort Abfahrtsort-->
            <p class="child"><span style="font-size: 18px;">Deutschland</span><br>50km zum Abfahrtssort</p>
          </div>
          <div class="icon-box col-lg-6 col-xl-6 col-12">
            <span class="fa-truck-moving fa fa-3x child"></span>
            <!--(fahrzeugtyp aus Fragebogen-->
            <p class="child"><span style="font-size: 18px;">Sattelzug</span><br>Du fährst einen Sattelzug</p>
          </div>
        </div>
        <div style="margin: 20px 0px 5px 0px;font-size: 18px;">
          <b>Anforderungen:</b></div>
        <br>
        <ul>
          <li>
            <div class="row">
              <p class="col-6">Führerscheinklasse:</p>
              <div class="col-6">
                <p style="display:inline-block">CE</p><span class="musthave" style="display:inline-block">must
                  have</span>
              </div>
            </div>
          </li>
          <li>
            <div class="row">
              <p class="col-6">zusätzliche Führerscheine:</p>
              <p class="col-6">ADR-Scheine</p>
            </div>
          </li>
          <li>
            <div class="row">
              <p class="col-6">Erfahrung auf Fahrzeugtyp:</p>
              <p class="col-6">Sattelzug</p>
            </div>
          </li>
          <li>
            <div class="row">
              <p class="col-6">Berufserfahrung:</p>
              <p class="col-6">min. 3 Jahre</p>
            </div>
          </li>
        </ul>

      </mat-card-content>
      <mat-card-actions>
        <button mat-flat-button color="primary" class="col">
          <span class="fas fa-1-5x fa-check-circle" style="padding-right:5px"></span> <span class="icon-text-button">Bewerben</span>
        </button>
        <button mat-flat-button class="col">
          <span class="fas fa-1-5x fa-times-circle" style="padding-right:5px"></span><span class="icon-text-button">Ablehnen</span>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
