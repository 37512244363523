import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})
export class JobComponent implements OnInit {
  schema = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    "title": "LKW Fahrer",
    "description": "<p>Wir suchen einen Motivierten LKW-Fahrer im Fernverkehr</p>",
    "identifier": {
      "@type": "PropertyValue",
      "name": "TruckerPilot",
      "value": "1234567"
    },
    "datePosted": "2020-01-01",
    "employmentType": ["FULL_TIME", "PART_TIME", "CONTRACTOR"],
    "hiringOrganization": {
      "@type": "Organization",
      "name": "TruckerPilot",
      "sameAs": "https://www.truckerpilot.de",
      "logo": "https://apptest123.truckerpilot.de/assets/Logogreen.png"
    },
    "jobLocation": [{
      "@type": "Place",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Mönchhaldenstraße 7",
        "addressLocality": ", Aidlingen",
        "postalCode": "71134",
        "addressCountry": "DE"
      }
    }, {
      "@type": "Place",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Hohenzollernstraße 10",
        "addressLocality": ", Karlsruhe",
        "postalCode": "76135",
        "addressCountry": "DE"
      }
    },{
      "@type": "Place",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Sperlingweg 4",
        "addressLocality": ", Weingarten",
        "postalCode": "76356",
        "addressCountry": "DE"
      }
    }]
  }

  public isMobile = false;
  public id: string;
  subscriptions: Array<Subscription> = [];

  constructor(
    private deviceService: DeviceDetectorService,
    public router: Router,
    public route: ActivatedRoute
  ) { 
    this.isMobile = this.deviceService.isMobile()  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.id = params['id'];
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

}
