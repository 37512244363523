<mat-spinner *ngIf="isSpinner"></mat-spinner>
<!-- <div class="{{isMobile?'not-scrollable-content':'not-scrollable-content '}}"> -->
<div *ngIf="notificationResult" style="height: {{ screenHeight }}px;
overflow-x: hidden !important;
padding: 0px 2px 0px 2px;
overflow-y: auto;border: 1px solid lightgrey;
background : #FBFBFB; width: 350px;
scrollbar-width: none;{{isMobile?'padding-top: 26px':'padding-left: 0px; '}}" ngxInfiniteScroller
  strategy="scrollingToBoth" initialScrollPosition="10" (onScrollUp)="onScrollUp()" (onScrollDown)="onScrollDown()"
  class="{{isMobile?'mt-5':''}}" id="scroller">
  <!-- Company NOTIFICATION LIST -->
  <div *ngIf="CuserUser?.role == 3"
    class="{{isMobile?'col-lg-12 col-md-12 col-sm-12':'mt-3 col-lg-12 col-md-12 col-sm-12'}} ">
    <div class="{{isMobile?'ml-2 heading-mobile':'heading ml-3 mb-3 mt-3'}}">Benachrichtigungen
      <span (click)="closeNotificationList()" class="{{isMobile?'close-mobile fas fa-times':'close fas fa-times'}}"></span>
    </div>
    <div *ngIf="notificationResult">
      <mat-list *ngFor="let result of notificationResult; let i = index">
        <mat-divider *ngIf="result.status == 2 || result.status == 42 || result.status == 5 || result.status == 10
            || result.status == 7 || result.status == 9"></mat-divider>
        <mat-list-item style="{{isMobile?'width: 352px;':''}}" *ngIf="result.status == 2"
          class="{{ result.is_read ? 'bullet-read' : 'bullet' }}" id="notifyId{{result.id}}"
          (click)="setMarkRead($event,result.id,result.match.id,result.match.applier.id,result.match.job.id,result.status)">
          <p><b>{{result.match?.applier.user.first_name}}
              {{result.match?.applier.user.last_name}} </b> hat sich beworben: <b>{{result.match?.job.title}}</b></p>
          <br />
          <!-- <p class="date-time">{{getDateFormat(result.updated_at)}}</p> -->
          <p class="date-time">{{getDateFormat(result.updated_at)}}</p>
        </mat-list-item>
        <mat-list-item style="{{isMobile?'width: 352px;':''}}" *ngIf="result.status == 42"
          class="{{ result.is_read ? 'bullet-read' : 'bullet' }}" id="notifyId{{result.id}}"
          (click)="setMarkRead($event,result.id,result.match.id,result.match.applier.id,result.match.job.id,result.status)">
          <p><b>{{result.match?.applier.user.first_name}} {{result.match?.applier.user.last_name}} </b> wünscht einen <b>alternativen Termin</b> für das <b>persönliche Interview</b>.</p>
          <br />
          <p class="date-time">{{getDateFormat(result.updated_at)}}</p>
        </mat-list-item>
        <mat-list-item style="{{isMobile?'width: 352px;':'height:100px'}}" *ngIf="result.status == 5"
          class="{{ result.is_read ? 'bullet-read' : 'bullet' }}" id="notifyId{{result.id}}"
          (click)="setMarkRead($event,result.id,result.match.id,result.match.applier.id,result.match.job.id,result.status)">
          <p class=""><b>{{result.match?.applier.user.first_name}}
            {{result.match?.applier.user.last_name}}</b> hat den Termin am <b>{{result.interview_date |  date:'dd.MM.yy'}}</b> für ein <b>persönliches Interview</b> zugesagt.</p>
          <br />
          <p class="date-timev2">{{getDateFormat(result.updated_at)}}</p>
        </mat-list-item>
        <mat-list-item style="{{isMobile?'width: 352px;':''}}" *ngIf="result.status == 7"
          class="{{ result.is_read ? 'bullet-read' : 'bullet' }}" id="notifyId{{result.id}}"
          (click)="setMarkRead($event,result.id,result.match.id,result.match.applier.id,result.match.job.id,result.status)">
          <p><b>Glückwunsch! {{result.match?.applier.user.first_name}} {{result.match?.applier.user.last_name}} </b> hat sich für ihr <b>Jobangebot</b> entschieden.</p>
          <br />
          <p class="date-time">{{getDateFormat(result.updated_at)}}</p>
        </mat-list-item>
        <mat-list-item style="{{isMobile?'width: 352px;':''}}" *ngIf="result.status == 9"
          class="{{ result.is_read ? 'bullet-read' : 'bullet' }}" id="notifyId{{result.id}}"
          (click)="setMarkRead($event,result.id,result.match.id,result.match.applier.id,result.match.job.id,result.status)">
          <p><b>{{result.match?.applier.user.first_name}} {{result.match?.applier.user.last_name}} </b> hat ihr <b>Jobangebot</b> nicht angenommen.</p>
          <br />
          <p class="date-time">{{getDateFormat(result.updated_at)}}</p>
        </mat-list-item>
      <mat-list-item style="{{isMobile?'width: 352px;':''}}" *ngIf="result.status == 10"
      class="{{ result.is_read ? 'bullet-read' : 'bullet' }}" id="notifyId{{result.id}}"
      (click)="setMarkRead($event,result.id,result.match.id,result.match.applier.id,result.match.job.id,result.status)">
      <p> Sie haben eine neue <b>Nachricht</b> von <b>{{result.match?.applier.user.first_name}} {{result.match?.applier.user.last_name}}</b>.</p>
      <br />
      <p class="date-time">{{getDateFormat(result.updated_at)}}</p>
    </mat-list-item>
      </mat-list>
    </div>
    <mat-list>
      <mat-divider></mat-divider>
      <mat-list-item style="{{isMobile?'width: 352px;':''}}" *ngIf="notificationResult.length == 0">Benachrichtigungen
        nicht gefunden.</mat-list-item>
    </mat-list>
  </div>
  <!-- Applier NOTIFICATION LIST -->
  <div *ngIf="CuserUser?.role == 2" class="col-lg-12 col-md-12 col-sm-12">
    <div class="{{isMobile?'mt-2 heading-mobile':'heading mb-3 mt-3 ml-3'}}">Benachrichtigungen
      <span (click)="closeNotificationList()" class="{{isMobile?'close-mobile fas fa-times':'close fas fa-times'}}"></span>
    </div>
    <div *ngIf="notificationResult">
      <mat-list *ngFor="let result of notificationResult; let i = index">
        <mat-divider *ngIf="result.status == 3
          || result.status == 41 || result.status == 6 || result.status == 8 || result.status == 10
          || result.status == 43 || result.status == 5"></mat-divider>
        <!-- <mat-list-item style="{{isMobile?'width: 352px;':''}}" *ngIf="result.status == 2"
          class="{{ result.is_read ? 'bullet-read' : 'bullet' }}" id="notifyId{{result.id}}"
          (click)="setMarkRead($event,result.id,result.match.id,result.match.applier.id,result.match.job.id,result.status)">
          <p>Du hast dich bei <b>{{result?.match?.company.name}}</b> als <b>{{result?.match?.job.title}}</b> beworben.
          </p>
          <br />
          <p class="date-time">{{getDateFormat(result.updated_at)}}</p>
        </mat-list-item> -->
        <mat-list-item style="{{isMobile?'width: 352px;':''}}" *ngIf="result.status == 3"
          class="{{ result.is_read ? 'bullet-read' : 'bullet' }}" id="notifyId{{result.id}}"
          (click)="setMarkRead($event,result.id,result.match.id,result.match.applier.id,result.match.job.id,result.status)">
          <p><b>{{result?.match?.company.name}}</b> prüft deine <b>Bewerbung</b>.</p>
          <br />
          <p class="date-time">{{getDateFormat(result.updated_at)}}</p>
        </mat-list-item>
        <mat-list-item style="{{isMobile?'width: 352px;':''}}" *ngIf="result.status == 41"
          class="{{ result.is_read ? 'bullet-read' : 'bullet' }}" id="notifyId{{result.id}}"
          (click)="setMarkRead($event,result.id,result.match.id,result.match.applier.id,result.match.job.id,result.status)">
          <p><b>{{result?.match?.company.name}}</b> lädt dich zum <b>persönlichen Interview</b> ein: <b>Termin auswählen</b></p>
          <br />
          <p class="date-time">{{getDateFormat(result.updated_at)}}</p>
        </mat-list-item>
        <mat-list-item style="{{isMobile?'width: 352px;':''}}" *ngIf="result.status == 43"
          class="{{ result.is_read ? 'bullet-read' : 'bullet' }}" id="notifyId{{result.id}}"
          (click)="setMarkRead($event,result.id,result.match.id,result.match.applier.id,result.match.job.id,result.status)">
          <p><b>{{result?.match?.company.name}}</b> lädt dich zum <b>persönlichen Interview</b> ein: <b>Termin auswählen</b></p>
          <br />
          <p class="date-time">{{getDateFormat(result.updated_at)}}</p>
        </mat-list-item>
        <mat-list-item style="{{isMobile?'width: 352px;':''}}" *ngIf="result.status == 5"
          class="{{ result.is_read ? 'bullet-read' : 'bullet' }}" id="notifyId{{result.id}}"
          (click)="setMarkRead($event,result.id,result.match.id,result.match.applier.id,result.match.job.id,result.status)">
          <p>Du hast am <b>{{result.interview_date |  date:'dd.MM.yy'}}</b> ein <b>persönliches Interview</b> mit <b>{{result?.match?.company.name}}</b>.</p>
          <br />
          <p class="date-timev2">{{getDateFormat(result.updated_at)}}</p>
        </mat-list-item>
        <mat-list-item style="{{isMobile?'width: 352px;':''}}" *ngIf="result.status == 6"
          class="{{ result.is_read ? 'bullet-read' : 'bullet' }}" id="notifyId{{result.id}}"
          (click)="setMarkRead($event,result.id,result.match.id,result.match.applier.id,result.match.job.id,result.status)">
          <p><b>{{result?.match?.company.name}}</b> macht dir ein <b>Jobangebot</b>. Teile jetzt deine <b>Entscheidung</b> mit.</p>
          <br />
          <p class="date-time">{{getDateFormat(result.updated_at)}}</p>
        </mat-list-item>
        <mat-list-item style="{{isMobile?'width: 352px;':''}}" *ngIf="result.status == 8"
          class="{{ result.is_read ? 'bullet-read' : 'bullet' }}" id="notifyId{{result.id}}"
          (click)="setMarkRead($event,result.id,result.match.id,result.match.applier.id,result.match.job.id,result.status)">
          <p><b>{{result?.match?.company.name}}</b> hat deine <b>Bewerbung</b> leider nicht ausgewählt.</p>
          <br />
          <p class="date-time">{{getDateFormat(result.updated_at)}}</p>
        </mat-list-item>
        <mat-list-item style="{{isMobile?'width: 352px;':''}}" *ngIf="result.status == 10"
        class="{{ result.is_read ? 'bullet-read' : 'bullet' }}" id="notifyId{{result.id}}"
        (click)="setMarkRead($event,result.id,result.match.id,result.match.applier.id,result.match.job.id,result.status)">
        <p> Du hast eine neue <b>Nachricht</b> von {{result?.match?.company.name}}.</p>
        <br />
        <p class="date-time">{{getDateFormat(result.updated_at)}}</p>
      </mat-list-item>
      </mat-list>
    </div>
    <mat-list>
      <mat-divider></mat-divider>
      <mat-list-item style="{{isMobile?'width: 352px;':''}}" *ngIf="notificationResult.length == 0">Benachrichtigungen
        nicht gefunden.</mat-list-item>
    </mat-list>
  </div>

</div>
