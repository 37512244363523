import {Component, EventEmitter, Inject, OnChanges, OnInit, Output} from "@angular/core";
import {DashboardService} from "../../../services/company/dashboard.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {JobService} from "../../../services/company/job.service";
import {Router} from "@angular/router";
import {ApplicantService} from "../../../services/applicant.service";
import {ApplicantStatusModel} from "../../../models/applicant-status.model";
import {Observable} from 'rxjs';
import {share, finalize} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../../environments/environment";
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CreateJobsComponent} from "../jobs/create-jobs/create-jobs.component";
import {validateCheckbox} from "../../../services/validateCheckbox";
import {fragment} from "@angular-devkit/core";
import {ViewportScroller} from "@angular/common";

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: any = [
  // {name: {test1: 'val-1', test2: 'test22', weight: 1.0079, symbol: 'H'}, matching: 20.1797, date: 'Ne', status: 10},
  // {position: 1, name: {test1: 1, test2: '1111111', weight: 1.0079, symbol: 'H'}, bewerbungsdatum: 1.0079, status: 'H'},
];


@Component({
  selector: 'app-company-dashboard',
  templateUrl: './company-dashboard.component.html',
  styleUrls: ['./company-dashboard.component.scss']
})
export class CompanyDashboardComponent implements OnInit, OnChanges {
  panelOpenState = false;
  @Output() loginEmiterNavbar = new EventEmitter<any>();
  form: FormGroup;
  isMobile: boolean;
  responseData: any;
  errorMessage: string;
  jobs: any;
  chatUnreadCount = 0;
  cancelCount = 0;
  cancelJobMatchid = 0;
  isSpinner = true;
  checkInactive = false;
  public resultss = [];
  singleRow = [];
  nextPage = 1;
  public news: Array<any> = [];
  private currentPage = 1;
  private request$: Observable<any>;
  screenHeight: number;
  questionnaireForm: FormGroup;
  displayedColumns: string[] = ['select', 'name', 'matchnumber', 'date', 'status', 'actions'];
  // displayedColumns: string[] = ['select', 'weight', 'test2', 'bewerbungsdatum', 'status'];
  dataSource: any;
  cancelArray = [];
  // dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  applerChkForm: FormGroup;
  constructor(
    private deviceService: DeviceDetectorService,
    private router: Router,
    private dashboardService: DashboardService,
    private applicantService: ApplicantService,
    private jobService: JobService,
    private http: HttpClient,
    private _formBuilder: FormBuilder,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private vps: ViewportScroller
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.screenHeight = (window.innerHeight - 100);
  }

  ngOnChanges() {
    this.getDashboard(1);
  }

  ngOnInit() {
    this.loginEmiterNavbar.emit("user Login ");
    this.applerChkForm = this._formBuilder.group({
      chkboxSingle: false,
    }, {
      validator: (formGroup: FormGroup) => {
        return validateCheckbox(formGroup);
      }
    });

    // var els = document.querySelectorAll(".commonAll");
    // Array.prototype.forEach.call(els, function(el) {
    //   el.classList.remove("mat-checkbox-checked");
    // });

    this.getDashboard(1);
    // this.questionnaireForm = this.fb.group({
    //   chkbox: new FormControl(),
    //   chkboxSingle: new FormControl([]),
    // });

    this.questionnaireForm = new FormGroup({
      chkbox: new FormControl(false),
      "chkboxSingle": this._formBuilder.array([])
    });

    // this.questionnaireForm = new FormGroup({
    //   chkbox: new FormControl(),
    //   chkboxSingle: this.applerChkForm,
    //   // chkboxSingle: new FormControl([]),
    // });

  }

  deleteJob(status, jobId) {
    const dialogRef = this.dialog.open(DashboardInfoDialog, {
      data: {messageType: "delete-job"},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data == "you confirmed") {
        if (status == true) {
          this.dashboardService.deleteJob(jobId).subscribe(resData => {
            this.router.routeReuseStrategy.shouldReuseRoute = function() {
              return false;
            };
            this.router.onSameUrlNavigation = "reload";
            this.router.navigate([`/company`]);
            console.log(resData);
            this.news = [];
            this.getDashboard(1);
          }, error => {
            console.log("error occurred");
            console.log(error);
          });
        } else {
          const dialogRef2 = this.dialog.open(DashboardInfoDialog, {
            data: {messageType: "application-open"},
          });
          dialogRef2.afterClosed().subscribe((result) => {
            if (result?.data == "you confirmed") {
              // console.log(" confirmed ");
            } else {
              // console.log(" cencel button ");
            }
          });
        }
      } else {
        console.log(" cencel button ");
      }
    });
  }

  getDashboard(page) {
    this.checkInactive = false;

    this.dashboardService.getApplicationsOverview(page).subscribe(resData => {
      this.jobs = resData;
      for (let job of this.jobs.results) {

        job.isApplicationOpen = true;
        if (this.checkInactive == false && job.is_active == false) {
          job.isInactiveStart = true;
          this.checkInactive = true;
        }
        // console.log(job.is_active + " is_active => " + this.checkInactive + " this.checkInactive => " + this.checkInactive)
        if (job.match.length > 0) {
          job.chatUnreadCount = 0;
          job.unSeenApplierCount = 0;
          job.cancelCount = 0;
          job.isInactiveStart = false;
          for (let match of job.match) {
            if (match.status < 8 && match.status > 9) {
              // job.isApplicationOpen = true;
            }
            if (match.chat_unread_count > 0) {
              job.chatUnreadCount += match.chat_unread_count;
              job.isChatMessage = true;
            }
            if (!match.is_read && match.status == 2) {
              // if (!match.is_read) {
              job.unSeenApplierCount += 1;
            }
            if (match.status < 8 || match.status > 9) {
              job.cancelCount += 1;
              job.isApplicationOpen = false;
            }
          }
        }
      }
      this.isSpinner = false;
      this.currentPage++;
      this.nextPage = this.jobs?.next;
      this.news = this.news.concat(this.jobs?.results);
      // this.dataSource = this.news.concat(this.jobs?.results);
    }, error => {
      console.log("error occurred");
      console.log(error);
    });

    // this.dashboardService.getApplicationsOverview(page).subscribe(
    //   resData => {
    //     this.jobs = resData;
    //     for (let job of this.jobs.results) {
    //       job.isInactiveStart = false;
    //       if (this.checkInactive == false && job.is_active == false) {
    //         job.isInactiveStart = true;
    //         this.checkInactive = true;
    //       }
    //       // console.log(job.is_active + " is_active => " + this.checkInactive + " this.checkInactive => " + this.checkInactive)
    //       if (job.match.length > 0) {
    //         job.chatUnreadCount = 0;
    //         job.unSeenApplierCount = 0;
    //         for (let match of job.match) {
    //           if (match.chat_unread_count > 0) {
    //             job.chatUnreadCount += match.chat_unread_count;
    //             job.isChatMessage = true;
    //           }
    //           if (!match.is_read && match.status == 2) {
    //             // if (!match.is_read) {
    //             job.unSeenApplierCount += 1;
    //           }
    //         }
    //       }
    //     }
    //     this.isSpinner = false;
    //   },
    //   errorMessage => {
    //     this.errorMessage = errorMessage;
    //     console.log(this.errorMessage);
    //   });
  }

  getLoadMoredata(page) {
    let parts = page.split("=");
    let param = parts[parts.length - 1];
    if (parts.length == 1) {
      param = 1;
    }
    this.isSpinner = true;
    this.getDashboard(param);
  }

  getUserName(fname, lname) {
    let fullname = fname + ' ' + lname;
    let stringlength = (this.isMobile) ? 12 : 22;
    if (fullname.length < stringlength) {
      return fullname;
    } else {
      return fullname.substring(0, stringlength) + "...";
    }
  }

  getTours(tours) {
    let translatedTours = [];
    tours.forEach(tour => {
      if (tour == "local") {
        translatedTours.push('Nahverkehr');
      } else if (tour == "national") {
        translatedTours.push("Fernverkehr (national)");
      } else if (tour == "international") {
        translatedTours.push("Fernverkehr (international)");
      } else {
        translatedTours.push("Not available");
      }
    });
    return translatedTours.join(', ');
  }

  getStatus(status) {
    switch (status) {
      case 1:
        // return 'abgestimmt';
        return 'Eingang';
      case 2:
        return 'Eingang';
      case 3:
        return 'Prüfung';
      case 4:
        return 'persönliches Interview';
      case 5:
        return 'persönliches Interview';
      case 41:
        return "persönliches Interview";
      case 42:
        return "persönliches Interview";
      case 43:
        return "persönliches Interview";
      case 6:
        return 'Entscheidung';
      case 7:
        return 'Entscheidung';
      case 8:
        return 'Bewerber abgesagt';
      case 9:
        return 'Bewerbung zurückgezogen';
      case 11:
        return 'Match abgelehnt';
      case 12:
        return 'beidseitige Zusage';
      default:
        return 'Not available';
    }
  }

  getApplicationStatusForProcessBar(status: number) {
    if (status == 2) {
      return 25;
    } else if (status == 3) {
      return 50;
    } else if (status == 4 || status == 5 || status > 40) {
      return 75;
    } else if (status == 6 || status == 7 || status == 12) {
      return 100;
    }
  }

  redirectToJobDetails(matchId, jobId, applierId, isRead, JobStatus) {
    if (JobStatus == 2) {
      this.updateStatus(new ApplicantStatusModel().CHECKED, matchId);
    }
    this.dashboardService.readUser(matchId).subscribe(resData => {
    }, error => {
      console.log("Error occurred while reading user");
      console.log(error);
    });
    this.router.navigate([`/job-profile/${jobId}/${applierId}`]);
  }

  updateStatus(changeStatus, matchId) {
    let data = {status: changeStatus};
    this.applicantService.doUpdateStatus(data, matchId).subscribe(resData => {
      if (changeStatus == new ApplicantStatusModel().COMPANY_CANCEL) {
        // window.location.reload();
        // this.currentPage = 1;
        // this.nextPage = null;
        // this.news = [];
        // document.getElementById("rejectBtn"+this.cancelJobMatchid).classList.add("mat-button-disabled");
        // document.getElementById("allChkbx"+this.cancelJobMatchid).classList.remove("mat-checkbox-checked");
        // this.getDashboard(1);
      }
    }, errorMessage => {
      console.log("errorMessage In user-match component");
      console.log(errorMessage);
    });
  }

  onScrollUp(): void {
    if (!this.nextPage) {
      return;
    }
    this.getNews(this.currentPage)
      .pipe(finalize(() => this.onFinalize()))
      .subscribe((news) => {
        // this.currentPage++;
        // this.news = news.concat(this.news);
      }, error => {
        console.log("error occurred");
        console.log(error);
      });
  }

  onScrollDown(): void {
    if(!this.nextPage){
      return
    }
    this.isSpinner = true;
    this.getNews(this.currentPage)
      .pipe(finalize(() => this.onFinalize()))
      .subscribe((news) => {
        this.nextPage = news.next;
        for (let job of news.results) {
          job.isInactiveStart = false;
          job.isApplicationOpen = true;
          if (this.checkInactive == false && job.is_active == false) {
            job.isInactiveStart = true;
            this.checkInactive = true;
          }
          if (job.match.length > 0) {
            job.chatUnreadCount = 0;
            job.unSeenApplierCount = 0;
            job.cancelCount = 0;
            for (let match of job.match) {
              if (match.chat_unread_count > 0) {
                job.chatUnreadCount += match.chat_unread_count;
                job.isChatMessage = true;
              }
              if (!match.is_read && match.status == 2) {
                job.unSeenApplierCount += 1;
              }
              if (match.status < 8 || match.status > 9) {
                job.cancelCount += 1;
                job.isApplicationOpen = false;
              }
            }
          }
        }
        this.isSpinner = false;
        this.news = this.news.concat(news.results);
      }, error => {
        console.log("error occurred");
        console.log(error);
      });
  }

  // Prevent duplicate requests on scroll.
  // More: https://stackoverflow.com/a/50865911/6441494
  private getNews(page: any = 1): Observable<any> {
    if (this.request$) {
      return this.request$;
    } else {
      this.currentPage++;
      this.request$ = this.dashboardService.getV2(page);
      return this.request$;
    }
  }

  private onFinalize(): void {
    this.isSpinner = false;
    this.request$ = null;
  }

  appendDataInDatasource(result) {
    this.dataSource = [];
    let res = result.match;

    res.map(val1 => {
      console.log(val1);
      this.dataSource.push({
        //  id: res.id,
        name: val1.applier.user.first_name + ' ' + val1.applier.user.last_name,
        rating: val1.rating,
        date: val1.history.updated_at,
        chat_unread_count: val1.chat_unread_count,
        duration: val1.duration,
        distance: val1.distance,
        is_read: val1.is_read,
        is_active: val1.is_active,
        job_id: val1.job.id,
        applier_id: val1.applier.id,
        status: val1.status
      });
    });
    return this.dataSource;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = 4;
    // const numRows = (this.dataSource?.length < 0 ) ? 0 : this.dataSource?.length;
    // const numRows = this.dataSource?.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  selectAll(jobId) {
    this.uncheckDifferentJob(jobId);
    this.cancelJobMatchid = jobId;
    this.cancelArray = [];
    let selected_rows = [];
    var els = document.querySelectorAll(".jobApplier");
    Array.prototype.forEach.call(els, function(el) {
      el.classList.remove("mat-checkbox-checked");
    });

    if (this.questionnaireForm.value.chkbox == true) {
      var elss = document.querySelector(".rejectBtn" + jobId);
      elss.classList.remove("mat-button-disabled");
      document.getElementById("rejectBtn" + jobId).classList.remove("mat-button-disabled");
      var els = document.querySelectorAll(".jobApplier" + jobId);
      Array.prototype.forEach.call(els, function(el) {
        let mId = Number(el.getAttribute('id').match(/\d+/));
        // var numberValue = Number(el.getAttribute('ng-reflect-value'));
        selected_rows.push(mId);
        // selected_rows.push(el.getAttribute('ng-reflect-value'))
        // el.style.backgroundColor = "red";
        el.classList.add("mat-checkbox-checked");
      });
      this.cancelArray = selected_rows;
    } else {
      document.getElementById("rejectBtn" + jobId).classList.add("mat-button-disabled");
      var els = document.querySelectorAll(".jobApplier" + jobId);
      Array.prototype.forEach.call(els, function(el) {
        selected_rows.push(el.getAttribute('ng-reflect-value'));
        el.classList.remove("mat-checkbox-checked");
      });
      this.cancelArray = [];
      selected_rows = [];
    }
  }


  checkIfOneSelected(matchid, event: any, jobId) {
    let cnt = 0;
    var els = document.querySelectorAll(".jobApplier" + jobId);
    this.uncheckDifferentJob(jobId);
    this.cancelJobMatchid = jobId;
    if (event.checked == true) {
      this.cancelArray.push(matchid);
    } else {
      document.getElementById("job" + matchid).classList.remove("mat-checkbox-checked");
      const index: number = this.cancelArray.indexOf(matchid);
      if (index !== -1) {
        this.cancelArray.splice(index, 1);
      }
      this.cancelArray = this.cancelArray.filter(item => item !== matchid);
    }
    Array.prototype.forEach.call(els, function(el) {
      cnt++;
    });
    if (this.cancelArray.length == 0) {
      document.getElementById("rejectBtn" + jobId).classList.add("mat-button-disabled");
    } else {
      document.getElementById("rejectBtn" + jobId).classList.remove("mat-button-disabled");
    }
    if (cnt == this.cancelArray.length) {
      document.getElementById("allChkbx" + jobId).classList.add("mat-checkbox-checked");
    } else {
      document.getElementById("allChkbx" + jobId).classList.remove("mat-checkbox-checked");
    }
  }

  uncheckDifferentJob(jobId) {
    // console.log("Previous JobId" + this.cancelJobMatchid +  " =>Current jobId" +  jobId )
    if (this.cancelJobMatchid > 0 && this.cancelJobMatchid != jobId) {
      document.getElementById("rejectBtn" + this.cancelJobMatchid).classList.add("mat-button-disabled");
      document.getElementById("allChkbx" + this.cancelJobMatchid).classList.remove("mat-checkbox-checked");
      var els = document.querySelectorAll(".jobApplier" + this.cancelJobMatchid);
      Array.prototype.forEach.call(els, function(el) {
        el.classList.remove("mat-checkbox-checked");
      });
      this.cancelJobMatchid = jobId;
      this.cancelArray = [];
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  openCencelPopup() {
    if(this.cancelArray.length == 0){
      return true;
    }
    const dialogRef = this.dialog.open(DashboardInfoDialog, {
      data: {messageType: "cencel-popup"},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data == "you confirmed") {
        this.questionnaireForm.patchValue({
          chkbox: false,
        });
        document.getElementById("rejectBtn" + this.cancelJobMatchid).classList.add("mat-button-disabled");
        // document.getElementById("allChkbx"+this.cancelJobMatchid).classList.remove("mat-checkbox-checked");
        this.cancelArray.forEach(childObj => {
          this.updateStatus(new ApplicantStatusModel().COMPANY_CANCEL, childObj);
        });
        this.currentPage = 1;
        this.nextPage = null;
        this.news = [];
        this.getDashboard(1);
        this.router.routeReuseStrategy.shouldReuseRoute = function() {
          return false;
        };
        this.router.onSameUrlNavigation = "reload";
        this.router.navigate([`/company`]);
      } else {
        console.log(" cencel button ");
      }
    });
  }
}


@Component({
  selector: "app-info-dialog",
  templateUrl: "info-dialog.html",
})
export class DashboardInfoDialog {
  messageType: any;
  constructor(private router: Router,
    private dialogRef: MatDialogRef<CreateJobsComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.messageType = data.messageType;
  }

  doRefresh() { }

  cancel() {
    // closing itself and sending data to parent component
    this.dialogRef.close({ data: "you cancelled" });
  }

  confirm() {
    // closing itself and sending data to parent component
    this.dialogRef.close({ data: "you confirmed" });
  }
}

