<!-- {{ applicationStatus }} -->
<mat-card class="application-details" *ngIf="applicationStatus > 1 && applicationStatus < 4">
<!--  <div class="job-header-image" style="background: url({{company.logo}}); background-position: center center; background-repeat: no-repeat;">-->
<!--    <div style="width:100%; text-align:right">-->
<!--      <button mat-button routerLink="/applications/">-->
<!--        <span class="fas fa-times"></span>-->
<!--      </button>-->
<!--    </div>-->
<!--  </div>-->
  <mat-card-header>
    <mat-card-title>
        <a class="back" routerLink="/applications" *ngIf="isMobile">
          <span class="fa fa-arrow-left" style="color:white"></span><span style="color:#ffffff"> {{job?.title}}</span></a>
     <span class="ml-1" *ngIf="!isMobile">{{job?.title}}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-tab-group mat-stretch-tabs (selectedTabChange)="tabClick($event)">
    <mat-tab label="Job Details">
      <mat-card-content class="card-content">
       <!-- <div class="row" *ngIf="!isMobile">
         <div class="{{applicationStatus >= 1 ? 'col col-lg-3 reached' :'col col-lg-3'}}">
           <span class="fas fa-paper-plane"></span>
           <div class="process-text">
             <span>Eingang</span>
           </div>
         </div>
         <div class="{{applicationStatus > 2 ? 'col col-lg-3 reached' :'col col-lg-3'}}">
           <span class="fas fa-check-square"></span>
           <div class="process-text">
             <span *ngIf="applicationStatus >= 2">Prüfung</span>
           </div>
         </div>
         <div class="{{applicationStatus >= 5 ? 'col col-lg-3 reached' :'col col-lg-3'}}">
           <span class="fas fa-users"></span>
           <div class="process-text">
             <span *ngIf="applicationStatus >= 2 ">persönliches Interview</span>
             <span *ngIf="applicationStatus >= 5 ">persönliches Interview</span>
           </div>
         </div>
         <div class="{{applicationStatus >= 6 ? 'col col-lg-3 reached' :'col col-lg-3'}}">
           <span class="fas fa-handshake"></span>
           <div class="process-text">
             <span>Entscheidung</span>
           </div>
         </div>
       </div> -->
       <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{applicationStatus}}"></app-status-bar>
       <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{applicationStatus}}"></app-status-bar>
        <app-user-details matchId="{{matchId}}"></app-user-details>
      </mat-card-content>
    </mat-tab>
    <mat-tab >
      <ng-template mat-tab-label>
        Nachrichten
        <i class="ml-3 " *ngIf="messageCount > 0"  matBadge="{{messageCount}}" matBadgeSize="small"
        matBadgeColor="warn"></i>
      </ng-template>

      <app-applier-news matchId="{{matchId}}" isStatus="{{responseData?.status}}"></app-applier-news>

      <!-- <div class="container my-3">
        <form class="bg-light " [formGroup]="chatFormGroup" >
          <div class="form-group">
            <label for="editor">Neue Nachricht schreiben</label>
            <button mat-button type="button" class="mr-1 float-right" color="#2fa16f"
            style="margin-top: -10px; border: 1px solid #2fa16f; color:#2fa16f;  cursor: pointer;"
            (click)="openCallDialog()">
            <i class="fas fa-lg fa-phone-alt"> </i><span  class="{{isMobile == true ? 'hideLabelForPhone'  : '' }}" style="color: black;">&nbsp;&nbsp;Telefonnummer anzeigen</span>
          </button>
            <ckeditor [editor]="Editor" formControlName="message" id="editor" data="<p>Hello, world!</p>"></ckeditor>
          </div>
          <div class="form-group">
          <div class="row mb-1" *ngIf="filename">
            <div class="col-lg-5 col-md-5 col-sm-12 ">
              {{filename }}
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12 ">
              {{filesize }} KB &nbsp; <i class="fas fa-trash"  (click)="deleteImage()" aria-hidden="true"></i>
            </div>
          </div>
          <hr *ngIf="filename">
          <div class="row button-padding">
            <div class="col-lg-3 col-md-3 col-sm-12 ">
              <button mat-raised-button class="mt-2" color="warm" style="outline: 0;" (click)="filePicker.click()"><i
                  class="fa fa-paperclip" aria-hidden="true"></i> &nbsp;Anhängen</button>
              <input type="file" accept="image/jpeg,image/jpg,image/png,application/pdf" #filePicker
                (change)="onImagePicked($event)">
            </div>
            <div class="col-lg-2 mt-3  col-md-2">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 mt-2">
              <button mat-raised-button color="primary" (click)="addMessage()" class="w-50 ml-1" [disabled]="!isImgSelected && chatFormGroup.invalid">
                <i class="fas fa-envelope mr-1" aria-hidden="true"></i>  Nachricht senden
              </button>
            </div>
          </div>
        </div>
          <div *ngIf="isImgError">
            <p class="text-danger">{{imgErrorMessage}}</p>
          </div>
        </form>
        <hr *ngIf="messages">
        <div class="col-12 px-0">
          <div class="px-1 py-1 chat-box bg-white">
            <div *ngFor="let message of messages">
              <div *ngIf="loggedInUserId !== message.created_by" class="media w-100 mb-3"><img
                src="https://image.flaticon.com/icons/png/512/1962/1962551.png" alt="user"
                width="50" class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span
                      class="mr-2"><b ng-if="message.name != null">{{message.name}}</b></span> {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}</p>
                      <p class="text-small mb-0 text-muted" *ngIf="message.message != 'null'" [innerHTML]="message.message"></p>
                      <p *ngIf="message?.file" class="text-small mb-0 text-muted">
                        <img src="{{message?.file}}" *ngIf="!message?.isPdf" alt="" width="50" class="rounded-circle">
                        <a target="_blank" href="{{message?.file}}" *ngIf="message?.isPdf"> <i class="fas fa-file-pdf" aria-hidden="true"></i>&nbsp; {{message?.fileName}}</a>
                      </p>
                  </div>
                </div>
              </div>
              <div *ngIf="loggedInUserId === message.created_by" class="media w-100 mb-3"><img
                src="https://www.clipartmax.com/png/middle/171-1717870_stockvader-predicted-cron-for-may-user-profile-icon-png.png"
                alt="user"
                width="50" class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span
                      class="mr-2"><b ng-if="message.name != null">{{message.name}}</b></span> {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}</p>
                      <p class="text-small mb-0 text-muted" *ngIf="message.message != 'null'" [innerHTML]="message.message"></p>
                      <p *ngIf="message?.file" class="text-small mb-0 text-muted">
                        <img src="{{message?.file}}" *ngIf="!message?.isPdf" alt="" width="50" class="rounded-circle">
                        <a target="_blank" href="{{message?.file}}" *ngIf="message?.isPdf"> <i class="fas fa-file-pdf" aria-hidden="true"></i>&nbsp; {{message?.fileName}}</a>
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </mat-tab>
  </mat-tab-group>
</mat-card>

<mat-card class="application-details" *ngIf="applicationStatus > 7 && applicationStatus < 40 &&  applicationStatus != 12">
    <mat-card-header>
      <mat-card-title>
          <a class="back" routerLink="/applications" *ngIf="isMobile">
            <span class="fa fa-arrow-left" style="color:white"></span><span style="color:#ffffff"> {{job?.title}}</span></a>
       <span class="ml-1" *ngIf="!isMobile">{{job?.title}}</span>
      </mat-card-title>
    </mat-card-header>
    <mat-tab-group mat-stretch-tabs (selectedTabChange)="tabClick($event)">
      <mat-tab label="Job Details">
        <mat-card-content class="card-content">
          <app-user-details matchId="{{matchId}}"></app-user-details>
        </mat-card-content>
      </mat-tab>
      <mat-tab >
        <ng-template mat-tab-label>
          Nachrichten
          <i class="ml-3 " *ngIf="messageCount > 0"  matBadge="{{messageCount}}" matBadgeSize="small"
          matBadgeColor="warn"></i>
        </ng-template>
        <!--   START    -->
        <app-applier-news matchId="{{matchId}}" isStatus="{{responseData?.status}}"></app-applier-news>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
<!-- <app-arrange-interview [class.isMobile]="isMobile" *ngIf="applicationStatus >= 41 " [jobHistory]="applicationHistory" jobStatus="{{applicationStatus}}" jobTitle="{{job?.title}}" matchId="{{matchId}}"></app-arrange-interview> -->
<app-arrange-interview [class.isMobile]="isMobile" *ngIf="(applicationStatus >= 4 && applicationStatus < 8) || applicationStatus >= 41 || applicationStatus == 12" [jobHistory]="applicationHistory" jobStatus="{{applicationStatus}}" jobTitle="{{job?.title}}" matchId="{{matchId}}"></app-arrange-interview>