import { Component, OnInit ,ViewChild,Output, EventEmitter } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
// import { AuthService } from "./services/auth.service";
// import { environment } from "../environments/environment";
import { Subscription } from "rxjs";
// import { JobService } from "./services/job.service";
import {DeviceDetectorService} from "ngx-device-detector";
import { NotificationService } from "../../../services/notification.service";
import { Observable } from 'rxjs';
import { share, finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {environment} from "../../../../environments/environment";
import {SelectionModel} from '@angular/cdk/collections';
import {Router} from "@angular/router";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { formatDate } from "@angular/common";
@Component({
  selector: 'app-company-notifications',
  templateUrl: './company-notifications.component.html',
  styleUrls: ['./company-notifications.component.scss']
})
export class CompanyNotificationsComponent implements OnInit {
  public isMobile = false;
 
  CuserUser :any ;
  screenHeight : number;
  jobs: any;
  isSpinner = true;
  checkInactive = false;
  nextPage =  1;
  public notificationResult: Array<any> = [];
  private currentPage = 1;
  private request$: Observable<any>;
  @Output() msgToSibling = new EventEmitter<any>();
  @Output() msgToSibling2 = new EventEmitter<any>();

  constructor(private deviceService: DeviceDetectorService,
              private router: Router,
              private notificationService: NotificationService) {
      this.isMobile = this.deviceService.isMobile();
      this.screenHeight = (window.innerHeight - 10);
  }

  getDateFormat(date){
    const monthNames = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun","Jul","Aug", "Sep", "Okt", "Nov", "Dez" ];
    // const monthNames = ["januari", "februari", "maart", "april", "mei", "juni","juli", 
    // "augustus", "september", "oktober", "november", "december" ];
    const d = new Date(date);
    return monthNames[d.getMonth()] +formatDate(date, ' dd, HH:mm', 'de-DE');
  }

  getNotifications(page) {
    this.checkInactive = false;
    this.notificationService.getNotificationOverview(page).subscribe(resData => {
      this.jobs = resData;
      for (let job of this.jobs.results) {
        job.isInactiveStart = false;
        if (this.checkInactive == false && job.is_active == false) {
          job.isInactiveStart = true;
          this.checkInactive = true;
        }
        if (job.match.length > 0) {
          job.chatUnreadCount = 0;
          job.unSeenApplierCount = 0;
          job.cancelCount = 0;
        }
      }
      this.isSpinner = false;
      this.currentPage++;
      this.nextPage = this.jobs?.next;
      this.notificationResult = this.notificationResult.concat(this.jobs?.results);
    }, error => {
      console.log("error occurred");
      console.log(error);
    });
  }

  onScrollUp(): void {
    if(!this.nextPage){
      return 
    }
    this.getNotification(this.currentPage)
      .pipe(finalize(() => this.onFinalize()))
      .subscribe((news) => {
      },error => {
        console.log("error occurred");
        console.log(error);
      });
  }

  onScrollDown(): void {
    if(!this.nextPage){
      return 
    }
    this.isSpinner = true;
    this.getNotification(this.currentPage)
      .pipe(finalize(() => this.onFinalize()))
      .subscribe((res) => {
        this.nextPage = res.next;
        for (let job of res.results) {
          job.isInactiveStart = false;
        }
        this.isSpinner = false;
        this.notificationResult = this.notificationResult.concat(res.results);
      }, error => {
          console.log("error occurred");
          console.log(error);
        });
  }

  // Prevent duplicate requests on scroll.
  // More: https://stackoverflow.com/a/50865911/6441494
  private getNotification(page: any = 1): Observable<any> {
    if (this.request$) {
      return this.request$;
    } else {
      this.currentPage++;
      this.request$ = this.notificationService.getV2(page);
      return this.request$;
    }
  }

  private onFinalize(): void {
    this.isSpinner = false;
    this.request$ = null;
  }

  setMarkRead(event,notificationId,matchid,applierid,jobid,status){
    console.log(notificationId,matchid,applierid,jobid,status);
    this.isSpinner = true;
    this.notificationService.readNotifiacation(notificationId).subscribe(resData => {
      resData;
      document.getElementById("notifyId"+notificationId).classList.remove("bullet");
      document.getElementById("notifyId"+notificationId).classList.add("bullet-read");
      this.isSpinner = false;
      this.msgToSibling.emit(new Date());
      this.msgToSibling2.emit("hide");
      if(this.CuserUser.role == 3){
        if(status == 10){
          this.router.navigate(['/job-profile/chat/'+jobid +"/"+applierid]);
        }else{
          this.router.navigate(['/job-profile/'+jobid +"/"+applierid]);
        }
      }else{
        this.router.navigate(['/applications/'+matchid]);
      }
    }, error => {
      console.log("error occurred");
      console.log(error);
    });

  }
 
  ngOnInit() {
    this.isSpinner = false;
    this.CuserUser = JSON.parse(localStorage.getItem("userData"));
    if(!this.CuserUser){
      return false
    }
    this.getNotifications(1);
  }  

  closeNotificationList() {
    this.msgToSibling2.emit("hide");
    // document.getElementById("notification-sidenav").style.visibility = "hidden";
  }
  
}
