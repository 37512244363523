<div class="row first">
  <div class="col first" style="max-width: 750px; padding: 0px;">
    <p class="h3">Bewirb dich bei uns!!!</p>
    <div class="question-content row">
    </div>
    <div class="button-area row">
      <div class="col"></div>
      <button mat-flat-button class="col" (click)="submit()" color="primary">
        <span class="icon-text-button">Weiter</span>
        <span class="fas fa-chevron-right"></span>
      </button>
      <div class="col"></div>
    </div>
  </div>
</div>