import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FormStepperModel, CustomMap} from "../models/form-stepper.model";

@Injectable({
  providedIn: 'root'
})
export class StepperService {
  //variables
  index: BehaviorSubject<number>;
  state: BehaviorSubject<CustomMap<FormStepperModel>>;

  constructor() {
    this.index = new BehaviorSubject<number>(0);
    const map: CustomMap<FormStepperModel> = new CustomMap();
    for (let i = 0; i < 14; i++) {
      map[i] = {active: false, valid: false, disabled: true}
    }
    this.state = new BehaviorSubject<CustomMap<FormStepperModel>>(map);
  }
}
