<div class="container my-3">
  <form class="bg-light" [formGroup]="chatForm">
    <!-- <form class="bg-light" [formGroup]="chatForm" (ngSubmit)="addMessage()"> -->
    <div class="form-group">
      <label for="editor">Neue Nachricht schreiben</label>
      <button mat-button type="button" class="mr-1 float-right" color="#2fa16f"
        style="margin-top: -10px; border: 1px solid #2fa16f; color:#2fa16f;  cursor: pointer;"
        (click)="openCallDialog()">
        <i class="fas fa-lg fa-phone-alt"> </i><span class="{{isMobile == true ? 'hideLabelForPhone'  : '' }}"
          style="color: black;">&nbsp;&nbsp;Telefonnummer anzeigen</span>
      </button>
      <ckeditor [editor]="Editor" [config]="{ scayt_sLang: 'de_DE' }" formControlName="message" id="editor">
      </ckeditor>
    </div>
    <div class="form-group">
      <!-- <div class="justify-content-start align-items-start">
        <img src="{{imgSrc}}" class="ml-1" style="width: 90px; height: 90px; border-radius: 50px; cursor: pointer;"
          (click)="filePicker.click()" />
      </div> -->
      <div class="row" *ngIf="filename">
        <div class="col-lg-8 col-md-8 col-sm-3 col-xs-4">
          {{filename }}
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-4 {{ isMobile ? 'mt-2' : ''}}">
          {{filesize }} KB &nbsp; <i class="fas fa-trash" style="cursor:pointer;" (click)="deleteImage()" aria-hidden="true"></i>
        </div>
      </div>


      <hr *ngIf="filename">
      <div class="{{ isMobile ? 'row' : 'row button-padding'}}">
        <div class="col-lg-3 col-md-9 col-sm-6 ">
          <button mat-raised-button class="{{ isMobile ? 'mt-2' : ''}}" color="warm" style="outline: 0;"
            (click)="filePicker.click()"><i class="fa fa-paperclip" aria-hidden="true"></i> &nbsp;Anhängen</button>
          <input type="file" accept="image/jpeg,image/jpg,image/png,application/pdf" #filePicker
            (change)="onImagePicked($event)">
        </div>
        <div *ngIf="!isMobile" class="col-lg-6 col-md-6 col-sm-12 {{ isMobile ? 'mt-3' : ''}}">
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6 {{ isMobile ? 'mt-2' : ' btn-right'}}">
          <button mat-raised-button color="primary" class="{{ isMobile ? '' : 'ml-2 float-right'}} "
            (click)="addMessage()" [disabled]="!isImgSelected && chatForm.invalid">
            <i class="fas fa-envelope mr-1" aria-hidden="true"></i> Nachricht senden
          </button>
        </div>
      </div>
      <!-- <button mat-raised-button  class="mt-2" color="primary"
              style="outline: 0;"
              (click)="filePicker.click()"><i class="fa fa-paperclip" aria-hidden="true"></i> Anhängen</button>
      <input type="file" accept="image/jpeg,image/jpg,image/png,application/pdf" #filePicker (change)="onImagePicked($event)"> -->
      <!-- <input type="file" accept="image/*" #filePicker (change)="onImagePicked($event)"> -->
    </div>
    <div *ngIf="isImgError">
      <p class="text-danger">{{imgErrorMessage}}</p>
    </div>
    <!-- <button mat-raised-button color="primary" class="w-100" [disabled]="chatForm.invalid">
      <i class="fas fa-envelope mr-1" aria-hidden="true"></i>
      Nachricht senden
    </button> -->
  </form>
  <hr *ngIf="messages?.results">
  <!-- Chat Box-->
  <div class="col-12">
    <div class="px-1 py-1 chat-box bg-white">
      <div *ngFor="let message of messages?.results">
        <div *ngIf="loggedInUserId === message?.created_by" class="media w-100 mb-3"><img
            src="https://image.flaticon.com/icons/png/512/1962/1962551.png" alt="user" width="50"
            class="rounded-circle">
          <div class="media-body ml-3">
            <div class="bg-light rounded py-2 px-3 mb-2">
              <p class="small text-muted"><span class="mr-2"><b *ngIf="message.name">{{message.name}}</b></span>
                {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                Uhr</p>
              <p class="text-small mb-0 text-muted" *ngIf="message.message != 'null'" [innerHTML]="message.message"></p>
              <p *ngIf="message?.file" class="text-small mb-0 text-muted">
                <!-- <a target="_blank" href="{{message?.file}}"><img src="{{message?.file}}" *ngIf="!message?.isPdf" alt="" width="50" class="rounded-circle"></a> -->
                <a target="_blank" href="{{message?.file}}" *ngIf="!message?.isPdf"> <i class="fas fa-file"
                    aria-hidden="true"></i>&nbsp; {{message?.fileName}}</a>
                <a target="_blank" href="{{message?.file}}" *ngIf="message?.isPdf"> <i class="fas fa-file-pdf"
                    aria-hidden="true"></i>&nbsp; {{message?.fileName}}</a>
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="loggedInUserId !== message?.created_by" class="media w-100 mb-3"><img
            src="https://www.clipartmax.com/png/middle/171-1717870_stockvader-predicted-cron-for-may-user-profile-icon-png.png"
            alt="user" width="50" class="rounded-circle">
          <div class="media-body ml-3">
            <div class="bg-light rounded py-2 px-3 mb-2">
              <p class="small text-muted"><span class="mr-2"><b *ngIf="message.name">{{message.name}}</b></span>
                {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
              </p>
              <p class="text-small mb-0 text-muted" *ngIf="message.message != 'null'" [innerHTML]="message.message"></p>
              <p *ngIf="message?.file" class="text-small mb-0 text-muted">
                <!-- <a target="_blank" href="{{message?.file}}"><img src="{{message?.file}}" *ngIf="!message?.isPdf" alt="" width="50" class="rounded-circle"></a> -->
                <a target="_blank" href="{{message?.file}}" *ngIf="!message?.isPdf"> <i class="fas fa-file"
                    aria-hidden="true"></i>&nbsp; {{message?.fileName}}</a>
                <a target="_blank" href="{{message?.file}}" *ngIf="message?.isPdf"> <i class="fas fa-file-pdf"
                    aria-hidden="true"></i>&nbsp; {{message?.fileName}}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--
<div class="container my-3">
  <form class="bg-light" [formGroup]="chatForm" (ngSubmit)="addMessage()">
    <div class="form-group">
      <label for="editor">Neue Nachricht schreiben</label>
      <button mat-button type="button" class="mr-1 float-right" color="#2fa16f"
        style="margin-top: -10px; border: 1px solid #2fa16f; color:#2fa16f;  cursor: pointer;"
        (click)="openCallDialog()">
        <i class="fas fa-lg fa-phone-alt"> </i><span  class="{{isMobile == true ? 'hideLabelForPhone'  : '' }}" style="color: black;">&nbsp;&nbsp;Telefonnummer anzeigen</span>
      </button>
      <ckeditor [editor]="Editor" formControlName="message" id="editor"></ckeditor>
    </div>
    <button mat-raised-button color="primary" class="w-100" [disabled]=chatForm.invalid>
      <i class="fas fa-envelope mr-1" aria-hidden="true"></i>
      Nachricht senden
    </button>
  </form>
  <hr>
  <div class="col-12 px-0">
    <div class="px-1 py-1 chat-box bg-white">
      <div *ngFor="let message of messages?.results">
        <div *ngIf="loggedInUserId === message?.created_by" class="media w-100 mb-3"><img
            src="https://image.flaticon.com/icons/png/512/1962/1962551.png" alt="user" width="50"
            class="rounded-circle}}">
          <div class="media-body ml-3">
            <div class="bg-light rounded py-2 px-3 mb-2">
              <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                Uhr</p>
              <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
            </div>
          </div>
        </div>
        <div *ngIf="loggedInUserId !== message?.created_by" class="media w-100 mb-3"><img
            src="https://www.clipartmax.com/png/middle/171-1717870_stockvader-predicted-cron-for-may-user-profile-icon-png.png"
            alt="user" width="50" class="rounded-circle}}">
          <div class="media-body ml-3">
            <div class="bg-light rounded py-2 px-3 mb-2">
              <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
              </p>
              <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
