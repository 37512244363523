<!--<div class="register" *ngIf="!isViewProfile">-->
<div class="register">
  <mat-card class="example-card">
    <form [formGroup]="companyProfileForm" (ngSubmit)="registerCompany()" >
      <mat-card-header class="m-0">
        <div class="row no-gutters">
          <div class="col">
            <div class="justify-content-start align-items-start">
              <img src="{{imgSrc}}" class="ml-1"
                   style="width: 90px; height: 90px; border-radius: 50px; cursor: pointer; "
                   (click)="filePicker.click()"
              />
            </div>
            <button mat-raised-button type="button" class="mt-2" color="primary"
                    style="line-height: 20px; border-radius: 20px; outline: 0;"
                    (click)="filePicker.click()">Upload logo</button>
            <input type="file" accept="image/*" #filePicker (change)="onImagePicked($event)">
          </div>
          <div *ngIf="isImgError">
            <p class="text-danger">{{imgErrorMessage}}</p>
          </div>
        </div>
      </mat-card-header>
      <mat-divider [inset]="true" class="my-3"></mat-divider>
      <mat-card-content class="mt-3">
        <div class="row">
          <div class="col">
            <h6>Unternehmensname<span class="text-danger"> *</span></h6>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput required formControlName="name"/>
            </mat-form-field>
            <mat-error *ngIf="companyProfileForm.get('name').invalid && submitted" style="margin-top: -20px;">
              <H6>Firmenname erforderlich</H6>
            </mat-error>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h6>Adresse suchen</h6>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput ngx-google-places-autocomplete [options]='options'
                     placeholder="Adresse eingeben"
                     value="{{address}}" (onAddressChange)="addressChange($event)"/>
              <!-- <mat-icon matSuffix>search</mat-icon> -->
              <mat-icon matSuffix><i class="fas fa-search"></i></mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h6>Straße, Nr.<span class="text-danger"> *</span></h6>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="text" formControlName="street">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 col-lg-5 col-sm-12">
            <h6>Postleitzahl<span class="text-danger"> *</span></h6>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="text"  (ngModelChange)="checkPostCode($event)" (focus)="checkPostCode($event)" (change)="checkPostCode($event)" formControlName="postcode">
            </mat-form-field>
          </div>
          <div class="col">
            <h6>Stadt<span class="text-danger"> *</span></h6>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="text" formControlName="city">
            </mat-form-field>
          </div>
        </div>
        <mat-error *ngIf="(companyProfileForm.get('street').invalid ||
                            companyProfileForm.get('postcode').invalid ||
                            companyProfileForm.get('city').invalid) && submitted" style="margin-top: -20px;">
          <H6>Firmenadresse erforderlich</H6>
        </mat-error>
        <mat-error *ngIf="isPostcodeValid" style="margin-top: -20px;">
          <strong>Bitte gebe eine deutsche Postleitzahl ein</strong>
        </mat-error>
        <div class="row" *ngIf="companyDetails?.phone_number">
          <div class="col">
            <h6>Telefon <span class="text-danger"> *</span></h6>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="tel" formControlName="phone_number" >
            </mat-form-field>
          </div>
        </div>   
      
        <h6 *ngIf="!companyDetails?.phone_number">Telefon <span class="text-danger"> *</span></h6>
        <div class="row no-gutters" *ngIf="!companyDetails?.phone_number">
          <div class="col-3 phoneDropdown mr-1"  style="max-width: 30%;">
              <mat-form-field appearance="outline" class="w-100">
                <input matInput ng2TelInput type="text"
                       (hasError)="hasError($event)"
                       (ng2TelOutput)="getNumber($event)"
                       (intlTelInputObject)="telInputObject($event)"
                       (countryChange)="onCountryChange($event)"
                       [ng2TelInputOptions]="{initialCountry: 'de'}"
                       formControlName="dial_code"
                       required>
              </mat-form-field>
          </div>
          <div class="col phoneDropdown">
            <mat-form-field appearance="outline" class="w-100">
              <input matInput formControlName="phone_number"
                     (keyup)="checkZero($event)"
                     placeholder="Telefonnummer"
                     required>
              <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
              <mat-hint  *ngIf="companyProfileForm.get('phone_number').valid" >Die Nummer darf nicht mit 0 beginnen. Die Ländervorwahl ist bereits ausgewählt.</mat-hint>
            </mat-form-field>
            <mat-error *ngIf="companyProfileForm.get('phone_number').invalid && submitted" style="margin-top: -20px;">
              <H6>Telefonnummer erforderlich</H6>
            </mat-error>
          </div>
        </div>

        <!-- <div class="row" *ngIf="companyDetails?.contact_email"> -->
          <div class="row mt-3">
            <div class="col">
              <h6>Kontakt-Emailadresse <span class="text-danger"> *</span></h6>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput type="tel" formControlName="contact_email">
              </mat-form-field>
            </div>
          </div>

        <div class="{{companyDetails?.phone_number ? 'row' : 'row mt-4'}}">
          <div class="col">
            <h6>Über das Unternehmen<span class="text-danger"> *</span></h6>
            <mat-form-field appearance="outline" class="w-100">
              <textarea matInput type="tel" [errorStateMatcher]="matcher" formControlName="about" rows="5"
                        placeholder="Stelle dein Unternehmen in wenigen Sätzen vor."></textarea>
            </mat-form-field>
<!--            <mat-error *ngIf="companyProfileForm.hasError('required')" style="margin-top: -20px;">-->
            <mat-error *ngIf="companyProfileForm.get('about').invalid && submitted" style="margin-top: -20px;">
              <H6>Unternehmensbeschreibung erforderlich</H6>
            </mat-error>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="text-danger">Mit einem Sternchen (*) markierte Felder sind Pflichtfelder.</p>
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <button mat-raised-button type="button"  *ngIf="!isEdit" (click)="resetForm()"  class="w-100">Abbrechen</button>
            <button mat-raised-button type="button" *ngIf="isEdit" routerLink="/company-profile"  class="w-100">Abbrechen</button>
          </div>
          <div class="col text-center">
<!--                        <button mat-raised-button type="submit" [disabled]="companyProfileForm.invalid"  color="primary" class="w-100">Speichern</button>-->
            <button mat-raised-button type="submit" color="primary" class="w-100">Speichern</button>
          </div>
        </div>
      </mat-card-content>
    </form>
    <div *ngIf="isError">
      <p class="text-center text-danger">{{errorMessage}}</p>
    </div>

  </mat-card>
</div>
