import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Subscription} from 'rxjs';
import {JobService} from '../../services/job.service';
import {environment} from "../../../environments/environment.prod";
declare var _paq: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  public matchId: number;
  isMobile: boolean;
  responseData: any;
  subscriptions: Array<Subscription> = [];
  isMatchFound = true;
  isSpinner = true;
  constructor(public router: Router,
              public activatedRoute: ActivatedRoute,
              private deviceService: DeviceDetectorService,
              private jobService: JobService) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.activatedRoute.params.subscribe(params => {
        this.matchId = +params['id'];
        this.isSpinner = false;
        // (this.isMobile) ? this.isMatchFound = false : '';
        if (!this.matchId && !this.isMobile) {
          this.jobService.getJobs(environment.STATUS_MATCHED)
            .subscribe(resData => {
              this.responseData = resData;
              this.isSpinner = false;
              if (this.responseData.results.length >= 1) {
                this.matchId = this.responseData.results[0].id;
                this.isMatchFound = true;
              } else {
                this.isMatchFound = false;
              }
              _paq.push(['setDocumentTitle', document.title]);
              _paq.push(['trackPageView']);
              _paq.push(['trackEvent', 'Applier', this.responseData.count + ' Matches loaded']);

            }, errorMessage => {
              console.log('errorMessage In user-match component');
              console.log(errorMessage);
            });
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
}
