<div *ngIf="loginUser?.role == 3">
  <div class="row no-gutters" style="margin-top: -30px;">
    <div class="col-11"></div>
    <div class="col">
      <button type="button" mat-icon-button title="close" class="close-button float-right" style="margin-right: -24px;"
        (click)="doClose()">
        <i class="fas fa-times fa-lg" style="color:#f44336" color="warn"></i>
      </button>
    </div>
  </div>

  <mat-dialog-content class="mat-typography justify-content-center">
    <!-- <p style="font-size: 20px;"><b>{{loginUser?.phone_number}}</b></p> -->
    <p style="font-size: 20px;"><b>{{applierDetails.applier.phone_number}}</b></p>
  </mat-dialog-content>
  <mat-dialog-actions class="">
    <p>Bewerber nicht erreicht?</p>
    <button type="button" mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Bewerber um
      Rückruf
      bitten</button>
  </mat-dialog-actions>
</div>

<div *ngIf="loginUser?.role == 2">
  <div class="row no-gutters" style="margin-top: -30px;">
    <div class="col-11"></div>
    <div class="col">
      <button type="button" mat-icon-button title="close" class="close-button float-right" style="margin-right: -24px;"
        (click)="doClose()">
        <i class="fas fa-times fa-lg" style="color:#f44336" color="warn"></i>
      </button>
    </div>
  </div>

  <mat-dialog-content class="mat-typography justify-content-center">
    <!-- <p style="font-size: 20px;"><b>{{loginUser?.phone_number}}</b></p> -->
    <p style="font-size: 20px;"><b>{{applierDetails.applier.phone_number}}</b></p>
  </mat-dialog-content>
  <mat-dialog-actions class="">
    <p>Unternehmen nicht erreicht?</p>
    <button type="button" mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Unternehmen um
      Rückruf bitten</button>
  </mat-dialog-actions>
</div>