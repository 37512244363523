<mat-spinner *ngIf="isSpinner"></mat-spinner>
<!--<mat-spinner style="z-index: 10000000;"></mat-spinner>-->
<div class="{{isMobile?'row mb-2 mt-2':'row mb-2'}}">
  <h5 class="{{isMobile?'mb-3 pt-2':'ml-3 mt-2'}}" style="{{isMobile ? 'margin-left: 31px':''}}">Dashboard</h5>
  <button mat-flat-button color="primary" routerLink="/job/create"
    style="{{isMobile?'position: absolute; right: 18px;':'position: absolute; right: 65px;'}}">
    <i class="fa fa-plus"></i>
    {{isMobile ? '' : 'Job erstellen'}}
  </button>
</div>
<!-- padding: 0px 2px 0px 37px -->
<div style="height: {{ screenHeight }}px;
overflow-x: hidden !important;
padding: 0px 2px 0px 2px;
overflow-y: auto;
background : #FBFBFB;
scrollbar-width: none;" ngxInfiniteScroller
strategy="scrollingToBoth"
initialScrollPosition="10"
(onScrollUp)="onScrollUp()"
(onScrollDown)="onScrollDown()" id="scroller">
<div *ngIf="news.length > 0">
  <mat-accordion *ngFor="let result of news; let i = index">
    <mat-expansion-panel id="{{result.id}}" [expanded]="i == 0 ? 'true' : 'false'" class="{{isMobile?'mb-3 mx-3':'mb-3 '}} {{ result.isInactiveStart == true ? 'spaceInJobs' : ''}}"
      (opened)="panelOpenState = true" (closed)="panelOpenState = false" [class.mat-elevation-z8]="false">
      <!-- (opened)="panelOpenState = true" (click)="hide = !hide" (click)="panelOpenState ? '': appendDataInDatasource(result)" (closed)="panelOpenState = false" [class.mat-elevation-z8]="false"> -->
      <mat-expansion-panel-header #panelH>
        <mat-panel-title   class="align-self-center {{isMobile?'':'col-md-8 col-lg-8'}}" style="font-size: 20px;">
          <div *ngIf="!isMobile">{{result.title}}</div>
          <div *ngIf="isMobile">
            <div *ngIf="result.title.length > 14">
              {{result.title.substring(0, 10) + "..."}}
            </div>
            <div *ngIf="result.title.length <= 14">
              {{result.title}}
            </div>
          </div>
        </mat-panel-title>
        <span class="align-self-center- {{isMobile?'':'col-md-1 ml-2'}} text-right- pr-2 mt-4 city-">
          {{result.city}}
        </span>
        <span *ngIf="!isMobile" matTooltip="{{ (result.is_active) ? 'Job ist aktiv':'Job ist inaktiv' }}"
        matTooltipPosition="above"  class="col-lg-1 col-md-1 mt-4 col-sm-1 text-center" style="">
          <mat-slide-toggle disabled="true" checked="{{result.is_active}}"  color="primary" class=""></mat-slide-toggle>
        </span>
        <span class="{{isMobile?'':'col-md-1'}} text-center mt-4" style="">
        <i class="fas fa-portrait fa-lg" *ngIf="result.unSeenApplierCount > 0" matBadge="{{result.unSeenApplierCount}}" matBadgeSize="small" matBadgeColor="warn">
          </i>
        </span>
        <span *ngIf="result.chatUnreadCount > 0" class="px-1 text-right mt-4" style="">
          <i class="fas fa-comment-alt" matBadge="{{'!'}}" matBadgeSize="small" matBadgeColor="warn"></i>
          <!-- <i class="fas fa-comment-alt" matBadge="{{result.chatUnreadCount}}" matBadgeSize="small" matBadgeColor="warn"></i> -->
        </span>
        <span class="px-3 mr-2 text-right" style="margin-top:10px;" (click)="panelH._toggle()">
          <mat-form-field class="text-right" style="width: 25px;">
            <mat-select class="alarm-dropdown" trigger="alarmTrigger" #alarmSelect>
              <mat-option routerLink="/job/{{result.id}}">Job bearbeiten</mat-option>
              <mat-option (click)="deleteJob(result.isApplicationOpen, result.id)" >Job löschen</mat-option>
            </mat-select>
            <i class="fas fa-ellipsis-v mr-1"></i>
          </mat-form-field>
        </span>
      </mat-expansion-panel-header>
      <!-- <form [formGroup]="questionnaireForm">
      <table mat-table *ngIf="result.match.length > 0"  [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox formControlName="chkbox" value="all"  (change)="selectAll(result.match[0].job.id);"></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox class="jobApplier applier_{{row.applier_id}}" id="job{{result.match[0].job.id}}" formControlName="chkboxSingle" [attr.attribute-you-are-targeting]="expression" [attr.data-sectionvalue]="filedata" value="{{row.applier_id}}" (change)="checkIfAllSelected(row.applier_id);"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element?.name  }} </td>
        </ng-container>

        <ng-container matColumnDef="matchnumber">
          <th mat-header-cell *matHeaderCellDef> Matching </th>
          <td mat-cell *matCellDef="let element">
            <app-circle-progress class="" [matchPercent]="element?.rating" [outerStrokeWidth]="3.2" [innerStrokeWidth]="1.6" [radius]="20" [titleFontSize]="12"></app-circle-progress>
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef> Bewerbungsdatum </th>
          <td mat-cell *matCellDef="let element"> {{element?.date |  date:'dd.MM.yyyy'}}  </td>

        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <div class="text-right" *ngIf="result.match.length > 5"
            routerLink="/job-profile/{{element?.job_id}}/{{element?.applier_id}}"
            style="cursor: pointer;">
            mehr anzeigen
          </div>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.status == 8 || element.status == 9"
            style="cursor: pointer; margin-left: 22px; outline: 0;" class="col-md-4 align-self-center">
            <div *ngIf="element.status == 8" class=" text-left align-self-center font-weight-bold">
              <i class="far fa-thumbs-down fa-lg"></i> Bewerber abgelehnt
            </div>
            <div *ngIf="element.status == 9" class=" text-left align-self-center font-weight-bold">
              <i class="far fa-thumbs-down fa-lg"></i> Bewerbung zurückgezogen
            </div>
            </div>

            <div *ngIf="element.status == 8 || element.status == 9"
            (click)="redirectToJobDetails(element_id,element.job_id,element.applier_id,element.is_read,element.status)"
            style="cursor: pointer; margin-left: 22px; outline: 0;"
            class="col align-self-center text-right  font-weight-bold">--</div>
            <div *ngIf="element.status != 8 || element.status != 9" class="col align-self-center"
            (click)="redirectToJobDetails(element_id,element.job_id,element.applier_id,element.is_read,element.status)"
            style="cursor: pointer; margin-left: 22px; outline: 0;"></div>
            <div class="row ml-2 no-gutters">
              <div
                class="{{element.status >= 1 ? 'col text-left align-self-center' :'col text-center align-self-center'}}">
                <span class="text-nowrap font-weight-bold" *ngIf="element.status == 1">Eingang</span>
                <span class="text-nowrap font-weight-bold" *ngIf="element.status == 2">Eingang </span>
                <span class="text-nowrap font-weight-bold" *ngIf="element.status == 3">Eingang </span>
                <span class="text-nowrap font-weight-bold" *ngIf="element.status > 40 || element.status == 4">Eingang</span>
                <span class="text-nowrap font-weight-bold" *ngIf="element.status == 5">Eingang</span>
                <span class="text-nowrap font-weight-bold" *ngIf="element.status == 6 || element.status == 7">Eingang</span>
              </div>
              <div
                class="{{element.status >= 3 ? 'col text-center align-self-center' :'col text-center align-self-center'}}">
                <div class="process-text">
                  <span class="text-nowrap font-weight-bold" *ngIf="element.status == 1">Eingang</span>
                  <span class="text-nowrap " *ngIf="element.status == 2">Prüfung </span>
                  <span class="text-nowrap font-weight-bold" *ngIf="element.status == 3">Prüfung </span>
                  <span class="text-nowrap font-weight-bold" *ngIf="element.status > 40 || element.status == 4">Prüfung</span>
                  <span class="text-nowrap font-weight-bold" *ngIf="element.status == 5">Prüfung</span>
                  <span class="text-nowrap font-weight-bold" *ngIf="element.status == 6 || element.status == 7">Prüfung</span>
                </div>
              </div>
              <div
                class="{{element.status >= 5 ? 'col text-center align-self-center' :'col text-center align-self-center'}}">
                <div class="process-text">
                  <span class="text-nowrap" *ngIf="element.status == 1">persönliches Interview</span>
                  <span class="text-nowrap" *ngIf="element.status == 2">persönliches Interview </span>
                  <span class="text-nowrap " *ngIf="element.status == 3">persönliches Interview</span>
                  <span class="text-nowrap font-weight-bold" *ngIf="element.status > 40 || element.status == 4">persönliches
                    Interview</span>
                  <span class="text-nowrap font-weight-bold" *ngIf="element.status == 5">persönliches
                    Interview</span>
                  <span class="text-nowrap font-weight-bold" *ngIf="element.status == 6 || element.status == 7">persönliches
                    Interview</span>
                </div>
              </div>
              <div *ngIf="element.status == 6 || element.status == 7"
                class="{{ (element.status == 6 || element.status == 7) ? 'col text-center align-self-center font-weight-bold' :'col text-center align-self-center'}}">
                <div class="process-text">
                  <span class="text-nowrap">Entscheidung</span>
                </div>
              </div>
              <div *ngIf="element.status != 6 && element.status != 7 && element.status != 9  && element.status != 8"
                class="{{ (element.status == 6 && element.status == 5 && element.status == 7) ? 'col text-center align-self-center font-weight-bold' :'col text-center align-self-center'}}">
                <div class="process-text">
                  <span class="text-nowrap">Entscheidung</span>
                </div>
              </div>
            </div>
            <div class="" *ngIf="element.status < 8 || element.status > 40 || element.status == 4">
              <mat-progress-bar mode="determinate" value="{{ getApplicationStatusForProcessBar(element.status) }}">
              </mat-progress-bar>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
          <td mat-cell *matCellDef="let element">
            <div class=" text-right" *ngIf="element.status < 8 || element.status > 40 || element.status == 4">
              <i class="fas fa-comment-alt size-18 mr-2" *ngIf="element.chat_unread_count > 0 "
                matBadge="{{element.chat_unread_count}}" matBadgeSize="small" matBadgeColor="warn"
                routerLink="/job-profile/chat/{{element.job_id}}/{{element.applier_id}}"
                style="cursor: pointer; outline: 0;"></i>
              <i class="fas fa-comment-alt size-18 mr-2" *ngIf="element.chat_unread_count == 0"
                routerLink="/job-profile/chat/{{element.job_id}}/{{element.applier_id}}"
                style="cursor: pointer; outline: 0;"></i>
              <i class="fas fa-angle-right"   (click)="redirectToJobDetails(element.id,element.job_id,element.applier_id,element.is_read,element.status)"
                style="cursor: pointer;color:#2fa16f;outline: 0;"></i>
            </div>
            <div class="text-right" *ngIf="element.status == 8 || element.status == 9">
              <i class="fas fa-angle-right"
                style="cursor: pointer;color:#2fa16f;outline: 0;"></i>
            </div>
          </td>
      </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="selection.toggle(row)">
        </tr>

      </table>
    </form> -->

      <div *ngIf="result.match.length > 0">
        <form [formGroup]="questionnaireForm">
        <div class="row mt-1" style="height: 30px; border-radius: 10px;" *ngIf="!isMobile">
          <div *ngIf="result.cancelCount > 0" class="2col-1 ml-2" style="max-width: 13.666667%;margin-top: 5px;">
            <mat-checkbox color="primary" formControlName="chkbox" value="all" id="allChkbx{{result.match[0].job.id}}" class="allChkbx{{result.match[0].job.id}} commonAll" (change)="selectAll(result.match[0].job.id);"></mat-checkbox>
          </div>
          <div  color="primary" class="col-2 justify-content-start  align-self-center " style="max-width: 13.666667%; ">
            Name
          </div>
          <div class="col-1 justify-content-start  align-self-center">
            Matching
          </div>
          <div class="col-1 text-left align-self-center text-nowrap" style="padding: 0;">
            Bewerbungsdatum
          </div>
          <div class="col text-left align-self-center" style="margin-left: 30px;">
            Status
          </div>
          <div class="col-2 align-self-center text-right" *ngIf="result.match.length > 5"
            routerLink="/job-profile/{{result.match[0].job.id}}/{{result.match[0]?.applier.id}}"
            style="cursor: pointer;">
            mehr anzeigen
          </div>
        </div>
      </form>
        <div *ngFor="let match of result.match; let i = index">
          <div *ngIf="i < 5">
            <div *ngIf="!isMobile">
              <div class="row list-row my-1 " style="height:60px; border: 1px solid #2fa16f; border-radius: 10px;">
                <div *ngIf="result.cancelCount > 0" class="1col-1 ml-2" style="cursor: pointer; max-width: 13.666667%; outline: 0;margin-top: 23px;">
                  <mat-checkbox color="primary" *ngIf="match.status < 8 || match.status > 9"  class="jobApplier jobApplier{{result.match[0].job.id}} applier_{{match.id}}" id="job{{match.id}}" value="{{match.id}}"  (change)="checkIfOneSelected(match.id,$event,result.match[0].job.id);"></mat-checkbox>
                  <h6  *ngIf="match.status == 8 || match.status == 9"  style="margin-top: 10px;">&nbsp;&nbsp;&nbsp;</h6>
                  <!-- <mat-checkbox class="jobApplier jobApplier{{result.match[0].job.id}} applier_{{match.id}}" [ngModel]="myModel"  (click)="changeValue(checked,$event)" id="job{{match.id}}" value="{{match.id}}"  (click)="checkIfOneSelected(match.id,$event,result.match[0].job.id);" (change)="checkIfOneSelected(match.id,$event,result.match[0].job.id);"></mat-checkbox> -->
                  <!-- <mat-checkbox class="jobApplier jobApplier{{result.match[0].job.id}} applier_{{match.id}}" id="job{{match.id}}" formControlName="chkboxSingle[{{match.id}}]" value="{{match.id}}" (change)="checkIfOneSelected(match.id,$event,result.match[0].job.id);"></mat-checkbox> -->
                </div>
                <div class="col-2 justify-content-start  align-self-center "
                  (click)="redirectToJobDetails(match.id,match.job.id,match.applier.id,match.is_read,match.status)"
                  style="cursor: pointer; max-width: 13.666667%; outline: 0;">
                  <h6 style="margin-top: 10px;">
                    {{ getUserName(match.applier.user.first_name,match.applier.user.last_name) }}</h6>
                </div>
                <div class="col-1 text-center justify-content-start  align-self-center"
                  (click)="redirectToJobDetails(match.id,match.job.id,match.applier.id,match.is_read,match.status)"
                  style="cursor: pointer; outline: 0;">
                  <app-circle-progress class="float-left pr-5 " [matchPercent]="match.rating" [outerStrokeWidth]="3.2"
                    [innerStrokeWidth]="1.6" [radius]="20" [titleFontSize]="12"></app-circle-progress>
                </div>
                <div class="col-1 text-left align-self-center text-nowrap"
                  (click)="redirectToJobDetails(match.id,match.job.id,match.applier.id,match.is_read,match.status)"
                  style="cursor: pointer; outline: 0;">
                  {{match.history.updated_at |  date:'dd.MM.yyyy'}}
                </div>

                <div *ngIf="match.status == 8 || match.status == 9"
                  (click)="redirectToJobDetails(match.id,match.job.id,match.applier.id,match.is_read,match.status)"
                  style="cursor: pointer; margin-left: 22px; outline: 0;" class="col-md-4 align-self-center">
                  <div *ngIf="match.status == 8" class=" text-left align-self-center font-weight-bold">
                    <i class="far fa-thumbs-down fa-lg"></i> Bewerber abgelehnt
                  </div>
                  <div *ngIf="match.status == 9" class=" text-left align-self-center font-weight-bold">
                    <i class="far fa-thumbs-down fa-lg"></i> Bewerbung zurückgezogen
                  </div>
                </div>

                <div *ngIf="match.status == 8 || match.status == 9"
                  (click)="redirectToJobDetails(match.id,match.job.id,match.applier.id,match.is_read,match.status)"
                  style="cursor: pointer; margin-left: 22px; outline: 0;"
                  class="col align-self-center text-right  font-weight-bold">--</div>
                <div *ngIf="match.status != 8 || match.status != 9" class="col align-self-center"
                  (click)="redirectToJobDetails(match.id,match.job.id,match.applier.id,match.is_read,match.status)"
                  style="cursor: pointer; margin-left: 22px; outline: 0;">
                  <div class="row ml-2 no-gutters">
                    <div
                      class="{{match.status >= 1 ? 'col text-left align-self-center' :'col text-center align-self-center'}}">
                      <span class="text-nowrap font-weight-bold" *ngIf="match.status == 1">Eingang</span>
                      <span class="text-nowrap font-weight-bold" *ngIf="match.status == 2">Eingang </span>
                      <span class="text-nowrap font-weight-bold" *ngIf="match.status == 3">Eingang </span>
                      <span class="text-nowrap font-weight-bold" *ngIf="match.status > 40 || match.status == 4">Eingang</span>
                      <span class="text-nowrap font-weight-bold" *ngIf="match.status == 5">Eingang</span>
                      <span class="text-nowrap font-weight-bold" *ngIf="match.status == 6 || match.status == 7 || match.status == 12">Eingang</span>
                    </div>
                    <div
                      class="{{match.status >= 3 ? 'col text-center align-self-center' :'col text-center align-self-center'}}">
                      <div class="process-text">
                        <span class="text-nowrap font-weight-bold" *ngIf="match.status == 1">Eingang</span>
                        <span class="text-nowrap " *ngIf="match.status == 2">Prüfung </span>
                        <span class="text-nowrap font-weight-bold" *ngIf="match.status == 3">Prüfung </span>
                        <span class="text-nowrap font-weight-bold" *ngIf="match.status > 40 || match.status == 4">Prüfung</span>
                        <span class="text-nowrap font-weight-bold" *ngIf="match.status == 5">Prüfung</span>
                        <span class="text-nowrap font-weight-bold" *ngIf="match.status == 6 || match.status == 7 || match.status == 12">Prüfung</span>
                      </div>
                    </div>
                    <div
                      class="{{match.status >= 5 ? 'col text-center align-self-center' :'col text-center align-self-center'}}">
                      <div class="process-text">
                        <span class="text-nowrap" *ngIf="match.status == 1">persönliches Interview</span>
                        <span class="text-nowrap" *ngIf="match.status == 2">persönliches Interview </span>
                        <span class="text-nowrap " *ngIf="match.status == 3">persönliches Interview</span>
                        <span class="text-nowrap font-weight-bold" *ngIf="match.status > 40 || match.status == 4">persönliches
                          Interview</span>
                        <span class="text-nowrap font-weight-bold" *ngIf="match.status == 5">persönliches
                          Interview</span>
                        <span class="text-nowrap font-weight-bold" *ngIf="match.status == 6 || match.status == 7 || match.status == 12">persönliches
                          Interview</span>
                      </div>
                    </div>
                    <div *ngIf="match.status == 6 || match.status == 7  || match.status == 12"
                      class="{{ (match.status == 6 || match.status == 7 || match.status == 12) ? 'col text-center align-self-center font-weight-bold' :'col text-center align-self-center'}}">
                      <div class="process-text">
                        <span class="text-nowrap">Entscheidung</span>
                      </div>
                    </div>
                    <div *ngIf="match.status != 6 && match.status != 7 && match.status != 9  && match.status != 8  && match.status != 12"
                      class="{{ (match.status == 6 && match.status == 5 && match.status == 7) ? 'col text-center align-self-center font-weight-bold' :'col text-center align-self-center'}}">
                      <div class="process-text">
                        <span class="text-nowrap">Entscheidung</span>
                      </div>
                    </div>
                  </div>
                  <div class="row ml-2 mt-2" *ngIf="match.status < 8 || match.status > 40 || match.status == 4 || match.status == 12">
                    <mat-progress-bar mode="determinate" value="{{ getApplicationStatusForProcessBar(match.status) }}">
                    </mat-progress-bar>
                  </div>
                </div>
                <div class="col-1 align-self-center text-right" *ngIf="match.status < 8 || match.status > 40 || match.status == 4  || match.status == 12">
                  <i class="fas fa-comment-alt size-18 mr-2" *ngIf="match.chat_unread_count > 0 "
                    matBadge="{{match.chat_unread_count}}" matBadgeSize="small" matBadgeColor="warn"
                    routerLink="/job-profile/chat/{{match.job.id}}/{{match.applier.id}}"
                    style="cursor: pointer; outline: 0;"></i>
                  <i class="fas fa-comment-alt size-18 mr-2" *ngIf="match.chat_unread_count == 0"
                    routerLink="/job-profile/chat/{{match.job.id}}/{{match.applier.id}}"
                    style="cursor: pointer; outline: 0;"></i>
                  <i class="fas fa-angle-right" (click)="redirectToJobDetails(match.id,match.job.id,match.applier.id,match.is_read,match.status)"
                    style="cursor: pointer;color:#2fa16f;outline: 0;"></i>
                </div>
                <div class="col-1 align-self-center text-right" *ngIf="match.status == 8 || match.status == 9">
                  <i class="fas fa-angle-right" (click)="redirectToJobDetails(match.id,match.job.id,match.applier.id,match.is_read,match.status)"
                    style="cursor: pointer;color:#2fa16f;outline: 0;"></i>
                </div>
              </div>
            </div>
            <div *ngIf="isMobile">
              <div class="row list-row my-1 " style="height:60px; border: 1px solid #2fa16f; border-radius: 10px;">
                <div class="col-5 justify-content-center  align-self-center "
                  routerLink="/job-profile/{{match.job.id}}/{{match.applier.id}}" style="cursor: pointer; outline: 0;">
                  <h6 style="margin-top: 10px;"> {{ getUserName(match.applier.user.first_name,match.applier.user.last_name) }}</h6>
                </div>
                <div class="col justify-content-center   align-self-center"
                  routerLink="/job-profile/{{match.job.id}}/{{match.applier.id}}"
                  style="cursor: pointer; margin-left: 22px; outline: 0;">
                  <h6 style="margin-top: 10px;">{{getStatus(match.status)}}</h6>
                </div>
                <div class="col-1  align-self-center text-left " style="max-width: none;">
                  <i class="fas fa-comment-alt size-18 mr-2" *ngIf="match.chat_unread_count > 0 && match.status < 8 "
                    matBadge="{{match.chat_unread_count}}" matBadgeSize="small" matBadgeColor="warn"
                    routerLink="/job-profile/chat/{{match.job.id}}/{{match.applier.id}}" class="mr-2"
                    style="cursor: pointer; outline: 0;"></i>
                  <i class="fas fa-comment-alt size-18 mr-2" *ngIf="match.chat_unread_count == 0 &&  match.status < 8"
                    routerLink="/job-profile/chat/{{match.job.id}}/{{match.applier.id}}" class="mr-2"
                    style="cursor: pointer; outline: 0;"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-1" *ngIf="result.match.length > 5 &&  i == 6">
            <div class="col"></div>
            <div class="col-2 text-nowrap align-self-center text-right" style="cursor: pointer;outline: 0;"
              routerLink="/job-profile/{{match.job.id}}/{{result.match[0].applier.id}}">mehr anzeigen
              <i class="fas fa-arrow-right" style="position: relative; top: 50%; transform: translateY(30%); color:#2fa16f; "></i>
            </div>
          </div>
        </div>
        <button *ngIf="result.cancelCount > 0" mat-flat-button color="primary" (click)="openCencelPopup(result.id)" class="mt-2 mat-button-disabled rejectBtn{{result.match[0].job.id}}" id="rejectBtn{{result.match[0].job.id}}">Bewerber absagen </button>
      </div>
      <div *ngIf="result.match.length == 0" class="row">
        <div class="col align-self-center pt-3">
          <h5 class="text-center">Noch keine Bewerbung</h5>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
</div>
<!-- <div class="text-center {{isMobile ? 'mb-5' : ''}} " style="{{isMobile ? 'margin-bottom: 20px;' : ''}}">
    <button mat-flat-button color="primary" *ngIf="jobs?.previous"
      (click)="getLoadMoredata(jobs?.previous)"><i class="fas fa-arrow-left" aria-hidden="true"> Zurück </i>
    </button>
    <button class="ml-2" mat-flat-button color="primary" *ngIf="jobs?.next" (click)="getLoadMoredata(jobs?.next)"> Weiter <i class="fas fa-arrow-right" aria-hidden="true"></i>
    </button>
  </div> -->
<div *ngIf="!isSpinner">
  <div *ngIf="jobs?.results.length <= 0" class="text-center"
    style="{{isMobile ? '' : 'margin-left: -100px; margin-top:100px;'}}">
    <h1>Du hast noch keine offene Stelle. </h1>
    <button mat-flat-button color="primary" class="mt-2" routerLink="/job/create">
      <i class="fas fa-plus"></i>
      {{isMobile ? '' : 'Job erstellen'}}
    </button>
  </div>
</div>
