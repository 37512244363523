import { FormGroup, FormControl } from '@angular/forms';

export function validateCheckbox(formGroup: FormGroup, min = 1) {
  var count = 0;
  for (let key in formGroup.controls) {
    if (formGroup.controls.hasOwnProperty(key)) {
      let control: FormControl = <FormControl>formGroup.controls[key];
      if (control.value) {
        count = count+1;
      }
    }
  }
  if ( count >= min ) {
    return null;
  } else {
    return {
      validateCheckbox: {
        valid: false
      }
    }
  };
}
