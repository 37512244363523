<div class="login">
  <div class="w-75 login">
    <mat-card style="{{isMobile ? '' :'margin-left: 15rem;'}}" class="login-card">
      <mat-card-header class="justify-content-center align-items-center">
        <mat-card-title>
          Geben Sie Ihre Zugangsdaten ein
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="loginForm" (submit)="doLogin()">
          <div class="row">
            <div class="col">
              <h6>Ihre E-Mail Adresse</h6>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput type="email" formControlName="email">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <h6>Passwort</h6>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput type="password" formControlName="password">
              </mat-form-field>
            </div>
          </div>
          <a routerLink="/reset-password-request">Passwort vergessen?</a>
          <div style="line-height: 20px;color: #808080;font-size: 12px;" class="mb-2 mt-3">
            Mit der Anmeldung akzeptiere ich die <a href="https://truckerpilot.de/agbs/" target="_blank">AGBs</a> und <a href="https://truckerpilot.de/datenschutz/" target="_blank">Datenschutzerklärung</a>
          </div>
          <div class="row mt-3">
            <div class="col text-center">
              <button mat-raised-button type="submit" [disabled]="loginForm.invalid" color="primary" class="w-100">
                Anmelden</button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <mat-checkbox color="primary" formControlName="rememberMe">Angemeldet bleiben</mat-checkbox>
            </div>
          </div>
          <p class="mt-3">Noch keinen Arbeitgeber Account? <a routerLink="/register-company">Registrieren Sie sich</a></p>
        </form>
        <div *ngIf="isError">
          <!-- <p class="text-center text-danger">{{errorMessage}}</p> -->
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="w-25" *ngIf="!isMobile">
    <mat-card style="background-color: #fafafa; box-shadow: 0 0 #fafafa;">
      <div>
        <h6 style="color: gray;">Sie haben noch keinen Account?</h6>
        <button mat-raised-button type="button" (click)="redirectToRegister()"
                style="color: gray;"  class="w-100">Jetzt registrieren
        </button>
      </div>
    </mat-card>
  </div>
</div>
<div class="mt-3" *ngIf="isMobile">
  <mat-card style="background-color: #fafafa; box-shadow: 0 0 #fafafa; margin-left: 2.5rem; margin-right: 2.5rem;">
    <div>
      <h6 style="color: gray;">Sie haben noch keinen Account?</h6>
      <button mat-raised-button type="button" (click)="redirectToRegister()"
              style="color: gray;"  class="w-100">Jetzt registrieren
      </button>
    </div>
  </mat-card>
</div>
