<mat-spinner *ngIf="isSpinner"></mat-spinner>
<div *ngIf="!isSpinner">
  <h3 class="{{isMobile?'ml-3':''}}">Unternehmensprofil</h3>
  <h6 class="{{isMobile?'ml-3':''}}">Erstelle hier einmalig in 2 Minuten dein Unternehmensprofil.</h6>
  <h6 class="{{isMobile?'ml-3':''}}">Damit kannst du ganz einfach Stellen in ansprechendem Design online stellen.</h6>

  <div class="{{isMobile?'':'edit-profile'}}">
    <mat-card>
      <div>
        <mat-grid-list cols="6" rowHeight="90px">
          <mat-grid-tile [colspan]="1" [rowspan]="1">
              <img 
                   src="{{company?.logo}}"
                   alt=""
                   style="{{isMobile?'width: 50px;height: 50px;border-radius: 50px;':'width: 90px;height: 90px;border-radius: 50px;'}}">
          </mat-grid-tile>
          <mat-grid-tile
            class="no-gutters"
            [colspan]="4"
            [rowspan]="1">

            <mat-grid-tile-header
              [style.color]="'black'"
              [style.background]="'white'"
              [style.align-items]="'start'">
              <p style="display: block; margin-bottom: 8px; font-size: 24px; font-weight: 500;">
                {{company?.name}} 
                <!--              {{"Spedition Uwe Ludwig GmbH"}}-->
              </p>
            </mat-grid-tile-header>
            <mat-grid-tile-footer
              [style.color]="'black'"
              [style.background]="'white'"
              [style.align-items]="'start'">
              <div>
                <mat-card-subtitle>
                  <span class="fas fa-map-marker-alt	 size-20" aria-hidden="true"></span>
                  {{company?.street + ', ' }} <br *ngIf="isMobile" />
                  {{company?.city + ', ' + company?.postcode}}
                </mat-card-subtitle>
              </div>
            </mat-grid-tile-footer>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1" [rowspan]="1" class="grid-icon">
            <span class="fas fa-lg fa-pencil-alt fa-primary size-17" (click)="enableEditMode()" style="cursor: pointer;padding-top: 7px;" color="primary"></span>
          </mat-grid-tile>
          <mat-grid-tile
            [colspan]="6"
            [rowspan]="1" class="mt-3 grid-height" *ngIf="!isMobile">
            <div class="mx-1">
              <i class="fas fa-user fa-lg fa-primary size-17" color="primary"></i>
              <span class="mt-1 ml-1">{{company?.user.first_name + ' ' + company?.user.last_name}}</span>
            </div>
            <div class="mx-1">
              <i class="fas fa-envelope fa-lg fa-primary size-17" color="primary"></i>
              <span class="mt-1 ml-1">{{company?.user.email}}</span>
            </div>
            <div class="mx-1">
              <i class="fas fa-lg fa-phone-alt fa-primary size-17" color="primary"></i>
              <span class="mt-1 ml-1">{{company?.phone_number ? company.phone_number : 'Not available'}}</span>
            </div>
          </mat-grid-tile>
          
        </mat-grid-list>

        <div *ngIf="isMobile" class="col-md-12 col-sm-12 col-lg-12 mb-5 mt-4">
          <div class="col-md-3 col-sm-12 col-lg-3 mt-1">
            <i class="fas fa-user fa-lg fa-primary size-17" color="primary"></i>
            <span class="mt-1 ml-1">{{company?.user.first_name + ' ' + company?.user.last_name}}</span>
          </div>
          <div class="col-md-3 col-sm-12 col-lg-3 mt-1">
            <i class="fas fa-envelope fa-lg fa-primary size-17" color="primary"></i>
            <span class="mt-1 ml-1">{{company?.user.email}}</span>
          </div>
          <div class="col-md-3 col-sm-12 col-lg-3 mt-1">
            <i class="fas fa-lg fa-phone-alt fa-primary size-17" color="primary"></i>
            <span class="mt-1 ml-1">{{company?.phone_number ? company.phone_number : 'Not available'}}</span>
          </div>
        </div>
      </div>
      <mat-card-content style="margin-top: -20px;">
        <h5>Über das Unternehmen:</h5>
        <p>
          {{company?.about}}
        </p>
      </mat-card-content>
    </mat-card>

    <div class="redirect-btn align-items-center justify-content-center ml-5" style="width: 250px;">
      <h2 class="">Nächster Schritt?</h2>
      <button mat-flat-button color="primary" class="ml-5 mt-3" routerLink="/job/create">
        <i class="fas fa-plus size-17"></i> <span> &nbsp; Job erstellen</span>
      </button>
    </div>
  </div>
  <div class="profile-card mt-5">
  </div>
  <mat-card class="profile-card">
    <h5>Noch Fragen oder Feedback?</h5>
    <h6>Dann ruf uns gerne an.</h6>
    <mat-grid-list cols="5" rowHeight="40px">
      <mat-grid-tile [rowspan]="3" class="align-items-center justify-content-center">
        <img mat-card-avatar
             src="assets/rsz_1user_profile.jpg"
             alt=""
             style="width: 100px;
                      height: 100px;
                      border-radius: 50px;
                      margin-top: 15px;
                      margin-left: 5px;">
      </mat-grid-tile>
      <mat-grid-tile [rowspan]="1" [colspan]="4"><h6>Jonathan Paule</h6></mat-grid-tile>
      <mat-grid-tile [rowspan]="1" [colspan]="4">
        <!-- <mat-icon class="icon" color="primary">call</mat-icon> -->
        <i class="fas fa-lg fa-phone-alt fa-primary size-17" style="margin-top: 10px;" color="primary"></i>
        <span class="ml-1 mt-1"> 015737236623</span>
      </mat-grid-tile>
      <mat-grid-tile [rowspan]="1" [colspan]="4">
        <!-- <mat-icon class="icon" color="primary">email</mat-icon> -->
        <i class="fas fa-envelope fa-lg fa-primary" color="primary"></i>
        <span class="ml-1 mt-0"><a href="mailto:jonathan@truckerpilot.de" target="_blank">jonathan@truckerpilot.de</a></span>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card>
</div>
