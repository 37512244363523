<div class="mx-3 mb-2" *ngIf="!isMobile">
  <mat-toolbar color="accent" style="height: 50px; background-color: #2fa16f;color:white;">
    <span>{{applicantDetails?.title}}</span>
  </mat-toolbar>
</div>

<div *ngIf="!isMobile" class="row not-scrollable">
  <div class="not-scrollable-content">
    <div class="col-4 col-list scrollable">
      <app-job-list [currentMsgFromChild1ToChild2]="currentMsgFromChild1ToChild2" [jobId] = jobId [applierId] = applierId (postMessageEvent)=postMessage($event)></app-job-list>
    </div>
    <div class="col col-details scrollable">
      <app-job-details (msgToSibling)="fwdMsgToSib2($event)" [jobId]=jobId [applierId]=applierId (postMessageEvent)=postMessage($event)></app-job-details>
    </div>
  </div>
</div>
<div class="row not-scrollable" *ngIf="isMobile" [class.isMobile]="isMobile">
  <div class="{{isMobile?'not-scrollable-content mt-1':'not-scrollable-content'}}" style="width: 95%;">
    <div class="col scrollable" [ngClass]="{'col':!ismobiledevice, 'col-lg-5':ismobiledevice}" *ngIf="!isMobileDetailsShow">
      <app-job-list [currentMsgFromChild1ToChild2]="currentMsgFromChild1ToChild2" [jobId] = jobId [applierId] = applierId (postMessageEvent)=postMessage($event)></app-job-list>
    </div>
    <div class="col col-details scrollable" *ngIf="isMobileDetailsShow">
      <app-job-details (msgToSibling)="fwdMsgToSib2($event)" [jobId]=jobId [applierId]=applierId (postMessageEvent)=postMessage($event)></app-job-details>
    </div>
  </div>
</div>
