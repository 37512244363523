<div class="register">
  <div class="w-75 register">
  <mat-card style="{{isMobile? '' : 'margin-left: 12rem;'}}">
    <mat-card-header class="justify-content-center align-items-center">
    </mat-card-header>
    <mat-card-content>
      <!-- <button type="button" class="btn btn-primary" (click)="onAcceptCookie()">Accept the cookie</button>
      
      <div 
  angulartics2On="click" 
  angularticsAction="DownloadClick" 
  angularticsLabel="label-name" 
  angularticsValue="value" 
  [angularticsProperties]="{'name': 'Fall Campaign'}">
  Click Me
</div> -->
      <form [formGroup]="companyRegistrationForm" (ngSubmit)="registerCompanyUser()">
        <div class="row no-gutters" *ngIf="!isMobile">
          <div class="col">
            <h6>Vorname</h6>
            <mat-form-field style="width: 215px" appearance="outline">
              <input matInput formControlName="first_name">
            </mat-form-field>
          </div>
          <div class="col">
            <h6 class="ml-4">Nachname</h6>
            <mat-form-field style="width: 215px" class="float-right" appearance="outline">
              <input matInput formControlName="last_name">
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="isMobile">
          <div class="col">
            <h6>Vorname</h6>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput formControlName="first_name">
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="isMobile">
          <div class="col">
            <h6>Nachname</h6>
            <mat-form-field  appearance="outline" class="w-100">
              <input matInput formControlName="last_name">
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <h6>E-Mail</h6>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="email" formControlName="email">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h6>Passwort</h6>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
              <!-- <button mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button> -->
              <button mat-icon-button matSuffix class="mb-2 mt-1" type="button" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <i class="loginFa fas fa-1-5x fa-{{hide ? 'eye-slash' : 'eye'}}"></i>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div style="line-height: 20px;color: #808080;font-size: 12px;" class="mb-2 mt-3">
          Mit der Anmeldung akzeptiere ich die <a href="https://truckerpilot.de/agbs/" target="_blank">AGBs</a> und <a href="https://truckerpilot.de/datenschutz/" target="_blank">Datenschutzerklärung</a>
        </div>
        <div class="row">
          <div class="col">
            <mat-checkbox color="primary" formControlName="acceptTerms">
              Ich habe die <a href=" https://truckerpilot.de/datenschutz/" target="_blank">Datenschutzhinweise</a>,<a
              href="https://truckerpilot.de/agbs/" target="_blank"> Allgemeinen Geschäftsbedingungen</a> und die
              <a href="https://truckerpilot.de/preisliste/" target="_blank">Preisliste</a> gelesen und stimme zu.
            </mat-checkbox>
          </div>
        </div>
        
        <div class="row mt-3">
          <div class="col text-center">
            <button mat-raised-button type="submit" [disabled]="companyRegistrationForm.invalid" color="primary"
                    class="w-100">Registrieren
            </button>
            <p class="mt-2">Wir melden uns in Kürze bei dir, um deinen Personalbedarf zu besprechen.</p>
          </div>
        </div>
<!--        <div class="row">-->
<!--          <div class="col ml-1">-->
<!--            Schon einen Account? <a routerLink="/login-company">Hier zum Login</a>-->
<!--          </div>-->
<!--        </div>-->
        <div class="row" *ngIf="isError">
          <div class="col">
            <!-- <p class="text-danger text-center">{{errorMessage}}</p> -->
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
  </div>
  <div class="w-25" *ngIf="!isMobile">
    <mat-card style="background-color: #fafafa; box-shadow: 0 0 #fafafa;">
      <div>
        <h6 style="color: gray;">Sie haben bereits einen Account?</h6>
        <button mat-raised-button type="button" (click)="redirectToLogin()" color=""
                style="color: gray;" class="w-100">Anmelden
        </button>
      </div>
    </mat-card>
  </div>
</div>
<div class="mt-3" *ngIf="isMobile">
  <mat-card style="background-color: #fafafa; box-shadow: 0 0 #fafafa;" class="mx-5">
    <div>
      <h6 style="color: gray;">Sie haben bereits einen Account?</h6>
      <button mat-raised-button type="button" (click)="redirectToLogin()" color=""
              style="color: gray;" class="w-100">Anmelden
      </button>
    </div>
  </mat-card>
</div>
