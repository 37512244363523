import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {JobService} from "../../../../services/company/job.service";
import {CompanyNotFoundWarningDialog} from "../../jobs/create-jobs/create-jobs.component";
import {MatDialog} from "@angular/material/dialog";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-edit-company-profile',
  templateUrl: './edit-company-profile.component.html',
  styleUrls: ['./edit-company-profile.component.scss']
})
export class EditCompanyProfileComponent implements OnInit {

  company : any;
  companyId: any;
  isSpinner = true;
  isMobile: boolean;
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog,
              private deviceService: DeviceDetectorService,
              private jobService: JobService) {
                this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.getCompanyDetails();
  }

  enableEditMode() {
    this.router.navigate(['./edit-company/'+  this.company.id]);
  }

  getCompanyDetails() {
    this.jobService.getCompanyDetails().subscribe(resData => {
      this.company = resData;
      this.isSpinner = false;
    }, error => {
      this.dialog.open(CompanyNotFoundWarningDialog);
      console.log("error occurred");
      console.log(error);
    });
  }
}
