<div class="row not-scrollable" [class.isMobile]="isMobile">
  <div class="{{isMobile?'not-scrollable-content mt-5':'not-scrollable-content'}}" style="width: 95%;">
    <div class="col scrollable">
      <div>
        <form [formGroup]="questionnaireForm" (ngSubmit)="createJob()" class="{{isMobile?'px-2':''}}">
          <!--  Title   -->
          <b>Jobtitel: <span class="text-danger"> *</span></b>
          <mat-form-field appearance="outline" class="w-100">
            <!--            <mat-label>Jobtitel</mat-label>-->
            <input matInput
                   placeholder="LKW Fahrer (m/w/d) Nahverkehr"
                   formControlName="title"
                   (keyup)="onChange('title',$event)"
                   (keypress)="onChange('title',$event)">
            <mat-hint style="padding: 0 !important;">Bitte beachte, den Jobtitel mit dem Hinweis (m/w/d) geschlechtsneutral zu halten</mat-hint>
          </mat-form-field>
          <mat-error *ngIf="questionnaireForm.get('title').invalid && submitted">
            <strong>Stellenbezeichnung ist erforderlich</strong>
          </mat-error>

          <!--  Tours  -->
          <div formGroupName="tours" class="my-2">
            <p><b>Tourenprofil:<span class="text-danger"> *</span></b></p>
            <div class="row">
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="checkLocalTransport($event)" (change)="onChange('tours',$event)"
                              formControlName="local" value="local">Nahverkehr
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('tours',$event)"
                              formControlName="national" value="national">Fernverkehr
                  (national)
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('tours',$event)"
                              formControlName="international" value="international">
                  Fernverkehr (international)
                </mat-checkbox>
              </div>
              <div class="col-lg-6"></div>
            </div>
            <mat-error *ngIf="toursForm.invalid && submitted">
              <strong>Tourenprofil ist erforderlich</strong>
            </mat-error>
          </div>

          <!--  Shifts  -->
          <div formGroupName="shift" class="my-2">
            <p><b>Arbeitszeiten:<span class="text-danger"> *</span></b></p>
            <div class="row">
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('shift',$event)"
                              formControlName="day" value="day">Tagesschicht (Mo.-Fr.)
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('shift',$event)"
                              formControlName="night" value="night">Nachtschicht
                  (Mo.-Fr.)
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('shift',$event)"
                              formControlName="all" value="all">Wechselschicht
                  (Mo.-Fr.)
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('shift',$event)"
                              formControlName="weekend" value="weekend">Wochenende
                </mat-checkbox>
              </div>
            </div>
            <mat-error *ngIf="shiftsFormGroup.invalid && submitted">
              <strong>Arbeitszeit ist erforderlich</strong>
            </mat-error>
          </div>

          <!--  Location/Departure point -->
          <b>Abfahrsort:<span class="text-danger"> *</span></b>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Adresse suchen</mat-label>
            <input matInput ngx-google-places-autocomplete [options]='options'
                   value="{{address}}" (onAddressChange)="AddressChange($event)"/>
            <!-- <mat-icon matSuffix>search</mat-icon> -->
            <mat-icon matSuffix><i class="fas fa-search"></i></mat-icon>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100" style="margin-top: -12px;">
            <mat-label>Straße, Nr.</mat-label>
            <input matInput formControlName="street">
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 30%;margin-top: -12px;">
            <mat-label>Postleitzahl</mat-label>
            <input matInput formControlName="postCode" (ngModelChange)="checkPostCode($event)" (focus)="checkPostCode($event)" (change)="checkPostCode($event)" >
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 70%;margin-top: -12px;">
            <mat-label>Stadt</mat-label>
            <input matInput class="pl-2" (keyup)="onChange('city',$event)" (keypress)="onChange('city',$event)"
                   formControlName="city">
          </mat-form-field>

<!--          <mat-error *ngIf="(questionnaireForm.get('street').invalid ||-->
          <mat-error *ngIf="(questionnaireForm.get('postCode').invalid ||
                            questionnaireForm.get('city').invalid) && submitted"  style="margin-top: -20px;">
            <strong>Abfahrtsort ist erforderlich</strong>
          </mat-error>
          <mat-error *ngIf="isPostcodeValid" style="margin-top: -20px;">
            <strong>Bitte gebe eine deutsche Postleitzahl ein</strong>
          </mat-error>
          <!--  Vehicle type -->
          <div formGroupName="vehicleType" class="my-2">
            <p><b>Fahrzeugtypen für diese Stelle:<span class="text-danger"> *</span></b></p>
            <div class="row">
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('vehicleType',$event)"
                              formControlName="tractor-trailer" value="tractor-trailer">
                  Sattelzug
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('vehicleType',$event)"
                              formControlName="solo-vehicle" value="solo-vehicle">
                  Solofahrzeug
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('vehicleType',$event)"
                              formControlName="articulated-train"
                              value="articulated-train">Gliederzug
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('vehicleType',$event)"
                              formControlName="refrigerated-vehicle"
                              value="refrigerated-vehicle">
                  Kühlfahrzeug
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('vehicleType',$event)"
                              formControlName="swap-body" value="swap-body">
                  Wechselbrücke
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('vehicleType',$event)"
                              formControlName="tanker" value="tanker">Tankfahrzeug
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('vehicleType',$event)"
                              formControlName="silo" value="silo">Silo
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('vehicleType',$event)"
                              formControlName="heavy-transport" value="heavy-transport">Schwertransporte
                </mat-checkbox>
              </div>
            </div>
            <mat-error *ngIf="vehicleType.invalid && submitted">
              <strong>Fahrzeugtyp ist erforderlich</strong>
            </mat-error>
          </div>

          <!--  driver license -->
          <div class="my-2"><p><b>höchste erforderliche Führerscheinklasse:<span class="text-danger"> *</span></b></p>
            <mat-radio-group class="row" (change)="onChange('drivingLicenseClass',$event)"
                             formControlName="driverLicense">
              <div class="col-lg-6">
                <mat-radio-button color="primary" labelPosition="after" value="CE">CE</mat-radio-button>
              </div>
              <div class="col-lg-6">
                <mat-radio-button color="primary" labelPosition="after" value="C">C</mat-radio-button>
              </div>
              <div class="col-lg-6">
                <mat-radio-button color="primary" labelPosition="after" value="C1E">C1E</mat-radio-button>
              </div>
              <div class="col-lg-6">
                <mat-radio-button color="primary" labelPosition="after" value="C1">C1</mat-radio-button>
              </div>
            </mat-radio-group>
            <mat-error *ngIf="questionnaireForm.get('driverLicense').invalid && submitted">
              <strong>Führerscheinklasse ist erforderlich</strong>
            </mat-error>
          </div>


          <!--  additional licence -->
          <div formGroupName="additionalLicense" class="my-2">
            <p><b>zusätzliche (Führer-) Scheine wünschenswert:<span class="text-danger"> *</span></b></p>
            <div class="row">
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after"
                              (change)="toggleAdditionalLicense($event)"
                              (change)="onChange('additionalDrivingLicense',$event)" formControlName="NO_LICENSE"
                              value="NO_LICENSE">
                  Keinen weiteren Führerschein
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after"
                              [disabled]="isAdditionalLicense"
                              (change)="onChange('additionalDrivingLicense',$event)" formControlName="FORKLIFT"
                              value="FORKLIFT">
                  Staplerführerschein
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after"
                              [disabled]="isAdditionalLicense"
                              (change)="onChange('additionalDrivingLicense',$event)" formControlName="CRANE"
                              value="CRANE">Kranführerschein
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after"
                              [disabled]="isAdditionalLicense"
                              (change)="onChange('additionalDrivingLicense',$event)" formControlName="ADR_BASIC"
                              value="ADR_BASIC">ADR-Basis
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after"
                              [disabled]="isAdditionalLicense"
                              (change)="onChange('additionalDrivingLicense',$event)" formControlName="ADR_TANK"
                              value="ADR_TANK">ADR-Tank
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after"
                              [disabled]="isAdditionalLicense"
                              (change)="onChange('additionalDrivingLicense',$event)" formControlName="ADR_EXPLOSIVE"
                              value="ADR_EXPLOSIVE">
                  ADR-Explosiv
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after"
                              [disabled]="isAdditionalLicense"
                              (change)="onChange('additionalDrivingLicense',$event)" formControlName="ADR_RADIOACTIVE"
                              value="ADR_RADIOACTIVE">
                  ADR-Radioaktiv
                </mat-checkbox>
              </div>
              <div class="col-lg-6"></div>
            </div>
            <mat-error *ngIf="additionalLicense.invalid && submitted">
              <strong>Pflichtfeld</strong>
            </mat-error>
          </div>

          <!--  minimum years of experience -->
          <div class="my-2">
            <p><b>min. x Jahre an Berufserfahrung erwünscht:<span class="text-danger"> *</span></b></p>
            <div class="row">
              <div class="col-lg-6 align-self-center">
                <mat-slider #mySlider color="primary" (input)="onChange('minWorkExperience',$event)"
                            formControlName="minimumExperience" style="width: 100%"
                            (change)="onChange('minWorkExperience',$event)" thumbLabel tickInterval="1" min="0"
                            max="30" step="1"></mat-slider>
              </div>
              <div class="col-lg-3 align-self-center pt-2">
                <p>{{ mySlider.value | json }} Jahre</p>
              </div>
              <div class="col-lg-3" style="display:inline-flex"></div>
            </div>
            <mat-error *ngIf="questionnaireForm.get('minimumExperience').invalid && submitted">
              <strong>Work experience is required</strong>
            </mat-error>
          </div>

          <!--  Start Date   -->
          <div class="my-2"><p><b>Eintrittsdatum:<span class="text-danger"> *</span></b></p>
            <mat-form-field appearance="outline" class="w-50">
              <mat-label>Eintrittsdatum</mat-label>
              <input matInput [matDatepicker]="picker"
                     formControlName="earliestStartDate"
                     [min]="minDate"
                     [matDatepickerFilter]="DateFilter"
                     (dateInput)="addEvent($event)" (dateChange)="addEvent($event)">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-error *ngIf="questionnaireForm.get('earliestStartDate').invalid && submitted">
              <strong>Frühester Starttermin ist erforderlich</strong>
            </mat-error>
          </div>

          <!--  language skills-->
          <p style="margin-bottom: -1.5rem;"><b>erwünschte Sprachkenntnisse:<span class="text-danger"> *</span></b></p><br>
          <div class="row">
            <div class="col ">
          <mat-form-field appearance="outline" class="mt-3 col-md-6  col-sm-12 col-lg-6 ml-lg-n2">
            <mat-label>Deutsch</mat-label>
            <mat-select [compareWith]="compareFunction" [(value)]="selectedGerman"
                        (selectionChange)="onChange('language',$event)"
                        formControlName="skillGermanLan">
              <mat-option value="10">keine Kenntnis</mat-option>
              <mat-option value="1">Grundkenntnisse</mat-option>
              <mat-option value="2">fließende Kenntnisse</mat-option>
              <mat-option value="3">Muttersprache</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mt-3  col-md-6 col-sm-12  col-lg-6 ml-lg-n2">
            <mat-label>Englisch</mat-label>
            <mat-select [compareWith]="compareFunction" [(value)]="selectedEnglish"
                        formControlName="skillEnglishLan">
              <mat-option value="11">keine Kenntnis</mat-option>
              <mat-option value="4">Grundkenntnisse</mat-option>
              <mat-option value="5">fließende Kenntnisse</mat-option>
              <mat-option value="6">Muttersprache</mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          </div>

          <mat-error *ngIf="questionnaireForm.get('skillGermanLan').invalid && submitted"  style="margin-top: -20px;">
            <strong> Kenntnisse der deutschen Sprache sind erforderlich</strong>
          </mat-error>
          <!--  What is being transported -->
          <mat-form-field appearance="outline" class="example-full-width w-100 my-2">
            <mat-label><b>Was wird transportiert</b> (optional):</mat-label>
            <textarea matInput #beingTransportedLen maxlength="200"
                      formControlName="beingTransported" rows="2"
                      (keyup)="onChange('beingTransported',$event)"></textarea>
            <mat-hint align="end">{{beingTransportedLen.value.length}} / 200</mat-hint>
          </mat-form-field>

          <!-- Other -->
          <mat-form-field appearance="outline" class="example-full-width w-100 my-2">
            <mat-label><b>Was uns sonst noch wichtig ist</b> (optional):</mat-label>
            <textarea matInput #messageOther maxlength="300" (keyup)="onChange('other',$event)"
                      formControlName="other"
            ></textarea>
            <mat-hint align="end">{{messageOther.value.length}} / 300</mat-hint>
          </mat-form-field>

          <b>Vorteile des Unternehmens:</b>
          <mat-form-field appearance="outline" class="w-100">
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let advantage of advantages" [selectable]="selectable"
                        [removable]="removable" (removed)="remove(advantage)">
                {{advantage.name}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input placeholder="Unternehmensvorteile"
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [matChipInputAddOnBlur]="addOnBlur"
                     (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
            <mat-hint>mit Enter oder , bestätigen</mat-hint>
          </mat-form-field>

          <p style="margin-bottom: -2.1rem;" class="mt-3"><b>Verdienst (optional) <i matTooltip="Angabe des Bruttomonatsgehalts zzgl. Spesen" matTooltipPosition="above" class="fas fa-question-circle"></i><span class="text-danger"></span>:</b></p><br>
          <div class="row">
            <div class="col ">
          <mat-form-field appearance="outline" class="mt-3 col-md-6  col-sm-6 col-lg-6 ml-lg-n2">
            <mat-label>Min</mat-label>
            <input matInput placeholder="z.B. 2300" type="number" class=" " formControlName="salary_minimum" (change)="onChange('salary_minimum',$event)" (keyup)="onChange('salary_minimum',$event)" (keypress)="onChange('salary_minimum',$event)">
            <span class="mt-0" matPrefix>€&nbsp;</span>
          </mat-form-field>
          <mat-form-field appearance="outline" class="mt-3 col-md-6  col-sm-6 col-lg-6 ml-lg-n2">
            <mat-label>Max</mat-label>
            <input matInput placeholder="z. B. 2900" type="number" formControlName="salary_maximum" (change)="onChange('salary_minimum',$event)" (keyup)="onChange('salary_maximum',$event)"  (keypress)="onChange('salary_maximum',$event)">
            <span matPrefix>€&nbsp;</span>
          </mat-form-field>
          </div>
          </div>
          <div class="my-2">
            <p><b>Pflichtanforderungen <i matTooltip="Hier können Sie festlegen, welche Anforderungen der Bewerber erfüllen muss. Bewerber, die diese Anforderungen nicht erfüllen, bekommen diese Stelle nicht vorgeschlagen." matTooltipPosition="above" class="fas fa-question-circle"></i><span class="text-danger"></span>:</b></p>
            <div class="row">
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('musthave',$event)"
                              formControlName="is_need_driver_license" value="driver_license">Führerschein
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('musthave',$event)"
                              formControlName="is_need_additional_driver_license" value="additional_driver_license">Weitere Führerscheine

                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('musthave',$event)"
                              formControlName="is_need_vehicle_type_experience" value="vehicle_type_experience">Erfahrung auf Fahrzeugtyp

                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('musthave',$event)"
                              formControlName="is_need_work_experience" value="work_experience">Arbeitserfahrung
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" labelPosition="after" (change)="onChange('musthave',$event)"
                              formControlName="is_need_language_skills" value="language_skills">Sprachkenntnisse
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
                <mat-checkbox color="primary" [disabled]="false"  labelPosition="after" (change)="onChange('professional_license',$event)" (change)="onChange('musthave',$event)"
                              formControlName="is_need_professional_license" value="true">Schlüsselzahl 95 erforderlich
                </mat-checkbox>
              </div>
              <div class="col-lg-6">
              </div>
            </div>
          </div>

          <!--Place job advertisement online -->
          <mat-slide-toggle formControlName="isJobActive" color="primary" class="mt-2 w-100">Jobausschreibung online schalten
          </mat-slide-toggle>

          <div class="row mt-2" *ngIf="questionnaireForm.invalid">
            <div class="col">
              <p class="text-danger">Mit einem Sternchen (*) markierte Felder sind Pflichtfelder.</p>
            </div>
          </div>

          <div *ngIf="!jobId">
            <!-- <button mat-button mat-flat-button  [disabled]="questionnaireForm.invalid" class="col mt-2 flex-row" -->
            <button mat-button mat-flat-button class="col mt-2 flex-row"
                    type="submit" color="primary">
              <span class="icon-text-button">Job erstellen</span>
            </button>
          </div>
          <div *ngIf="jobId">
            <div class="row">
              <div class="col">
                <button mat-button mat-flat-button class="col mt-3 flex-row"
                        type="button" (click)="refreshPage()" style="background-color: gray;">
                  <span class="icon-text-button">Abbrechen</span>
                </button>
              </div>
              <div class="col">
                <!-- <button mat-button mat-flat-button  [disabled]="questionnaireForm.invalid" class="col mt-3 flex-row" -->
                <button mat-button mat-flat-button class="col mt-3 flex-row"
                        type="submit" color="primary">
                  <span class="icon-text-button">Speichern</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- Details view starts from here -->
    <div class="col scrollable" *ngIf="!isMobile">
      <div [class.isMobile]="!isMobile">
        <mat-card class="match-details user-details">
          <mat-card-header style="height: 3rem;">
            <mat-card-title>
              <a class="back" routerLink="/company" *ngIf="isMobile">
                <span class="fa fa-arrow-left" style="color:white"></span><span
                style="color:#ffffff"> {{responseData.title}}</span></a>
              <span *ngIf="!isMobile">{{title}}</span>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="row row-no-gutters icon-table">
              <div class="icon-box col-lg-6 col-xl-6 col-12">
                <span class="fa-road fa fa-3x child" style="vertical-align: top;"></span>
                <!--Tourengebie wie in Fragebogen-->
                <p class="child"><span style="font-size: 18px;">
                    {{tours}}
                  </span><br>Tourenprofil</p>
              </div>
              <div class="icon-box col-lg-6 col-xl-6 col-12">
                <span class="fa-clock fa fa-3x child" style="vertical-align: top;"></span>
                <!--Schicht wie im Fragebogen-->
                <p class="child"><span style="font-size: 18px;">{{shift}}</span><br>Art der Schichten</p>
              </div>
            </div>
            <div class="row row-no-gutters icon-table mt-3">
              <div class="icon-box col-lg-6 col-xl-6 col-12">
                <span class="fa-map-signs fa fa-3x child"></span>
                <!-- Job.Standort Abfahrtsort -->
                <p class="child"><span style="font-size: 18px;">{{city}}</span><br>Stadt</p>
              </div>
              <div class="icon-box col-lg-6 col-xl-6 col-12">
                <span class="fa-truck-moving fa fa-3x child" style="vertical-align: top;"></span>
                <!-- fahrzeugtyp aus Fragebogen -->
                <p class="child"><span style="font-size: 18px;">{{vehicleTypeDisplay}}</span><br>Fahrzeugtyp</p>
              </div>
            </div>

            <div *ngIf="minSalary > 0 " style="font-size: 18px;" class="mb-0">
              <hr>
              <p><strong>Verdienst :</strong></p>
              <p>{{ minSalary }}€  <span *ngIf="maxSalary">- {{ maxSalary }}€ </span></p>
            </div>
           <hr>
            <div style="font-size: 18px;" class="mb-0">
              <b>Anforderungen:</b></div>
            <br>
            <ul>
              <li class="my-3">
                <div class="row">
                  <p class="col-6">Führerscheinklasse:</p>
                  <p class="col-6 d-inline-block">{{drivingLicenseClass}}
                    <span *ngIf="IsNeedDriverLicense" class="musthave" style="display:inline-block">must have</span>
                  </p>
                </div>
              </li>
              <li class="my-3">
                <div class="row">
                  <p class="col-6">Schlüsselzahl 95 erforderlich:</p>
                  <p class="col-6">Ja <span *ngIf="professionaLicense =='Ja'" class="musthave" style="display:inline-block">must have</span> </p>
                  <!-- <p class="col-6">{{professionaLicense}} <span *ngIf="professionaLicense =='Ja'" class="musthave" style="display:inline-block">must have</span> </p>  -->
                </div>
               </li>
              <li class="my-3">
                <div class="row">
                  <p class="col-6">zusätzliche Führerscheine:</p>
                  <p class="col-6">{{additionalDrivingLicense}}
                    <span *ngIf="IsNeedAdditionalDriverLicense" class="musthave" style="display:inline-block">must have</span>
                  </p>
                </div>
              </li>
              <li class="my-3">
                <div class="row">
                  <p class="col-6">Erfahrung auf Fahrzeugtyp:</p>
                  <p class="col-6">{{vehicleTypeDisplay}}
                    <span *ngIf="IsNeedVehicleTypeExperience" class="musthave" style="display:inline-block">must have</span>
                  </p>
                </div>
              </li>
              <li class="my-3">
                <div class="row">
                  <p class="col-6">Berufserfahrung:</p>
                  <p class="col-6">{{minWorkExperience > 0 ? 'min. ' + minWorkExperience + ' Jahre' : 'Keine Berufserfahrung notwendig'}}
                    <span *ngIf="IsNeedWorkExperience" class="musthave" style="display:inline-block">must have</span>
                  </p>
                </div>
              </li>
              <li class="my-3">
                <div class="row">
                  <p class="col-6">Deutschkenntnisse:</p>
                  <p class="col-6">{{germanSkills}}
                    <span *ngIf="IsNeedLanguageSkills" class="musthave" style="display:inline-block">must have</span>
                  </p>
                </div>
               </li>

               <!-- <li class="my-3">
                <div class="row">
                  <p class="col-6">Pflichtanforderungen:</p>
                  <p class="col-6">{{requiredFields}}   <span class="musthave" style="display:inline-block">must have</span></p>
                </div>
              </li> -->
            </ul>
            <hr>
            <div *ngIf="beingTransported">
              <p style="font-size: 18px;" class="mb-0 p-0"><b>Was wird transportiert:</b></p>
<!--              <div style="margin-top: -20px;">{{beingTransported}}</div>-->
              <div style="margin-top: -20px;" [innerHtml]="beingTransported" style="white-space: pre-line"></div>
              <!--                <p *ngIf="responseData.specific_tasks">Was sind deine konkreten Aufgaben: {{responseData.specific_tasks}}</p>-->
            </div>
            <div style="font-size: 18px;" class="mb-0 mt-2" *ngIf="other"><b>Was uns sonst noch wichtig ist:</b></div>
<!--            <div>{{other}}</div>-->
            <div [innerHtml]="other" style="white-space: pre-line"></div>

            <hr *ngIf="beingTransported">
            <div class="mt-2" *ngIf="advantages.length > 0">
              <p style="font-size: 18px;" class="mb-0 p-0"><b>Vorteile des Unternehmens:</b></p>
              <mat-chip-list class="advantages-chips">
                <div *ngFor="let advantage of advantages">
                  <mat-chip color="accent" selected>{{advantage.name}}</mat-chip>
                </div>
              </mat-chip-list>
            </div>
            <p style="font-size: 18px;" class="mt-3 mb-0" *ngIf="companyDetails"><b>Über das Unternehmen:</b>
            </p>
            <div>{{companyDetails?.about}}</div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
