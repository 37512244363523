<mat-spinner *ngIf="isSpinner"></mat-spinner>
<mat-card-header *ngIf="isMobile">
  <mat-card-title>
    <a class="back ml-3" (click)=handleClick() routerLink="/job-profile/{{jobId}}/{{applierId}}">
      <span class="fa fa-arrow-left mr-2" *ngIf="selectedApplier" style="color:white"></span><span
        style="color:#ffffff">{{selectedApplier?.job.title}}</span>
    </a>
  </mat-card-title>
</mat-card-header>
<mat-card class="application-details" *ngIf="selectedApplier?.status <= 3 && isApplierDetailsShow">
  <mat-tab-group mat-stretch-tabs [selectedIndex]="selected.value" (selectedTabChange)="tabClick($event)">
    <mat-tab label="Bewerbungsprozess">
      <mat-card-content class="card-content overflow-hidden" style="min-height: 350px;">
        <form [formGroup]="meetingForm">

          <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{ selectedApplier?.status }}">
          </app-status-bar>
          <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{ selectedApplier?.status }}">
          </app-status-bar>
        </form>
        <div class="mt-3 mx-2">
          <div class="row my-4 no-gutters overflow-hidden">
            <div class="col align-self-center">
              <h2>
                {{selectedApplier?.applier.user.first_name }} {{selectedApplier?.applier.user.last_name}}
              </h2>
              <p>Geburtsjahr: {{selectedApplier?.applier.birth_year}}</p>
            </div>
            <div class="col align-self-center text-right pr-5">
              Einsatzbereit:
              <mat-chip-list aria-label="Fish selection">
                <mat-chip>
                  {{selectedApplier?.applier.start_work.substr(8, 10)}}.
                  {{selectedApplier?.applier.start_work.substr(5, 2)}}.
                  {{selectedApplier?.applier.start_work.substr(0, 4)}}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <div class="chip-details">
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Wohnort</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2">{{selectedApplier?.applier.postcode}}{{', ' + selectedApplier?.applier.city}}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Führerscheinklasse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.license}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Kennzahl 95</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2" *ngIf="selectedApplier?.applier.professional_license">Ja</mat-chip>
                  <mat-chip class="mx-2" *ngIf="!selectedApplier?.applier.professional_license">Nein</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Deutschkenntnisse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.language}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Berufserfahrung</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.work_experience + '  Jahre'}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Letzte Arbeitgeber</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_1_year + ' : ' + selectedApplier?.applier.employer_1 }} </mat-chip>
                </mat-chip-list>
                <br>
                <mat-chip-list aria-label="Fish selection" style="margin-top: 5px;">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_2_year + ' : ' + selectedApplier?.applier.employer_2 }} </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Erfahrung mit folg. Fahrzeugtypen</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let vehicalExperiance of selectedApplier?.applier.vehicle_experience">
                  <mat-chip class="mx-2 mb-2"> {{getVehicleExperience(vehicalExperiance)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Zusätzliche Führerscheine</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let additionalLicense of selectedApplier?.applier.additional_license">
                  <mat-chip class="mx-2 mb-2">{{getAdditionalLicense(additionalLicense)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Gewünschte Schicht</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection" *ngFor="let shift of selectedApplier?.applier.shift">
                  <mat-chip class="mx-2 mb-2"> {{getShift(shift)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class=" col-lg-4 {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Gewünschtes Tourengebiet</b></div>
              <div class="col-lg-6">
                <mat-chip-list aria-label="Fish selection" *ngFor="let tours of selectedApplier?.applier.tours">
                  <mat-chip class="mx-2 mb-2"> {{getTour(tours)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions class="row no-gutters">
        <button mat-button class="{{isMobile == true ? 'col-md-10 mx-1' : 'col mx-1' }}" (click)="changeTab()">
          <span class="fas fa-1-5x fa-check-circle" style="padding-right:5px"></span> <span
            class="icon-text-button">Bewerber kontaktieren</span>
        </button>

        <button mat-flat-button color="primary" class="{{isMobile == true ? 'col-md-10 mx-1' : 'col mx-1' }}"
          (click)="openDialog()">
          <span class="fas fa-1-5x fa-users" style="padding-right:5px"></span><span
            class="icon-text-button">Persönliches Interview vereinbaren</span>
        </button>
      </mat-card-actions>
      <div class="row no-gutters ">
        <button mat-button class="{{isMobile == true ? 'col-md-10 mx-1' : 'col mx-1 mb-1' }}"
          (click)="rejectApplication()">
          <span class="fas fa-1-5x fa-times-circle" style="padding-right:5px"></span><span
            class="icon-text-button">Bewerber absagen</span>
        </button>
      </div>
    </mat-tab>

    <mat-tab label="Nachrichten" class="-align-center">
      <app-news addText={{addText}} matchId="{{matchId}}" applierId={{applierId}} jobId={{jobId}} isStatus="{{selectedApplier?.status}}">
      </app-news>
      <!-- <div class="container my-3">
        <form class="bg-light" [formGroup]="chatForm" (ngSubmit)="addMessage()">
          <div class="form-group">
            <label for="editor">Neue Nachricht schreiben</label>
            <button mat-button type="button" class="mr-1 float-right" color="#2fa16f"
              style="margin-top: -10px; border: 1px solid #2fa16f; color:#2fa16f;  cursor: pointer;"
              (click)="openCallDialog()">
              <i class="fas fa-lg fa-phone-alt"> </i><span  class="{{isMobile == true ? 'hideLabelForPhone'  : '' }}" style="color: black;">&nbsp;&nbsp;Telefonnummer anzeigen</span>
            </button>
            <ckeditor [editor]="Editor" formControlName="message" id="editor"></ckeditor>
          </div>
          <button mat-raised-button color="primary" class="w-100" [disabled]=chatForm.invalid>

            <i class="fas fa-envelope mr-1" aria-hidden="true"></i>
            Nachricht senden
          </button>
        </form>
        <hr>
        <div class="col-12 px-0">
          <div class="px-1 py-1 chat-box bg-white">
            <div *ngFor="let message of messages?.results">
              <div *ngIf="loggedInUserId === message?.created_by" class="media w-100 mb-3"><img
                  src="https://image.flaticon.com/icons/png/512/1962/1962551.png" alt="user" width="50"
                  class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                      Uhr</p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
              <div *ngIf="loggedInUserId !== message?.created_by" class="media w-100 mb-3"><img
                  src="https://www.clipartmax.com/png/middle/171-1717870_stockvader-predicted-cron-for-may-user-profile-icon-png.png"
                  alt="user" width="50" class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                    </p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </mat-tab>
  </mat-tab-group>
</mat-card>
<mat-card *ngIf="(selectedApplier?.status == 41 || selectedApplier?.status == 43) && isPrpposalDetailsShow == true"
  class="application-details">
  <mat-tab-group mat-stretch-tabs [selectedIndex]="selected.value" (selectedTabChange)="tabClick($event)">
    <mat-tab label="Bewerbungsprozess">
      <mat-card-content class="card-content overflow-hidden" style="min-height: auto;">
        <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}"></app-status-bar>
        <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}"></app-status-bar>
        <div [class.isMobile]="!isMobile">
          <div class="match-details user-details">
            <div style="font-size: 18px;" class="mb-2 mt-3"> <b>Termine vorgeschlagen:</b></div>
            <div class="mb-2 mb-3">Folgende Termine wurden dem Bewerber vorgeschlagen: </div>

            <div class="row" *ngIf="proposalDetails">
              <div class="col-lg-5 col-md-6 col-sm-12 dateDisplay">
                <h6>
                  <span class="ml-4 mr-2"> Datum</span>
                  <span class="ml-5">Uhrzeit</span>
                </h6>
                <span *ngFor="let index of proposalDetails['interview_date'];let i = index">
                  <b class="{{isMobile == true ? 'mb-1 ml-2' : 'col-2' }}">{{ index.date |date:'dd MMM yyyy':'locale'}}
                  </b>
                  <b class="{{isMobile == true ? 'mb-1' : 'col-2' }}">{{ index.start_time.substring(0, index.start_time.length-3) }}
                    -
                    {{ index.end_time.substring(0, index.end_time.length-3) }} </b>
                  <br>
                </span>
              </div>
              <div class="col-lg-7 col-md-6 col-sm-12 {{isMobile == true ? 'mt-3' : '' }}">
                <h6>Ort des Interviews</h6>
                <p style="font-size: 18px;" class="fas fa-map-marker-alt font-17 mb-3" aria-hidden="true">&nbsp;</p>
                {{ proposalDetails?.street }}, &nbsp;
                {{ proposalDetails?.city }}, &nbsp;
                {{ proposalDetails?.postcode }}
                <h6 *ngIf="proposalDetails?.notes">Anmerkung </h6>
                <p>{{ proposalDetails?.notes  }}</p>
              </div>
            </div>
            <p class="mt-1">Sobald der Bewerber einen Termin bestätigt, werden Sie benachrichtigt. </p>

            <mat-divider style="margin: 20px;"></mat-divider>
            <br/>
            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Bewerber hat Termin nicht bestätigt?
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div  class="timeline-m">
                  <mat-card-actions class="row no-gutters">
                    <button mat-button class="{{isMobile == true ? 'col-md-10 mx-1' : 'col mx-1' }}" (click)="proposeNewAppointment()">
                      <span class="icon-text-button">Neuen Termin vorschlagen</span>
                    </button>
                    <button mat-flat-button color="primary" class="{{isMobile == true ? 'col-md-10 mx-1' : 'col- mx-10' }}" (click)="placePersonalInterview()">
                      <span class="icon-text-button">persönliches Interview hat bereits stattgefunden</span>
                    </button>
                  </mat-card-actions>
                  </div>
                  <mat-divider></mat-divider>
              </mat-expansion-panel>

            </mat-accordion>
            <mat-card-actions class="{{isMobile == true ? 'mb-5' : 'mt-3' }}">
              <button mat-flat-button color="accent" class="col mx-1" (click)="rejectConfirm()">
                <span class="icon-text-button">Bewerber Absagen</span>
              </button>
            </mat-card-actions>
          </div>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Bewerberdetails">
      <mat-card-content class="card-content overflow-hidden" style="min-height: 350px;">
        <div class="mt-3 mx-2">
          <div class="row my-4 no-gutters overflow-hidden">
            <div class="col align-self-center">
              <h2>
                {{selectedApplier?.applier.user.first_name }} {{selectedApplier?.applier.user.last_name}}
              </h2>
              <p>Geburtsjahr: {{selectedApplier?.applier.birth_year}}</p>
            </div>
            <div class="col align-self-center text-right pr-5">
              Einsatzbereit:
              <mat-chip-list aria-label="Fish selection">
                <mat-chip>
                  {{selectedApplier?.applier.start_work.substr(8, 10)}}.
                  {{selectedApplier?.applier.start_work.substr(5, 2)}}.
                  {{selectedApplier?.applier.start_work.substr(0, 4)}}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <div class="chip-details">
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Wohnort</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2">{{selectedApplier?.applier.postcode}}{{', ' + selectedApplier?.applier.city}}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Führerscheinklasse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.license}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Kennzahl 95</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2" *ngIf="selectedApplier?.applier.professional_license">Ja</mat-chip>
                  <mat-chip class="mx-2" *ngIf="!selectedApplier?.applier.professional_license">Nein</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Deutschkenntnisse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.language}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Berufserfahrung</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.work_experience + '  Jahre'}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Letzte Arbeitgeber</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_1_year + ' : ' + selectedApplier?.applier.employer_1 }} </mat-chip>
                </mat-chip-list>
                <br>
                <mat-chip-list aria-label="Fish selection" style="margin-top: 5px;">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_2_year + ' : ' + selectedApplier?.applier.employer_2 }} </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Erfahrung mit folg. Fahrzeugtypen</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let vehicalExperiance of selectedApplier?.applier.vehicle_experience">
                  <mat-chip class="mx-2 mb-2"> {{getVehicleExperience(vehicalExperiance)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Zusätzliche Führerscheine</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let additionalLicense of selectedApplier?.applier.additional_license">
                  <mat-chip class="mx-2 mb-2">{{getAdditionalLicense(additionalLicense)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Gewünschte Schicht</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection" *ngFor="let shift of selectedApplier?.applier.shift">
                  <mat-chip class="mx-2 mb-2"> {{getShift(shift)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class=" col-lg-4  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Gewünschtes Tourengebiet</b></div>
              <div class="col-lg-6">
                <mat-chip-list aria-label="Fish selection" *ngFor="let tours of selectedApplier?.applier.tours">
                  <mat-chip class="mx-2 mb-2"> {{getTour(tours)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Nachrichten" class="-align-center">
      <app-news  addText={{addText}} matchId="{{matchId}}" applierId={{applierId}} jobId={{jobId}} isStatus="{{selectedApplier?.status}}">
      </app-news>
      <!-- <div class="container my-3">
        <form class="bg-light" [formGroup]="chatForm" (ngSubmit)="addMessage()">
          <div class="form-group col-md-12 col-lg-12 col-sm-12">
            <label for="editor">Neue Nachricht schreiben</label>
            <button mat-button type="button" class="mr-1 float-right" color="#2fa16f"
              style="margin-top: -10px; border: 1px solid #2fa16f; color:#2fa16f;  cursor: pointer;"
              (click)="openCallDialog()">
              <i class="fas fa-lg fa-phone-alt"> </i><span  class="{{isMobile == true ? 'hideLabelForPhone'  : '' }}" style="color: black;">&nbsp;&nbsp;Telefonnummer anzeigen</span>
            </button>
            <ckeditor [editor]="Editor" formControlName="message" id="editor"></ckeditor>
          </div>
          <button mat-raised-button color="primary" class="w-100" [disabled]=chatForm.invalid>
            <i class="fas fa-envelope mr-1" aria-hidden="true"></i>
            Nachricht senden
          </button>
        </form>
        <hr>

        <div class="col-12 px-0">
          <div class="px-1 py-1 chat-box bg-white">
            <div *ngFor="let message of messages?.results">
              <div *ngIf="loggedInUserId === message?.created_by" class="media w-100 mb-3"><img
                  src="https://image.flaticon.com/icons/png/512/1962/1962551.png" alt="user" width="50"
                  class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                      Uhr</p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
              <div *ngIf="loggedInUserId !== message?.created_by" class="media w-100 mb-3"><img
                  src="https://www.clipartmax.com/png/middle/171-1717870_stockvader-predicted-cron-for-may-user-profile-icon-png.png"
                  alt="user" width="50" class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                    </p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </mat-tab>
  </mat-tab-group>
</mat-card>

<mat-card *ngIf="!isSpinner && addInterviewDates" class="application-details">
  <mat-tab-group mat-stretch-tabs [selectedIndex]="selected.value" (selectedTabChange)="tabClick($event)">
    <mat-tab label="Bewerbungsprozess">
      <mat-card-content class="card-content overflow-hidden mb-3" style="min-height: 350px;">
        <form [formGroup]="meetingForm" (ngSubmit)="createInterViewPraposal()">
          <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}">
          </app-status-bar>
          <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}">
          </app-status-bar>
          <!-- Arrange Interview -->
          <div class="row mt-5">
            <div class="col">
              <h5 class="ml-1">Vorstellungsgespräch vereinbaren</h5>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <mat-card-subtitle class="ml-1">Hier kannst du dem Bewerber Termine für ein {{ status_4 }}
                vorschlagen
              </mat-card-subtitle>
            </div>
          </div>

          <div class="row" *ngFor="let index of datePickedCountArray">
            <div class="col ">
              <mat-form-field appearance="outline" class="col-md-4 col-lg-4 col-sm-12">
                <mat-label>Datum wählen</mat-label>
                <input matInput [matDatepicker]="picker" (focus)="picker.open()" (click)="picker.open()" [min]="minDate"
                  formControlName="meetingDate_{{index}}" (dateChange)="onDateChange()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <mat-form-field *ngIf="index == 1" appearance="outline" class="col-md-3 col-lg-3 col-sm-12 " style="">
                <mat-label>Startzeit</mat-label>
                <mat-select (selectionChange)="setEndTime($event,index)" formControlName="startTime_{{index}}">
                  <mat-option *ngFor="let time of startTimes_1; index as i; first as isFirst"
                    [selected]="time.displayTime == 0800" [value]="time.hours + time.minutes">
                    {{time.displayTime}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="index == 2" appearance="outline" class="col-md-3 col-lg-3 col-sm-12 " style=" ">
                <mat-select (selectionChange)="setEndTime($event,index)" formControlName="startTime_{{index}}">
                  <mat-option *ngFor="let time of startTimes_2" [value]="time.hours + time.minutes">
                    {{time.displayTime}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="index == 3" appearance="outline" class="col-md-3 col-lg-3 col-sm-12" style=" ">
                <mat-select (selectionChange)="setEndTime($event,index)" formControlName="startTime_{{index}}">
                  <mat-option *ngFor="let time of startTimes_3" [value]="time.hours + time.minutes">
                    {{time.displayTime}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="index == 1" appearance="outline" class="col-md-3 col-lg-3 col-sm-8" style=" ">
                <mat-label>Ende</mat-label>
                <mat-select formControlName="endTime_{{index}}">
                  <mat-option *ngFor="let time of endTimes_1; index as i;" [value]="time.hours + time.minutes">
                    {{time.displayTime}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="index == 2" appearance="outline" class="col-md-3 col-lg-3 col-sm-8 ml-1" style=" ">
                <mat-select formControlName="endTime_{{index}}">
                  <mat-option *ngFor="let time of endTimes_2" [value]="time.hours + time.minutes">
                    {{time.displayTime}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="index == 3" appearance="outline" class="col-md-3 col-lg-3 col-sm-8 ml-1" style=" ">
                <mat-select formControlName="endTime_{{index}}">
                  <mat-option *ngFor="let time of endTimes_3" [value]="time.hours + time.minutes">
                    {{time.displayTime}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <i class="fas fa-lg fa-plus mt-lg-3 ml-2 col-md-1 col-sm-1 col-lg-1"
                *ngIf="datePickedCountArray.length < 3 && index  < 2" style="cursor: pointer;" aria-hidden="true"
                (click)="addRow()"></i>
              <i class="fas fa-lg fa-trash mt-lg-3 ml-2 col-md-1 col-sm-1 col-lg-1" *ngIf="index > 1"
                style="cursor: pointer; " aria-hidden="true" (click)="removeRow(index)"></i>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col mt-sm-3">
              <h6>Ort des Interviews: <span class="text-danger"> *</span></h6>
              <mat-form-field appearance="outline" class="col-md-10 col-sm-12 col-lg-10  ml-lg-n2">
                <mat-label>Adresse suchen</mat-label>
                <input class="ml-lg-0" matInput ngx-google-places-autocomplete [options]='options'
                  formControlName="address" value="{{address}}" (onAddressChange)="AddressChange($event)" />
                <mat-icon matSuffix><i class="fas fa-search"></i></mat-icon>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col px-3">
              <mat-form-field appearance="outline" class="col-md-10 col-sm-12 col-lg-10  ml-lg-n2" style="">
                <mat-label>Straße, Nr.</mat-label>
                <input matInput formControlName="street" class="{{ IsStreetNumberMsg ? 'streetReqired':''}}">
              </mat-form-field>
            </div>
          </div>
          <p class="text-danger ml-2 streetValidation" *ngIf="IsStreetNumberMsg">Bitte wähle eine Hausnummer</p>
          <div class="row">
            <div class="col ">
              <mat-form-field appearance="outline" class="col-md-3 col-sm-4 col-lg-3  ml-lg-n2" style="">
                <mat-label>Postleitzahl</mat-label>
                <input matInput formControlName="postcode" (ngModelChange)="checkPostCode($event)"
                  (focus)="checkPostCode($event)" (change)="checkPostCode($event)">
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-md-7 col-sm-6 col-lg-7" style="">
                <mat-label>Stadt</mat-label>
                <input matInput class="" formControlName="city">
              </mat-form-field>
            </div>
          </div>
          <mat-error *ngIf="isPostcodeValid" class="ml-1 hide">
            <strong>Bitte gebe eine deutsche Postleitzahl ein</strong>
          </mat-error>

          <mat-form-field appearance="outline" class="col-md-10 col-sm-12 col-lg-10  ml-lg-n2 ">
            <mat-label><b>Anmerkung</b> (optional):</mat-label>
            <textarea matInput formControlName="messageOther" #messageOther maxlength="300"></textarea>
            <mat-hint align="end">{{messageOther.value.length}} / 300</mat-hint>
          </mat-form-field>
          <div class="row mt-2 ml-1">
            <div class="col">
              <p class="text-danger">Mit einem Sternchen (*) markierte Felder sind Pflichtfelder.</p>
            </div>
          </div>

          <mat-card-actions class="{{isMobile == true ? 'row no-gutters' : 'row no-gutters' }}">
            <button mat-flat-button color="accent" class="{{isMobile == true ? 'col-md-10 mb-2  mx-1' : 'col mx-1' }}"
              (click)="rejectConfirm()">
              <span class="icon-text-button">Bewerber Absagen</span>
            </button>
            <button mat-flat-button color="primary"
              [disabled]="meetingForm.invalid || IsStreetNumberMsg || isPostcodeValid"
              class="{{isMobile == true ? 'col-md-10 mx-1' : 'col mx-1' }}">
              <span class="icon-text-button">Terminvorschläge senden</span>
            </button>
          </mat-card-actions>
        </form>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Bewerberdetails">
      <mat-card-content class="card-content overflow-hidden" style="min-height: 350px;">
        <div class="mt-3 mx-2">
          <div class="row my-4 no-gutters overflow-hidden">
            <div class="col align-self-center">
              <h2>
                {{selectedApplier?.applier.user.first_name }} {{selectedApplier?.applier.user.last_name}}
              </h2>
              <p>Geburtsjahr: {{selectedApplier?.applier.birth_year}}</p>
            </div>
            <div class="col align-self-center text-right pr-5">
              Einsatzbereit:
              <mat-chip-list aria-label="Fish selection">
                <mat-chip>
                  {{selectedApplier?.applier.start_work.substr(8, 10)}}.
                  {{selectedApplier?.applier.start_work.substr(5, 2)}}.
                  {{selectedApplier?.applier.start_work.substr(0, 4)}}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <div class="chip-details">
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Wohnort</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2">{{selectedApplier?.applier.postcode}}{{', ' + selectedApplier?.applier.city}}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Führerscheinklasse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.license}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Kennzahl 95</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2" *ngIf="selectedApplier?.applier.professional_license">Ja</mat-chip>
                  <mat-chip class="mx-2" *ngIf="!selectedApplier?.applier.professional_license">Nein</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Deutschkenntnisse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.language}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Berufserfahrung</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.work_experience + '  Jahre'}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Letzte Arbeitgeber</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_2_year + ' : ' + selectedApplier?.applier.employer_2 }} </mat-chip>
                </mat-chip-list>
                <br>
                <mat-chip-list aria-label="Fish selection" style="margin-top: 5px;">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_2_year + ' : ' + selectedApplier?.applier.employer_2 }} </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Erfahrung mit folg. Fahrzeugtypen</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let vehicalExperiance of selectedApplier?.applier.vehicle_experience">
                  <mat-chip class="mx-2 mb-2"> {{getVehicleExperience(vehicalExperiance)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Zusätzliche Führerscheine</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let additionalLicense of selectedApplier?.applier.additional_license">
                  <mat-chip class="mx-2 mb-2">{{getAdditionalLicense(additionalLicense)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Gewünschte Schicht</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection" *ngFor="let shift of selectedApplier?.applier.shift">
                  <mat-chip class="mx-2 mb-2"> {{getShift(shift)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class=" col-lg-4  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Gewünschtes Tourengebiet</b></div>
              <div class="col-lg-6">
                <mat-chip-list aria-label="Fish selection" *ngFor="let tours of selectedApplier?.applier.tours">
                  <mat-chip class="mx-2 mb-2"> {{getTour(tours)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Nachrichten" class="-align-center">
      <!--   START    -->
      <app-news  addText={{addText}} matchId="{{matchId}}" applierId={{applierId}} jobId={{jobId}} isStatus="{{selectedApplier?.status}}">
      </app-news>
      <!-- <div class="container my-3">
        <form class="bg-light" [formGroup]="chatForm" (ngSubmit)="addMessage()">
          <div class="form-group">
            <label for="editor">Neue Nachricht schreiben</label>
            <button mat-button type="button" class="mr-1 float-right" color="#2fa16f"
              style="margin-top: -10px; border: 1px solid #2fa16f; color:#2fa16f;  cursor: pointer;"
              (click)="openCallDialog()">
              <i class="fas fa-lg fa-phone-alt"> </i><span  class="{{isMobile == true ? 'hideLabelForPhone'  : '' }}" style="color: black;">&nbsp;&nbsp;Telefonnummer anzeigen</span>
            </button>

            <ckeditor [editor]="Editor" formControlName="message" id="editor"></ckeditor>
          </div>
          <button mat-raised-button color="primary" class="w-100" [disabled]=chatForm.invalid>
            <i class="fas fa-envelope mr-1" aria-hidden="true"> Telefonnummer anzeigen</i>
            Nachricht senden
          </button>
        </form>
        <hr>
         <div class="col-12 px-0">
          <div class="px-1 py-1 chat-box bg-white">
            <div *ngFor="let message of messages?.results">
              <div *ngIf="loggedInUserId === message?.created_by" class="media w-100 mb-3"><img
                  src="https://image.flaticon.com/icons/png/512/1962/1962551.png" alt="user" width="50"
                  class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                      Uhr</p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
              <div *ngIf="loggedInUserId !== message?.created_by" class="media w-100 mb-3"><img
                  src="https://www.clipartmax.com/png/middle/171-1717870_stockvader-predicted-cron-for-may-user-profile-icon-png.png"
                  alt="user" width="50" class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                    </p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </mat-tab>
  </mat-tab-group>
</mat-card>

<mat-card *ngIf="!isSpinner && isApplierSuggested" class="application-details">
  <mat-tab-group mat-stretch-tabs [selectedIndex]="selected.value" (selectedTabChange)="tabClick($event)">
    <mat-tab label="Bewerbungsprozess">
      <mat-card-content class="card-content overflow-hidden mb-3" style="min-height: 350px;">

        <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}"></app-status-bar>
        <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}"></app-status-bar>
        <!-- Arrange Interview -->
        <div class="match-details user-details ">
          <mat-card-subtitle class="mt-2" align="center">
            <strong>Der Bewerber kann leider keinen der vorgeschlagenen Termine wahrnehmen.</strong>
          </mat-card-subtitle>
          <mat-card-content align="center" class=""> Er freut sich jedoch auf weitere Terminvorschläge.
          </mat-card-content>

          <mat-card-content class="mt-4">
            Terminwunsch des Bewerbers: <br /> <b>{{ dayEnum[proposalDetails?.applier_choice] }}</b>
          </mat-card-content>
          <mat-card-content class="mt-4 mb-4" *ngIf="proposalDetails?.applier_notes">
            <b>Anmerkung</b> <br /> {{ proposalDetails?.applier_notes }}
          </mat-card-content>

          <mat-card-actions *ngIf="!isMobile" class="{{isMobile == true ? 'mb-5' : '' }}">
            <button mat-flat-button color="accent" class="col-sm-12 col-md-12 col-lg-6 mx-1" (click)="rejectConfirm()">
              <span class="icon-text-button">Bewerber Absagen</span>
            </button>
            <button (click)="addNewDate()" mat-flat-button color="primary"
              class="col-sm-12 col-md-12 col-lg-6 mx-1  pl-0 ml0 mr-0">
              <span class="icon-text-button">Neue Termin vorschlagen </span>
            </button>
          </mat-card-actions>
          <div *ngIf="isMobile == true">
            <mat-card-actions class="">
              <button mat-flat-button color="accent" (click)="rejectConfirm()">
                <span class="icon-text-button">Bewerber Absagen</span>
              </button>
            </mat-card-actions>
            <mat-card-actions class="">
              <button (click)="addNewDate()" mat-flat-button color="primary">
                <span class="icon-text-button">Neue Termin vorschlagen </span>
              </button>
            </mat-card-actions>
          </div>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Bewerberdetails">
      <mat-card-content class="card-content overflow-hidden" style="min-height: 350px;">

        <div class="mt-3 mx-2">
          <div class="row my-4 no-gutters overflow-hidden">
            <div class="col align-self-center">
              <h2>
                {{selectedApplier?.applier.user.first_name }} {{selectedApplier?.applier.user.last_name}}
              </h2>
              <p>Geburtsjahr: {{selectedApplier?.applier.birth_year}}</p>
            </div>
            <div class="col align-self-center text-right pr-5">
              Einsatzbereit:
              <mat-chip-list aria-label="Fish selection">
                <mat-chip>
                  {{selectedApplier?.applier.start_work.substr(8, 10)}}.
                  {{selectedApplier?.applier.start_work.substr(5, 2)}}.
                  {{selectedApplier?.applier.start_work.substr(0, 4)}}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <div class="chip-details">
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Wohnort</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2">{{selectedApplier?.applier.postcode}}{{', ' + selectedApplier?.applier.city}}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Führerscheinklasse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.license}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Kennzahl 95</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2" *ngIf="selectedApplier?.applier.professional_license">Ja</mat-chip>
                  <mat-chip class="mx-2" *ngIf="!selectedApplier?.applier.professional_license">Nein</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Deutschkenntnisse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.language}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Berufserfahrung</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.work_experience + '  Jahre'}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Letzte Arbeitgeber</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_1_year + ' : ' + selectedApplier?.applier.employer_1 }} </mat-chip>
                </mat-chip-list>
                <br>
                <mat-chip-list aria-label="Fish selection" style="margin-top: 5px;">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_2_year + ' : ' + selectedApplier?.applier.employer_2 }} </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Erfahrung mit folg. Fahrzeugtypen</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let vehicalExperiance of selectedApplier?.applier.vehicle_experience">
                  <mat-chip class="mx-2 mb-2"> {{getVehicleExperience(vehicalExperiance)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Zusätzliche Führerscheine</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let additionalLicense of selectedApplier?.applier.additional_license">
                  <mat-chip class="mx-2 mb-2">{{getAdditionalLicense(additionalLicense)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Gewünschte Schicht</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection" *ngFor="let shift of selectedApplier?.applier.shift">
                  <mat-chip class="mx-2 mb-2"> {{getShift(shift)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class=" col-lg-4  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Gewünschtes Tourengebiet</b></div>
              <div class="col-lg-6">
                <mat-chip-list aria-label="Fish selection" *ngFor="let tours of selectedApplier?.applier.tours">
                  <mat-chip class="mx-2 mb-2"> {{getTour(tours)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Nachrichten" class="-align-center">
      <!--   START    -->
      <app-news  addText={{addText}} matchId="{{matchId}}" applierId={{applierId}} jobId={{jobId}} isStatus="{{selectedApplier?.status}}">
      </app-news>
      <!-- <div class="container my-3">
        <form class="bg-light" [formGroup]="chatForm" (ngSubmit)="addMessage()">
          <div class="form-group">
            <label for="editor">Neue Nachricht schreiben</label>
            <button mat-button type="button" class="mr-1 float-right" color="#2fa16f"
              style="margin-top: -10px; border: 1px solid #2fa16f; color:#2fa16f;  cursor: pointer;"
              (click)="openCallDialog()">
              <mat-icon>call</mat-icon>
            </button>
            <ckeditor [editor]="Editor" formControlName="message" id="editor"></ckeditor>
          </div>
          <button mat-raised-button color="primary" class="w-100" [disabled]=chatForm.invalid>
            <i class="fas fa-envelope mr-1" aria-hidden="true"></i>
            Nachricht senden
          </button>
        </form>
        <hr>

        <div class="col-12 px-0">
          <div class="px-1 py-1 chat-box bg-white">
            <div *ngFor="let message of messages?.results">
              <div *ngIf="loggedInUserId === message?.created_by" class="media w-100 mb-3"><img
                  src="https://image.flaticon.com/icons/png/512/1962/1962551.png" alt="user" width="50"
                  class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                      Uhr</p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
              <div *ngIf="loggedInUserId !== message?.created_by" class="media w-100 mb-3"><img
                  src="https://www.clipartmax.com/png/middle/171-1717870_stockvader-predicted-cron-for-may-user-profile-icon-png.png"
                  alt="user" width="50" class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                    </p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </mat-tab>
  </mat-tab-group>
</mat-card>

<mat-card *ngIf="!isSpinner && selectedApplier?.status == 5 && !viewConfirm" class="application-details">
  <mat-tab-group mat-stretch-tabs [selectedIndex]="selected.value" (selectedTabChange)="tabClick($event)">
    <mat-tab label="Bewerbungsprozess">
      <mat-card-content class="card-content overflow-hidden mb-3" style="min-height: 350px;">
        <app-status-bar *ngIf="!isMobile" isStatus="{{selectedApplier?.status}}"></app-status-bar>
        <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}"></app-status-bar>
        <!-- Arrange Interview -->
        <div class="match-details user-details {{isMobile == true ? 'mt-4'  : '' }}">
          <div class="text-center  {{isMobile == true ? 'iconDivMobile mt-1'  : 'iconDiv mt-5' }}" style="">
            <p class="{{isMobile == true ? 'iconPMobile' : 'iconP' }}" style=""> &#10004;</p>
          </div>
          <mat-card-subtitle class="{{isMobile == true ? 'mt-2'  : 'mt-4' }} text-center" lign="center">
            <strong>Terminvorschlag bestätigt</strong>
          </mat-card-subtitle>
          <mat-card-content>Der Bewerber hat den folgenden Termin für ein persönliches Interview bestätigt.
          </mat-card-content>
          <mat-card-content *ngIf="selectedApplier.history">
            <div class="row">
              <div class="col-lg-6 col-md-8 col-sm-12">
                 <h6 class="col-12">Datum:</h6>
                  <p class="{{isMobile == true ? '' : 'col-12 pr-2' }}"  style="">{{ selectedApplier.history.date | date:'dd.MM.yyyy':'locale'}} </p>
                  <h6 class="col-12">Uhrzeit:</h6>
                  <p class="{{isMobile == true ? '' : 'col-12 pr-2' }}" *ngIf="selectedApplier?.history.start_time" style="">
                      {{ selectedApplier.history.start_time.substring(0, selectedApplier.history.start_time.length-3) }} Uhr -
                      {{ selectedApplier.history.end_time.substring(0, selectedApplier.history.end_time.length-3) }} Uhr
                  </p>
                <!-- <p class="{{isMobile == true ? '' : 'col-12 pr-2' }} "
                  style="{{isMobile == true ? 'font-size: 18px;' : 'font-size: 22px;' }}"> Datum
                  <b>{{ selectedApplier.history.date | date:'dd.MM.yyyy':'locale'}}</b></p>
                <hr />
                <p class="{{isMobile == true ? '' : 'col-12 pr-2' }}"
                  style="{{isMobile == true ? 'font-size: 18px;' : 'font-size: 22px;' }}"> Uhrzeit
                  <b>{{ selectedApplier.history.start_time.substring(0, selectedApplier.history.start_time.length-3) }}
                    -
                    {{ selectedApplier.history.end_time.substring(0, selectedApplier.history.end_time.length-3) }}</b>
                </p> -->
              </div>
              <div class="col-lg-6 col-md-8 col-sm-12 align-self-center mb-5">
                <h6>Ort des Interviews</h6>
                <p class="fas fa-map-marker-alt font-18 " aria-hidden="true">&nbsp;</p>
                {{ selectedApplier.history?.interview_schedule.street }}, &nbsp;
                {{ selectedApplier.history?.interview_schedule.city }}, &nbsp;
                {{ selectedApplier.history?.interview_schedule.postcode }}
                <h6 class="mt-3" *ngIf="selectedApplier.history?.interview_schedule.notes">Anmerkung </h6>
                <p>{{ selectedApplier.history?.interview_schedule.notes }} </p>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions>

            <button mat-flat-button color="accent" class="{{isMobile == true ? 'col mx-1' : 'col mx-1' }}"
              (click)="rejectConfirm()">
              <span class="icon-text-button">Bewerber Absagen</span>
            </button>
          </mat-card-actions>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Bewerberdetails">
      <mat-card-content class="card-content overflow-hidden" style="min-height: 350px;">

        <div class="mt-3 mx-2">
          <div class="row my-4 no-gutters overflow-hidden">
            <div class="col align-self-center">
              <h2>
                {{selectedApplier?.applier.user.first_name }} {{selectedApplier?.applier.user.last_name}}
              </h2>
              <p>Geburtsjahr: {{selectedApplier?.applier.birth_year}}</p>
            </div>
            <div class="col align-self-center text-right pr-5">
              Einsatzbereit:
              <mat-chip-list aria-label="Fish selection">
                <mat-chip>
                  {{selectedApplier?.applier.start_work.substr(8, 10)}}.
                  {{selectedApplier?.applier.start_work.substr(5, 2)}}.
                  {{selectedApplier?.applier.start_work.substr(0, 4)}}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <div class="chip-details">
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Wohnort</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2">{{selectedApplier?.applier.postcode}}{{', ' + selectedApplier?.applier.city}}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Führerscheinklasse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.license}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Kennzahl 95</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2" *ngIf="selectedApplier?.applier.professional_license">Ja</mat-chip>
                  <mat-chip class="mx-2" *ngIf="!selectedApplier?.applier.professional_license">Nein</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Deutschkenntnisse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.language}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Berufserfahrung</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.work_experience + '  Jahre'}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Letzte Arbeitgeber</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_1_year + ' : ' + selectedApplier?.applier.employer_1 }} </mat-chip>
                </mat-chip-list>
                <br>
                <mat-chip-list aria-label="Fish selection" style="margin-top: 5px;">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_2_year + ' : ' + selectedApplier?.applier.employer_2 }} </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Erfahrung mit folg. Fahrzeugtypen</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let vehicalExperiance of selectedApplier?.applier.vehicle_experience">
                  <mat-chip class="mx-2 mb-2"> {{getVehicleExperience(vehicalExperiance)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Zusätzliche Führerscheine</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let additionalLicense of selectedApplier?.applier.additional_license">
                  <mat-chip class="mx-2 mb-2">{{getAdditionalLicense(additionalLicense)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Gewünschte Schicht</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection" *ngFor="let shift of selectedApplier?.applier.shift">
                  <mat-chip class="mx-2 mb-2"> {{getShift(shift)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class=" col-lg-4  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Gewünschtes Tourengebiet</b></div>
              <div class="col-lg-6">
                <mat-chip-list aria-label="Fish selection" *ngFor="let tours of selectedApplier?.applier.tours">
                  <mat-chip class="mx-2 mb-2"> {{getTour(tours)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Nachrichten" class="-align-center">
      <!--   START    -->
      <app-news  addText={{addText}} matchId="{{matchId}}" applierId={{applierId}} jobId={{jobId}} isStatus="{{selectedApplier?.status}}">
      </app-news>
      <!-- <div class="container my-3">
        <form class="bg-light" [formGroup]="chatForm" (ngSubmit)="addMessage()">
          <div class="form-group">
            <label for="editor">Neue Nachricht schreiben</label>
            <button mat-button type="button" class="mr-1 float-right" color="#2fa16f"
              style="margin-top: -10px; border: 1px solid #2fa16f; color:#2fa16f;  cursor: pointer;"
              (click)="openCallDialog()">
              <i class="fas fa-lg fa-phone-alt"> </i><span  class="{{isMobile == true ? 'hideLabelForPhone'  : '' }}" style="color: black;">&nbsp;&nbsp;Telefonnummer anzeigen</span>
            </button>

            <ckeditor [editor]="Editor" [config]="{ scayt_sLang: 'de_DE' }" formControlName="message" id="editor">
            </ckeditor>
          </div>
          <button mat-raised-button color="primary" class="w-100" [disabled]=chatForm.invalid>
            <i class="fas fa-envelope mr-1" aria-hidden="true"></i>
            Nachricht senden
          </button>
        </form>
        <hr>

        <div class="col-12 px-0">
          <div class="px-1 py-1 chat-box bg-white">
            <div *ngFor="let message of messages?.results">
              <div *ngIf="loggedInUserId === message?.created_by" class="media w-100 mb-3"><img
                  src="https://image.flaticon.com/icons/png/512/1962/1962551.png" alt="user" width="50"
                  class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                      Uhr</p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
              <div *ngIf="loggedInUserId !== message?.created_by" class="media w-100 mb-3"><img
                  src="https://www.clipartmax.com/png/middle/171-1717870_stockvader-predicted-cron-for-may-user-profile-icon-png.png"
                  alt="user" width="50" class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                    </p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </mat-tab>
  </mat-tab-group>
</mat-card>
<mat-card *ngIf="!isSpinner && selectedApplier.status == 6" class="application-details">
  <mat-tab-group mat-stretch-tabs [selectedIndex]="selected.value" (selectedTabChange)="tabClick($event)">
    <mat-tab label="Bewerbungsprozess">
      <mat-card-content class="card-content overflow-hidden mb-3" style="min-height: 350px;">
        <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}"></app-status-bar>
        <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}"></app-status-bar>
        <!-- Arrange Interview -->
        <div class="match-details user-details mb-5">
          <mat-card-title class="{{isMobile == true ? 'mt-2'  : 'mt-4' }} text-center" align="center">
            <strong> Du hast {{selectedApplier?.applier.user.first_name }} {{selectedApplier?.applier.user.last_name}}
              zugesagt!</strong>
          </mat-card-title>
          <mat-card-content>Der Bewerber wird Ihnen innerhalb der nächsten 7 Tage Rückmeldung geben. </mat-card-content>
          <mat-card-content>Sie können dem Bewerber eine Nachricht schreiben, falls Sie ihm noch weitere Informationen
            zukommen lassen wollen.</mat-card-content>
          <mat-card-subtitle class="mt-2 text-center" align="center">

          </mat-card-subtitle>
          <mat-card-actions class="text-center mt-2" style="padding: 0% 25% 0% 25%;">
            <button mat-flat-button color="accent" class="col mx-1" (click)="gotoChatTab()">
              <span class="icon-text-button ">Nachricht senden</span>
            </button>
          </mat-card-actions>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Bewerberdetails">
      <mat-card-content class="card-content overflow-hidden" style="min-height: 350px;">
        <div class="mt-3 mx-2">
          <div class="row my-4 no-gutters overflow-hidden">
            <div class="col align-self-center">
              <h2>
                {{selectedApplier?.applier.user.first_name }} {{selectedApplier?.applier.user.last_name}}
              </h2>
              <p>Geburtsjahr: {{selectedApplier?.applier.birth_year}}</p>
            </div>
            <div class="col align-self-center text-right pr-5">
              Einsatzbereit:
              <mat-chip-list aria-label="Fish selection">
                <mat-chip>
                  {{selectedApplier?.applier.start_work.substr(8, 10)}}.
                  {{selectedApplier?.applier.start_work.substr(5, 2)}}.
                  {{selectedApplier?.applier.start_work.substr(0, 4)}}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <div class="chip-details">
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Wohnort</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2">{{selectedApplier?.applier.postcode}}{{', ' + selectedApplier?.applier.city}}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Führerscheinklasse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.license}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Kennzahl 95</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2" *ngIf="selectedApplier?.applier.professional_license">Ja</mat-chip>
                  <mat-chip class="mx-2" *ngIf="!selectedApplier?.applier.professional_license">Nein</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Deutschkenntnisse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.language}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Berufserfahrung</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.work_experience + '  Jahre'}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Letzte Arbeitgeber</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_1_year + ' : ' + selectedApplier?.applier.employer_1 }} </mat-chip>
                </mat-chip-list>
                <br>
                <mat-chip-list aria-label="Fish selection" style="margin-top: 5px;">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_2_year + ' : ' + selectedApplier?.applier.employer_2 }} </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Erfahrung mit folg. Fahrzeugtypen</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let vehicalExperiance of selectedApplier?.applier.vehicle_experience">
                  <mat-chip class="mx-2 mb-2"> {{getVehicleExperience(vehicalExperiance)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Zusätzliche Führerscheine</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let additionalLicense of selectedApplier?.applier.additional_license">
                  <mat-chip class="mx-2 mb-2">{{getAdditionalLicense(additionalLicense)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Gewünschte Schicht</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection" *ngFor="let shift of selectedApplier?.applier.shift">
                  <mat-chip class="mx-2 mb-2"> {{getShift(shift)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class=" col-lg-4  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Gewünschtes Tourengebiet</b></div>
              <div class="col-lg-6">
                <mat-chip-list aria-label="Fish selection" *ngFor="let tours of selectedApplier?.applier.tours">
                  <mat-chip class="mx-2 mb-2"> {{getTour(tours)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Nachrichten" class="-align-center">
      <!--   START    -->
      <app-news  addText={{addText}} matchId="{{matchId}}" applierId={{applierId}} jobId={{jobId}} isStatus="{{selectedApplier?.status}}">
      </app-news>
      <!-- <div class="container my-3">
        <form class="bg-light" [formGroup]="chatForm" (ngSubmit)="addMessage()">
          <div class="form-group">
            <label for="editor">Neue Nachricht schreiben</label>
            <button mat-button type="button" class="mr-1 float-right" color="#2fa16f"
              style="margin-top: -10px; border: 1px solid #2fa16f; color:#2fa16f;  cursor: pointer;"
              (click)="openCallDialog()">
              <i class="fas fa-lg fa-phone-alt"> </i><span  class="{{isMobile == true ? 'hideLabelForPhone'  : '' }}" style="color: black;">&nbsp;&nbsp;Telefonnummer anzeigen</span>
            </button>
            <ckeditor [editor]="Editor" [config]="{ scayt_sLang: 'de_DE' }" formControlName="message" id="editor">
            </ckeditor>
          </div>
          <button mat-raised-button color="primary" class="w-100" [disabled]=chatForm.invalid>
            <i class="fas fa-envelope mr-1" aria-hidden="true"></i>
            Nachricht senden
          </button>
        </form>
        <hr>
        <div class="col-12 px-0">
          <div class="px-1 py-1 chat-box bg-white">
            <div *ngFor="let message of messages?.results">
              <div *ngIf="loggedInUserId === message?.created_by" class="media w-100 mb-3"><img
                  src="https://image.flaticon.com/icons/png/512/1962/1962551.png" alt="user" width="50"
                  class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                      Uhr</p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
              <div *ngIf="loggedInUserId !== message?.created_by" class="media w-100 mb-3"><img
                  src="https://www.clipartmax.com/png/middle/171-1717870_stockvader-predicted-cron-for-may-user-profile-icon-png.png"
                  alt="user" width="50" class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                    </p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </mat-tab>
  </mat-tab-group>
</mat-card>

<mat-card *ngIf="!isSpinner && selectedApplier.status == 7" class="application-details">
  <mat-tab-group mat-stretch-tabs [selectedIndex]="selected.value" (selectedTabChange)="tabClick($event)">
    <mat-tab label="Bewerbungsprozess">
      <mat-card-content class="card-content overflow-hidden" style="min-height: auto;">
        <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}"></app-status-bar>
        <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}"></app-status-bar>
        <!-- Arrange Interview -->

        <div class="match-details user-details mb-5">
          <mat-card-subtitle class="mt-3 text-center" align="center">
            <div class="text-center">
              <p>Gratulation. {{selectedApplier?.applier.user.first_name }} {{selectedApplier?.applier.user.last_name}}
                hat die Stelle angenommen.</p>
              <!-- <p>Sprechen Sie mit {{selectedApplier?.applier.user.first_name }}
                {{selectedApplier?.applier.user.last_name}}, wie es weiter geht</p> -->
            </div>
          </mat-card-subtitle>
          <mat-card-subtitle class="mt-2 text-center" align="center">
            <div class="text-center  {{isMobile == true ? 'iconDivMobile mt-1'  : 'iconDiv mt-5' }}">
              <p class="{{isMobile == true ? 'iconPMobile' : 'iconP' }}"><i class="far fa-handshake "></i></p>
            </div>
          </mat-card-subtitle>
          <form [formGroup]="confirmInterviewDateForm" (ngSubmit)="addStartDate()" >
            <div class="row ">
              <div class="col text-center">
                <b class="text-right">Startdatum eintragen:</b>
                <mat-form-field appearance="outline" class="col-md-4 col-lg-4 col-sm-6">
                  <mat-label>Startdatum eintragen:</mat-label>
                  <input matInput [matDatepicker]="picker" (focus)="picker.open()" (click)="picker.open()" [min]="minDate2"
                    formControlName="job_start_date" (dateChange)="onDateChange()">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                  <button mat-flat-button [disabled]="confirmInterviewDateForm.invalid" color="primary" class="col-md-3 col-lg-3 col-sm-4 mx-1">
                    <span class="icon-text-button">Speichern</span>
                  </button>
              </div>
            </div>
          </form>
          <p class="text-center">Sprechen Sie mit {{selectedApplier?.applier.user.first_name }}
            {{selectedApplier?.applier.user.last_name}}, wie es weiter geht</p>
          <mat-card-actions class="text-center" style="padding: 0% 25% 0% 25%;">
            <button mat-flat-button color="accent" class="col mx-1" (click)="gotoChatTab()">
              <span class="icon-text-button ">Nachricht senden</span>
            </button>
          </mat-card-actions>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Bewerberdetails">
      <mat-card-content class="card-content overflow-hidden" style="min-height: 350px;">
        <div class="mt-3 mx-2">
          <div class="row my-4 no-gutters overflow-hidden">
            <div class="col align-self-center">
              <h2>
                {{selectedApplier?.applier.user.first_name }} {{selectedApplier?.applier.user.last_name}}
              </h2>
              <p>Geburtsjahr: {{selectedApplier?.applier.birth_year}}</p>
            </div>
            <div class="col align-self-center text-right pr-5">
              Einsatzbereit:
              <mat-chip-list aria-label="Fish selection">
                <mat-chip>
                  {{selectedApplier?.applier.start_work.substr(8, 10)}}.
                  {{selectedApplier?.applier.start_work.substr(5, 2)}}.
                  {{selectedApplier?.applier.start_work.substr(0, 4)}}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <div class="chip-details">
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Wohnort</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2">{{selectedApplier?.applier.postcode}}{{', ' + selectedApplier?.applier.city}}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Führerscheinklasse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.license}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Kennzahl 95</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2" *ngIf="selectedApplier?.applier.professional_license">Ja</mat-chip>
                  <mat-chip class="mx-2" *ngIf="!selectedApplier?.applier.professional_license">Nein</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Deutschkenntnisse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.language}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Berufserfahrung</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.work_experience + '  Jahre'}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Letzte Arbeitgeber</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_1_year + ' : ' + selectedApplier?.applier.employer_1 }} </mat-chip>

                </mat-chip-list>
                <br>
                <mat-chip-list aria-label="Fish selection" style="margin-top: 5px;">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_2_year + ' : ' + selectedApplier?.applier.employer_2 }} </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Erfahrung mit folg. Fahrzeugtypen</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let vehicalExperiance of selectedApplier?.applier.vehicle_experience">
                  <mat-chip class="mx-2 mb-2"> {{getVehicleExperience(vehicalExperiance)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Zusätzliche Führerscheine</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let additionalLicense of selectedApplier?.applier.additional_license">
                  <mat-chip class="mx-2 mb-2">{{getAdditionalLicense(additionalLicense)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Gewünschte Schicht</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection" *ngFor="let shift of selectedApplier?.applier.shift">
                  <mat-chip class="mx-2 mb-2"> {{getShift(shift)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class=" col-lg-4  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Gewünschtes Tourengebiet</b></div>
              <div class="col-lg-6">
                <mat-chip-list aria-label="Fish selection" *ngFor="let tours of selectedApplier?.applier.tours">
                  <mat-chip class="mx-2 mb-2"> {{getTour(tours)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Nachrichten" class="align-center">
      <app-news  addText={{addText}} matchId="{{matchId}}" applierId={{applierId}} jobId={{jobId}} isStatus="{{selectedApplier?.status}}">
      </app-news>
    </mat-tab>
  </mat-tab-group>
</mat-card>

<mat-card *ngIf="!isSpinner && selectedApplier.status == 12" class="application-details">
  <mat-tab-group mat-stretch-tabs [selectedIndex]="selected.value" (selectedTabChange)="tabClick($event)">
    <mat-tab label="Bewerbungsprozess">
      <mat-card-content class="card-content overflow-hidden" style="min-height: auto;">
        <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}"></app-status-bar>
        <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}"></app-status-bar>
        <!-- Arrange Interview -->

        <div class="match-details user-details mb-5">
          <mat-card-subtitle class="mt-3 text-center" align="center">
            <div class="text-center">
              <p>Gratulation. {{selectedApplier?.applier.user.first_name }} {{selectedApplier?.applier.user.last_name}}
                hat die Stelle angenommen.</p>

            </div>
          </mat-card-subtitle>
          <mat-card-subtitle class="mt-2 text-center" align="center">
            <div class="text-center  {{isMobile == true ? 'iconDivMobile mt-1'  : 'iconDiv mt-5' }}">
              <p class="{{isMobile == true ? 'iconPMobile' : 'iconP' }}"><i class="far fa-handshake "></i></p>
            </div>
          </mat-card-subtitle>
          <form [formGroup]="confirmInterviewDateForm" (ngSubmit)="addStartDate()" >
            <div class="row ">
              <!-- <div *ngIf="!jobStartDate && !IsJobStartDate " class="col text-center"> -->
              <div *ngIf="IsJobStartDate " class="col text-center">
                <b class="text-right">Startdatum eintragen:</b>
                <mat-form-field appearance="outline" class="col-md-4 col-lg-4 col-sm-6">
                  <mat-label>Startdatum eintragen:</mat-label>
                  <input matInput [matDatepicker]="picker" (focus)="picker.open()" (click)="picker.open()" [min]="minDate2"
                    formControlName="job_start_date" (dateChange)="onDateChange()">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                  <button mat-flat-button [disabled]="confirmInterviewDateForm.invalid" color="primary" class="col-md-3 col-lg-3 col-sm-4 mx-1">
                    <span class="icon-text-button">Speichern</span>
                  </button>
              </div>
              <div *ngIf="jobStartDate && !IsJobStartDate" class="col text-center mb-2">
                <p><b class="text-right col-lg-4 col-md-12">Startdatum eintragen:</b>
                  {{ jobStartDate | date:'dd.MM.yyyy':'locale'}} &nbsp;<a href="javascript:;"><i (click)="editJobDate()" style="color: black;" class="fas fa-pencil-alt edit-icon size-17" aria-hidden="true"></i></a> </p>
              </div>
            </div>
          </form>
          <p class="text-center">Sprechen Sie mit {{selectedApplier?.applier.user.first_name }}
            {{selectedApplier?.applier.user.last_name}}, wie es weiter geht</p>
          <mat-card-actions class="{{isMobile == true ? 'mt-2' : 'text-center mb-2' }}" style="padding: 0% 25% 0% 25%;">
            <button mat-flat-button color="accent" class="col mx-1" (click)="gotoChatTab()">
              <span class="icon-text-button ">Nachricht senden </span>
            </button>
          </mat-card-actions>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Bewerberdetails">
      <mat-card-content class="card-content overflow-hidden" style="min-height: 350px;">
        <div class="mt-3 mx-2">
          <div class="row my-4 no-gutters overflow-hidden">
            <div class="col align-self-center">
              <h2>
                {{selectedApplier?.applier.user.first_name }} {{selectedApplier?.applier.user.last_name}}
              </h2>
              <p>Geburtsjahr: {{selectedApplier?.applier.birth_year}}</p>
            </div>
            <div class="col align-self-center text-right pr-5">
              Einsatzbereit:
              <mat-chip-list aria-label="Fish selection">
                <mat-chip>
                  {{selectedApplier?.applier.start_work.substr(8, 10)}}.
                  {{selectedApplier?.applier.start_work.substr(5, 2)}}.
                  {{selectedApplier?.applier.start_work.substr(0, 4)}}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <div class="chip-details">
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Wohnort</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2">{{selectedApplier?.applier.postcode}}{{', ' + selectedApplier?.applier.city}}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Führerscheinklasse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.license}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Kennzahl 95</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2" *ngIf="selectedApplier?.applier.professional_license">Ja</mat-chip>
                  <mat-chip class="mx-2" *ngIf="!selectedApplier?.applier.professional_license">Nein</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Deutschkenntnisse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.language}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Berufserfahrung</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.work_experience + '  Jahre'}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Letzte Arbeitgeber</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_1_year + ' : ' + selectedApplier?.applier.employer_1 }} </mat-chip>

                </mat-chip-list>
                <br>
                <mat-chip-list aria-label="Fish selection" style="margin-top: 5px;">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_2_year + ' : ' + selectedApplier?.applier.employer_2 }} </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Erfahrung mit folg. Fahrzeugtypen</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let vehicalExperiance of selectedApplier?.applier.vehicle_experience">
                  <mat-chip class="mx-2 mb-2"> {{getVehicleExperience(vehicalExperiance)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Zusätzliche Führerscheine</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let additionalLicense of selectedApplier?.applier.additional_license">
                  <mat-chip class="mx-2 mb-2">{{getAdditionalLicense(additionalLicense)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Gewünschte Schicht</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection" *ngFor="let shift of selectedApplier?.applier.shift">
                  <mat-chip class="mx-2 mb-2"> {{getShift(shift)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class=" col-lg-4  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Gewünschtes Tourengebiet</b></div>
              <div class="col-lg-6">
                <mat-chip-list aria-label="Fish selection" *ngFor="let tours of selectedApplier?.applier.tours">
                  <mat-chip class="mx-2 mb-2"> {{getTour(tours)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Nachrichten" class="align-center">
      <app-news  addText={{addText}} matchId="{{matchId}}" applierId={{applierId}} jobId={{jobId}} isStatus="{{selectedApplier?.status}}">
      </app-news>
    </mat-tab>
  </mat-tab-group>
</mat-card>

<mat-card *ngIf="!isSpinner && viewConfirm" class="application-details">
  <mat-tab-group mat-stretch-tabs [selectedIndex]="selected.value" (selectedTabChange)="tabClick($event)">
    <mat-tab label="Bewerbungsprozess">
      <mat-card-content class="card-content overflow-hidden mb-3" style="min-height: auto;">
        <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}"></app-status-bar>
        <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}"></app-status-bar>
        <!-- Arrange Interview -->
        <div class="match-details user-details {{isMobile == true ? 'mb-5' : '' }}">
          <mat-card-title class="mt-2 text-center" align="center">
            <strong>Wollen Sie dem Bewerber zusagen?</strong>
          </mat-card-title>
          <mat-card-content class="p-2">Wenn Sie der Bewerber überzeugt hat, können Sie hier dem Bewerber über einen
            Klick zusagen und mit einer persönlichen Nachricht versehen. </mat-card-content>
            <mat-divider style="margin: 20px;"></mat-divider>
            <br/>
            <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Informationen zum persönlichen Interview anzeigen
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row" *ngIf="selectedApplier.history">
                <div class="col-lg-6 col-md-8 col-sm-12 align-self-center mb-5">
                  <h6 class="col-12">Datum:</h6>
                  <p class="{{isMobile == true ? '' : 'col-12 pr-2' }}"  style="">{{ selectedApplier.history.date | date:'dd.MM.yyyy':'locale'}} </p>
                  <h6 class="col-12">Uhrzeit:</h6>
                  <p class="{{isMobile == true ? '' : 'col-12 pr-2' }}" *ngIf="selectedApplier?.history.start_time" style="">
                      {{ selectedApplier.history?.start_time.substring(0, selectedApplier.history?.start_time.length-3) }} Uhr -
                      {{ selectedApplier.history?.end_time.substring(0, selectedApplier.history?.end_time.length-3) }} Uhr
                  </p>
                  <!-- <p class="{{isMobile == true ? '' : 'col-12 pr-2' }} "
                    style="{{isMobile == true ? 'font-size: 18px;' : 'font-size: 22px;' }}"> Datum
                    <b>{{ selectedApplier.history.date | date:'dd.MM.yyyy':'locale'}}</b></p>
                  <hr />
                  <p *ngIf="selectedApplier.history?.start_time" class="{{isMobile == true ? '' : 'col-12 pr-2' }}"
                    style="{{isMobile == true ? 'font-size: 18px;' : 'font-size: 22px;' }}"> Uhrzeit
                    <b>{{ selectedApplier.history?.start_time.substring(0, selectedApplier.history?.start_time.length-3) }}
                      -
                      {{ selectedApplier.history?.end_time.substring(0, selectedApplier.history?.end_time.length-3) }}</b>
                  </p> -->
                </div>
                <div class="col-lg-6 col-md-8 col-sm-12 align-self-center mb-5">
                  <h6>Ort des Interviews</h6>
                  <p class="fas fa-map-marker-alt font-18 " aria-hidden="true">&nbsp;</p>
                  {{ selectedApplier.history?.interview_schedule?.street }}, &nbsp;
                  {{ selectedApplier.history?.interview_schedule?.city }}, &nbsp;
                  {{ selectedApplier.history?.interview_schedule?.postcode }}
                  <h6 class="mt-3" *ngIf="selectedApplier.history?.interview_schedule?.notes">Anmerkung </h6>
                  <p>{{ selectedApplier.history?.interview_schedule?.notes }} </p>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-card-actions>
            <button mat-flat-button color="accent" class="col mx-1" (click)="openCencelPopup()">
              <span class="icon-text-button">Absagen</span>
            </button>
            <button mat-flat-button color="primary" class="col mx-1" (click)="openConfirmPopup()">
              <span class="icon-text-button">Zusagen</span>
            </button>
          </mat-card-actions>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Bewerberdetails">
      <mat-card-content class="card-content overflow-hidden" style="min-height: 350px;">
        <div class="mt-3 mx-2">
          <div class="row my-4 no-gutters overflow-hidden">
            <div class="col align-self-center">
              <h2>
                {{selectedApplier?.applier.user.first_name }} {{selectedApplier?.applier.user.last_name}}
              </h2>
              <p>Geburtsjahr: {{selectedApplier?.applier.birth_year}}</p>
            </div>
            <div class="col align-self-center text-right pr-5">
              Einsatzbereit:
              <mat-chip-list aria-label="Fish selection">
                <mat-chip>
                  {{selectedApplier?.applier.start_work.substr(8, 10)}}.
                  {{selectedApplier?.applier.start_work.substr(5, 2)}}.
                  {{selectedApplier?.applier.start_work.substr(0, 4)}}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <div class="chip-details">
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Wohnort</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2">{{selectedApplier?.applier.postcode}}{{', ' + selectedApplier?.applier.city}}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Führerscheinklasse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.license}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Kennzahl 95</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2" *ngIf="selectedApplier?.applier.professional_license">Ja</mat-chip>
                  <mat-chip class="mx-2" *ngIf="!selectedApplier?.applier.professional_license">Nein</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Deutschkenntnisse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.language}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Berufserfahrung</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.work_experience + '  Jahre'}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Letzte Arbeitgeber</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_1_year + ' : ' + selectedApplier?.applier.employer_1 }} </mat-chip>

                </mat-chip-list>
                <br>
                <mat-chip-list aria-label="Fish selection" style="margin-top: 5px;">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_2_year + ' : ' + selectedApplier?.applier.employer_2 }} </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Erfahrung mit folg. Fahrzeugtypen</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let vehicalExperiance of selectedApplier?.applier.vehicle_experience">
                  <mat-chip class="mx-2 mb-2"> {{getVehicleExperience(vehicalExperiance)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Zusätzliche Führerscheine</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let additionalLicense of selectedApplier?.applier.additional_license">
                  <mat-chip class="mx-2 mb-2">{{getAdditionalLicense(additionalLicense)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Gewünschte Schicht</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection" *ngFor="let shift of selectedApplier?.applier.shift">
                  <mat-chip class="mx-2 mb-2"> {{getShift(shift)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class=" col-lg-4  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Gewünschtes Tourengebiet</b></div>
              <div class="col-lg-6">
                <mat-chip-list aria-label="Fish selection" *ngFor="let tours of selectedApplier?.applier.tours">
                  <mat-chip class="mx-2 mb-2"> {{getTour(tours)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Nachrichten" class="-align-center">
      <!--   START    -->
      <app-news  addText={{addText}} matchId="{{matchId}}" applierId={{applierId}} jobId={{jobId}} isStatus="{{selectedApplier?.status}}">
      </app-news>
      <!-- <div class="container my-3">
        <form class="bg-light" [formGroup]="chatForm" (ngSubmit)="addMessage()">
          <div class="form-group">
            <label for="editor">Neue Nachricht schreiben</label>
            <button mat-button type="button" class="mr-1 float-right" color="#2fa16f"
              style="margin-top: -10px; border: 1px solid #2fa16f; color:#2fa16f;  cursor: pointer;"
              (click)="openCallDialog()">
              <i class="fas fa-lg fa-phone-alt"> </i><span  class="{{isMobile == true ? 'hideLabelForPhone'  : '' }}" style="color: black;">&nbsp;&nbsp;Telefonnummer anzeigen</span>
            </button>
            <ckeditor [editor]="Editor" [config]="{ scayt_sLang: 'de_DE' }" formControlName="message" id="editor">
            </ckeditor>
          </div>
          <button mat-raised-button color="primary" class="w-100" [disabled]=chatForm.invalid>
            <i class="fas fa-envelope mr-1" aria-hidden="true"></i>
            Nachricht senden
          </button>
        </form>
        <hr>
        <div class="col-12 px-0">
          <div class="px-1 py-1 chat-box bg-white">
            <div *ngFor="let message of messages?.results">
              <div *ngIf="loggedInUserId === message?.created_by" class="media w-100 mb-3"><img
                  src="https://image.flaticon.com/icons/png/512/1962/1962551.png" alt="user" width="50"
                  class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                      Uhr</p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
              <div *ngIf="loggedInUserId !== message?.created_by" class="media w-100 mb-3"><img
                  src="https://www.clipartmax.com/png/middle/171-1717870_stockvader-predicted-cron-for-may-user-profile-icon-png.png"
                  alt="user" width="50" class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                    </p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </mat-tab>
  </mat-tab-group>
</mat-card>

<mat-card *ngIf="!isSpinner && isCanceledByCompany" class="application-details">
  <mat-tab-group mat-stretch-tabs [selectedIndex]="selected.value" (selectedTabChange)="tabClick($event)">
    <mat-tab label="Bewerberdetails">
      <mat-card-content class="card-content overflow-hidden mb-3" style="min-height: 350px;">
        <div class="mt-3 mx-2">
          <div class="row my-4 no-gutters overflow-hidden">
            <div class="col align-self-center">
              <h2>
                {{selectedApplier?.applier.user.first_name }} {{selectedApplier?.applier.user.last_name}}
              </h2>
              <p>Geburtsjahr: {{selectedApplier?.applier.birth_year}}</p>
            </div>
            <div class="col align-self-center text-right pr-5">
              Einsatzbereit:
              <mat-chip-list aria-label="Fish selection">
                <mat-chip>
                  {{selectedApplier?.applier.start_work.substr(8, 10)}}.
                  {{selectedApplier?.applier.start_work.substr(5, 2)}}.
                  {{selectedApplier?.applier.start_work.substr(0, 4)}}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <div class="chip-details">
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Wohnort</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2">{{selectedApplier?.applier.postcode}}{{', ' + selectedApplier?.applier.city}}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Führerscheinklasse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.license}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Kennzahl 95</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2" *ngIf="selectedApplier?.applier.professional_license">Ja</mat-chip>
                  <mat-chip class="mx-2" *ngIf="!selectedApplier?.applier.professional_license">Nein</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Deutschkenntnisse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.language}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Berufserfahrung</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.work_experience + '  Jahre'}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Letzte Arbeitgeber</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_1_year + ' : ' + selectedApplier?.applier.employer_1 }} </mat-chip>

                </mat-chip-list>
                <br>
                <mat-chip-list aria-label="Fish selection" style="margin-top: 5px;">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_2_year + ' : ' + selectedApplier?.applier.employer_2 }} </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Erfahrung mit folg. Fahrzeugtypen</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let vehicalExperiance of selectedApplier?.applier.vehicle_experience">
                  <mat-chip class="mx-2 mb-2"> {{getVehicleExperience(vehicalExperiance)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Zusätzliche Führerscheine</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let additionalLicense of selectedApplier?.applier.additional_license">
                  <mat-chip class="mx-2 mb-2">{{getAdditionalLicense(additionalLicense)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Gewünschte Schicht</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection" *ngFor="let shift of selectedApplier?.applier.shift">
                  <mat-chip class="mx-2 mb-2"> {{getShift(shift)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class=" col-lg-4  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Gewünschtes Tourengebiet</b></div>
              <div class="col-lg-6">
                <mat-chip-list aria-label="Fish selection" *ngFor="let tours of selectedApplier?.applier.tours">
                  <mat-chip class="mx-2 mb-2"> {{getTour(tours)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-tab>

    <mat-tab label="Nachrichten" class="-align-center">
      <app-news  addText={{addText}} matchId="{{matchId}}" applierId={{applierId}} jobId={{jobId}} isStatus="{{selectedApplier?.status}}">
      </app-news>
      <!-- <div class="container my-3">
        <form class="bg-light" [formGroup]="chatForm" (ngSubmit)="addMessage()">
          <div class="form-group">
            <label for="editor">Neue Nachricht schreiben</label>
            <button mat-button type="button" class="mr-1 float-right" color="#2fa16f"
              style="margin-top: -10px; border: 1px solid #2fa16f; color:#2fa16f;  cursor: pointer;"
              (click)="openCallDialog()">
              <i class="fas fa-lg fa-phone-alt"> </i><span  class="{{isMobile == true ? 'hideLabelForPhone'  : '' }}" style="color: black;">&nbsp;&nbsp;Telefonnummer anzeigen</span>
            </button>
            <ckeditor [editor]="Editor" formControlName="message" id="editor"></ckeditor>
          </div>
          <button mat-raised-button color="primary" class="w-100" [disabled]=chatForm.invalid>
            <i class="fas fa-envelope mr-1" aria-hidden="true"></i>
            Nachricht senden
          </button>
        </form>
        <hr>

        <div class="col-12 px-0">
          <div class="px-1 py-1 chat-box bg-white">
            <div *ngFor="let message of messages?.results">
              <div *ngIf="loggedInUserId === message?.created_by" class="media w-100 mb-3"><img
                  src="https://image.flaticon.com/icons/png/512/1962/1962551.png" alt="user" width="50"
                  class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                      Uhr</p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
              <div *ngIf="loggedInUserId !== message?.created_by" class="media w-100 mb-3"><img
                  src="https://www.clipartmax.com/png/middle/171-1717870_stockvader-predicted-cron-for-may-user-profile-icon-png.png"
                  alt="user" width="50" class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                    </p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </mat-tab>
  </mat-tab-group>
</mat-card>

<mat-card *ngIf="!isSpinner && confirmCuserDate" class="application-details">
  <mat-tab-group mat-stretch-tabs [selectedIndex]="selected.value" (selectedTabChange)="tabClick($event)">
    <mat-tab label="Bewerbungsprozess">
      <mat-card-content class="card-content overflow-hidden mb-3" style="min-height: 350px;">
        <form [formGroup]="meetingForm" (ngSubmit)="createInterViewPraposalV2()">
          <app-status-bar *ngIf="!isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}">
          </app-status-bar>
          <app-status-bar *ngIf="isMobile" matchId="{{matchId}}" isStatus="{{selectedApplier?.status}}">
          </app-status-bar>
          <!-- Arrange Interview -->
          <div class="row mt-5">
            <div class="col">
              <h5 class="ml-1">Das persönliche Kennenlernen hat bereits stattgefunden?</h5>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <mat-card-subtitle class="ml-1">Dann tragen Sie hier den Termin ein.
              </mat-card-subtitle>
            </div>
          </div>
          <div class="row" *ngFor="let index of datePickedCountArray">
            <div class="col ">
              <mat-form-field appearance="outline" class="col-md-4 col-lg-4 col-sm-12">
                <mat-label>Datum wählen</mat-label>
                <input matInput [matDatepicker]="picker" (focus)="picker.open()" (click)="picker.open()"
                  formControlName="meetingDate_{{index}}" (dateChange)="onDateChange()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <mat-form-field *ngIf="index == 1" appearance="outline" class="col-md-3 col-lg-3 col-sm-12 " style="">
                <mat-label>Startzeit</mat-label>
                <mat-select (selectionChange)="setEndTime($event,index)" formControlName="startTime_{{index}}">
                  <mat-option *ngFor="let time of startTimes_1; index as i; first as isFirst"
                    [selected]="time.displayTime == 0800" [value]="time.hours + time.minutes">
                    {{time.displayTime}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field *ngIf="index == 1" appearance="outline" class="col-md-3 col-lg-3 col-sm-8" style=" ">
                <mat-label>Ende</mat-label>
                <mat-select formControlName="endTime_{{index}}">
                  <mat-option *ngFor="let time of endTimes_1; index as i;" [value]="time.hours + time.minutes">
                    {{time.displayTime}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </div>

          <div class="row mt-3 hide">
            <div class="col mt-sm-3">
              <h6>Ort des Interviews: <span class="text-danger"> *</span></h6>
              <mat-form-field appearance="outline" class="col-md-10 col-sm-12 col-lg-10  ml-lg-n2">
                <mat-label>Adresse suchen</mat-label>
                <input class="ml-lg-0" matInput ngx-google-places-autocomplete [options]='options'
                  formControlName="address" value="{{address}}" (onAddressChange)="AddressChange($event)" />
                <mat-icon matSuffix><i class="fas fa-search"></i></mat-icon>
              </mat-form-field>
            </div>
          </div>

          <div class="row hide">
            <div class="col px-3 hide">
              <mat-form-field appearance="outline" class="col-md-10 col-sm-12 col-lg-10  ml-lg-n2" style="">
                <mat-label>Straße, Nr.</mat-label>
                <input matInput formControlName="street" class="{{ IsStreetNumberMsg ? 'streetReqired':''}}">
              </mat-form-field>
            </div>
          </div>
          <p class="text-danger hide ml-2 streetValidation" *ngIf="IsStreetNumberMsg">Bitte wähle eine Hausnummer</p>
          <div class="row hide">
            <div class="col ">
              <mat-form-field appearance="outline" class="col-md-3 col-sm-4 col-lg-3  ml-lg-n2" style="">
                <mat-label>Postleitzahl</mat-label>
                <input matInput formControlName="postcode" (ngModelChange)="checkPostCode($event)"
                  (focus)="checkPostCode($event)" (change)="checkPostCode($event)">
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-md-7 col-sm-6 col-lg-7" style="">
                <mat-label>Stadt</mat-label>
                <input matInput class="" formControlName="city">
              </mat-form-field>
            </div>
          </div>
          <mat-error *ngIf="isPostcodeValid" class="ml-1">
            <strong>Bitte gebe eine deutsche Postleitzahl ein</strong>
          </mat-error>
          <p style="" class="ml-1"><b>Notizen zum persönlichen Interview (optional) <i
                matTooltip="Hier können Sie wichtige Informationen zum Bewerber aus dem persönlichen Interview festhalten"
                matTooltipPosition="above" class="fas fa-question-circle"></i><span class="text-danger"></span>:</b></p>
          <mat-form-field appearance="outline" class="col-md-10 col-sm-12 col-lg-10  ml-lg-n2 ">
            <mat-label><b>Notizen zum persönlichen Interview</b> (optional):</mat-label>
            <textarea matInput formControlName="messageOther" #messageOther maxlength="300"></textarea>
            <mat-hint align="end">{{messageOther.value.length}} / 300</mat-hint>
          </mat-form-field>
          <div class="row mt-2 ml-1 hide">
            <div class="col">
              <p class="text-danger">Mit einem Sternchen (*) markierte Felder sind Pflichtfelder.</p>
            </div>
          </div>

          <mat-card-actions class="{{isMobile == true ? 'row no-gutters ' : 'row no-gutters' }}">
            <button mat-flat-button color="primary" [disabled]="meetingForm.invalid"
              class="{{isMobile == true ? 'col-md-10 mx-1' : 'col mx-1' }}">
              <span class="icon-text-button">Termin speichern</span>
            </button>
          </mat-card-actions>
        </form>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Bewerberdetails">
      <mat-card-content class="card-content overflow-hidden" style="min-height: 350px;">
        <div class="mt-3 mx-2">
          <div class="row my-4 no-gutters overflow-hidden">
            <div class="col align-self-center">
              <h2>
                {{selectedApplier?.applier.user.first_name }} {{selectedApplier?.applier.user.last_name}}
              </h2>
              <p>Geburtsjahr: {{selectedApplier?.applier.birth_year}}</p>
            </div>
            <div class="col align-self-center text-right pr-5">
              Einsatzbereit:
              <mat-chip-list aria-label="Fish selection">
                <mat-chip>
                  {{selectedApplier?.applier.start_work.substr(8, 10)}}.
                  {{selectedApplier?.applier.start_work.substr(5, 2)}}.
                  {{selectedApplier?.applier.start_work.substr(0, 4)}}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <div class="chip-details">
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Wohnort</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2">{{selectedApplier?.applier.postcode}}{{', ' + selectedApplier?.applier.city}}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Führerscheinklasse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.license}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Kennzahl 95</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2" *ngIf="selectedApplier?.applier.professional_license">Ja</mat-chip>
                  <mat-chip class="mx-2" *ngIf="!selectedApplier?.applier.professional_license">Nein</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Deutschkenntnisse</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.language}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Berufserfahrung</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.work_experience + '  Jahre'}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Letzte Arbeitgeber</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_1_year + ' : ' + selectedApplier?.applier.employer_1 }} </mat-chip>

                </mat-chip-list>
                <br>
                <mat-chip-list aria-label="Fish selection" style="margin-top: 5px;">
                  <mat-chip class="mx-2"> {{selectedApplier?.applier.employer_2_year + ' : ' + selectedApplier?.applier.employer_2 }} </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Erfahrung mit folg. Fahrzeugtypen</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let vehicalExperiance of selectedApplier?.applier.vehicle_experience">
                  <mat-chip class="mx-2 mb-2"> {{getVehicleExperience(vehicalExperiance)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Zusätzliche Führerscheine</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection"
                  *ngFor="let additionalLicense of selectedApplier?.applier.additional_license">
                  <mat-chip class="mx-2 mb-2">{{getAdditionalLicense(additionalLicense)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-4 col-lg-4 col-sm-12 text-nowrap"><b>Gewünschte Schicht</b></div>
              <div class="col-md-6 col-sm-12">
                <mat-chip-list aria-label="Fish selection" *ngFor="let shift of selectedApplier?.applier.shift">
                  <mat-chip class="mx-2 mb-2"> {{getShift(shift)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div class="row my-2">
              <div class=" col-lg-4  {{ screenWidth > 1020 && screenWidth < 1070 ? 'text-wrap' : 'text-nowrap' }}"><b>Gewünschtes Tourengebiet</b></div>
              <div class="col-lg-6">
                <mat-chip-list aria-label="Fish selection" *ngFor="let tours of selectedApplier?.applier.tours">
                  <mat-chip class="mx-2 mb-2"> {{getTour(tours)}}</mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Nachrichten" class="-align-center">
      <app-news  addText={{addText}} matchId="{{matchId}}" applierId={{applierId}} jobId={{jobId}} isStatus="{{selectedApplier?.status}}">
      </app-news>
      <!-- <div class="container my-3">
        <form class="bg-light" [formGroup]="chatForm" (ngSubmit)="addMessage()">
          <div class="form-group">
            <label for="editor">Neue Nachricht schreiben</label>
            <button mat-button type="button" class="mr-1 float-right" color="#2fa16f"
              style="margin-top: -10px; border: 1px solid #2fa16f; color:#2fa16f;  cursor: pointer;"
              (click)="openCallDialog()">
              <i class="fas fa-lg fa-phone-alt"> </i><span  class="{{isMobile == true ? 'hideLabelForPhone'  : '' }}" style="color: black;">&nbsp;&nbsp;Telefonnummer anzeigen</span>
            </button>

            <ckeditor [editor]="Editor" formControlName="message" id="editor"></ckeditor>
          </div>
          <button mat-raised-button color="primary" class="w-100" [disabled]=chatForm.invalid>
            <i class="fas fa-envelope mr-1" aria-hidden="true"> Telefonnummer anzeigen</i>
            Nachricht senden
          </button>
        </form>
        <hr>

        <div class="col-12 px-0">
          <div class="px-1 py-1 chat-box bg-white">
            <div *ngFor="let message of messages?.results">
              <div *ngIf="loggedInUserId === message?.created_by" class="media w-100 mb-3"><img
                  src="https://image.flaticon.com/icons/png/512/1962/1962551.png" alt="user" width="50"
                  class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                      Uhr</p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
              <div *ngIf="loggedInUserId !== message?.created_by" class="media w-100 mb-3"><img
                  src="https://www.clipartmax.com/png/middle/171-1717870_stockvader-predicted-cron-for-may-user-profile-icon-png.png"
                  alt="user" width="50" class="rounded-circle}}">
                <div class="media-body ml-3">
                  <div class="bg-light rounded py-2 px-3 mb-2">
                    <p class="small text-muted"><span class="mr-2"><b>{{message.name}}</b></span>
                      {{  message.created_at|date:'dd MMM yyyy, H:mm':'locale'}}
                    </p>
                    <p class="text-small mb-0 text-muted" [innerHTML]="message.message"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </mat-tab>
  </mat-tab-group>
</mat-card>
