<mat-dialog-content class="mat-typography">
  <p *ngIf="!isEdit && !isConfirm" >Job wurde erfolgreich erstellt</p>
  <p *ngIf="isEdit">Der Job wurde erfolgreich aktualisiert</p>
  <p *ngIf="isConfirm">Willst du die Stelle wirklich ändern?</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button  mat-raised-button color="primary"
           *ngIf="isConfirm"
           [mat-dialog-close]="true"
           (click)="doConfirm()"
           cdkFocusInitial>Ja</button>
  <!--  <button  mat-raised-button color="accent" mat-dialog-close>Abbrechen</button>-->
  <button  mat-raised-button color="primary"
           [mat-dialog-close]="true"
           (click)="doRefresh()"
           *ngIf="!isConfirm"
           cdkFocusInitial>OK</button>
</mat-dialog-actions>
