<form [formGroup]="toursFormGroup">
    <p class="h3">Welche Touren willst du fahren?</p>
    <div class="question-content">
      <div class="mat-check-group row">
        <div class="col-lg-6">
          <mat-checkbox checked labelPosition="before" (change)="checkLocalTransport($event)" (change)="onChange($event)" formControlName="local" value="local">Nahverkehr
          </mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox checked labelPosition="before" formControlName="national" (change)="onChange($event)" value="national">Fernverkehr (national)</mat-checkbox>
        </div>
        <div class="col-lg-6">
          <mat-checkbox checked labelPosition="before" formControlName="international" (change)="onChange($event)" value="international">Fernverkehr (international)</mat-checkbox>
        </div>
      </div>
    </div>
    <div class="button-area row" *ngIf="isEnable">
      <div class="col-lg-6"></div>
      <button mat-flat-button class="col" mat-button matStepperPrevious>
        <span class="icon-text-button">Zurück</span>
      </button>
      <button mat-flat-button [disabled]="toursFormGroup.invalid" class="col" color="primary" mat-button matStepperNext>
        <span class="icon-text-button">Weiter</span>
        <span class="fas fa-chevron-right"></span>
      </button>
    </div>
  </form>
