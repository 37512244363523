<!-- <mat-spinner *ngIf="isSpinner"></mat-spinner> -->
<!-- <div class="row not-scrollable" [class.isMobile]="isMobile" *ngIf="isMatchFound"> -->
<div class="{{isMobile?'not-scrollable':'row not-scrollable'}}" *ngIf="isMatchFound">
  <div class="{{isMobile?'not-scrollable-content mt-5 mb-5':'not-scrollable-content'}}">
    <div style="{{isMobile ? 'margin-bottom: 5rem!important;' : '' }}" class="{{isMobile?'col scrollables mb-lg-5 ':'col scrollable'}}" [ngClass]="{'col':!matchId, 'col-lg-4 col-md-4 col-sm-12':matchId}"
         *ngIf="matchId && !isMobile  || !matchId">
      <app-user-match matchId="{{matchId}}"></app-user-match>
    </div>
    <div class="col scrollable" *ngIf="matchId">
      <app-user-details matchId="{{matchId}}"></app-user-details>
    </div>
  </div>
</div>

<!-- <div class="{{isMobile?'not-scrollable':'row not-scrollable'}}" [class.isMobile]="isMobile">
  <div class="{{isMobile?'not-scrollable-content mt-5 mb-5':'not-scrollable-content'}}" style="">
    <div style="{{isMobile ? 'margin-bottom: 5rem!important;' : '' }}" class="{{isMobile?'col scrollable mb-lg-5 ':'col scrollable'}}" [ngClass]="{'col':!matchId, 'col-lg-4':matchId}" *ngIf="matchId && !isMobile  || !matchId">
      <app-user-match matchId="{{matchId}}"></app-user-match>
    </div>
    <div class="col scrollable" *ngIf="matchId">
      <app-user-details matchId="{{matchId}}"></app-user-details>
    </div>
  </div>
</div> -->


<div *ngIf="!isMatchFound">
  <h1 class="text-center" style="{{isMobile?'margin-top: 5rem;font-size:25px;':'margin-top: 10rem;'}}">Momentan haben wir keine passende Stelle für dich!</h1>
</div>
