<circle-progress
  [percent]=matchPercent
  [radius]="radius"
  [outerStrokeWidth]=outerStrokeWidth
  [innerStrokeWidth]=innerStrokeWidth
  [outerStrokeColor]="'#2fa16f'"
  [innerStrokeColor]="'#eaddd5'"
  [animation]="true"
  [animationDuration]="200"
  [showSubtitle]="false"
  [titleFontSize]="titleFontSize"
></circle-progress>
