<mat-spinner *ngIf="isSpinner"></mat-spinner>
<div *ngIf="!matchId && !isMatchFound" class="text-center">
  <h1 *ngIf="!matchId" class="text-center" style="{{isMobile?'margin-top: 5rem;font-size:25px;':'margin-top: 10rem;'}}">Du hast keine offene Bewerbung!</h1>
</div>
<div class="{{isMobile?'not-scrollable':'row not-scrollable'}}" [class.isMobile]="isMobile">
  <div class="{{isMobile?'not-scrollable-content mt-5 mb-5':'not-scrollable-content'}}" style="">
    <div style="{{isMobile ? 'margin-bottom: 5rem!important;' : '' }}" class="{{isMobile?'col scrollables mb-lg-5 ':'col scrollable'}}" [ngClass]="{'col':!matchId, 'col-lg-4':matchId}" *ngIf="matchId && !isMobile  || !matchId">
      <app-list-item matchId="{{matchId}}" class="justify-content-center"></app-list-item>
    </div>
    <div class="col scrollable" *ngIf="matchId">
      <app-application-details matchId="{{matchId}}"></app-application-details>
    </div>
  </div>
</div>
