<div *ngIf="messageType == 'addInterview'">
  <mat-dialog-content>
    <p >Du hast dem Unternehmen einen Termin für ein persönliches Interview
      bestätigt</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [mat-dialog-close]="true">OK
    </button>
  </mat-dialog-actions>
</div>
<div *ngIf="messageType == 'reSchedule'">
  <mat-dialog-content>
    <p>Du hast das Unternehmen darum gebeten, dir neue Terminvorschläge zu senden</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [mat-dialog-close]="true">OK</button>
  </mat-dialog-actions>
</div>

<div *ngIf="messageType == 'cencel-popup'">
  <mat-dialog-content>
    <p>Willst du dem Unternehmen tatsächlich absagen?</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="accent" (click)="cancel()" [mat-dialog-close]="true">Abbrechen</button>
    <button mat-raised-button color="warn" (click)="confirm()" [mat-dialog-close]="true" cdkFocusInitial>Absagen</button>
  </mat-dialog-actions>
</div>

<div *ngIf="messageType == 'confirm-popup'">
  <mat-dialog-content>
    <p>Willst du dem Unternehmen tatsächlich zusagen?</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="accent" (click)="cancel()" [mat-dialog-close]="true">Abbrechen</button>
    <button mat-raised-button color="primary" (click)="confirm()" [mat-dialog-close]="true" cdkFocusInitial>Zusagen</button>
  </mat-dialog-actions>
</div>