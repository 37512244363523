<div class="row" *ngIf="index < 13">
  <div class="col-1">
    <button mat-flat-button style="display:inline-block" (click)="goToBack()">
      <span class="fas fa-2x fa-angle-left" style="display:inline-block; font-size: 20px;"></span>
    </button>
  </div>
  <div class="col">
    <div class="row" style="margin-left: 37px !important; font-size: 16px;
    font-weight: lighter;">{{index}} von 12 beantwortet </div>
    <div class="row" style="margin-left: 36px !important;"><mat-progress-bar [value]="process" style="display:inline-block;margin:0px 5px"></mat-progress-bar></div>
  </div>
  <div class="col-1">
    <button mat-flat-button style="display:inline-block; margin-left: -14px " (click)="goToForward()">
      <span class="fas fa-2x fa-angle-right" style="display:inline-block; font-size: 20px;"></span>
    </button>
  </div>
</div>
<!--<div class="row" >-->
<!--  <div class="col-1">-->
<!--    <button mat-flat-button style="display:inline-block" (click)="goToBack()">-->
<!--      <span class="fas fa-2x fa-angle-left" style="display:inline-block; font-size: 20px;"></span>-->
<!--    </button>-->
<!--  </div>-->
<!--  <div class="col">-->
<!--    <div class="row" style="margin-left: 15px !important; font-size: 16px;-->
<!--    font-weight: lighter;">{{index}} von 13 beantwortet </div>-->
<!--    <div class="row" style="margin-left: 12px !important;"><mat-progress-bar [value]="process" style="display:inline-block;margin:0px 5px"></mat-progress-bar></div>-->
<!--  </div>-->
<!--  <div class="col-1">-->
<!--    <button mat-flat-button style="display:inline-block; margin-left: -14px " (click)="goToForward()">-->
<!--      <span class="fas fa-2x fa-angle-right" style="display:inline-block; font-size: 20px;"></span>-->
<!--    </button>-->
<!--  </div>-->
<!--</div>-->
