import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { JobService } from "../../../services/job.service";
import { environment } from "../../../../environments/environment.prod";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit {
  @Input('matchId') matchId: number;
  isMobile: boolean;
  public id: number;
  subscriptions: Array<Subscription> = [];
  applications: any;
  applicationList: any;
  responseData: any;

  constructor(public router: Router, private deviceService: DeviceDetectorService,public route: ActivatedRoute, private jobService: JobService) {
    this.isMobile = this.deviceService.isMobile();
  }

  checkImageExists(url: string) {
    let parts = url.split("/");
    let param = parts[parts.length - 1];
    if (param.length < 5) {
        return false;
    }else{
        return true;
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.id = +params['id'];
      })
    );
    this.getJobs();
  }

  getJobs() {
    this.jobService.getJobsForApplication(environment.STATUS_APPLIED)
      .subscribe(resData => {
        this.responseData = resData;
        this.applicationList = this.responseData.results;

        let applications = [];
        for (const list of this.applicationList) {
          if (list.status >= 2) {
            let applicationObj = {};
            for (const companyList of this.applicationList) {
              if (list.job.company === companyList.company.id) {
                applicationObj = {
                  ...list.job, logo: companyList.company.logo, companyName: companyList.company.name,
                };
              }
            }
            applicationObj = {
              ...list.job,
              ...applicationObj,
              status: list.status,
              application_id: list.id
            }
            applications.push(applicationObj);
          }
        }
        this.applications = applications;
      }, errorMessage => {
        console.log("errorMessage In user-match component");
        console.log(errorMessage);
      });
  }

  getApplicationStatus(status: number) {
    switch (status) {
      case 1:
        return "eingang";
      case 2:
        return "Eingang";
      case 3:
        return "Prüfung";
      case 4:
        return "persönliches Interview";
      case 5:
        return "persönliches Interview";
      case 41:
        return "persönliches Interview";
      case 42:
        return "persönliches Interview";
      case 43:
        return "persönliches Interview";
      case 6:
        return 'Entscheidung';
      case 7:
        return 'Entscheidung';
      case 8:
        return 'Bewerber abgesagt';
      case 9:
        return 'Bewerbung zurückgezogen';
      case 11:
        return 'Match abgelehnt';
      case 12:
        return 'beidseitige Zusage';
      default:
        return "N/A";
    }
  }

  getApplicationStatusForProcessBar(status: number) {
    if (status == 2) {
      return 25;
    } else if (status == 3) {
      return 50;
    } else if (status == 4 || status == 5 || status > 40) {
      return 75;
    } else if (status == 6 || status == 7 || status == 12) {
      return 100;
    }
  }

  getApplicationStatusForProcessBarTextDisplay(status: number) {
    if (status == 2) {
      return 1;
    } else if (status == 3) {
      return 2;
    } else if (status == 4 || status == 5 || status > 40) {
      return 3;
    } else if (status == 6 || status == 7 || status == 12) {
      return 4;
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
}
