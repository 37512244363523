<div *ngFor="let match of jobs?.match" class="p-1">
    <mat-card class="" routerLink="/job-profile/{{jobId}}/{{match.applier.id}}" (click)=handleClick() style="{{match.applier.id == applierId ? 'border : 2px solid #2fa16f;' : '' }}">
      <div class="tool-tip">
        <mat-card-header>
          <mat-card-subtitle class="mb-0">
            <span class="fas fa-flag-checkered"></span>
            {{getApplicationStatus(match?.status)}} |
            <span class="fas fa-map-marker-alt"></span>
            {{match.applier?.city}}
          </mat-card-subtitle>
          <mat-card-title>
            <div class="row no-gutters">
              <div class="col align-self-center">
                {{match.applier.user.first_name}} {{match.applier.user.last_name}}
              </div>
              <div class="col-2 align-self-center">
                <app-circle-progress
                  [matchPercent]="match?.rating"
                  [outerStrokeWidth]="3.2"
                  [innerStrokeWidth]="1.6"
                  [radius]="20"
                  [titleFontSize]="12"
                ></app-circle-progress>
              </div>
            </div>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="!currentMsgFromChild1ToChild2" class="row no-gutters">
            <div *ngIf="match.status  < 8 || match.status > 40  || match.status == 12" class="col">
              Status {{getApplicationStatusForProcessBarTextDisplay(match.status)}} von 4 erreicht.
              <mat-progress-bar  mode="determinate" value="{{ getApplicationStatusForProcessBar(match.status) }}"></mat-progress-bar>
            </div>
            <div *ngIf="match.status == 8 && match.status < 40" class="col text-center">
              <b>Bewerber abgesagt</b>
            </div>
             <div *ngIf="match.status == 9 && application?.status < 40" class="col text-center">
              <b>Bewerbung zurückgezogen</b>
            </div>

          </div> 
          <div *ngIf="currentMsgFromChild1ToChild2" class="row no-gutters">
            <div *ngIf="match.status  < 8 || match.status > 40  || match.status == 12" class="col">
              Status {{getApplicationStatusForProcessBarTextDisplay(currentMsgFromChild1ToChild2)}} von 4 erreicht.
              <mat-progress-bar  mode="determinate" value="{{ getApplicationStatusForProcessBar(currentMsgFromChild1ToChild2) }}"></mat-progress-bar>
            </div>
          </div>
          <!--        <mat-progress-bar *ngIf="id" mode="determinate" value="{{getApplicationStatusForProcessBar(application.status)}}"></mat-progress-bar>-->
        </mat-card-content>
        <mat-card-actions class="row no-gutters pb-0" style="{{match.applier.id == applierId ? 'margin-bottom: 0;' : 'margin-bottom: 0;' }}">
          <button mat-button class="col"  (click)=handleClick() routerLink="/job-profile/{{jobId}}/{{match.applier.id}}">
            <span class="fas fa-1-5x fa-info-circle"></span> <span class="icon-text-button">Details</span>
          </button>
        </mat-card-actions>
      </div>
    </mat-card>
  </div>
