import { Component, Inject, Input, OnChanges, Output, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { DashboardService } from "../../../../services/company/dashboard.service";
import { JobService } from "../../../../services/company/job.service";
import { LanguageModel } from "src/app/models/language.model";
import { MatTabGroup } from "@angular/material/tabs";
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MessageService } from "../../../../services/message.service";
import { AuthService } from "../../../../services/auth.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { DialogCall } from "../../../applications/application-details/application-details.component";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CreateJobsComponent } from "../../jobs/create-jobs/create-jobs.component";
import { JobCreateSuccessDialog } from "../../jobs/create-jobs/questionnaire-form/questionnaire-form.component";
import { MatCalendarCellCssClasses } from "@angular/material/datepicker";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { formatDate } from "@angular/common";
import { ApplicantService } from "../../../../services/applicant.service";
import { ApplicantStatusModel } from "../../../../models/applicant-status.model";
import { DeviceDetectorService } from "ngx-device-detector";
const codes = require("german-postal-codes");

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnChanges {
  isMobile: boolean;
  @Input("jobId") jobId: number;
  @Input("addText") addText: any;
  @Input("applierId") applierId: number;
  @ViewChild("tabs", { static: false }) tabGroup: MatTabGroup;
  public Editor = ClassicEditor;
  applicationStatus = 2;
  applicantDetails: any;
  errorMessage: string;
  selected = new FormControl(0);
  selectedApplier: any;
  isError = false;
  chatForm: FormGroup;
  meetingForm: FormGroup;
  messages: any;
  loggedInUserId: number;
  isRead = false;
  chatTextOnCallFail: string;
  isSpinner = true;
  panelOpenState = false;
  @Input('matchId') matchId: number;
  @Input('jobTitle') jobTitle: any;
  @Input('isStatus') isStatus: any;
  options = {
    componentRestrictions: {
      country: ["de"],
    },
  };
  address: string;
  messageCount = 0;
  formData: any;
  chatFormData: any;
  proposalDetails: any;
  submitted = false;
  mobileCurentStatus: any = "";
  isPostcodeValid = false;
  viewConfirm = false;
  @Output() postMessageEvent = new EventEmitter<any>();
  @Input() msgFromParent1: any[];
  @Input() currentMsgFromChild2ToChild1: any[];
  @Output() msgToSibling = new EventEmitter<any>();
  isImgError = false;
  isImgSelected = false;
  imgErrorMessage = '';
  filename = '';
  filesize = '';
  imagePreview: string;
  imgSrc = './assets/no-image.jpeg';
  isFileIsPdf = false;
  constructor(
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private messageService: MessageService,
    private applicantService: ApplicantService,
    private authService: AuthService,
    private router: Router,
    private jobService: JobService,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog
  ) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {
    this.getProfile();
    if (this.router.routerState.snapshot.url == `/job-profile/chat/${this.jobId}/${this.applierId}`) {
      this.selected.setValue(2);
      this.isRead = true;
    }
    // this.chatForm = new FormGroup({
    //   message: new FormControl(null, Validators.required),
    //   logo: new FormControl(null),
    // });

    this.chatForm = this.formBuilder.group({
      message: ['', [Validators.required]],
      logo: ['']
    });

  }

  onButtonClick() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate([`/job-profile/${this.jobId}/${this.applierId}`]);
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  markUserAsread() {
    this.dashboardService.readUser(this.matchId).subscribe(
      (resData) => {
        //  console.log("user mark as read");
      },
      (error) => {
        console.log("Error occurred while reading user");
        console.log(error);
      }
    );
  }

  getProfile() {
    let responseData;
    this.dashboardService.getJobProfile(this.jobId).subscribe(
      (resData) => {
        responseData = resData;
        this.isSpinner = false;
        // @ts-ignore
        for (let match of responseData?.match) {
          if (match.applier.id == this.applierId) {
            this.matchId = match.id;
            this.selectedApplier = match;
            this.getChatHistory(false);
            this.markUserAsread();
            let languageId = match.applier.language[0];
          }
        }
        if(this.addText == true || this.addText == "true"){
          let companyUser = JSON.parse(localStorage.getItem("userData"));
          let userName = companyUser.first_name + " " + companyUser.last_name;
          this.isRead = true;
          this.selected.setValue(1);
          let chatTextOnReject = `
          <p>Guten Tag ${this.selectedApplier?.applier.user.first_name +
            " " +
            this.selectedApplier?.applier.user.last_name
            },</p>
          <p>wir bedanken uns für deine Bewerbung und das damit zum Ausdruck gebrachte Interesse an unserem Unternehmen.</p>
          <p>Nach eingehender Prüfung müssen wir dir leider mitteilen, dass wir dir zum jetzigen Zeitpunkt keine Stelle anbieten können, die deinen Wünschen und Qualifikationen entspricht.</p>
          <p>Wir bedauern Dir keine positive Nachricht geben zu können und hoffen, dass Du bald eine deinen Vorstellungen entsprechende Stelle findest.</p>
          <p>Viele Grüße,</p>
          <p>${userName}</p>
              `;
          this.chatForm.patchValue({
            message: chatTextOnReject,
          });
        }else{
          this.chatForm.patchValue({ message: null });
        }
      },
      (errorMessage) => {
        this.errorMessage = errorMessage;
        console.log(this.errorMessage);
      }
    );
  }


  tabClick(tab) {
    const selectedTab = tab.tab.textLabel;
    if (selectedTab === "Nachrichten" || selectedTab === '') {
      this.isRead = true;
      this.getChatHistory(true);
      setTimeout(() => {
        this.messageCount = 0;
      }, 4000);
    } else {
      this.selected.setValue(0);
    }
  }


  getChatHistory(type) {
    let res;
    if (this.matchId) {
      this.messageCount = 0;
      this.messageService.getMessages(this.matchId).subscribe(
        (getResData) => {
          this.isError = false;
          this.errorMessage = "";
          this.messages = getResData;
          this.isSpinner = false;
          this.loggedInUserId = this.authService.getUserData().id;
          // this.messages?.results.forEach(val => {
          //   if (val.is_read == false) {
          //     this.messageCount++;
          //   }
          // });
          let applierUser = JSON.parse(localStorage.getItem('userData'));
          for (let message of this.messages?.results) {
            message.isPdf = false;
            message.fileName = "";
            if(message.file){
              message.isPdf = this.checkPdfExists(message.file)
              message.fileName = this.getFileNameFromUrl(message.file)
            }
            if (message.is_read == false && applierUser.id != message.created_by) {
              this.messageCount++;
            }
          }
          if (this.messageCount > 0 && type == true) {
            this.messageService.readChatMessages(this.matchId).subscribe(
              (resData) => { },
              (error) => {
                console.log("Error occurred while reading chat ");
              }
            );
          }
        },
        (errorMessage) => {
          this.isError = true;
          this.errorMessage = errorMessage;
          console.log(this.errorMessage);
        }
      );
    }
  }

  checkPdfExists(url: string) {
    let parts = url.split(".");
    let param = parts[parts.length - 1];
    if (param=="pdf") {
        return true;
    }else{
        return false;
    }
  }

  getFileNameFromUrl(url: string) {
    let parts = url.split("/");
    let param = parts[parts.length - 1];
    return param;
  }

  addMessage() {
    let id = this.matchId
    this.chatFormData = new FormData();
    if(this.isImgSelected){
      let logoType = typeof this.chatForm.get('logo').value;
      if (logoType == "object") {
        this.chatFormData.append('file', this.chatForm.get('logo').value);
      }
    }
    this.chatFormData.append('message', this.chatForm.value.message);
    this.chatFormData.append('match_id', this.matchId);

    if(this.chatForm.get('logo').value || this.chatForm.value.message){
      this.chatForm.reset();
        this.messageService.addMessage(this.chatFormData, id).subscribe(
          (postResData) => {
            this.getChatHistory(true);
            this.isImgSelected = false;
            this.addText = false;
            this.filename = "";
            this.filesize = "";
          },
          (errorMessage) => {
            this.isError = true;
            this.errorMessage = errorMessage;
            console.log(this.errorMessage);
          }
        );
    }

    // let message = this.chatForm.value.message;
    // let postData = {
    //   match_id: id,
    //   message: message,
    // };
    // this.chatForm.reset();
    // this.messageService.addMessage(postData, id).subscribe(
    //   (postResData) => {
    //     this.getChatHistory(true);
    //   },
    //   (errorMessage) => {
    //     this.isError = true;
    //     this.errorMessage = errorMessage;
    //     console.log(this.errorMessage);
    //   }
    // );
  }

  changeTab() {
    this.isRead = true;
    this.selected.setValue(1);
  }

  checkFileIsPdf(fileName) {

    // this.isFileIsPdf = true;
  }

  openCallDialog() {
    var updatePhoneNumber = this.authService.getUserData();
    updatePhoneNumber.phone_number = this.selectedApplier?.company?.phone_number;
    localStorage.setItem("userData", JSON.stringify(updatePhoneNumber));

    const dialogRef = this.dialog.open(DialogCall, {
      width: "250px",
      data: {
        applier: this.selectedApplier.applier,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let companyUser = JSON.parse(localStorage.getItem("userData"));
        this.chatTextOnCallFail = `Hallo ${this.selectedApplier.applier.user.first_name +
          " " +
          this.selectedApplier.applier.user.last_name
          }, gerne würden wir Sie telefonisch sprechen, konnten Sie jedoch nicht erreichen.
        Bitte rufen Sie uns unter folgender Nummer zurück: ${companyUser.phone_number
          // Bitte rufen Sie uns unter folgender Nummer zurück: ${this.selectedApplier.applier.phone_number
          }`;
        this.chatForm.patchValue({
          message: this.chatTextOnCallFail,
        });
      }
    });
  }


  gotoChatTab() {
    this.selected.setValue(2);
  }

  handleClick() {
    this.mobileCurentStatus = "details";
  }

  deleteImage(){
    this.chatForm.get('logo').setValue(null);
    this.imgSrc = '';
    this.isImgSelected = false;
    this.filesize = this.filename = '';
  }

  onImagePicked(event) {
    this.isImgError = false;
    this.isImgSelected = true;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.filename = file.name;
      this.filesize = file.size;
      if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png" && file.type != "application/pdf") {
        this.isImgError = true;
        this.imgErrorMessage = 'Bitte wählen Sie den Dateityp jpeg, png, pdf.';
        return true
      }
      if (file.size <= 2000000) {
        // this.chatForm.controls['message'].setErrors({ 'incorrect': false });
        // this.chatForm.get('message').clearValidators();
        // this.chatForm.get('message').updateValueAndValidity();
        // this.chatForm.patchValue({
        //   message: " ",
        // });
        this.isImgError = false;
        this.imgErrorMessage = '';
        const reader = new FileReader();
        this.chatForm.get('logo').setValue(file);
        reader.onload = () => {
          this.imagePreview = reader.result as string;
          this.imgSrc = this.imagePreview;
        }
        reader.readAsDataURL(file);
      } else {
        this.isImgError = true;
        this.chatForm.get('logo').setValue(null);
        this.imgSrc = '';
        this.isImgSelected = false;
        this.filesize = this.filename = '';
        this.imgErrorMessage = 'Die Logo-Größe sollte weniger als 2 MB betragen.';
      }
    }
  }

}

