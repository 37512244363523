<mat-spinner *ngIf="isSpinner"></mat-spinner>

<div *ngFor="let job of jobs">
  <mat-card class="match-card" style="{{job.matchId == matchId ? 'border : 2px solid #2fa16f;' : '' }}">
    <div routerLink="/dashboard/{{job.matchId}}" class="tool-tip">
      <app-circle-progress *ngIf="!isMobile" style="height: 50px;" class="float-right" [matchPercent]=job.matchPercent [outerStrokeWidth]="6.4"
        [innerStrokeWidth]="3.2" [radius]="30" [titleFontSize]="20"></app-circle-progress>
      <app-circle-progress *ngIf="isMobile" style="height: 50px;" class="float-right" [matchPercent]=job.matchPercent [outerStrokeWidth]="5.5"
        [innerStrokeWidth]="2.5" [radius]="25" [titleFontSize]="15"></app-circle-progress>

      <mat-card-title matTooltip="{{ job.title }}" matTooltipPosition="above" style="{{isMobile ? 'font-size: 18px;' : '' }}">
        {{ getUserName(job.title) }}
      </mat-card-title>

      <div class="row ml-0">
        <!-- <div class="{{isMobile == true ? 'col-md-2 ' : 'col-md-2' }}" style="margin-left: -10px;">
          <img src="{{job.logo}}" *ngIf="job.logo" class="mt-1" height="30px" alt="">
        </div> -->
        <div *ngIf="checkImageExists(job.logo)" class="{{isMobile == true ? 'col-md-2 ' : 'col-md-2' }}" style="margin-left: -10px;">
          <img src="{{job.logo}}" *ngIf="job.logo" class="mt-1" height="30px" style="max-width: 55px;" alt="">
        </div>
        <div *ngIf="!checkImageExists(job.logo)" class="{{isMobile == true ? 'col-md-2 ' : 'col-md-2' }}" style="margin-left: -10px;">
          <span class="ml-1 mt-1" height="30px"  alt="">&nbsp;&nbsp;&nbsp;</span>
        </div>
        <div class="col-md-10  ">
          <mat-card-subtitle style="{{isMobile == true && job.logo ? 'margin: -33px;padding-left: 30px;' : '' }}" class="{{isMobile == true ? 'ml-3 mb-1' : 'ml-2 mb-1' }}">{{ job.companyName }}</mat-card-subtitle>
          <mat-card-subtitle style="{{isMobile == true && job.logo ? 'margin: -33px;padding-left: 30px;padding-top: 24px;' : '' }}" class="{{isMobile == true ? 'ml-3' : 'ml-2' }}">{{ job.city }}</mat-card-subtitle>
        </div>
      </div>
    </div>
    <mat-card-actions class="{{isMobile  && job.title.length < 15 ? 'mt-3' : 'row no-gutters' }}">
      <button mat-button class="col" routerLink="/dashboard/{{job.matchId}}">
        <span class="fas fa-1-5x fa-info-circle"></span> <span class="icon-text-button">Details</span>
      </button>
    </mat-card-actions>
  </mat-card>
</div>

<!-- <div class="text-center  mt-2 {{isMobile ? 'mb-5' : ''}} " style="{{isMobile ? 'margin-bottom: 20px;' : ''}}">
  <button mat-flat-button color="primary" *ngIf="responseData?.previous" (click)="getLoadMoredata(responseData?.previous)"><i class="fas fa-arrow-left" aria-hidden="true"> Zurück </i>
  </button>
  <button class="ml-2" mat-flat-button color="primary" *ngIf="responseData?.next" (click)="getLoadMoredata(responseData?.next)"> Weiter <i class="fas fa-arrow-right" aria-hidden="true"></i>
  </button>
</div> -->
<div *ngIf="jobs?.length == 0 && isMobile">
  <h1 class="text-center" style="{{isMobile?'margin-top: 5rem;font-size:25px;':'margin-top: 10rem;'}}">Momentan haben wir keine passende Stelle für dich!</h1>
</div>
