import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

import {Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {ApplicantProfileModel} from "../models/applicant-profile.model";
import {MessageService} from './message.service';
import {environment} from "../../environments/environment";
import {AuthService} from "./auth.service";

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})

export class ProfileService {

  constructor(private http: HttpClient,
              private messageService: MessageService,
              private authService: AuthService) {
  }

  get(url, params = {}) {
    return this.http.get(`${environment.BASE_URL}${url}`, params).pipe(
      catchError(this.handleError)
    );
  }

  /** GET profile from the server */
  getProfile() {
    let httpOptions = this.authService.getHTTPOption();
    return this.get('account/applier/me/', httpOptions);
  }

  handleError(errorRes: HttpErrorResponse) {
    console.log(errorRes);
    let errorMessage = "An unknown error occurred!"
    return throwError(errorMessage)
  }

}

