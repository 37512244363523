// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // BASE_URL: 'http://45.79.248.86:8000/api/v1/',
  BASE_URL: 'https://tpapi.kanhasoftdev.com/api/v1/',
  BASE_URL_WEB: "https://app.truckerpilot.de/",
  GOOGLE_CLIENT_KEY:'991551654700-g4sqpljf41a7ireuirmu1h84bc7pfcis.apps.googleusercontent.com',
  FACEBOOK_CLIENT_KEY:'2641528516177860',
  MOBILE_PREFIX: '+',
  SOCIAL_ACCOUNT_GOOGLE: 1,
  SOCIAL_ACCOUNT_FACEBOOK: 2,
  ROLE_APPLIER: 2,
  ROLE_COMPANY: 3,
  STATUS_MATCHED:1,
  STATUS_APPLIED:2,

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
