<mat-card class="match-details">
  <mat-card-content>
    <app-circle-progress class="float-right"
                         [matchPercent]="90"
                         [outerStrokeWidth]="6.4"
                         [innerStrokeWidth]="3.2"
                         [radius]="40"
                         [titleFontSize]="20"
    ></app-circle-progress>
    <h3 class="h4">Matching Score</h3>
    <p>Der Matching Score zeigt dir auf einen Blick, welche Stellenangebote am besten zu dir passen. Damit sparst du dir
      Zeit und Aufwand, passende Stellenenangebote für dich zu finden. Je größer der Matching Score (max. 100%), desto
      besser passt die Stelle und das Unternehmen zu deinen Wünschen und Vorstellungen.
      <br>
      Klicke auf „Weiter“, um die Jobangebote zu sehen. Du bist nur noch wenige Klicks von deiner ersten Bewerbung
      entfernt. </p>
    <div class="row">
      <div class="col-3"></div>
      <button mat-flat-button color="primary" [disabled]="!isEnable" routerLink="/dashboard" class="col-6">
        <span class="icon-text-button">Weiter</span>
        <!--<span class="fas fa-chevron-right"></span>-->
      </button>
      <div class="col-3"></div>
    </div>
  </mat-card-content>
</mat-card>
