import {Component, OnInit, Input} from '@angular/core';
import {JobModel} from "../../../models/job.model";
import {JobService} from 'src/app/services/job.service';
import {ActivatedRoute, Router} from '@angular/router';
// @ts-ignore
import {DeviceDetectorService} from 'ngx-device-detector';
import {MatDialog} from "@angular/material/dialog";
import {DialogComponent} from "../../common/dialog/dialog.component";
import {LanguageModel} from "../../../models/language.model";
// import { Angulartics2 } from 'angulartics2';
// import { Angulartics2Piwik } from 'angulartics2/piwik';
declare var _paq: any;
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  @Input('matchId') matchId: number;
  public isMobile = false;
  responseData: any;
  applier: any;
  company: any;
  job: any;
  JobStatus:any;
  distance: any;
  germanLanSkill: string;
  isApplicationPage = false;
  isSpinner = true;
  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private jobService: JobService,
              private deviceService: DeviceDetectorService,
              // private angulartics2: Angulartics2,
              // private angulartics2Piwik: Angulartics2Piwik,
              public dialog: MatDialog) {
    this.isMobile = this.deviceService.isMobile();
    if (this.router.routerState.snapshot.url.startsWith("/applications")) {
      this.isApplicationPage = true;
    }
  }

  ngOnInit() {
    this.getJob();
  }

  ngOnChanges() {
    this.getJob();
  }

  getJob(): void {
    const matchId = this.matchId;
    this.jobService.getJob(matchId)
      .subscribe(resData => {
        this.responseData = resData;
        this.JobStatus = this.responseData.status;
        this.applier = this.responseData.applier;
        this.company = this.responseData.company;
        this.job = this.responseData.job;
        this.distance = this.responseData.distance;
        this.isSpinner = false;
        for (let lan of this.job.language) {
          if (lan <= 3) {
            this.germanLanSkill = new LanguageModel().getLanguageSkill(lan);
          } else {
            this.germanLanSkill = 'Keine Kenntnis';
          }
        }
      }, errorMessage => {
        this.isSpinner = false;
        console.log(errorMessage);
      });
  }

  confirmJobApplication() {
    // this.angulartics2Piwik.startTracking();
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        jobId: this.matchId,
        isApply: true,
        isReject: false,
        isRefuse: false,
        jobStatus: this.JobStatus,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result == true){
        _paq.push(['setDocumentTitle', document.title]);
        _paq.push(['trackPageView']);
        _paq.push(['trackEvent', 'Applier', 'Applier match on dashboard']);
        // this.angulartics2.eventTrack.next({
        //   action: 'Applier match on dashboard',
        //   properties: { category: 'Applier' },
        // });
      }
      console.log(`Dialog result: ${result}`);
    });
  }

  rejectJobApplication() {

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        jobId: this.matchId,
        isReject: true,
        isApply: false,
        isRefuse: false,
        jobStatus: this.JobStatus,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.data == "you confirmed") {
        // this.getJob();
        location.reload()
        if(this.isApplicationPage == true){
          this.router.navigate([`/applications`]);
        }else{
          this.router.navigate([`/dashboard`]);
        }
      }
      console.log(`Dialog result: ${result}`);
    });
  }

  refuseJobApplication() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        jobId: this.matchId,
        isReject: false,
        isRefuse: true,
        isApply: false,
        jobStatus: this.JobStatus,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.data == "you confirmed") {
        // this.getJob();
        location.reload()
        if(this.isApplicationPage == true){
          this.router.navigate([`/applications`]);
        }else{
          this.router.navigate([`/dashboard`]);
        }
      }
      console.log(`Dialog result: ${result}`);
    });
  }

  getTours(tours: [string]) {
    let translatedTours = [];
    tours.forEach(tour => {
      if (tour == "local") {
        translatedTours.push('Nahverkehr');
      } else if (tour == "national") {
        translatedTours.push("Fernverkehr (national)");
      } else if (tour == "international") {
        translatedTours.push("Fernverkehr (international)");
      } else {
        translatedTours.push("Not available");
      }
    });
    return translatedTours.join(', ');
  }

  getShifts(shifts: [string]) {
    let translatedShifts = [];
    shifts.forEach(shift => {
      if (shift == "day") {
        translatedShifts.push('Tagesschicht');
      } else if (shift == "night") {
        translatedShifts.push("Nachtschicht");
      } else if (shift == "weekend") {
        translatedShifts.push("Wochenende");
      } else if (shift == "all") {
        translatedShifts.push("Wechselschicht");
      } else {
        translatedShifts.push("Not available");
      }
    });
    return translatedShifts.join(', ');
  }

  getVehicleTypeExperience(vehicleType: [string]) {
    let translatedVehicleType = [];
    vehicleType.forEach(type => {
      if (type == "tractor-trailer") {
        translatedVehicleType.push('Sattelzug');
      } else if (type == "solo-vehicle") {
        translatedVehicleType.push("Solofahrzeug");
      } else if (type == "articulated-train") {
        translatedVehicleType.push("Gliederzug");
      } else if (type == "refrigerated-vehicle") {
        translatedVehicleType.push("Kühlfahrzeug");
      } else if (type == "swap-body") {
        translatedVehicleType.push("Wechselbrücke");
      } else if (type == "tanker") {
        translatedVehicleType.push("Tankfahrzeug");
      } else if (type == "silo") {
        translatedVehicleType.push("Silo");
      } else if (type == "heavy-transport") {
        translatedVehicleType.push("Schwertransporte");
      } else {
        translatedVehicleType.push("Not available");
      }
    });

    return translatedVehicleType.join(', ');
  }

  getAdditionalLicense(additionalLicense: [string]) {
    let translatedAdditionalLicense = [];
    additionalLicense.forEach(license => {
      if (license == "NO_LICENSE") {
        translatedAdditionalLicense.push('Keinen weiteren Führerschein');
      } else if (license == "FORKLIFT") {
        translatedAdditionalLicense.push("Staplerführerschein");
      } else if (license == "CRANE") {
        translatedAdditionalLicense.push("Kranführerschein");
      } else if (license == "ADR_BASIC") {
        translatedAdditionalLicense.push("ADR-Basis");
      } else if (license == "ADR_TANK") {
        translatedAdditionalLicense.push("ADR-Tank");
      } else if (license == "ADR_EXPLOSIVE") {
        translatedAdditionalLicense.push("ADR Explosiv");
      } else if (license == "ADR_RADIOACTIVE") {
        translatedAdditionalLicense.push("ADR-Radioaktiv");
      } else {
        translatedAdditionalLicense.push("Not available");
      }
    });

    return translatedAdditionalLicense.join(', ');
  }

}
